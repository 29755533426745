// @flow
import * as React from 'react';
import DropDownFormField from 'ui/self-signup/components/DropdownFormField';
import { injectIntl } from 'react-intl';

import type { IntlShape } from 'react-intl';

type Props = {
  intl: IntlShape,
  controlId: string,
  control: any,
  register: any,
};

const GenderDropdownFormField = ({
  intl: { formatMessage },
  controlId,
  control,
  register,
}: Props) => {
  const textKeysPrefix = `signupPage.field.${controlId ? controlId : 'gender'}`;
  return (
    <DropDownFormField
      control={control}
      register={register}
      controlId={controlId ? controlId : 'gender'}
      controlLabel={`${textKeysPrefix}.label`}
      options={[
        {
          value: 'M',
          label: formatMessage({
            id: `${textKeysPrefix}.options.male.label`,
          }),
          key: 'genderMale',
        },
        {
          value: 'F',
          label: formatMessage({
            id: `${textKeysPrefix}.options.female.label`,
          }),
          key: 'genderFemale',
        },
        {
          value: 'D',
          label: formatMessage({
            id: `${textKeysPrefix}.options.divers.label`,
          }),
          key: 'genderDivers',
        },
      ]}
    />
  );
};

export default injectIntl(GenderDropdownFormField);
