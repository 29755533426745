import { PaymentApi } from './PaymentApi';
import { SignupPageApi } from './SignupPageApi';
import { UserMembershipsAndOffersApi } from './UserMembershipsAndOffers';
import { GeocodingPlacesApi } from './GeocodingPlacesApi';
import { GymFinderApi } from './GymFinderApi';
import { CompanyApi } from './CompanyApi';

export * from './PaymentApi';
export * from './SignupPageApi';
export * from './UserMembershipsAndOffers';
export * from './GeocodingPlacesApi';
export * from './CompanyApi';

class Api {
  paymentApi = new PaymentApi();
  signupPageApi = new SignupPageApi();
  userMembershipsApi = new UserMembershipsAndOffersApi();
  geocodingPlacesApi = new GeocodingPlacesApi();
  gymFinderApi = new GymFinderApi();
  companyApi = new CompanyApi();
}

const api = new Api();
const useApi = () => {
  return api;
};

export default useApi;
