import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { formatCurrency } from '../../service/currencyUtil';
import { formatISOZonedDateTimeToCETDate } from '../../util/format/dateTimeFormatter';
import type { MinifiedMembershipWrapperType } from '../admin/companies/memberships/types';

type Props = {
  membershipWrapper: MinifiedMembershipWrapperType,
  intl: IntlShape,
};

const CurrentMembership = ({ membershipWrapper, intl: { locale } }: Props) => {
  let activeMembership;
  if (
    (membershipWrapper.membershipOffer.priceAmount ||
      membershipWrapper.membershipOffer.priceAmount === 0) &&
    membershipWrapper.membershipOffer.priceCurrency
  ) {
    activeMembership = (
      <FormattedMessage
        id="myMembershipsPage.activeMembership.vat"
        values={{
          price: formatCurrency(
            locale,
            membershipWrapper.membershipOffer.priceAmount,
            membershipWrapper.membershipOffer.priceCurrency
          ),
        }}
      />
    );
  } else {
    activeMembership = (
      <FormattedMessage id="myMembershipsPage.activeMembership.noPriceInfoAvailable" />
    );
  }
  return (
    <>
      <p>
        <b>
          <FormattedMessage id="myMembershipsPage.activeMembership.monthlyFee" />
        </b>
        {activeMembership}
      </p>

      <p>
        <b>
          <FormattedMessage id="myMembershipsPage.activeMembership.startDate" />
        </b>
        {formatISOZonedDateTimeToCETDate(membershipWrapper.membership.membershipStartTimestamp)}
      </p>
    </>
  );
};
export default injectIntl(CurrentMembership);
