// @flow
import React from 'react';

import searchIcon from 'styles/img/icons/search.svg';
type Props = {
  searchTerm: string,
  placeholder: string,
  setSearchTerm: Function,
  setEffectiveSearchTerm: Function,
};

const SearchField = ({ searchTerm, setSearchTerm, setEffectiveSearchTerm, placeholder }: Props) => {
  return (
    <div className="form-floating d-flex input-group input-group-sm search-field">
      <input
        id="company-membership-search-field"
        type="text"
        value={searchTerm}
        className="form-control form-select-padding-left border-end-0"
        placeholder={placeholder}
        onChange={event => setSearchTerm(event.target.value)}
      />
      <label
        className={`${searchTerm ? 'label-touched ' : ''} label-touched-z-idx`}
        htmlFor="company-membership-search-field"
      >
        {placeholder}
      </label>
      {searchTerm && (
        <button
          className="btn border-start-0 border-focus search-field-button"
          onClick={event => {
            event.preventDefault();
            setSearchTerm('');
          }}
        >
          <i className="text-muted fa fa-times px-1" />
        </button>
      )}
      <button
        className="btn border-start-0 border-focus search-field-button"
        onClick={() => setEffectiveSearchTerm(searchTerm)}
      >
        <img className="search-icon" src={searchIcon} alt="" />
      </button>
    </div>
  );
};

export default SearchField;
