// @flow
import React, { useContext, useEffect } from 'react';
import { locale } from '../../config/i18n/WithI18nSupport';
import WelcomeContext from './WelcomeContext';
import WelcomeMain from './components/WelcomeMain';
import WelcomeUnlimitedOffering from './components/WelcomeUnlimitedOffering';
import GymFinderStandalone from './components/GymFinderStandalone';

type Props = {
  signupPage: any,
  signupPageOffer: any,
  gymsCount: number,
  isPartnerSignup?: boolean,
  countryCode?: string,
  onCountryCodeChangeEvent?: Function,
  showStandalone?: boolean,
};

const Welcome = ({
  signupPage,
  signupPageOffer,
  gymsCount,
  isPartnerSignup,
  countryCode,
  onCountryCodeChangeEvent,
  showStandalone,
}: Props) => {
  const { setGymFinderUrl } = useContext(WelcomeContext);

  useEffect(() => {
    const host = window.GYMFINDER_FRONTEND_URL;
    const localization = signupPage && signupPage.localization;
    const latitude = localization && JSON.parse(localization).latitude;
    const longitude = localization && JSON.parse(localization).longitude;
    const searchTerm = localization && JSON.parse(localization).searchTerm;

    try {
      let url = new URL(host);
      url.pathname = '/embed/web/sign-up/';
      if (localization && latitude && longitude) {
        url.searchParams.append('p', `${latitude}%3B${longitude}`);
      }
      if (localization && searchTerm) {
        url.searchParams.append('l', searchTerm);
      }
      if (isPartnerSignup) {
        url.searchParams.append('membershipIncluded', 'QUALITRAIN_PLUS_ONE');
      }
      url.searchParams.append('lang', locale);
      setGymFinderUrl(url);
    } catch (e) {
      console.log(`Error on construction of URL: `, e);
      console.log(`URL host: `, host);
    }
  }, [setGymFinderUrl, isPartnerSignup, signupPage]);

  const isWelcomeMain = !showStandalone && signupPageOffer.type !== 'FREE';
  const isWelcomeUnlimited = !showStandalone && signupPageOffer.type === 'FREE';

  return (
    <>
      {isWelcomeMain && (
        <WelcomeMain
          signupPage={signupPage}
          signupPageOffer={signupPageOffer}
          gymsCount={gymsCount}
          isPartnerSignup={isPartnerSignup}
          countryCode={countryCode}
          onCountryCodeChangeEvent={onCountryCodeChangeEvent}
        />
      )}
      {isWelcomeUnlimited && (
        <WelcomeUnlimitedOffering signupPage={signupPage} isPartnerSignup={isPartnerSignup} />
      )}
      {showStandalone && <GymFinderStandalone />}
    </>
  );
};

export default Welcome;
