import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Modal from 'ui/common/modal/Modal';

const PaymentDetailsErrorModal = ({ isOpen, onRequestClose, intl: { formatMessage } }: Props) => {
  const modalTitle = formatMessage({ id: 'signupPage.paymentDetailsModal.title' });
  return (
    <Modal
      isOpen={isOpen}
      closable={false}
      closeButtonId=""
      modalTitle={modalTitle}
      modalClass="payment-details-error-modal"
      modalSizeClass="modal-md"
    >
      <div>
        <span>
          <FormattedMessage id="signupPage.paymentDetailsModal.body" />{' '}
        </span>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-secondary text-uppercase"
            onClick={onRequestClose}
          >
            <FormattedMessage id="signupPage.paymentDetailsModal.button">
              {txt => txt}
            </FormattedMessage>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(PaymentDetailsErrorModal);
