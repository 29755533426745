import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const DataSharingOptInDropdown = ({ value, onChange, disabled }) => {
  const { formatMessage } = useIntl();

  const options = [
    {
      key: 'label',
      value: '',
      label: formatMessage({
        id: 'accountSettingsPage.privacy.subtitle',
      }),
      hidden: true,
    },
    {
      key: 'empty-option',
      value: '',
      label: formatMessage({
        id: 'accountSettingsPage.privacy.options.empty',
      }),
    },
    {
      key: 'accepted',
      value: 'ACCEPTED',
      label: formatMessage({
        id: 'accountSettingsPage.privacy.options.employer',
      }),
    },
    {
      key: 'revoked',
      value: 'REVOKED',
      label: formatMessage({
        id: 'accountSettingsPage.privacy.options.nobody',
      }),
    },
  ];

  return (
    <div className={`${value ? 'form-floating' : ''}`}>
      <select
        id="data-sharing-dropdown-id"
        className="form-select form-select-padding-left"
        aria-describedby="HelpTextAfter"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map(({ value, key, label, hidden }) => (
          <option key={key} value={value} disabled={value === ''} hidden={hidden}>
            {label}
          </option>
        ))}
      </select>
      {value && (
        <label htmlFor="data-sharing-dropdown-id">
          <FormattedMessage id="accountSettingsPage.privacy.subtitle" />
        </label>
      )}
    </div>
  );
};

DataSharingOptInDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.string,
};

export default DataSharingOptInDropdown;
