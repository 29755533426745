// @flow
import React from 'react';
import { ReactComponent as ArrowIconSvg } from 'styles/img/icons/arrow_down.svg';
import type { DropdownItem } from './types';
import { useIntl } from 'react-intl';

type Props = {
  items: DropdownItem[],
  onChange?: Function,
  selectedItems?: string[],
  placeholder?: string,
  className?: string,
  fillWidth?: boolean,
  dropdownId?: string,
};

const Dropdown = ({
  items,
  onChange,
  selectedItems,
  placeholder,
  className,
  fillWidth = false,
  dropdownId = '',
}: Props) => {
  const { formatMessage } = useIntl();

  const onClick = item => {
    onChange && onChange(item);
  };

  const filterSelectedValue = selectedValue => {
    return items.find(item => item.value === selectedValue);
  };

  return (
    <div
      className={`dropdown justify-content-center ${className ? className : ''} ${
        fillWidth ? 'w-100' : ''
      }`}
    >
      <button
        id={dropdownId}
        className={`btn btn-default-outline dropdown-toggle bg-white ${fillWidth ? 'w-100' : ''}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="true"
        disabled={!items.length}
      >
        {selectedItems.length === 0
          ? placeholder
          : selectedItems.length === 1
          ? filterSelectedValue(selectedItems[0])?.label
          : formatMessage({
              id: 'membershipsManagement.filterMemberships.multipleSelection',
            })}
        <ArrowIconSvg className="arrow-icon" />
      </button>
      <div className={`dropdown-menu ${fillWidth ? 'w-100' : ''}`}>
        {items.map((item, index) => (
          <div key={item.value}>
            <label key={item.value} className="checkbox-item px-3">
              <input
                checked={selectedItems.includes(item.value) ? true : false}
                onChange={() => onClick(item.value)}
                id={dropdownId ? `${dropdownId}.${item.value}` : ''}
                type="checkbox"
                className={'form-check-input me-2'}
              />
              {item.label}
            </label>
            {index < items.length - 1 && <div className="dropdown-divider" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
