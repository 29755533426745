import type { MembershipOffer } from '../ui/self-signup/types';

/**
 * Returns the active offer with the most recent available from
 */
export const getActiveOffer = (signupPageOffers: MembershipOffer[]) => {
  if (signupPageOffers) {
    signupPageOffers.sort(function (a, b) {
      return b.id - a.id;
    });
    return signupPageOffers[0];
  }
};
