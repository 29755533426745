const messages = {
  welcome: {
    intro: {
      title: 'One membership.\nCountless opportunities.',
      descriptionHtmlWithPrice:
        'For <b>only {price}</b> per month, unlimited swimming, fitness, climbing, yoga and more.\n' +
        'An exclusive offer for you provided by {name}.',
      descriptionHtmlWithoutPrice:
        'Signup now to get access to unlimited swimming, fitness, climbing, yoga and more.\n' +
        'An exclusive offer for you provided by {name}.',
      plusOneDescriptionHtmlWithPrice:
        'For <b>only {price}</b> per month, unlimited swimming, fitness, climbing, yoga and more.\n' +
        'An exclusive offer for you as an invited Plus1 by one of our Wellpass members.',
      plusOneDescriptionHtmlWithoutPrice:
        'Signup now to get access to unlimited swimming, fitness, climbing, yoga and more.\n' +
        'An exclusive offer for you as an invited Plus1 by one of our Wellpass members.',

      button: 'Sign up now',
      customOfferHeader: 'Special Offer',
      viewStudio: 'View studios',
    },
    wellpassInfo: {
      beActive: {
        title: 'Be Active',
        description: 'Unlimited number of check-ins',
      },
      enjoyVariety: {
        title: 'Enjoy Variety',
        description: '{number} studios in our partner network',
      },
      exclusiveOffer: {
        title: 'Exclusive Offer',
        description: 'only {price} monthly, exclusively for {name}',
        partnerDescription: '{price} monthly, only for Plus1 members',
      },
      workoutEverywhere: {
        title: 'Workout Everywhere',
        description: '+4000 online classes for fitness and mindfulness',
      },
      flexibility: { title: 'Stay Flexible', description: 'Monthly cancellable membership' },
    },
    studios: {
      moreThanStudioTitle: 'More than just a classic fitness studio',
      fitness: 'Fitness\nstudios',
      pool: 'Swimming\npools',
      boulder: 'Boulder\nhalls',
      yoga: 'Yoga\nstudios',
      description:
        "Due to the variety of sports facilities and courses Wellpass covers the individual sporting needs and fitness levels of all participants. Whether fitness, swimming, yoga, climbing or bouldering. You decide what is right for you. If your dream studio is not yet there, just contact us and we'll take care of it.",
      search: 'To Studio-Search',
    },
    gymMap: {
      title: 'Sport & fitness locations next to you',
    },
    memberships: {
      title: 'Ready for your Wellpass membership?',
    },
    card: {
      button: 'Sign up now',
      benefitsTitle: 'Benefits',
      partnerOfferCard: {
        headline: 'Plus1 membership - <span>Workout anywhere as often as you want</span>',
        type: 'Plus1',
        typeTitle: 'Membership',
        price: 'only <span>{price}</span> /month',
        benefits:
          '<span class="offer-card-benefit-title">Exclusively for invited Plus1 members</span>Through the invitation by one of our members, now you can also become a Wellpass member, if you have either the same last name or primary residence as your referral.\n' +
          '<span class="offer-card-benefit-title">Visit all Plus1 locations</span>All locations that are participating can be found in the gym finder.\n' +
          '<span class="offer-card-benefit-title">Unlimited Check-ins</span>\n' +
          '<span class="offer-card-benefit-title">Wellpass App</span>Check-in feature, history and online offering can be found in our app.\n' +
          '<span class="offer-card-benefit-title">Online classes and meditation offering</span>\n' +
          '<span class="offer-card-benefit-title">Personal induction training for normally €44</span>To be paid to the gym directly.\n' +
          '<span class="offer-card-benefit-title">Cancellable monthly</span>',
      },
    },
    faq: {
      title: 'Frequently Asked Questions',
      items: {
        howMembershipWorks: {
          question: 'How does the Wellpass membership work?',
          answer:
            '<p>With the EGYM Wellpass membership, you can access all {gymCount} sports and wellness facilities, use our online offering and get motivated by fitness challenges. EGYM Wellpass is offered as an employee benefit by companies who want to promote the health and fitness of their employees. Sign up on this webpage, download the Wellpass App and do something good for your health. There is no minimum duration and your Wellpass membership can be cancelled monthly before the 15th then ending at the end of the month if your needs change.</p>',
        },
        availableStudios: {
          question: 'Which studios may I visit?',
          answer:
            '<p>You can visit all studios that have entered into a partnership with us. Your Wellpass membership does not commit you to one studio: if you want, you can go to a gym today, go swimming tomorrow and relax with a massage on the weekend. You can find all these studios in our <anchor>studio search</anchor> on this webpage or in our app.</p>' +
            '<p>If you prefer to train from home, you can also use our free <link>digital offerings</link>. Click on "Online offers" in our app and join a yoga session, for example. In addition, you will get access to premium plans of our partner programs 7mind, Fitnessraum or Lifesum.</p>',
        },
        howCheckInWorks: {
          question: 'How does the check-in work in the partner studios?',
          answer:
            '<p>To access the partner studios, you will need to check in via the Wellpass App on your smartphone. Simply scan the QR code displayed at the gym entrance and show the confirmation screen to the gym staff. Some studios additionally offer alternative ways to access the studio which is described on the detail pages in the <anchor>studio search</anchor>, e.g. by providing their gym ID card to you.</p>',
        },
        additionalCosts: {
          question: 'Are there any additional costs?',
          answer:
            '<p>Gyms may charge an induction fee of normally €44, in order to ensure that fitness equipment is adjusted to your needs and that you achieve your goals with the help of a training plan. Such an induction training is usually much more expensive with a regular gym membership. Usually, you only have to pay this fee once during your first visit to a gym with Wellpass.</p>' +
            '<p>In about 98% of the studios, all services are covered by your monthly membership fee. In some high-priced studios, however, you may have to pay a small additional fee. This is the case, for example, with massage or EMS studios. To find out which services are subject to a co-payment, click on the studio of your choice. Under the studio description (Wellpass conditions) you will find out which services you can use free of charge (see Included services) and which services are subject to a co-payment (see Co-payment).</p>',
        },
        howSignupWorks: {
          question: 'How does the sign up work?',
          answer:
            '<p>After you have clicked on the button "Sign up now" on this page, we will ask you to create an EGYM ID. We will need your full name, date of birth, email address and a password. {isB2C, select, true {To deduct the membership fee via a SEPA direct debit, we will additionally ask you to enter your payment details.} other {}} Afterwards you can download our Wellpass App from the Apple or Google Play Store and login with your email address and your password, that you set during the registration process.</p>' +
            '<p>Before your membership starts, your eligibility will usually be checked by your HR department. You will get a welcome email shortly before the start of your membership, summarising the most important information for an ideal,smooth start with Wellpass. Your HR department will only see your name, date of birth and membership start date.</p>',
        },
        howPlusOneWorks: {
          question: 'Can other people who do not work in my company sign up for Wellpass?',
          answer:
            '<p>You can invite another person outside your company to use Wellpass for €49.90 per month. We call this other person Plus1. To be eligible, this person must have either the same last name or main residence as you.</p>' +
            '<p>Just like you, your Plus1 can then train an unlimited number of times in our Wellpass network. Over 90% of our studios offer access to Plus1 members. To find out which studios these are, you can activate the Plus1 filter in the studio search. The Plus1 membership can also be cancelled monthly by the 15th at the end of the month. If you would like to invite a person as your Plus1, you can simply send an online invitation via our app after you have signed up.</p>',
        },
      },
    },
    welcomeFree: {
      introSection: {
        headline: 'Free workout videos <br></br> and challenges',
        text: 'An exclusive offer for you provided by {name}',
        ctaButton: 'Sign up',
      },
      offers: {
        unlimitedCheckIns: 'Unlimited Studio Check-ins',
        partnerApps: 'Partner Apps (Lifesum, 7mind, …)',
        liveOnlineCourses: 'Live Online Courses',
        onlinePrevention: 'Online Prevention and Workout Classes',
        challenges: 'Challenges',
        free: {
          badge: 'FREE',
          title: 'Basic Online Offering',
          perMonth: '/month',
        },
        recommended: {
          badge: 'RECOMMENDED',
          title: 'Unlimited Offering',
          subtitle: 'For a monthly fee',
          description:
            'Discover your employer’s exclusive price by <button>going back</button> and selecting "Unlimited Offer".',
        },
      },
      sportSearch: {
        title: 'Sport & wellness locations next to you',
        body: 'The Basic Online Offering is not enough for your fitness needs? Explore our partner network available only with our Unlimited Offering.',
        infoAlertTitle:
          'Check-ins at the partner studios are only available with our Unlimited Offering',
        infoAlert:
          'To know more, <button>go back</button> to the offering selection page and select the Unlimited Offering.',
      },
    },
  },
  multiEntitySignupPage: {
    headline: 'Welcome to EGYM Wellpass',
    dropdownText: 'Select your {label}',
    continueButton: 'Proceed',
    radioButtonSelect: {
      selectHeadline: 'Please select',
      free: 'Basic Online Offering',
      standard: 'Unlimited Offering ',
      tooltip:
        'Includes digital workout and prevention classes. Does not include access to the studio partner network.',
    },
  },
  signupPage: {
    headerTitle: 'Wellpass sign-up',
    corpControlEnabledEligibilityInfo:
      '<strong>To check your eligibility, we compare your personal data with the data in your HR system.</strong>' +
      '<br></br>Please make sure that your name, surname and birthdate are spelled the same way as you provided them to your employer.',
    corpControlDisabledEligibilityInfo:
      '<strong>Your personal information will be shared with your HR department.</strong>' +
      '<br></br>Please make sure that your name, surname and birthdate are spelled the same way as you provided them to your employer.',
    matchButNotEligible: {
      title: 'We can’t proceed with the sign up right now',
      message:
        'According to the rules for this signup page, you are not eligible for a Wellpass membership. If you have any questions, please reach out to your HR department.',
      alert:
        'If your company has multiple subsidiaries, make sure you are signing up on the correct sign up page for your subsidiary.',
      close: 'Close',
    },
    multipleMatches: {
      title: 'We could not find a match in the HR system yet.',
      message:
        'We still could not find a match in the HR system of your company. If you think there has been a mistake, contact us at info@egym-wellpass.com',
      alert:
        'If your company has multiple subsidiaries, make sure you are signing up on the correct sign up page for your subsidiary.',
      close: 'Close',
    },
    noExactMatch: {
      title: 'We could not verify your eligibility',
      message:
        'We still could not find a match in the HR system of your company. If you think there has been a mistake, contact us at info@egym-wellpass.com',
      alert:
        'If your company has multiple subsidiaries, make sure you are signing up on the correct sign up page for your subsidiary.',
      close: 'Okay',
    },
    matchButAlreadyRegistered: {
      title: 'Looks like you already have an EGYM ID',
      message:
        'Please login using your EGYM ID email address <strong>{maskEmail}</strong>. <br></br>If you no longer have access to this email address please reach out to us via info@egym-wellpass.com',
      alert: '',
      close: 'Login',
    },
    step1: {
      title: 'Personal information',
      titleAccount: 'Login details',
      welcomeBack: 'Welcome back',
      subTitle: 'I’m new here',
      nextbutton: 'Register & proceed',
      sharedInfo:
        'Your name and birthdate will be shared with your <strong>HR department</strong>.',
    },
    step2: {
      title: 'Make sure your personal information is correct and complete',
      nextbutton: 'Save & proceed',
    },
    step3: {
      title: 'Your membership start',
      additional: 'Additional Information',
      preCountrySelectionText: 'You selected that you will use the Wellpass membership mainly in',
      postCountrySelectionText: ' ',
      noAvailableStartDateWarning:
        "Currently you can't book a membership as it must overlap with your period of employment. If you have any questions, please contact your HR department.",
      warnAboutOnlineOffering:
        '<b>You are signing up for our “Basic Online Offering” membership:</b> <ul><li><done></done> You can access our digital Prevention Classes</li> <li><done></done> You can access our on-demand Featured Class Videos</li> <li><b><close></close> You cannot visit our large partner network with {gymCount} studios</b></li> </ul> If you’d like to change your selection, <button>please go</button> back to the first page to choose the Unlimited Offering.',
    },
    field: {
      zipCode: {
        label: 'ZIP code',
        placeholder: 'ZIP Code',
      },
      city: {
        label: 'City',
        placeholder: 'City',
      },
      iban: {
        label: 'IBAN',
        placeholder: 'IBAN',
      },
      email: {
        label: 'Personal Email',
        placeholder: 'Personal Email',
        helpTextAfter:
          'We send important information via email before your membership starts. This email address will not be shared with {name}.',
        plusOneHelpTextAfter:
          'We will send an email to this email address to confirm your account. Please make sure you have unlimited access to this email account.',
      },
      gender: {
        label: 'Title',
        options: {
          empty: {
            label: 'Please select',
          },
          male: {
            label: 'Mr.',
          },
          female: {
            label: 'Ms.',
          },
          divers: {
            label: 'Mx.',
          },
        },
      },
      street: {
        label: 'Street',
        placeholder: 'Street',
      },
      consent: {
        label: 'Consent',
        helpText:
          'I hereby assure that all statements standing above are correct and I’m allowed to sign up for Wellpass. Your membership is automatically renewed monthly and can be cancelled for the end of the current month any time before the 16th {cancellationUrl}. The general {termsAndConditionsUrl} of EGYM Wellpass GmbH apply. Details on the processing of your personal data can be found in our {privacyPolicyUrl}.',
        acceptTextPart:
          'I agree to the monthly payment of the membership fee via SEPA direct debit.',
        termsAndConditionsUrl:
          'https://egym-wellpass.com/agb-mitgliedschaft/?utm_source=referral&utm_medium=signup-page',
        termsAndConditionsText: 'Terms and Conditions',
        privacyPolicyUrl:
          'https://egym-wellpass.com/datenschutz/?utm_source=referral&utm_medium=signup-page',
        privacyPolicyText: 'Privacy Policy',
        cancellationUrl: 'https://egym-wellpass.com/kuendigung',
        cancellationText: 'here',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Last name',
      },
      birthday: {
        label: 'Birthdate dd.mm.yyyy',
        placeholder: 'Birthdate dd.mm.yyyy',
      },
      workEmail: {
        label: 'Work email',
        placeholder: 'Work email',
      },
      personalEmail: {
        label: 'Personal email',
        placeholder: 'Personal email',
        helpTextAfter: 'Please insert the personal email that you have shared with your employer.',
      },
      employeeNumber: {
        label: 'Employee Number',
        placeholder: 'Employee Number',
        helpTextAfter: '{employeeNumberDescription}',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
      firstName: {
        label: 'First name',
        placeholder: 'First name',
      },
      houseNumber: {
        label: 'House number',
        placeholder: 'House number',
      },
      repeatPassword: {
        label: 'Repeat password',
        placeholder: 'Repeat password',
      },
      paymentDetails: {
        title: 'Payment details',
        paymentAddress:
          'EGYM Wellpass GmbH, Einsteinstr. 172, 81677 Munich\n' +
          'Creditor-ID:  DE43ZZZ00000585851\n',
        sepaPayment: ' SEPA Direct Debit Mandate for recurring payments:\n',
        paymentPermission:
          'I hereby authorize EGYM Wellpass GmbH to collect the payments payable by me from my account when due by means of a direct debit from my account. Furthermore I instruct my bank to honour account debit requests from EGYM Wellpass GmbH. \n',
        refundClaim:
          'As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. \n',
        part2WithPrice:
          'Work out in the whole Wellpass network with {gymCount} sports facilities for just {price} monthly. An exclusive offer for you provided by {name}. When using Wellpass for the first time you may receive a personal briefing in the partner gym of your choice for which a non-recurring fee of usually {briefingFee} will be charged, which has to be paid directly to the gym. This briefing is intended to make your start easier and help you achieve your training goals. \n\n' +
          '<b>You already have a membership in one of our partner gyms?</b> In this case the fee for the personal briefing can be waived upon discussion with the gym.  You are also able to pause your current membership for the duration of your Wellpass membership. Please contact your gym as early as possible to pause your membership.\n',
        part2WithoutPrice:
          ' Work out in the whole Wellpass network with {gymCount} sports facilities. An exclusive offer for you provided by {name}. When using Wellpass for the first time you may receive a personal briefing in the partner gym of your choice for which a non-recurring fee of usually {briefingFee} will be charged, which has to be paid directly to the gym. This briefing is intended to make your start easier and help you achieve your training goals. \n\n' +
          '<b>You already have a membership in one of our partner gyms?</b> In this case the fee for the personal briefing can be waived upon discussion with the gym.  You are also able to pause your current membership for the duration of your Wellpass membership. Please contact your gym as early as possible to pause your membership.\n',
        plusOnePart2WithPrice:
          ' Work out in the whole Wellpass network with {gymCount} sports facilities for just {price} monthly. When using Wellpass for the first time you may receive a personal briefing in the partner gym of your choice for which a non-recurring fee of usually {briefingFee} will be charged, which has to be paid directly to the gym. This briefing is intended to make your start easier and help you achieve your training goals.\n',
        plusOneSpecialCondition:
          '<b>Special conditions for Plus1 members:</b> \n ' +
          '<ul>' +
          '<li>Either <b>your last name or your primary residence must match with the data provided by your referral.</b> If the participation requirements are not met, we will terminate your membership without further notice. When we ask you and the inviting member for proof of identity, this must be provided within 5 working days.</li>' +
          '<li>Some studios in our network can only be visited with a corporate fitness membership and not with a Plus1 membership. <b>Excluded studios</b> are marked on the gym finder.</li>' +
          '<li>You also <b>can not pause your private gym membership</b> for as long as you have a Wellpass membership.</li>' +
          '<li>Your <b>Plus1 membership is linked</b> with the membership of the Wellpass member that invited you. If this Wellpass member ends their membership, your membership will end too.</li>' +
          '</ul>',
        plusOnePart2WithoutPrice:
          'Work out in the whole Wellpass network with {gymCount} sports facilities. When using Wellpass for the first time you may receive a personal briefing in the partner gym of your choice for which a non-recurring fee of usually {briefingFee} will be charged, which has to be paid directly to the gym. This briefing is intended to make your start easier and help you achieve your training goals.\n\n',
        part2GymCountPlaceHolder: 'more than 3.000',
        part3: {
          b2c: ' Please carefully review your payment details. The monthly price of {price} will be deducted from that account.',
          nonB2cWithPrice:
            'The monthly EGYM Wellpass membership fee of {price} will be deducted directly from your salary by your employer.',
          nonB2cWithoutPrice:
            'The monthly EGYM Wellpass membership fee will be deducted directly from your salary by your employer.',
        },
      },
      bankAccountHolderName: {
        label: 'Bank account holder',
        placeholder: 'Bank Account Holder',
      },
      employeeIdentifier: {
        label: 'Personal number',
        placeholder: 'Personal Number',
      },
      membershipStartDate: {
        label: 'Start your membership from',
        warningLabel: 'No available start date',
        helpTextAfter:
          'You can still sign up until {signupDeadline} to start your membership at {earliestStartDate}.',
        options: {
          empty: {
            label: 'Choose...',
          },
        },
      },
      reviewedGender: {
        label: 'Title',
        options: {
          empty: {
            label: 'Please select',
          },
          male: {
            label: 'Mr.',
          },
          female: {
            label: 'Ms.',
          },
          divers: {
            label: 'Mx.',
          },
        },
      },
      reviewedFirstName: {
        label: 'First name',
        placeholder: 'First name',
      },
      reviewedLastName: {
        label: 'Last name',
        placeholder: 'Last name',
      },
      reviewedBirthday: {
        label: 'Birthdate dd.mm.yyyy',
        placeholder: 'Birthdate dd.mm.yyyy',
      },
    },
    paymentDetailsModal: {
      title: 'Invalid payment information',
      body: 'Your EGYM ID was successfully created, but we could not sign you up for a Wellpass membership. Please sign up by logging in with the email and password you just set and enter different payment information.',
      button: 'Login',
    },
  },
  signupSuccessPage: {
    title: 'Ready to workout, {firstName}?',
    successAlert: 'Your sign up for a Wellpass membership was successful',
    infoAlert:
      'Remember to verify your email address by clicking the link we just sent you. If you spot any typos in your email, please contact us at <a>info@egym-wellpass.com.</a>',
    overViewCard: {
      title: 'Quick overview',
      membership: 'Your membership start date',
      egymId: 'Your EGYM ID',
    },
    steps: {
      headline: 'Next steps',
      step1: 'Download the Wellpass app',
      step2: 'Find your favourite studios with our Gym Search',
      step3: 'Check-in at a partner studio',
      step4: 'Repeat 🔁',
    },
    feesInfo:
      'Some gyms may charge a one time fee of usually 44€ for your first induction training, unless your employer informed you otherwise.',
    qrCode: 'Scan to download the Wellpass app →',
    button: 'Download the app',
  },
  signupErrorPage: {
    invalidCompanyToken: {
      title: 'Something went wrong',
      description:
        'Please contact the HR team of your company in order to get a valid link to the Wellpass signup page',
    },
  },
  passwordResetPage: {
    headerTitle: 'Forgot your password?',
    resetPasswordBtn: 'Reset password',
    body1:
      'Don’t worry! Just enter your email address and we’ll send you instructions for resetting your password.',
    body2:
      'If you are not yet a Wellpass user, but you already have an EGYM account, please reset your password via the <a>EGYM password reset functionality.</a>',
    receivedEmailSuccessHeader: "Thank you! We've just sent you a password reset email.",
    receivedEmailSuccessBody:
      'You should receive an email with a password reset link in the next few minutes. Please refresh your email inbox and also check your spam folder.',
    noEmailSuccessHeader: "Didn't receive an email?",
    noEmailSuccessBody:
      "If you never had a Wellpass membership with this email address, we can't send you a password reset email here. Please use the {egymPasswordReset}  instead. You can then log in with your EGYM ID to subscribe to Wellpass. If this does not work for you, please contact our support team via {wellpassSupportMail}",
    egymPasswordReset: 'EGYM password reset functionality',
  },
  newPasswordPage: {
    headerTitle: 'Set your password',
    resetPasswordBtn: 'Save new password',
    clickHere: 'Click here',
    clickHereTo: ' to request a new password-reset email.',
    passwordRules: 'Your password must be at least 8 characters long.',
    aboveBody: 'Please set your new EGYM ID password',
    successPage: {
      headerTitle: 'Thank you',
      title: 'Your new password was saved successfully.',
      text: 'You can now login with your email address and your new password.',
    },
    errorPage: {
      title: 'Something went wrong',
      linkToPasswordResetPage: 'Set new password',
    },
  },
  errorPage: {
    title: 'We did not find the page you are looking for',
    invalidLinkTitle: 'Your registration link does not work?',
    invalidLinkTip:
      'Please double-check the registration link and open it with another browser or request the link once again from your companies’ Wellpass contact person.',
    alreadyMemberTitle: 'Are you already a Wellpass member?',
    alreadyMemberTip: 'Use this link to log into your account: ',
    alreadyMemberLink: 'https://app.egym-wellpass.com/login',
    callToActionTitle: 'What is Wellpass?',
    callToActionTip:
      'Wellpass offers you unlimited swimming, fitness, climbing, yoga and more. An exclusive offer for you from your employer. Everything you need to know about Wellpass can be found on our website: ',
    callToActionLink: 'https://egym-wellpass.com/',
    helpTitle: 'You have a different question?',
    helpTip: 'Check our help center for further information and our contact form: ',
    helpLink: 'https://egym-wellpass.com/faq/',
  },
  colleagueSignupErrorPage: {
    title: 'We are happy that you would like to sign up for EGYM Wellpass!',

    part1:
      'As the administration of Wellpass memberships is done exclusively by your employer, we can’t provide you with the sign up page link for the Wellpass membership to you directly.',

    part2:
      'To sign up for a Wellpass membership, please contact the responsible person in your company directly. You will usually find this person in your HR department.',
  },
  partnerSignUp: {
    generalError:
      'Oops, your link seems to have expired or is invalid. Please ask the Wellpass member which invited you, to send you the invitation link again.',
  },
  loginPage: {
    headerTitle: 'Login',
    title: 'Log in',
    field: {
      username: {
        label: 'Email Address',
        placeholder: 'Email Address',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
    },
    loginButton: 'Log in',
    forgotPassword: 'Forgot your password?',
    welcome: {
      headerTitle: 'Welcome',
      title: 'Account overview',
      tile: {
        memberManagementHTML: 'Membership management',
        magazineHTML: 'Magazin',
        paymentDetailsHTML: 'My payment data',
        marketingMaterialHTML: 'Marketing materials',
        accountSettingsHTML: 'My account settings',
      },
    },
  },
  loginNoEmailVerification: {
    headerTitle: 'Welcome',
    title: 'Email verification required!',
    welcomeText: 'Welcome {firstName} {lastName}',
    description: 'Please verify your Email by clicking on the link on the email we’ve just sent.',
  },
  loginNotAuthorized: {
    headerTitle: 'Access denied',
    title: '403 Forbidden',
    description: 'You are not allowed to view this page.',
    linkToProfilePage: 'Go to your profile',
  },
  general: {
    field: {
      email: {
        label: 'Email',
        placeholder: 'Email',
        helper: 'This will be the new {egymId} of this user.',
      },
      newPassword: {
        label: 'New password',
        placeholder: 'New password',
      },
      repeatPassword: {
        label: 'Repeat password',
        placeholder: 'Repeat password',
      },
      firstName: {
        label: 'First name',
        placeholder: 'First name',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Last name',
      },
    },
    button: {
      next: 'Next',
      continue: 'Continue',
      previous: 'Back',
      submit: 'Submit',
      confirm: 'Confirm',
      renderLogin: 'Login',
      loginProceed: 'Login & proceed',
      renderRegister: 'Register',
    },
    required: '* Required',
    toTop: 'to the top',
    wellpassSupportMail: 'support@egym-wellpass.com',
    wellpassInfoMail: 'info@egym-wellpass.com',
  },
  validation: {
    general: {
      requiredField: '{fieldLabel} is required',
      invalid: '{fieldLabel} is not valid',
      minLength: 'The minimum length of {fieldLabel} should be {0} characters',
      maxLength: 'The maximum length of {fieldLabel} should be {0} characters',
      datePast: '{fieldLabel} must be in the past',
      dateAfterMinimal: 'Please enter a valid date of birth.',
      dateFuture: '{fieldLabel} must be in the future',
      invalidEmail: 'Invalid email address',
      invalidDate: 'Invalid date. The format should be: dd.mm.yyyy',
      invalidRegexMatch:
        'Your entry is invalid. Please check the format of your entered data or contact your HR department.',
      invalidRegex:
        'There is an issue on our side and we couldn\'t save your membership booking. Please contact us immediately via info@egym-wellpass.com and report this problem "Error in validation rule".',
      invalidSize: '{fieldLabel} must have between {1} and {0} characters',
      invalidName:
        'Please enter your full name. Numbers and special characters are not allowed and it must be at least two letters long.',
      serverError: 'Unexpected server error. Please try again in a few minutes.',
      requestError: 'Bad Request. Please check your data.',
      token: {
        notFound: 'Please reset your password again. The link you used is unfortunately invalid.',
        timeout:
          'The link you used is no longer valid. Please request a new email to set your new password.',
      },
      invalidNameOfUser:
        'Please enter the full name. Numbers and special characters are not allowed and it must be at least two letters long.',
      genericError: 'Oops, something went wrong. Please try again later.',
    },
    signup: {
      general: {
        userAlreadyExists:
          'User with email address: {0} already exists. <loginstep>Please log in.</loginstep>',
        serverError:
          "Oops, something went wrong. There is an issue on our side. Please try again a little bit later. In case it then still doesn't work, please contact us via info@egym-wellpass.com.",
      },
      duplicateSignup: {
        title: 'Have you signed up with us recently? If so, do not continue here.',
        infoText:
          "To correct an error in your data (e.g. a typo in your email address) please contact our support team via {wellpassSupportMail}, they're happy to help you. If you sign up again you will create a second membership, and we will need to charge you twice.",
      },
      email: {
        invalid: 'Invalid email address',
      },
      iban: {
        invalid: 'Invalid IBAN',
      },
      password: {
        invalid: 'Invalid password (the password must contain between 8 to 14 characters)',
      },
      repeatPassword: {
        notMatch: 'The value of the Repeat Password does not match',
      },
      companyToken: {
        invalid:
          'Sign up is not allowed. Please contact the Wellpass contact person of your company. For more information about Wellpass please visit egym-wellpass.com',
        expired:
          'Sign up token is expired. Please contact the Wellpass contact person of your company. For more information about Wellpass please visit egym-wellpass.com',
      },
      birthday: {
        underMinimumSignupAge:
          'You must be over 13 years old to sign up for a Wellpass membership.',
      },
      address: {
        invalid: 'Please provide your full address (zip code, city, street, street No.)',
      },
    },
    user: {
      membership: {
        overlappingMemberships:
          'The selected membership start date overlaps with another Wellpass membership assigned to your account. Please select a different start date.',
      },
    },
    login: {
      invalidUsernameOrPassword: 'Invalid Username or Password',
    },
  },
  doubleOptInSuccessPage: {
    headerTitle: 'Welcome to Wellpass',
    title: 'Thank you, your email address has been verified.',
    body: 'This email address is your EGYM ID. You can use it to log in to the Wellpass app and to all other EGYM products.',
    downloadAppBtn: 'Download the app',
  },
  doubleOptInFailurePage: {
    headerTitle: 'Something went wrong',
    title: 'Unfortunately we could not verify your registration',
    description:
      'Unfortunately something went wrong. We could not verify your registration. Please try it again at a later point in time. In case the problem persists, please contact our support:',
    contactSupportBtn: 'Contact support',
  },
  companyMemberships: {
    headerTitle: 'Membership management',
    title: 'Your Wellpass Memberships',
    companyDropdownLabel: 'Company',
    filter: {
      allMembers: 'All Memberships',
      activeMembers: 'Active Memberships',
      newRegistrations: 'Starting Soon Memberships',
      endedMemberships: 'Ending Soon Memberships',
      status: {
        label: 'Filter by status :',
      },
    },
    searchField: {
      placeholder: "Search by member's name",
    },
    tableHeadline: {
      memberships: 'Members',
      additionalInformation: 'Additional information',
      employeeNumber: {
        title: 'Employee Number',
        tooltip: 'This data point was synched from your HR-System.',
      },
      plan: 'Plan',
      startDate: 'Start date',
      endOfContract: 'End of contract',
      status: 'Status',
    },
    memberInfo: {
      dateOfBirth: 'Date of birth',
      status: {
        active: 'Active',
        startingSoon: 'Starting soon',
        endingSoon: 'Ending soon',
        inactive: 'Inactive',
      },
      endMembershipButton: 'End membership',
      name: {
        notAvailable: 'deleted / not available',
      },
    },
    membershipOffer: {
      type: {
        standard: 'Standard',
        free: 'Free',
      },
    },
    button: {
      exportRecords: 'Export records as CSV',
    },
    exportCsvHeaders: {
      lastName: 'Last Name',
      firstName: 'First Name',
      dateOfBirth: 'Date of Birth',
      additionalInformationLabel: 'Additional information (label)',
      additionalInformationValue: 'Additional information (value)',
      employeeNumber: 'Employee Number',
      startDate: 'Start Date',
      endDate: 'End Date',
      cancellationReason: 'Cancellation Reason',
      status: 'Status',
      plan: 'Plan',
    },
    file: {
      membershipCsv: 'memberships.csv',
    },
    emptySet: 'No results found. Please check if any filters might be active.',
    membershipsNote: {
      headline: 'Important note',
      body: "According to German law we can't provide data about memberships that have ended before {year}. Please make yourself familiar with internal needs for documentation and if required save an overview of active Wellpass membership regularly.",
    },
    endMembershipSuccessfulNote:
      'The change was successfully saved. The member has been informed via email.',
  },
  header: {
    welcomeUser: 'Welcome {userName}',
    signOut: 'Sign out',
  },
  footer: {
    logo: {
      url: 'https://egym-wellpass.com',
    },
    title: 'Wellpass',
    links: {
      title: 'Legal links',
      imprint: {
        text: 'Imprint',
        url: 'https://egym-wellpass.com/impressum/',
      },
      privacyPolicy: {
        text: 'Privacy policy',
        url: 'https://egym-wellpass.com/datenschutz/',
      },
      termsAndConditions: {
        text: 'Terms and conditions',
        url: 'https://egym-wellpass.com/agb-mitgliedschaft/',
      },
      cookiePolicy: {
        text: 'Cookies',
        url: 'https://egym-wellpass.com/cookie-richtlinie/',
      },
    },
    priceVatIncluded: 'All prices include the statutory value added tax.',
    copyright: {
      text: '@{year} EGYM Wellpass GmbH',
      link: {
        text: 'An EGYM group company',
        url: 'https://egym.de/en',
      },
    },
  },
  declarationOfConsentAlert: {
    warning: {
      body: 'Since you are not yet 18 years old, you need to upload a {documentUrl} signed by your legal guardians to proceed with the sign up.',
      button: {
        title: 'Upload Declaration of Consent',
      },
    },
    success: {
      body: 'You have successfully uploaded the Declaration of Consent.',
      button: {
        title: 'Edit Declaration of Consent',
      },
    },
  },
  additionalVerificationOptionAlert: {
    multipleOptionWarning: {
      title: 'We could not find a match in the HR system yet.',
      body:
        '<ul><li>If you spot any error in your personal details, please correct it and try again.</li>' +
        '<li>Still can’t proceed? Then add a verification option.</li></ul>',
      button: {
        title: 'Add verification option',
      },
    },
    singleOptionWarning: {
      title: 'We could not find a match in the HR system yet.',
      body:
        '<ul><li>If you spot any error in your personal details, please correct it and try again.</li>' +
        '<li>Still can’t proceed? Then add your {verificationOption} to check your eligibility.</li></ul>',
      button: {
        title: 'Add {verificationOption}',
      },
    },
    info: {
      title:
        'If your company has multiple subsidiaries, make sure you are signing up on the correct sign up page for your subsidiary.',
    },
  },
  additionalVerificationModal: {
    title: 'Add verification option',
    body: 'Choose an option that allows us to find you in your HR system.',
    button: {
      continue: 'Continue',
    },
    defaultOption: {
      label: 'Verification Option',
    },
  },
  declarationOfConsentModal: {
    title: 'Upload the Declaration of Consent',
    button: {
      uploadFile: 'Upload file',
      continue: 'Continue',
    },
    uploadedDocument: 'Uploaded documents',
    document: {
      url: 'https://storage.googleapis.com/qualitrain-user-frontend-assets/signup_consent_form_underage_en.pdf',
      text: 'Declaration of Consent',
    },
    uploadConstraints:
      'The formats allowed are PDF, JPG, PNG or HEIC. You can upload up to two files of max 15MB each.',
    uploadSizeExceedError: 'The file is too big (max 15MB).',
    body:
      '<ol><li>Download the {documentUrl}</li>' +
      '<li>Print it and get it filled out by your legal guardians</li>' +
      '<li>Upload a scan or photos of the signed document</li></ol>',
    declarationConfirmation:
      '<p> I confirm that the uploaded Declaration of Consent document has been filled out truthfully and in full by my legal guardians.</p>',
  },
  endMembershipModal: {
    title: 'End membership for:',
    nameTitle: 'Name:',
    birthdayTitle: 'Date of birth:',
    button: {
      endButton: 'End membership',
      cancelButton: 'Close',
    },
    dateFormat: 'DD.MM.YYYY',
    info: 'The employee will receive an automated email notification on the membership cancellation.',
    error: 'The membership could not be ended. Please contact support: info@egym-wellpass.com.',
    errorAfterParent:
      'Your membership can’t end later than the membership of the Wellpass member inviting you.',
    cancellationReasons: {
      default: {
        label: 'Reason for cancellation',
      },
    },
    endOfMembership: {
      default: {
        label: 'End of membership',
      },
    },
    systemCancellationNote: {
      header: 'This cancellation can’t be edited',
      body: 'This cancellation occurred based on a Wellpass internal process and can not be changed. For questions please reach out to info@egym-wellpass.com',
    },
    rejectMembership: {
      note: 'The rejection is irrevocable and becomes effective immediately. This membership will no longer be displayed in the list.',
      endDateLabel: 'With immediate effect',
    },
  },
  editPersonalIdModal: {
    title: 'Edit {personalId}',
    error: 'Oops, something went wrong. Please try again later.',
    regexWarning:
      'The entry does not comply with the validation rules stored for the sign up page.',
    user: {
      name: 'Name',
      dateOfBirth: 'Date of Birth',
    },
    personalId: 'Personal Number',
    validationRule: {
      headline: 'Validation rule shown on the sign up page',
      body: '',
    },
    btn: {
      savePersonalId: 'Save this change',
    },
  },
  paymentDetailsPage: {
    headerTitle: 'Payment data',
    headerBody: 'My bank account',
    editPaymentData: 'Change payment data',
    ibanLabel: 'IBAN',
    ownerLabel: 'Bank account holder',
  },
  updatePaymentInfoModal: {
    headerTitle: 'Please verify your payment information',
    message:
      'The payment method you just selected will not be used automatically for future payments. Please log in and update your payment information if necessary.',
  },
  changePaymentDataModal: {
    headerTitle: 'Change your payment data',
    headerBody: 'SEPA Direct Debit Mandate for recurring payments:',
    body1_1: 'EGYM Wellpass GmbH, Einsteinstr. 172, 81677 Munich',
    body1_2: 'Creditor-ID: DE43ZZZ00000585851',
    body2: 'Please carefully review your payment details.',
    ownerName: 'Bank account holder',
    iban: 'IBAN',
    legalConsent:
      'I hereby authorize EGYM Wellpass GmbH to collect the payments payable by me from my account ' +
      'when due by means of a direct debit from my account. Furthermore I instruct my bank to honour account ' +
      'debit requests from EGYM Wellpass GmbH. As part of your rights, you are entitled to a refund from your bank ' +
      'under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks ' +
      'starting from the date on which your account was debited. ' +
      'The {generalTermsAndConditions} of EGYM Wellpass GmbH apply.',
    savePayment: 'Change payment data',
    generalTermsAndConditions: {
      text: 'general terms and conditions',
      url: 'https://egym-wellpass.com/agb-mitgliedschaft/',
    },
  },
  accountSettingsPage: {
    headerTitle: 'Account settings',
    titles: {
      account: 'My account',
      privacy: 'Privacy settings',
    },
    verification: {
      title: 'Verification-TAN',
      description:
        'Some of our partner studios have an automated entry system installed. In order to get an access card for this gym, that allows you as a Wellpass member to check-in, the gym needs to use your Verification TAN to create a profile for you in their member management system.',
      btn: 'Retrieve verification-TAN',
      expirationText: 'This TAN is valid till {expirationTime}.',
      expirationTimeFormat: 'MM-DD-YYYY HH:mm',
      legalText:
        'Your personal data (name, e-mail address, gender) and your Verification TAN will be needed by the gym to create a profile in their member management system this will be linked with your Wellpass account. This is required to allow you to get access to the Wellpass partner studio with an access card / wristband while your Wellpass check-in is automatically tracked and allows the gym to provide the best care. This functionality is enabled by the EGYM-Technology which we are using within a data-processing agreement. For more information visit our {link}.',
      error:
        'Oops, something went wrong and we couldn’t retrieve your Verification-TAN. Please try again later or contact our support team via info@egym-wellpass.com.',
    },
    egymId: {
      title: 'My EGYM ID',
      label: 'E-Mail:',
      description:
        'The EGYM ID allows you to access all EGYM products with your credentials. Lern more about the EGYM ID {link}.',
      link: {
        text: 'here',
        url: 'https://egym-wellpass.com/egym-id/?utm_source=login',
      },
    },
    privacy: {
      title: 'Data processing',
      subtitle: 'Sharing of anonymous and aggregated data',
      legalText: {
        part1:
          'We provide metrics to your employer, that are based on the aggregated and anonymous workout data of you and your colleagues. With these metrics we want to prove that it is worth subsidizing Wellpass memberships. We assure you that it wont be possible to draw any conclusions from this data about you as an individual. You can change your mind at any time and change your settings here. For more information and details on this and your rights, please visit our {link}.',
        part2:
          'In some cases your employer requires a non-anonymous view of your Check-ins, we show these special conditions during the signup for a Wellpass memberships and your settings here have to leave such additional agreements untouched.',
      },
      options: {
        empty: 'Please select...',
        employer: 'With my employer',
        nobody: 'With nobody',
      },
      error: {
        text: 'Oops, something went wrong and we couldn’t {action} your privacy settings. Please try again later or contact our support team via info@egym-wellpass.com.',
        save: 'save',
        get: 'retrieve',
      },
      reference: {
        text: 'Details on the processing of your personal data can be found in our {link}.',
      },
    },
    privacyPolicyLink: {
      text: 'Privacy Policy',
      url: 'https://egym-wellpass.com/datenschutz/',
    },
  },
  membershipsManagement: {
    filterMemberships: {
      multipleSelection: 'Multiple selected',
    },
  },
  myMembershipsPage: {
    headerTitle: 'My memberships',
    activeMembership: {
      header: 'Active membership',
      monthlyFee: 'Monthly membership fee: ',
      vat: '{price} incl. VAT',
      startDate: 'Start date: ',
      noPriceInfoAvailable: 'n/a',
    },
    membershipTermination: {
      header: 'Membership termination',
      membershipRejectionInformation:
        '<strong>You can withdraw your future membership here until the 15th of the month before your membership starts. To exercise your right of withdrawal after this date, please send an email to <a>info@egym-wellpass.com</a>.</strong>',
      winBackText:
        'We are very sorry, that you are deciding to cancel your Wellpass membership. ' +
        'We always aim to improve our offering to meet all wishes of our members.',
      howContactOps:
        'In case there is an issue with your cancellation, please reach out to us via email to <a>info@egym-wellpass.com</a>',
      endDate: 'End date',
      cancellationReason: 'Cancellation reason',
      terminateButton: 'Terminate membership',
      stopCancelButton: 'Stop cancellation',
      successMessage:
        'Your termination was saved successfully. We’re always improving our offering and hope to welcome you back as a member very soon.',
      successStayingMessage:
        'Your cancellation has been successfully removed. Thank you for staying with wellpass.',
      immediateEffectLabel: 'With immediate effect',
    },
    noActiveMembershipHeader: 'My memberships',
    noActiveMembership: "You don't have an active or future membership at the moment.",
    futureMembership: {
      header: 'Future membership',
    },
  },
  egymId: {
    modal: {
      title: 'What is the EGYM ID?',
      body: 'The EGYM ID allows you to use your credentials to access all products of Wellpass’s parent company EGYM. The advantage for you is that you only need to remember one email and password and that you will have a seemless user experience e.g. your workout on EGYM strength machines will automatically count towards the Challenges in the Wellpass App. More information can be found <a>here.</a>',
    },
    banner: {
      signup: {
        newUserText: 'Create an EGYM ID to sign up for a Wellpass membership.',
        existingUserText: 'Log in with your EGYM ID to sign up for a Wellpass membership.',
      },
      loginPageText: 'Log in with your EGYM ID.',
      newPasswordPageText:
        'You can use your email and new password for all EGYM products, not just for EGYM Wellpass. Some gyms allow Wellpass members to use their <strong>gym app</strong> to perform actions e.g. book classes. These apps are often based on the EGYM ID and you can login with your new password there too.',
    },
  },
  membership: {
    cancellationReasons: {
      uponEmployeesRequest: {
        label: 'Upon employees request',
      },
      endOfEmployment: {
        label: 'End of employment',
      },
      otherReason: {
        label: 'Other reason',
      },
      networkNotAttractiveEnough: {
        label: 'Gym network not attractive',
      },
      companyContractEnded: {
        label: 'Company contract ended',
      },
      plus1RefusedDataNotMatching: {
        label: 'Plus1 refused, data not matching',
      },
      plus1IdCheckInvalid: {
        label: 'Plus1, insufficient proof of identity',
      },
      fraud: {
        label: 'Cancellation by Wellpass',
      },
      notEntitledForParticipation: {
        label: 'No longer entitled for participation',
      },
      notEnoughTime: {
        label: 'No time / not motivated to workout',
      },
      preferOutdoorWorkout: {
        label: 'Fitness at home or outdoor preferred',
      },
      tooExpensive: {
        label: 'Too expensive',
      },
      healthRelatedReasons: {
        label: 'Health related reasons',
      },
      endOfReferrerMembership: {
        label: 'End of referrer membership',
      },
      b2cPaymentChargeback: {
        label: "Membership fee wasn't paid",
      },
      badExperience: {
        label: 'Bad experience with Wellpass',
      },
      b2cPaymentRefused: {
        label: "Membership fee wasn't paid",
      },
      movedToNewOffer: {
        label: 'Switch to new conditions',
      },
      reject: {
        label: 'Rejection of the membership',
      },
      b2cReject: {
        label: 'Rejection of the membership',
      },
      lostEligibility: {
        label: 'No longer entitled for participation',
      },
      default: {
        label: 'Select your cancellation reason',
      },
    },
  },
  countrySelection: {
    changeCountryLink: 'Change country',
    label: 'Country',
    modal: {
      header: 'Change your country',
      text: 'Please select the country in which you will mainly use Wellpass. Your selection may also change the conditions of your membership.',
    },
  },
  countries: {
    DE: 'Germany',
    AT: 'Austria',
  },
  feedbackBanner: {
    headline: '🔥 NEW: Automate the controlling of Wellpass signup and cancellation!',
    body: 'Simply connect your HR system with Wellpass. Integrations are now available with SAP Successfactors, Workday, Personio and Sage.',
    button: 'Learn more',
    link: 'https://egym.typeform.com/to/qVzUJMvJ',
  },
  searchAddress: {
    headline: 'Your current primary residence address',
    helperText: 'Your provided data must be true and will be checked systematically.',
    noOptions: 'Address could not be found. Please try again!',
    input: {
      label: 'Primary residence address',
      placeholder: 'Search your address',
    },
  },
  navigationDrawer: {
    companySection: {
      title: 'Company portal',
      membershipManagement: 'Membership management',
      marketingMaterials: 'Marketing materials',
      companySettings: 'Company settings',
      helpCenter: 'Help Center',
    },
    accountSection: {
      title: 'Personal account',
      accountSettings: 'Account settings',
      paymentData: 'Payment data',
    },
  },
  logout: 'Log out',
  roles: {
    admin: 'Admin',
    adminDescription: 'Can access company-related features and add other managers.',
    editor: 'Editor',
    editorDescription: 'Can access company-related features.',
  },
  companySettings: {
    headerTitle: 'Company settings',
    titles: {
      managers: 'Managers',
    },
    add: {
      triggerButton: 'Add',
      dialogTitle: 'Add a manager',
      step1: 'Personal details',
      step2: 'Assign role',
      step3: 'Summary',
      navigateForwardButton: 'Continue',
      navigateBackwardButton: 'Back',
      companyCounter: '{selected} of {total} selected',
      successSnackbar: '{email} has now manager permissions and has been informed via email.',
      errorAlert:
        'This user is already a manager. To change their permissions, please edit them from the managers’ table.',
    },
    edit: {
      dialogTitle: 'Edit manager',
      infoAlert: 'You can only edit the role for the companies you are admin of.',
      successSnackbar:
        'The manager permissions for {email} have been updated. We have sent them an email to notify them of the changes.',
      tooltip: 'There must always be at least one admin per company.',
    },
  },
};

export default messages;
