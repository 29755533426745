/**
 *
 * @return ValidationErrorType
 * {
 *      //mandatory by react-hook-form
 *      type: string;
 *
 *      //Egym custom
 *      code: string; //translation ID
 *      args: {
 *          [key: string]: string | number
 *      }
 * }
 */
const parseError = errors => {
  if (errors) {
    for (const [key, value] of Object.entries(errors)) {
      if (!!value) {
        errors[key]['type'] = 'validate';
      } else {
        delete errors[key];
      }
    }

    return errors;
  }
  return {};
};

export default function customResolver(validator) {
  return (values, context, options) => {
    const result = validator(values);
    const parsedErrors = parseError(result);

    return {
      values: values,
      errors: parsedErrors,
    };
  };
}
