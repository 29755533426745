import { httpPostUpload } from '../../service/http/http-client';
import { DECLARATION_OF_CONSENT_ACTIONS } from './reducers/useDeclarationOfConsent';

export const uploadDeclarationOfConsent = async (
  membershipId,
  declarationOfConsentDetails,
  setDeclarationOfConsentDetails,
  alertDetails
) => {
  if (declarationOfConsentDetails.confirmed && alertDetails && membershipId) {
    await httpPostUpload('/v1/signup/upload-consent', declarationOfConsentDetails.files, {
      membershipId: membershipId,
    });

    setDeclarationOfConsentDetails({
      action: DECLARATION_OF_CONSENT_ACTIONS.RESET_MODAL,
    });
  }
};

export const getEligibilityStatusError = (
  employeeEligibilityStatus
):
  | {
      id: string,
      showModal: boolean,
      severity: string,
      displayAlert: boolean,
    }
  | undefined => {
  const {
    matchButNotEligible,
    noExactMatch,
    multipleMatches,
    showErrorModal,
    matchButAlreadyRegistered,
  } = employeeEligibilityStatus;
  if (matchButNotEligible) {
    return {
      id: 'matchButNotEligible',
      severity: 'error',
      showModal: showErrorModal,
      displayAlert: false,
    };
  } else if (noExactMatch) {
    return {
      id: 'noExactMatch',
      severity: 'warning',
      showModal: showErrorModal,
      displayAlert: true,
    };
  } else if (multipleMatches) {
    return {
      id: 'multipleMatches',
      severity: 'warning',
      showModal: showErrorModal,
      displayAlert: true,
    };
  } else if (matchButAlreadyRegistered) {
    return {
      id: 'matchButAlreadyRegistered',
      severity: 'warning',
      showModal: true,
      displayAlert: false,
    };
  }
  return undefined;
};
