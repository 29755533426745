import { inputLabelClasses } from '@mui/material';
import styles from './InputLabelTheme.module.scss';

const inputLabelTheme = {
  MuiInputLabel: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        fontWeight: 400,
        lineHeight: '21px',
        color: styles.textColor,
        transform: 'translate(20px, 12px)',
        ...(ownerState.shrink && {
          transform: 'translate(45px, 2px)',
          fontSize: 12,
          fontWeight: 500,
          color: styles.textColor,
          opacity: '.65',
        }),
        [`&.Mui-error`]: {
          color: styles.textColor,
          opacity: 0.65,
        },
        [`&.${inputLabelClasses.focused}`]: {
          fontWeight: 500,
          color: styles.textColor,
          opacity: 0.65,
        },
      }),
    },
  },
};

export default inputLabelTheme;
