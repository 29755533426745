import * as React from 'react';
import Iframe from 'react-iframe';
import gymFinderStaticImage from '../../../styles/img/welcome/welcome-gym-finder-static.png';
import style from './GymFinderSection.module.scss';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import WelcomeContext from '../WelcomeContext';
import { useLocation, useNavigate } from 'react-router-dom';

const GymFinderSection = () => {
  const { gymFinderUrl } = useContext(WelcomeContext);
  const location = useLocation();
  const navigate = useNavigate();

  const showStandalonePage = () => {
    const currentUrlParams = new URLSearchParams(location.search.substring(1));
    currentUrlParams.set('showStandalone', 'true');
    navigate(location.pathname + '?' + currentUrlParams.toString());
  };

  return (
    <div className={style.gymFinderContainer}>
      <div className={style.gymFinderSection}>
        <Iframe
          loading={'lazy'}
          url={gymFinderUrl}
          id="gymFinderIframe"
          className={style.gymFinderIframe}
        />
      </div>
      <div className={style.gymFinderImage}>
        <img src={gymFinderStaticImage} alt="Go to gym search" />
        <button
          type="button"
          id="selfsignup.btn.gymfinder"
          className={`btn btn-lg btn-primary btn-shadow ${style.gymFinderButton}`}
          onClick={() => showStandalonePage()}
        >
          <FormattedMessage id="welcome.intro.viewStudio" />
        </button>
      </div>
    </div>
  );
};

export default GymFinderSection;
