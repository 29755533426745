// @flow
import * as React from 'react';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import { isCompanyUser } from 'ui/common/authentication/authenticationUtil';
import { Navigate } from 'react-router-dom';
import { usePayment } from 'service/hooks';

const getFirstPageFromTopOfNavigation = (user, hasMemberships, hasPayment) => {
  if (isCompanyUser(user)) {
    return '/admin/companies';
  }
  if (hasMemberships) {
    return '/account-settings';
  }

  if (hasPayment) {
    return '/payment-details';
  }

  return null;
};
const LoginWelcome = () => {
  const { user, hasMemberships } = React.useContext(AuthenticationContext);
  const { hasPayment } = usePayment();

  const navigateTo = getFirstPageFromTopOfNavigation(user, hasMemberships, hasPayment);
  if (navigateTo) {
    return <Navigate to={navigateTo} />;
  }

  return null;
};

export default LoginWelcome;
