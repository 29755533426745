// @flow
import * as React from 'react';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import {
  getAccessibleCompaniesForCompanyUser,
  isCompanyUser,
} from 'ui/common/authentication/authenticationUtil';
import { httpGet } from 'service/http/http-client';
import { isDev } from 'util/env/envUtils';
import type { SfCompany } from 'ui/common/authentication/types';

type State = {
  companies: Array<SfCompany>,
  selectedCompanyId: ?number,
  setSelectedCompanyId: Function,
};

type Props = {
  children: any,
};

const CompanyUserContext = React.createContext<State>({});

/**
 * React Context that holds information specific for the Company User, such as:
 * - The companies (not only the ids) that user has access to
 * - The currently selected company
 */
const CompanyUserProvider = ({ children }: Props) => {
  const { user } = React.useContext(AuthenticationContext);
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(undefined);

  React.useEffect(
    () => {
      // We are fetching the companies in order to get the "company names"
      const fetchCompanies = async () => {
        if (user && isCompanyUser(user)) {
          const companyIds = getAccessibleCompaniesForCompanyUser(user);
          const allCompanies = [];
          await Promise.all(
            companyIds.map(async companyId => {
              const sfCompany = (await getCompanyFromSalesForce(companyId): SfCompany);
              if (sfCompany) {
                allCompanies.push(sfCompany);
              }
            })
          );
          // make the first company in the list as currently selected
          setSelectedCompanyId(
            allCompanies && allCompanies.length > 0 ? allCompanies[0].CanonicalId__c : undefined
          );
          setCompanies(allCompanies);
        }
      };
      fetchCompanies();
    },
    [user] // do this when the user changes (i.e. authenticates)
  );

  const getCompanyFromSalesForce = async companyId => {
    let result = null;

    if (!companyId) {
      return result;
    }

    try {
      result = await httpGet(
        `/v1/salesforce/company?sfAccountCanonicalId=${companyId}&minified=true`
      );
    } catch (error) {
      if (isDev()) {
        console.log(
          `Could not fetch company with sfAccountId: ${companyId}. Error is ${error.message}`
        );
      }
    }
    return result;
  };

  return (
    <CompanyUserContext.Provider
      value={{
        companies: companies,
        selectedCompanyId: selectedCompanyId,
        setSelectedCompanyId: setSelectedCompanyId,
      }}
    >
      {children}
    </CompanyUserContext.Provider>
  );
};

export { CompanyUserProvider };
export default CompanyUserContext;
