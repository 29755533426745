// @flow
import type {
  ChronologyType,
  MembershipType,
  MembershipWrapperType,
  MinifiedMembershipWrapperType,
} from 'ui/admin/companies/memberships/types';
import moment from 'moment-timezone';
import newMoment, { Moment } from 'moment';
import { ServerTimeZone } from './dateUtil';

export const isFutureMembership = (membership: MembershipType, now: moment = moment()): boolean => {
  return !!(
    membership.membershipStartTimestamp && moment(membership.membershipStartTimestamp).isAfter(now)
  );
};

const isActiveMembership = (membership: MembershipType, now: moment): boolean => {
  return !!(
    (!membership.membershipStartTimestamp ||
      moment(membership.membershipStartTimestamp).isSameOrBefore(now)) &&
    (!membership.membershipEndTimestamp ||
      moment(membership.membershipEndTimestamp).isSameOrAfter(now))
  );
};

const isEndingCurrentMonth = (membership: MembershipType, now: moment): boolean => {
  return !!(
    membership.membershipEndTimestamp &&
    moment(membership.membershipEndTimestamp).isSame(now, 'month')
  );
};

export const getMembershipChronology = (
  membership: MembershipType,
  now: moment
): ChronologyType => {
  if (isFutureMembership(membership, now)) {
    return 'FUTURE';
  } else if (isActiveMembership(membership, now)) {
    return isEndingCurrentMonth(membership, now) ? 'ENDING_SOON' : 'ACTIVE';
  } else {
    return 'PAST';
  }
};

const chronologyOrder = {
  FUTURE: -1,
  ACTIVE: 0,
  ENDING_SOON: 1,
  PAST: 2,
};

const chronologyComparator = (c1: ChronologyType, c2: ChronologyType) => {
  return chronologyOrder[c1] - chronologyOrder[c2];
};

/**
 * Comparator that sorts the memberships.
 * 1) It first orders based on the chronology: FUTURE, ACTIVE and then PAST.
 * 2) Within the same chronology group, ordering is based on membership start date (in descending order).
 *    Memberships without a start date come after dates with a start date.
 */
export const membershipComparator = (
  mw1: MembershipWrapperType,
  mw2: MembershipWrapperType
): number => {
  const chronologyResult = chronologyComparator(mw1.chronology, mw2.chronology);
  if (chronologyResult !== 0) return chronologyResult;

  const m1 = mw1.membership;
  const m2 = mw2.membership;

  if (!m1.membershipStartTimestamp) return 1;
  if (!m2.membershipStartTimestamp) return -1;
  if (moment(m1.membershipStartTimestamp).isBefore(moment(m2.membershipStartTimestamp))) {
    return 1;
  }
  if (moment(m1.membershipStartTimestamp).isAfter(moment(m2.membershipStartTimestamp))) {
    return -1;
  }
  return 0;
};

export const isPersonalIdLabelPresent = (membershipWrappers: Array<MembershipWrapperType>) => {
  return membershipWrappers.some(
    wrapper => wrapper.membershipOfferDto.employeeInternalIdentifierLabel
  );
};

export const isMultipleTypePresent = (membershipWrappers: Array<MembershipWrapperType>) => {
  const membershipOffers = membershipWrappers.map(membership => membership.membershipOfferDto);
  const anyStandardType = membershipOffers.some(offer => offer.type === 'STANDARD');
  const anyFreeType = membershipOffers.some(offer => offer.type === 'FREE');
  return anyStandardType && anyFreeType;
};

export const isEmployeeNumberPresent = (membershipWrappers: Array<MembershipWrapperType>) => {
  return membershipWrappers.some(wrapper => wrapper.corporateEmployee?.employeeNumber);
};

export const CancellationReason = {
  OTHER: 'OTHER_REASON',
  EMPTY: 'EMPTY',
  MOVED_TO_NEW_OFFER: 'MOVED_TO_NEW_OFFER',
  B2C_PAYMENT_REFUSED: 'B2C_PAYMENT_REFUSED',
  COMPANY_CONTRACT_ENDED: 'COMPANY_CONTRACT_ENDED',
  COVID19_PAUSE_MEMBERSHIP: 'COVID19_PAUSE_MEMBERSHIP',
  UPON_EMPLOYEES_REQUEST: 'UPON_EMPLOYEES_REQUEST',
  NETWORK_NOT_ATTRACTIVE: 'NETWORK_NOT_ATTRACTIVE',
  NOT_ENTITLED_FOR_PARTICIPATION: 'NOT_ENTITLED_FOR_PARTICIPATION',
  END_OF_EMPLOYMENT: 'END_OF_EMPLOYMENT',
  HEALTH_CONCERNS_ISSUES: 'HEALTH_CONCERNS_ISSUES',
  INACTIVE_NOT_ENOUGH_TIME: 'INACTIVE_NOT_ENOUGH_TIME',
  PREFER_OUTDOOR_HOME_WORKOUTS: 'PREFER_OUTDOOR_HOME_WORKOUTS',
  TOO_EXPENSIVE: 'TOO_EXPENSIVE',
  BAD_EXPERIENCE: 'BAD_EXPERIENCE',
  END_OF_REFERRER_MEMBERSHIP: 'END_OF_REFERRER_MEMBERSHIP',
  B2C_PAYMENT_CHARGEBACK: 'B2C_PAYMENT_CHARGEBACK',
  B2C_END_OF_EMPLOYMENT: 'B2C_END_OF_EMPLOYMENT',
  PLUS1_REFUSED_DATA_NOT_MATCHING: 'PLUS1_REFUSED_DATA_NOT_MATCHING',
  PLUS1_ID_CHECK_INVALID: 'PLUS1_ID_CHECK_INVALID',
  FRAUD: 'FRAUD',
  REJECT_MEMBERSHIP: 'REJECT_MEMBERSHIP',
  B2C_REJECT_MEMBERSHIP: 'B2C_REJECT_MEMBERSHIP',
  REFERRER_MEMBERSHIP_MOVED_TO_NEW_OFFER: 'REFERRER_MEMBERSHIP_MOVED_TO_NEW_OFFER',
  API_END_OF_EMPLOYMENT: 'API_END_OF_EMPLOYMENT',
  API_LOST_ELIGIBILITY: 'API_LOST_ELIGIBILITY',
};

/**
 * Returns the active membership
 */
export const getActiveMembershipAndOffer = (
  membershipsAndOffers: MinifiedMembershipWrapperType[]
) => {
  if (membershipsAndOffers) {
    membershipsAndOffers = membershipsAndOffers.filter(membershipAndOffer =>
      isActive(membershipAndOffer)
    );
    return membershipsAndOffers[0];
  }
};

/**
 * Returns the future membership (first)
 */
export const getFutureMembershipAndOffer = (
  membershipsAndOffers: MinifiedMembershipWrapperType[]
) => {
  if (membershipsAndOffers) {
    const futureMembershipAndOffer = membershipsAndOffers
      .sort(
        (a, b) =>
          new Date(a.membership.membershipStartTimestamp) -
          new Date(b.membership.membershipStartTimestamp)
      )
      .find(
        membershipAndOffer =>
          (membershipAndOffer.membership.membershipEndTimestamp === null ||
            newMoment(membershipAndOffer.membership.membershipEndTimestamp).isAfter(newMoment())) &&
          newMoment(membershipAndOffer.membership.membershipStartTimestamp).isAfter(newMoment())
      );
    return futureMembershipAndOffer;
  }
};

export const getMembershipToDisplay = (membershipsAndOffers: MinifiedMembershipWrapperType[]) => {
  const futureMembership = getFutureMembershipAndOffer(membershipsAndOffers);
  const activeMembership = getActiveMembershipAndOffer(membershipsAndOffers);
  return activeMembership || futureMembership;
};

export const getMinifiedMembershipFromWrapper = (membershipWrapper: MembershipWrapperType) => {
  if (membershipWrapper && membershipWrapper.membership && membershipWrapper.membershipOfferDto) {
    return {
      membership: membershipWrapper.membership,
      membershipOffer: membershipWrapper.membershipOfferDto,
    };
  }
  return null;
};

function isActive(membershipAndOffer) {
  return (
    (membershipAndOffer.membership.membershipEndTimestamp === null ||
      moment(membershipAndOffer.membership.membershipEndTimestamp).isAfter(newMoment.now())) &&
    moment(membershipAndOffer.membership.membershipStartTimestamp).isBefore(newMoment.now())
  );
}

export const systemCancellationReasons = [
  CancellationReason.MOVED_TO_NEW_OFFER,
  CancellationReason.B2C_PAYMENT_REFUSED,
  CancellationReason.COMPANY_CONTRACT_ENDED,
  CancellationReason.COVID19_PAUSE_MEMBERSHIP,
  CancellationReason.FRAUD,
  CancellationReason.REJECT_MEMBERSHIP,
  CancellationReason.REFERRER_MEMBERSHIP_MOVED_TO_NEW_OFFER,
  CancellationReason.API_LOST_ELIGIBILITY,
  CancellationReason.API_END_OF_EMPLOYMENT,
];

const cancellationReason = (value, labelId) => {
  return {
    value,
    labelId,
  };
};

export const allCancellationReasons = [
  cancellationReason(CancellationReason.EMPTY, 'membership.cancellationReasons.default.label'),
  cancellationReason(
    CancellationReason.UPON_EMPLOYEES_REQUEST,
    'membership.cancellationReasons.uponEmployeesRequest.label'
  ),
  cancellationReason(CancellationReason.OTHER, 'membership.cancellationReasons.otherReason.label'),
  cancellationReason(
    CancellationReason.NETWORK_NOT_ATTRACTIVE,
    'membership.cancellationReasons.networkNotAttractiveEnough.label'
  ),
  cancellationReason(
    CancellationReason.NOT_ENTITLED_FOR_PARTICIPATION,
    'membership.cancellationReasons.notEntitledForParticipation.label'
  ),
  cancellationReason(
    CancellationReason.INACTIVE_NOT_ENOUGH_TIME,
    'membership.cancellationReasons.notEnoughTime.label'
  ),
  cancellationReason(
    CancellationReason.PREFER_OUTDOOR_HOME_WORKOUTS,
    'membership.cancellationReasons.preferOutdoorWorkout.label'
  ),
  cancellationReason(
    CancellationReason.TOO_EXPENSIVE,
    'membership.cancellationReasons.tooExpensive.label'
  ),
  cancellationReason(
    CancellationReason.HEALTH_CONCERNS_ISSUES,
    'membership.cancellationReasons.healthRelatedReasons.label'
  ),
  cancellationReason(
    CancellationReason.END_OF_REFERRER_MEMBERSHIP,
    'membership.cancellationReasons.endOfReferrerMembership.label'
  ),
  cancellationReason(
    CancellationReason.B2C_PAYMENT_CHARGEBACK,
    'membership.cancellationReasons.b2cPaymentChargeback.label'
  ),
  cancellationReason(
    CancellationReason.BAD_EXPERIENCE,
    'membership.cancellationReasons.badExperience.label'
  ),
  cancellationReason(
    CancellationReason.B2C_PAYMENT_REFUSED,
    'membership.cancellationReasons.b2cPaymentRefused.label'
  ),
  cancellationReason(
    CancellationReason.END_OF_EMPLOYMENT,
    'membership.cancellationReasons.endOfEmployment.label'
  ),
  cancellationReason(
    CancellationReason.B2C_END_OF_EMPLOYMENT,
    'membership.cancellationReasons.endOfEmployment.label'
  ),
  cancellationReason(
    CancellationReason.COMPANY_CONTRACT_ENDED,
    'membership.cancellationReasons.companyContractEnded.label'
  ),
  cancellationReason(
    CancellationReason.PLUS1_REFUSED_DATA_NOT_MATCHING,
    'membership.cancellationReasons.plus1RefusedDataNotMatching.label'
  ),
  cancellationReason(
    CancellationReason.PLUS1_ID_CHECK_INVALID,
    'membership.cancellationReasons.plus1IdCheckInvalid.label'
  ),
  cancellationReason(CancellationReason.FRAUD, 'membership.cancellationReasons.fraud.label'),
  cancellationReason(
    CancellationReason.MOVED_TO_NEW_OFFER,
    'membership.cancellationReasons.movedToNewOffer.label'
  ),
  cancellationReason(
    CancellationReason.REJECT_MEMBERSHIP,
    'membership.cancellationReasons.reject.label'
  ),
  cancellationReason(
    CancellationReason.API_END_OF_EMPLOYMENT,
    'membership.cancellationReasons.endOfEmployment.label'
  ),
  cancellationReason(
    CancellationReason.API_LOST_ELIGIBILITY,
    'membership.cancellationReasons.lostEligibility.label'
  ),
  cancellationReason(
    CancellationReason.B2C_REJECT_MEMBERSHIP,
    'membership.cancellationReasons.b2cReject.label'
  ),
];

const cancellationReasons = (reasons: Array) =>
  allCancellationReasons
    .filter(item => reasons.includes(item.value))
    .sort((a, b) => reasons.indexOf(a.value) - reasons.indexOf(b.value));

export const adminCancellationReasons = cancellationReasons([
  CancellationReason.REJECT_MEMBERSHIP,
  CancellationReason.UPON_EMPLOYEES_REQUEST,
  CancellationReason.END_OF_EMPLOYMENT,
  CancellationReason.NOT_ENTITLED_FOR_PARTICIPATION,
  CancellationReason.OTHER,
]);

export const userCancellationReasons = cancellationReasons([
  CancellationReason.B2C_REJECT_MEMBERSHIP,
  CancellationReason.HEALTH_CONCERNS_ISSUES,
  CancellationReason.PREFER_OUTDOOR_HOME_WORKOUTS,
  CancellationReason.INACTIVE_NOT_ENOUGH_TIME,
  CancellationReason.NETWORK_NOT_ATTRACTIVE,
  CancellationReason.TOO_EXPENSIVE,
  CancellationReason.BAD_EXPERIENCE,
  CancellationReason.B2C_END_OF_EMPLOYMENT,
  CancellationReason.OTHER,
]);

export const defaultLabelForMyMembershipsPage =
  'myMembershipsPage.membershipTermination.cancellationReason';

export const checkIfMembershipTerminationIsEditable = (
  currentTime: Moment,
  membershipEndTimestamp: Moment,
  cancellationDeadLine: number
) => {
  return membershipEndTimestamp.date(cancellationDeadLine).isBefore(currentTime);
};

export const ACTIVE_MEMBERS_FILTER_VALUE = 'ACTIVE_MEMBERS';
export const NEW_REGISTRATIONS_FILTER_VALUE = 'NEW_REGISTRATIONS';
export const ALL_MEMBERS_FILTER_VALUE = 'ALL_MEMBERS';
export const ENDING_MEMBERSHIP_FILTER_VALUE = 'ENDING_SOON';

export const membershipFilters = [
  {
    key: 'All',
    value: ALL_MEMBERS_FILTER_VALUE,
    textKey: 'companyMemberships.filter.allMembers',
  },
  {
    key: 'Active',
    value: ACTIVE_MEMBERS_FILTER_VALUE,
    textKey: 'companyMemberships.filter.activeMembers',
  },
  {
    key: 'StartSoon',
    value: NEW_REGISTRATIONS_FILTER_VALUE,
    textKey: 'companyMemberships.filter.newRegistrations',
  },
  {
    key: 'EndingSoon',
    value: ENDING_MEMBERSHIP_FILTER_VALUE,
    textKey: 'companyMemberships.filter.endedMemberships',
  },
];

export const companyMembershipCancellationDeadline = 25;

export const replaceCancellationReason = (
  membershipWrapper: MembershipWrapperType,
  cancellationReasonToBeReplaced,
  newCancellationReason
) => {
  if (
    membershipWrapper.membership.membershipCancellationReason === cancellationReasonToBeReplaced
  ) {
    membershipWrapper.membership.membershipCancellationReason = newCancellationReason;
  }
  return membershipWrapper;
};

export const generateEndDateOptions = ({
  timeZoneId = ServerTimeZone,
  dateFormat,
  presetValue,
  startDate,
  endDate = null,
  numberOfOptions = 6,
  latestDayInMonthCanCancel = 25,
  immediateEffect = false,
  immediateEffectLabel,
  immediateEffectValue = new Date(),
}: {
  timeZoneId: string,
  presetValue: string,
  startDate: Moment,
  endDate: Moment,
  numberOfOptions: number,
  latestDayInMonthCanCancel: number,
  immediateEffect: boolean,
  immediateEffectLabel: string,
  immediateEffectValue: Date,
}) => {
  const dateOptions = [];

  if (presetValue) {
    dateOptions.push({
      key: moment(presetValue).format(dateFormat),
      value: presetValue,
      label: moment(presetValue).format(dateFormat),
    });
  }

  startDate = moment(startDate).tz(timeZoneId);
  const firstPossibleCancellationDate =
    startDate.date() > latestDayInMonthCanCancel
      ? startDate.add(1, 'M').endOf('month')
      : startDate.endOf('month');

  for (let i = 0; i < numberOfOptions; i++) {
    let option = firstPossibleCancellationDate.clone().add(i, 'M').endOf('month');
    if (endDate && option.isAfter(endDate)) {
      break;
    }
    if (!presetValue || option.format(dateFormat) !== moment(presetValue).format(dateFormat)) {
      dateOptions.push({
        key: option.format(dateFormat),
        value: option.format('YYYY-MM-DD'),
        label: option.format(dateFormat),
      });
    }
  }

  if (immediateEffect && immediateEffectLabel) {
    dateOptions.push({
      key: 'IMMEDIATE_EFFECT',
      value: immediateEffectValue,
      label: immediateEffectLabel,
    });
  }

  return dateOptions;
};

type MembershipCancellationReasonType = {
  membershipStartTimestamp: string,
  cancellationDeadLine: number,
  fromDate?: Moment,
};
export const futureMembershipCanBeB2CRejected = (
  membershipStartTimestamp,
  cancellationDeadLine,
  fromDate = moment()
): MembershipCancellationReasonType => {
  if (!membershipStartTimestamp) return false;
  return fromDate.isBefore(
    moment(membershipStartTimestamp).date(cancellationDeadLine).subtract(1, 'month').endOf('day')
  );
};
