import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle } from '@mui/material';

const SystemCancellationNote = () => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage id="endMembershipModal.systemCancellationNote.header" />
    </AlertTitle>
    <FormattedMessage id="endMembershipModal.systemCancellationNote.body" />
  </Alert>
);

export default SystemCancellationNote;
