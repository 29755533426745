import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';

const EgymIdSection = () => {
  const { formatMessage } = useIntl();
  const { user } = React.useContext(AuthenticationContext);

  return (
    <>
      <h4 className="fw-bold mb-3">
        <FormattedMessage id="accountSettingsPage.egymId.title" />
      </h4>

      <p className="mb-2">
        <strong>
          <FormattedMessage id="accountSettingsPage.egymId.label" />
        </strong>
        <span className="ms-2">{user.email}</span>
      </p>

      <p className="text-muted small pb-3">
        <FormattedMessage
          id="accountSettingsPage.egymId.description"
          values={{
            link: (
              <a
                href={formatMessage({ id: 'accountSettingsPage.egymId.link.url' })}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted text-underlined"
              >
                <FormattedMessage id="accountSettingsPage.egymId.link.text" />
              </a>
            ),
          }}
        />
      </p>
    </>
  );
};

export default EgymIdSection;
