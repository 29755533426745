import { useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import gymBackgroundMobileImage from 'styles/img/welcome/welcome-header-bg-mobile.jpg';
import gymBackgroundTabletImage from 'styles/img/welcome/welcome-header-bg-tablet.jpg';
import gymBackgroundDesktopMiniImage from 'styles/img/welcome/welcome-header-bg-desktop-mini.jpg';
import gymBackgroundDesktopImage from 'styles/img/welcome/welcome-header-bg-desktop.jpg';

export const useHeaderBackgroundImage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isDesktopMini = useMediaQuery('(max-width:1650px)');
  let topBackgroundSectionImage = gymBackgroundDesktopImage;
  if (isMobile) {
    topBackgroundSectionImage = gymBackgroundMobileImage;
  } else if (isTablet) {
    topBackgroundSectionImage = gymBackgroundTabletImage;
  } else if (isDesktopMini) {
    topBackgroundSectionImage = gymBackgroundDesktopMiniImage;
  }
  return topBackgroundSectionImage;
};
