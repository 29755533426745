// @flow
import React from 'react';
import PropTypes from 'prop-types';
import TextFormField from 'ui/common/components/TextFormField';

/**
 * Wrapper around TextFormField which, based on the controlId, sets default values for: controlLabel and placeholder.
 *
 * The rules are:
 *   controlLabel = 'signupPage.field.${controlId}.label'
 *   placeholder = 'signupPage.field.${controlId}.placeholder'
 * </ul>
 * The rest of the props are just passed down to the TexFormField component.
 *
 * @param controlId the ID of the form field
 * @param controlLabel the label of the field
 * @param placeholder the placeholder text for the field
 * @param rest the rest of the props
 */
const DefaultTextFormField = ({ control, controlId, controlLabel, placeholder, ...rest }) => {
  return (
    <TextFormField
      controlId={controlId}
      controlLabel={controlLabel ? controlLabel : `signupPage.field.${controlId}.label`}
      placeholder={placeholder ? placeholder : `signupPage.field.${controlId}.placeholder`}
      {...rest}
      register={{
        ...control.register(controlId),
      }}
    />
  );
};

DefaultTextFormField.propTypes = {
  control: PropTypes.any,
  controlId: PropTypes.string,
  controlLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DefaultTextFormField;
