import React from 'react';
import { FormattedMessage } from 'react-intl';
import VerificationTanSection from 'ui/account/VerificationTanSection';
import EgymIdSection from 'ui/account/EgymIdSection';
import DataPrivacySection from 'ui/account/DataPrivacySection';

const AccountSettingsPage = () => {
  return (
    <>
      <h2 id="accountSettingsPage.headerTitle.id" className="text-dark mb-3">
        <FormattedMessage id="accountSettingsPage.headerTitle" />
      </h2>
      <div className=" mt-3">
        <div className="mb-3">
          <h3 className="text-dark">
            <FormattedMessage id="accountSettingsPage.titles.account" />
          </h3>
        </div>

        <div className="text-start">
          <VerificationTanSection />
        </div>

        <div className="text-start mt-2rem mb-3">
          <EgymIdSection />
        </div>

        <div className="text-start mt-4 mb-3">
          <h3 className="text-dark">
            <FormattedMessage id="accountSettingsPage.titles.privacy" />
          </h3>
        </div>

        <div className="text-start mt-3 mb-3">
          <DataPrivacySection />
        </div>
      </div>
    </>
  );
};

export default AccountSettingsPage;
