import React from 'react';
import { ReactComponent as CheckmarkIcon } from 'styles/img/card/checkmark.svg';

type Props = {
  description: any,
};

const BenefitItem = ({ description }: Props) => (
  <div className="col-12 mb-3 ps-0">
    <div className="container">
      <div className="row">
        <div className="col-2 offer-card-benefit-img">
          <CheckmarkIcon className="text-primary" />
        </div>
        <div className="col-10 pe-0 offer-card-benefit-description small">{description}</div>
      </div>
    </div>
  </div>
);

export default BenefitItem;
