// @flow
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { validateWhetherTheUserIsMinor } from 'validation/common/additionalInfoValidators';
import { extractRequestParameter } from 'service/http/requestUtil';
import { hashSignupData } from 'service/duplicateSignupUtil.ts';
import moment from 'moment';
import { LocalStorage } from 'service/local-storage/localStorage';
import { DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX } from 'service/duplicateSignupUtil.ts';
import RegistrationContext from './RegistrationContext';
import { useFormContext } from 'react-hook-form';

/**
 SignupFormListener is a listener component that needs to be attached at the bottom
 of the form definition in order to listen for all of the changed values from the form.
 */
const SignupFormListener = () => {
  const { alertNotification, setAlertNotification, declarationOfConsentDetails } =
    useContext(RegistrationContext);
  const { formatMessage } = useIntl();
  const signupPageToken = extractRequestParameter('companyToken');
  const localStorage = useMemo(() => new LocalStorage(), []);
  const { watch } = useFormContext();

  const supportMailOnClick = useCallback(
    event => {
      window.open('mailto:' + formatMessage({ id: 'general.wellpassSupportMail' }), 'Mail');
      event.preventDefault();
    },
    [formatMessage]
  );

  const validateBirthday = useCallback(
    birthday => {
      const isMinor = validateWhetherTheUserIsMinor(birthday);
      return {
        warning: isMinor
          ? {
              message: 'declarationOfConsentAlert.warning.body',
              values: {
                documentUrl: (
                  <a
                    href={formatMessage({
                      id: 'declarationOfConsentModal.document.url',
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted text-underlined"
                  >
                    <FormattedMessage id="declarationOfConsentModal.document.text" />
                  </a>
                ),
                wellpassSupportMail: (
                  <a
                    onClick={supportMailOnClick}
                    href={'mailto:' + formatMessage({ id: 'general.wellpassSupportMail' })}
                    className="text-muted text-underlined"
                  >
                    <FormattedMessage id="general.wellpassSupportMail" />
                  </a>
                ),
              },
              buttonText: 'declarationOfConsentAlert.warning.button.title',
            }
          : undefined,
      };
    },
    [formatMessage, supportMailOnClick]
  );

  const checkDuplicateSignup = useCallback(
    (firstName, lastName, birthday, signupPageToken) => {
      if (firstName && lastName && birthday && signupPageToken) {
        const hashedData = hashSignupData(
          `${signupPageToken},${firstName.toLowerCase()},${lastName.toLowerCase()},${birthday}`,
          DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX
        );

        let isDuplicateSignup = false;
        const expirationDate = localStorage.readValue(hashedData);
        if (expirationDate) {
          isDuplicateSignup = moment(expirationDate).isAfter(moment());
        }

        return {
          warning: isDuplicateSignup
            ? {
                title: 'validation.signup.duplicateSignup.title',
                message: 'validation.signup.duplicateSignup.infoText',
                values: {
                  wellpassSupportMail: (
                    <a
                      onClick={supportMailOnClick}
                      href={'mailto:' + formatMessage({ id: 'general.wellpassSupportMail' })}
                      className="text-muted text-underlined"
                    >
                      <FormattedMessage id="general.wellpassSupportMail" />
                    </a>
                  ),
                },
              }
            : undefined,
        };
      }
    },
    [formatMessage, localStorage, supportMailOnClick]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'birthday' && value?.birthday) {
        const birthdayValidation = validateBirthday(value.birthday);
        if (birthdayValidation?.warning) {
          setAlertNotification({ name, type: 'warning', data: birthdayValidation });
        } else {
          alertNotification?.birthday?.warning &&
            setAlertNotification({ name, type: 'warning', data: undefined });
        }
      }

      if (name === 'reviewedBirthday' && value?.reviewedBirthday) {
        const birthdayValidation = validateBirthday(value.reviewedBirthday);
        if (birthdayValidation?.warning) {
          setAlertNotification({ name, type: 'warning', data: birthdayValidation });
        } else {
          alertNotification?.reviewedBirthday?.warning &&
            setAlertNotification({ name, type: 'warning', data: undefined });
        }
      }

      if (value.firstName && value.lastName && value.birthday && signupPageToken) {
        const duplicateSignupDetails = checkDuplicateSignup(
          value.firstName,
          value.lastName,
          value.birthday,
          signupPageToken
        );

        if (duplicateSignupDetails?.warning) {
          setAlertNotification({
            name: 'duplicateSignUp',
            type: 'warning',
            data: duplicateSignupDetails,
          });
        } else {
          alertNotification?.duplicateSignUp?.warning &&
            setAlertNotification({ name: 'duplicateSignUp', type: 'warning', data: undefined });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    alertNotification,
    setAlertNotification,
    signupPageToken,
    validateBirthday,
    checkDuplicateSignup,
    declarationOfConsentDetails,
  ]);

  return null;
};
export default SignupFormListener;
