export const formatValue = (value: ?string): string => {
  if (!value) return '';
  return value;
};

export const bytesToKilobytes = bytes => {
  return (bytes / 1024).toFixed(2);
};

export const truncateName = (name, maxLength) => {
  if (name.length <= maxLength) {
    return name;
  }

  const ellipsis = '...';
  const truncatedLength = maxLength - ellipsis.length;
  return name.substring(0, truncatedLength).trim() + ellipsis;
};

export const generateQueryParams = filters => {
  return filters.map(filter => {
    return Object.keys(filter)
      .map(key => {
        return key + '=' + filter[key];
      })
      .join('&');
  });
};
