import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import type { MinifiedMembershipWrapperType } from 'ui/admin/companies/memberships/types';
import {
  allCancellationReasons,
  CancellationReason,
  checkIfMembershipTerminationIsEditable,
  futureMembershipCanBeB2CRejected,
  generateEndDateOptions,
  isFutureMembership,
  userCancellationReasons,
} from 'service/membershipUtil';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { FORM_ERROR } from 'final-form';
import { extractRequestParameter } from '../../service/http/requestUtil';
import moment from 'moment';
import useApi from '../../service/api';
import Form from '../common/components/Form';
import DropDownFormField from '../self-signup/components/DropdownFormField';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import { useAutoPopulateCancelMembershipForm } from './hooks/useAutoPopulateCancelMembershipForm';
import { formatDateWithTimeZoneId, ServerTimeZone } from '../../service/dateUtil';

const numberOfOptions = 3;
const cancellationDeadLine = 15;

const createPatchBody = values => ({
  membershipCancellationReason: values?.membershipCancellationReason,
  endDate: values?.membershipEndDate ? moment(values.membershipEndDate).format('YYYY-MM-DD') : null,
});

const shouldDisableForm = membershipWrapper => {
  return (
    membershipWrapper.membership.membershipCancellationReason ===
      CancellationReason.MOVED_TO_NEW_OFFER ||
    (allCancellationReasons.some(
      allCancellationReason =>
        allCancellationReason.value === membershipWrapper.membership.membershipCancellationReason
    ) &&
      !userCancellationReasons.some(
        userCancellationReason =>
          userCancellationReason.value === membershipWrapper.membership.membershipCancellationReason
      ) &&
      membershipWrapper.membership.membershipCancellationReason !== CancellationReason.EMPTY) ||
    checkIfMembershipTerminationIsEditable(
      moment(),
      moment(membershipWrapper.membership.membershipEndTimestamp),
      cancellationDeadLine
    )
  );
};

type Props = {
  onEndMembershipSuccess: Function,
  membershipWrapperInput: MinifiedMembershipWrapperType,
  intl: IntlShape,
};

const CancelMembershipForm = ({
  onEndMembershipSuccess,
  membershipWrapperInput,
  intl: { formatMessage },
}: Props) => {
  const { user } = useContext(AuthenticationContext);
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { userMembershipsApi } = useApi();
  const [errors, setErrors] = useState(undefined);
  const [corporateEmployee: EvaluatedCorporateEmployeeType, setCorporateEmployee] = useState(null);
  const [membershipWrapper, setMembershipWrapper] = useState(membershipWrapperInput);
  const [isStopCancellation, setIsStopCancellation] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(false);
  const [initialValues, setInitialValues] = useState({
    membershipCancellationReason:
      membershipWrapper?.membership?.membershipCancellationReason === CancellationReason.EMPTY
        ? extractRequestParameter('cancellationReason') || undefined
        : membershipWrapper?.membership?.membershipCancellationReason,
    membershipEndDate: formatDateWithTimeZoneId(
      membershipWrapper?.membership?.membershipEndTimestamp,
      membershipWrapper?.membership?.timeZoneId,
      'YYYY-MM-DD'
    ),
  });

  const dateFormat = formatMessage({ id: 'endMembershipModal.dateFormat' });
  const errorDefaultMessage = formatMessage({ id: 'endMembershipModal.error' });
  const errorAfterParentMessage = formatMessage({ id: 'endMembershipModal.errorAfterParent' });
  const endDateDefaultLabel = 'myMembershipsPage.membershipTermination.endDate';
  const cancellationDefaultLabel = 'myMembershipsPage.membershipTermination.cancellationReason';
  const immediateEffectLabel = formatMessage({
    id: 'myMembershipsPage.membershipTermination.immediateEffectLabel',
  });

  useEffect(() => {
    if (
      user.id &&
      membershipWrapper &&
      membershipWrapper.membershipOffer &&
      membershipWrapper.membershipOffer.integrationScopeId
    ) {
      userMembershipsApi
        .fetchCorporateEmployee(user.id, membershipWrapper.membershipOffer.integrationScopeId, null)
        .then(corporateEmployeeResponse => {
          setCorporateEmployee(corporateEmployeeResponse);
        });
    }
  }, [user.id, userMembershipsApi, membershipWrapper]);

  const b2CMembershipRejectionAvailable = futureMembershipCanBeB2CRejected(
    membershipWrapper?.membership?.membershipStartTimestamp,
    cancellationDeadLine,
    moment()
  );

  const endDateOptions = generateEndDateOptions({
    timeZoneId: membershipWrapper?.membership?.timeZoneId,
    dateFormat,
    presetValue: formatDateWithTimeZoneId(
      membershipWrapper?.membership?.membershipEndTimestamp,
      membershipWrapper?.membership?.timeZoneId,
      'YYYY-MM-DD'
    ),
    startDate: isFutureMembership(membershipWrapper?.membership)
      ? moment(membershipWrapper?.membership?.membershipStartTimestamp)
      : moment(),
    endDate: corporateEmployee ? corporateEmployee.eligibility.effectiveTo : null,
    numberOfOptions,
    latestDayInMonthCanCancel: cancellationDeadLine,
    immediateEffect: b2CMembershipRejectionAvailable,
    immediateEffectLabel,
    immediateEffectValue: formatDateWithTimeZoneId(
      membershipWrapper?.membership?.membershipStartTimestamp,
      membershipWrapper?.membership?.timeZoneId,
      'YYYY-MM-DD'
    ),
  });

  const shouldEnableStopCancelButton = (membershipWrapper, isDirty) => {
    return (
      membershipWrapper.membership.membershipEndTimestamp &&
      initialValues.membershipCancellationReason &&
      !isDirty
    );
  };

  const cancelMembership = requestBody => {
    return executeWithSpinner(
      userMembershipsApi
        .cancelMembership(
          membershipWrapper.membership.uuid,
          requestBody.membershipCancellationReason,
          requestBody.endDate
        )
        .then(response => {
          onEndMembershipSuccess(response);
          setMembershipWrapper(response);
          const timeZoneId = response?.membership?.timeZoneId ?? ServerTimeZone;
          const endDate = formatDateWithTimeZoneId(
            response.membership.membershipEndTimestamp,
            timeZoneId,
            'YYYY-MM-DD'
          );
          setInitialValues({
            membershipCancellationReason: response.membership.membershipCancellationReason,
            membershipEndDate: endDate,
          });
          setRequestSuccessful(true);
        })
        .catch(errorResponse => {
          const error = {
            [FORM_ERROR]:
              errorResponse.message === 'error.EndDateAfterParent'
                ? errorAfterParentMessage
                : errorDefaultMessage,
          };
          setErrors(error);
          return error;
        })
    );
  };

  const cancellationReasons = useCallback(() => {
    const selectedReason = allCancellationReasons.find(
      r => r.value === initialValues.membershipCancellationReason
    );
    const availableUserCancellationReasons = b2CMembershipRejectionAvailable
      ? userCancellationReasons
      : userCancellationReasons.filter(r => r.value !== CancellationReason.B2C_REJECT_MEMBERSHIP);

    const reasons =
      selectedReason &&
      !availableUserCancellationReasons.find(r => r.value === selectedReason.value)
        ? [selectedReason, ...availableUserCancellationReasons]
        : availableUserCancellationReasons;

    return reasons.map(({ value, labelId }) => ({
      key: value,
      value: value,
      label: formatMessage({ id: labelId }),
    }));
  }, [initialValues.membershipCancellationReason, b2CMembershipRejectionAvailable, formatMessage]);

  const onSubmit = values => {
    setErrors(undefined);
    const requestBody = isStopCancellation
      ? {
          membershipCancellationReason: CancellationReason.EMPTY,
          endDate: null,
        }
      : createPatchBody(values);
    return cancelMembership(requestBody);
  };

  const updateFormDataOnChange = useAutoPopulateCancelMembershipForm({
    immediateEffectValue: formatDateWithTimeZoneId(
      membershipWrapper?.membership?.membershipStartTimestamp,
      membershipWrapper?.membership?.timeZoneId,
      'YYYY-MM-DD'
    ),
    defaultCancellationReason: initialValues.membershipCancellationReason,
  });

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={initialValues}
      shouldKeepValues={false}
      customDependantFieldUpdate={updateFormDataOnChange}
      dependantFieldWatch={['membershipEndDate', 'membershipCancellationReason']}
    >
      {({ register, control, formState: { isDirty }, watch }) => {
        const watchCancellationReason = watch('membershipCancellationReason');
        const watchMembershipEndDate = watch('membershipEndDate');

        return (
          <fieldset
            id="myMembershipsPage.membershipTermination.fieldset"
            disabled={shouldDisableForm(membershipWrapper)}
          >
            <div>
              <div className="text-start mt-4">
                <h3 className="fw-lighter my-4">
                  <FormattedMessage id="myMembershipsPage.membershipTermination.header" />
                </h3>
              </div>

              {isFutureMembership(membershipWrapper.membership) && (
                <div className="mb-3">
                  <EdsAlert type="info">
                    <FormattedMessage
                      id="myMembershipsPage.membershipTermination.membershipRejectionInformation"
                      values={{
                        a: chunks => (
                          <a href="mailto:info@egym-wellpass.com" className="body2-link">
                            {chunks}
                          </a>
                        ),
                        strong: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  </EdsAlert>
                </div>
              )}

              <p
                className={classNames({
                  'mb-1': shouldDisableForm(membershipWrapper),
                })}
              >
                <FormattedMessage id="myMembershipsPage.membershipTermination.winBackText" />
              </p>

              {shouldDisableForm(membershipWrapper) && (
                <p>
                  <FormattedMessage
                    id="myMembershipsPage.membershipTermination.howContactOps"
                    values={{
                      a: chunks => <a href="mailto:info@egym-wellpass.com">{chunks}</a>,
                    }}
                  />
                </p>
              )}

              <div className="col-sm-6 mb-3">
                <DropDownFormField
                  control={control}
                  controlId="membershipEndDate"
                  controlLabel={endDateDefaultLabel}
                  register={{ ...register('membershipEndDate') }}
                  options={endDateOptions}
                  required={false}
                  placeholder={endDateDefaultLabel}
                />
              </div>

              <div className="col-sm-6 mb-3">
                <DropDownFormField
                  control={control}
                  controlId="membershipCancellationReason"
                  register={{ ...register('membershipCancellationReason') }}
                  controlLabel={cancellationDefaultLabel}
                  options={cancellationReasons()}
                  required={false}
                  placeholder={cancellationDefaultLabel}
                />
              </div>

              {errors && (
                <>
                  <div className="col-sm-12 mb-4">
                    <div className="alert alert-danger align-content-center text-start">
                      {errors[FORM_ERROR]}
                    </div>
                  </div>
                </>
              )}
              {requestSuccessful && !errors && (
                <>
                  <div className="col-sm-12 mb-4">
                    <div
                      className="alert alert-success align-content-center text-start"
                      id="myMembershipsPage.membershipTermination.successStayingMessage"
                    >
                      {(isStopCancellation && (
                        <FormattedMessage id="myMembershipsPage.membershipTermination.successStayingMessage" />
                      )) || (
                        <FormattedMessage id="myMembershipsPage.membershipTermination.successMessage" />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className="col-sm-6 mb-3 d-grid">
                  {shouldEnableStopCancelButton(membershipWrapper, isDirty) ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => setIsStopCancellation(true)}
                      id="myMemberships.stopCancellation.button"
                    >
                      <FormattedMessage id="myMembershipsPage.membershipTermination.stopCancelButton" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => setIsStopCancellation(false)}
                      disabled={
                        !(
                          watchCancellationReason &&
                          watchCancellationReason !== CancellationReason.EMPTY &&
                          watchMembershipEndDate
                        )
                      }
                      id="myMemberships.terminateMembership.button"
                    >
                      <FormattedMessage id="myMembershipsPage.membershipTermination.terminateButton" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        );
      }}
    </Form>
  );
};

export default injectIntl(CancelMembershipForm);
