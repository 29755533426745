import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { EdsLabel } from '@egym/react-design-system/EdsLabel';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import style from './OfferingSection.module.scss';
import { useNavigate } from 'react-router-dom';

const OfferingSection = () => {
  const navigate = useNavigate();

  return (
    <div className={style.mainContainer}>
      <div className={style.offersSection}>
        <div className={style.offersContainer}>
          <div className={style.offer}>
            <div className={style.offerHeader}>
              <EdsLabel type="neutral" className={style.badgeFree}>
                <FormattedMessage id="welcome.welcomeFree.offers.free.badge" />
              </EdsLabel>
              <div className={style.basicOnlineOffering}>
                <span className={style.basicOnlineOfferingTitle}>
                  <FormattedMessage id="welcome.welcomeFree.offers.free.title" />
                </span>
                <div className={style.offerPrice}>
                  <span className={style.zero}>0</span>
                  <span className={style.dotEuro}>.00€</span>
                  <span className={style.perMonth}>
                    <FormattedMessage id="welcome.welcomeFree.offers.free.perMonth" />
                  </span>
                </div>
              </div>
            </div>
            <ul className={style.offerList}>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.red)}>
                  close
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.unlimitedCheckIns" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.red)}>
                  close
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.partnerApps" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.red)}>
                  close
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.liveOnlineCourses" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.onlinePrevention" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.challenges" />
              </li>
            </ul>
          </div>
          <div className="divider" />
          <div className={style.offer}>
            <div className={style.offerHeader}>
              <EdsLabel type="warning" className={style.badgeRecommended}>
                <FormattedMessage id="welcome.welcomeFree.offers.recommended.badge" />
              </EdsLabel>
              <div>
                <p className={style.offerTitle}>
                  <FormattedMessage id="welcome.welcomeFree.offers.recommended.title" />
                </p>
                <p className={style.offerSubtitle}>
                  <FormattedMessage id="welcome.welcomeFree.offers.recommended.subtitle" />
                </p>
                <p className={style.offerDescription}>
                  <FormattedMessage
                    id="welcome.welcomeFree.offers.recommended.description"
                    values={{
                      button: text => (
                        <button onClick={() => navigate(-1)} className={'link-button'}>
                          {text}
                        </button>
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <ul className={style.offerList}>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.unlimitedCheckIns" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.partnerApps" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.liveOnlineCourses" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.onlinePrevention" />
              </li>
              <li>
                <EdsIcon filled className={classNames(style.icon, style.green)}>
                  done
                </EdsIcon>
                <FormattedMessage id="welcome.welcomeFree.offers.challenges" />
              </li>
            </ul>
          </div>
        </div>

        <div className={style.offerImageContainer}>
          <div className={style.offerImage} />
        </div>
      </div>
    </div>
  );
};
export default OfferingSection;
