import React from 'react';
import { ReactComponent as DangerousIcon } from 'styles/img/icons/dangerous.svg';
import { ReactComponent as WarningIcon } from 'styles/img/icons/warning.svg';
import { ReactComponent as InfoIcon } from 'styles/img/icons/info.svg';

const alertTheme = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        info: <InfoIcon />,
        warning: <WarningIcon />,
        error: <DangerousIcon />,
      },
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '16px',
        margin: '16px 0',
        fontSize: '14px',
      },
      icon: {
        color: 'inherit !important',
      },
      standardSuccess: {
        color: '#0f5132',
        backgroundColor: '#d1e7dd',
        borderColor: '#badbcc',
      },
      standardError: {
        color: '#9C1C31',
        backgroundColor: '#FFEDEE',
        borderColor: '#FFEDEE',
      },
      standardWarning: {
        color: '#6B5000',
        background: '#FFF7E0',
        borderColor: '#FFF7E0',
      },
      standardInfo: {
        color: '#15587A',
        backgroundColor: '#EDF6FD',
        borderColor: '#EDF6FD',
      },
      sideLine: {
        color: '#000000',
        border: 'none',
        borderLeft: '4px solid #19808E',
        borderRadius: '4px',
        backgroundColor: '#F5F5F5',
        fontSize: '14px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        marginBottom: 0,
        fontSize: '14px',
      },
    },
  },
};

export default alertTheme;
