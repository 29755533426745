// @flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { get } from 'service/http/http-client';
import withSpinner from 'ui/common/spinner/withSpinner';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import Footer from 'ui/layout/Footer';
import BackgroundSection from 'ui/common/components/BackgroundSection';
import wellpassLogo from 'styles/img/wellpass-logo-white.svg';
import ErrorPage from 'ui/error/ErrorPage';
import MultiEntitySignupInfoSection from 'ui/multi-entity-signup/components/MultiEntitySignupInfoSection';
import { withPageTemplate } from 'ui/layout/PageTemplate.jsx';
import { useHeaderBackgroundImage } from '../common/hooks';

const MultiEntitySignupComponent = () => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { slug } = useParams();

  const [error, setError] = useState(0);
  const [multiEntitySignupPage, setMultiEntitySignupPage] = useState({});

  const headerTitleKey = 'signupPage.headerTitle';

  const validateResponseStatus = response => {
    if (!response.ok) {
      setError(response.status);
    }

    return response.json();
  };

  const gymBackgroundImage = useHeaderBackgroundImage();

  const getMultiEntitySignupPageBySlug = useCallback(async currentSlug => {
    try {
      const multiEntitySignupPage = await get(
        `/v1/multi-entity-signup-page/slug/${currentSlug}`
      ).then(response => validateResponseStatus(response));
      setMultiEntitySignupPage(multiEntitySignupPage);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    if (slug) {
      executeWithSpinner(getMultiEntitySignupPageBySlug(slug));
    }
  }, [executeWithSpinner, getMultiEntitySignupPageBySlug, slug]);

  if (error > 0) {
    const Component = withPageTemplate(ErrorPage, { headerTitle: headerTitleKey });
    return <Component />;
  }

  return (
    <>
      <div className="d-flex flex-column min-vh-100 multi-entity-signup">
        <BackgroundSection
          className="header-bg-color-height"
          backgroundImageUrl={gymBackgroundImage}
        >
          <MultiEntitySignupInfoSection
            logo={(multiEntitySignupPage && multiEntitySignupPage.logo) || wellpassLogo}
            dropdownLabel={multiEntitySignupPage.dropdownLabel}
            linkedSignupPages={multiEntitySignupPage.linkedSignupPages || []}
          />
        </BackgroundSection>
        <Footer />
      </div>
    </>
  );
};

const MultiEntitySignupPage = props => <MultiEntitySignupComponent {...props} />;

export default withSpinner(injectIntl(MultiEntitySignupPage));
