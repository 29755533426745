import React from 'react';
import RegistrationContext from './RegistrationContext';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withSelfSignupStepTemplate } from 'ui/self-signup/SelfSignupStepTemplate';
import DefaultTextFormField from 'ui/self-signup/components/DefaultTextFormField';
import GenderDropdownFormField from 'ui/self-signup/components/GenderDropdownFormField';
import SearchAddress from '../common/components/SearchAddress/SearchAddress';
import { Alert } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CustomAlert from '../common/components/CustomAlert';
import DeclarationOfConsentAlertSection from './DeclarationOfConsentAlertSection';
import { getEligibilityStatusError } from './SignupUtil';
import type { MembershipOffer } from './types';
import AdditionalVerificationAlertSection from './AdditionalVerificationAlertSection';
import AdditionalVerificationContainer from './components/hris/AdditionalVerificationContainer';
import AdditionVerificationInfoModal from './components/hris/AdditionalVerificationInfoModal';
import { AMERICA } from '../../config/i18n/countryCodes';

type Props = {
  isPartnerSignup?: boolean,
  signupPageOffer: MembershipOffer,
};

const Step2 = ({ isPartnerSignup, signupPageOffer }: Props) => {
  const {
    existingUser,
    alertNotification,
    declarationOfConsentDetails,
    setDeclarationOfConsentDetails,
    additionalVerification,
    employeeEligibilityStatus,
    setEmployeeEligibilityStatus,
  } = React.useContext(RegistrationContext);

  const eligibilityStatusError = getEligibilityStatusError(employeeEligibilityStatus);
  const eligibilityInfo = signupPageOffer?.integrationScopeId
    ? 'corpControlEnabledEligibilityInfo'
    : 'corpControlDisabledEligibilityInfo';
  const [openAdditionalVerificationModal, setOpenAdditionalVerificationModal] =
    React.useState(false);
  const isUSOffer = signupPageOffer?.sfAccountCountryCode === AMERICA;

  const MessageElements = {
    strong: chunks => <strong>{chunks}</strong>,
    br: () => <br />,
    ul: chunks => <ul>{chunks}</ul>,
    li: chunks => <li>{chunks}</li>,
  };

  const { control, register } = useFormContext();

  return (
    <div className={'container'}>
      <h3 className="text-dark mt-3">
        <FormattedMessage id="signupPage.step2.title" />
      </h3>
      <div className="text-start mt-3">
        {!isPartnerSignup && (
          <Alert severity="info">
            <FormattedMessage id={`signupPage.${eligibilityInfo}`} values={MessageElements} />
          </Alert>
        )}
      </div>
      {existingUser && (
        <>
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <div className="container">
                <GenderDropdownFormField
                  control={control}
                  register={register}
                  controlId="reviewedGender"
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-sm-6">
              <div className="container">
                <DefaultTextFormField control={control} controlId="reviewedFirstName" />
              </div>
            </div>

            <div className="col-12 my-2 d-md-none" />
            <div className="col-12 col-md-6">
              <div className="container">
                <DefaultTextFormField control={control} controlId="reviewedLastName" />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <div className="container">
                <DefaultTextFormField control={control} controlId="reviewedBirthday" />
              </div>
            </div>
          </div>
          {(isPartnerSignup || isUSOffer) && (
            <SearchAddress
              controlId="searchAddressValidation"
              control={control}
              needTimeZoneId={isUSOffer}
            />
          )}
        </>
      )}
      {!isPartnerSignup && eligibilityStatusError?.displayAlert && (
        <AdditionalVerificationAlertSection
          openModal={openAdditionalVerificationModal}
          setOpenModal={setOpenAdditionalVerificationModal}
          showAlert={!additionalVerification.showField}
        />
      )}
      {!isPartnerSignup && additionalVerification.showField && !openAdditionalVerificationModal && (
        <div className="container mb-3">
          <div className="col-12 p-0">
            <AdditionalVerificationContainer containerClass="additional-verification-container" />
          </div>
        </div>
      )}

      {!isPartnerSignup && eligibilityStatusError?.displayAlert && (
        <Alert severity="info">
          <FormattedMessage
            id={`additionalVerificationOptionAlert.info.title`}
            values={MessageElements}
          />
        </Alert>
      )}
      {!isPartnerSignup &&
        eligibilityStatusError &&
        eligibilityStatusError.showModal &&
        eligibilityStatusError.id && (
          <AdditionVerificationInfoModal
            errorType={eligibilityStatusError.id}
            isOpen={eligibilityStatusError.showModal}
            variant={eligibilityStatusError.severity}
            onRequestClose={() =>
              setEmployeeEligibilityStatus({
                ...employeeEligibilityStatus,
                [eligibilityStatusError.id]: false,
              })
            }
          />
        )}
      <DeclarationOfConsentAlertSection
        declarationOfConsentDetails={declarationOfConsentDetails}
        alertDetails={alertNotification?.reviewedBirthday?.warning}
        setDeclarationOfConsentDetails={setDeclarationOfConsentDetails}
      />
      {alertNotification?.duplicateSignUp?.warning && (
        <CustomAlert
          controlId={'duplicateSignUpWarning'}
          info={alertNotification.duplicateSignUp?.warning}
          className={'warning'}
        />
      )}
    </div>
  );
};

export default withSelfSignupStepTemplate(injectIntl(Step2));
