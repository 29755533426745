import React from 'react';

import { FormattedMessage } from 'react-intl';

const ColleagueSignupErrorPage = () => (
  <React.Fragment>
    <div className="container">
      <div>
        <h2 id="colleagueSignup.error.title" className="my-5 text-dark">
          <FormattedMessage id={`colleagueSignupErrorPage.title`} />
        </h2>

        <p>
          <FormattedMessage id={`colleagueSignupErrorPage.part1`} />
        </p>

        <p className="my-2">
          <FormattedMessage id={`colleagueSignupErrorPage.part2`} />
        </p>
      </div>
    </div>
  </React.Fragment>
);

export default ColleagueSignupErrorPage;
