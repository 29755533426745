// @flow
import { getCookie } from 'service/http/cookieUtil';
import {
  CSRF_TOKEN_COOKIE_NAME,
  CSRF_TOKEN_HEADER_NAME,
} from 'ui/common/authentication/AuthenticationContext';
import { USER_LOCAL_STORAGE_KEY } from 'ui/common/authentication/AuthenticationContext';

type RequestHeaders = {
  [key: string]: string,
};

const isDev = () => {
  return process.env.NODE_ENV !== 'production';
};

// https://scotch.io/tutorials/how-to-use-the-javascript-fetch-api-to-get-data
export const get = (url: string): Promise<Response> => {
  const promise = fetch(url, {
    method: 'get',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
    },
    credentials: 'same-origin',
  });

  promise.then(response => {
    if (
      response.status === 401 &&
      window.location.pathname !== '/login' &&
      !window.location.pathname.includes('/signup')
    ) {
      //Removing cookie is not needed, because it is expired, and will be overwritten on next login.
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      window.location.href = '/login';
    }
  });

  return promise;
};

export const post = (url: string, postBody: Object): Promise<Response> => {
  const promise = fetch(url, {
    method: 'post',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    credentials: 'same-origin',
    body: JSON.stringify(postBody),
  });

  promise.then(response => {
    if (
      response.status === 401 &&
      window.location.pathname !== '/login' &&
      !window.location.pathname.includes('/signup')
    ) {
      //Removing cookie is not needed, because it is expired, and will be overwritten on next login.
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      window.location.href = '/login';
    }
  });

  return promise;
};

export const put = (url: string, postBody: Object): Promise<Response> => {
  const promise = fetch(url, {
    method: 'put',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    credentials: 'same-origin',
    body: JSON.stringify(postBody),
  });

  promise.then(response => {
    if (
      response.status === 401 &&
      window.location.pathname !== '/login' &&
      !window.location.pathname.includes('/signup')
    ) {
      //Removing cookie is not needed, because it is expired, and will be overwritten on next login.
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      window.location.href = '/login';
    }
  });

  return promise;
};

/** HTTP Get with error handling. */
export const httpGet = (
  url: string,
  headers: RequestHeaders = {},
  handleResponseFunction: Function = handleResponse
): Promise<any> => {
  const promise = fetch(url, {
    method: 'get',
    headers: {
      ...headers,
      Accept: 'application/json',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    credentials: 'same-origin',
  }).then(handleResponseFunction);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP GET request succeeded for endpoint:', url))
      .catch(error => console.log('HTTP GET request failed with error:', error, 'endpoint:', url));
  }
  return promise;
};

/** HTTP Post with error handling. */
export const httpPost = (url: string, body: Object, headers: RequestHeaders = {}): Promise<any> => {
  const promise = fetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    credentials: 'same-origin',
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP POST request succeeded for endpoint:', url))
      .catch(error => console.log('HTTP POST request failed with error:', error, 'endpoint:', url));
  }
  return promise;
};

export const httpPatch = (
  url: string,
  body: Object,
  headers: RequestHeaders = {}
): Promise<any> => {
  const promise = fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    credentials: 'same-origin',
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP PATCH request succeeded for endpoint:', url))
      .catch(error =>
        console.log('HTTP PATCH request failed with error:', error, 'endpoint:', url)
      );
  }
  return promise;
};

export const httpPostUpload = (
  url: string,
  files: File[],
  headers: RequestHeaders = {}
): Promise<any> => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });
  const promise = fetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    body: formData,
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP POST upload request succeeded for endpoint:', url))
      .catch(error =>
        console.log('HTTP POST upload request failed with error:', error, 'endpoint:', url)
      );
  }
  return promise;
};

const handleResponse = response => {
  if (response.ok) {
    // in case the response body is empty, response.json() will fail. That is why we need the catch()
    return response.json().catch(() => Promise.resolve());
  } else if (
    response.status === 401 &&
    window.location.pathname !== '/login' &&
    !window.location.pathname.includes('/signup')
  ) {
    //Removing cookie is not needed, because it is expired, and will be overwritten on next login.
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    window.location.href = '/login';
  }
  return response.json().then(error => Promise.reject(error));
};
