// @flow
import React, { useContext } from 'react';
import Modal from '../../../common/modal/Modal';
import { FormattedMessage } from 'react-intl';
import styles from '../../../common/modal/consentDeclarationModal.module.scss';
import AdditionalVerificationContainer from './AdditionalVerificationContainer';
import RegistrationContext from '../../RegistrationContext';

type Props = {
  isOpen: boolean,
  closable?: boolean,
  onRequestClose: Function,
  modalTitle?: string,
  modalClass?: string,
  modalSizeClass?: any,
  noFooter?: boolean,
  proceedAction?: Function,
};

const VerificationOptionModal = ({
  isOpen = false,
  closable = true,
  onRequestClose,
  modalTitle = <FormattedMessage id="additionalVerificationModal.title" />,
  modalClass = 'verification-option-modal',
  modalSizeClass,
  noFooter = true,
}: Props) => {
  const { additionalVerification } = useContext(RegistrationContext);

  return (
    <Modal
      modalTitle={modalTitle}
      modalClass={modalClass}
      modalSizeClass={modalSizeClass}
      isOpen={isOpen}
      closable={closable}
      onRequestClose={onRequestClose}
      noFooter={noFooter}
    >
      <div className={styles.bodyContainer}>
        <FormattedMessage id="additionalVerificationModal.body"></FormattedMessage>
      </div>
      <div className={'mx-4'}>
        <AdditionalVerificationContainer />
      </div>

      <div className="py-4 px-4 d-md-flex justify-content-end">
        <div className="col-12 col-md-5">
          <button
            id="verification-option-continue-btn"
            onClick={() => {
              onRequestClose();
            }}
            type="button"
            className="btn btn-primary w-100"
            disabled={additionalVerification.value === ''}
          >
            <FormattedMessage id="declarationOfConsentModal.button.continue" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VerificationOptionModal;
