import { httpGet } from 'service/http/http-client';

export class GeocodingPlacesApi {
  getAutocompletePlacePredictions = (location, sessionToken) => {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('location', location);

    if (sessionToken) {
      urlSearchParams.append('session-token', sessionToken);
    }

    let url = `/v1/place/autocomplete?${urlSearchParams.toString()}`;

    return httpGet(url, { 'Accept-Language': 'de' }, null).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET autocomplete returned ' + response.status);
      }
    });
  };

  getPlaceDetails = (placeId, sessionToken, needTimeZoneId) => {
    let urlSearchParams = new URLSearchParams();
    if (needTimeZoneId) {
      urlSearchParams.append('timeZone', true);
    }
    if (sessionToken) {
      urlSearchParams.append('session-token', sessionToken);
    }

    let url = `/v1/place/${placeId}?${urlSearchParams.toString()}`;

    return httpGet(url, { 'Accept-Language': 'de' }, null).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET place details returned ' + response.status);
      }
    });
  };
}
