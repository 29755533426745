import React from 'react';
import type { IntlShape } from 'react-intl';
import { injectIntl } from 'react-intl';

type Props = {
  intl: IntlShape,
};

const NoActiveMembershipNote = ({ intl: { formatMessage } }: Props) => {
  const body = formatMessage({ id: 'myMembershipsPage.noActiveMembership' });

  return (
    <p id="myMembershipsPage.noActiveMembership" className={'alert alert-dark small'}>
      {body}
    </p>
  );
};

export default injectIntl(NoActiveMembershipNote);
