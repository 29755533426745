import React, { useEffect } from 'react';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import CurrentMembership from './CurrentMembership';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';

import useApi from 'service/api';
import { FORM_ERROR } from 'final-form';
import CancelMembershipForm from './CancelMembershipForm';
import { FormattedMessage } from 'react-intl';
import NoActiveMembershipNote from './NoActiveMembershipNote';
import {
  CancellationReason,
  getMembershipToDisplay,
  getMinifiedMembershipFromWrapper,
  isFutureMembership,
  replaceCancellationReason,
} from '../../service/membershipUtil';

const MyMembershipsPage = () => {
  const { user } = React.useContext(AuthenticationContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  let [membershipAndOffer, setMembershipAndOffer] = React.useState('');
  const { userMembershipsApi } = useApi();

  useEffect(() => {
    executeWithSpinner(
      userMembershipsApi
        .fetchUserActiveMemberships()
        .then(membershipsAndOffers => {
          setMembershipAndOffer(
            replaceCancellationReason(
              getMembershipToDisplay(membershipsAndOffers),
              CancellationReason.API_END_OF_EMPLOYMENT,
              CancellationReason.B2C_END_OF_EMPLOYMENT
            )
          );
        })
        .catch(errorMessage => {
          return {
            [FORM_ERROR]: errorMessage,
          };
        })
    );
  }, [userMembershipsApi, executeWithSpinner, setMembershipAndOffer, user.id]);

  const membershipUpdate = patchedMembership => {
    const updatedMembership = replaceCancellationReason(
      getMinifiedMembershipFromWrapper(patchedMembership),
      CancellationReason.API_END_OF_EMPLOYMENT,
      CancellationReason.B2C_END_OF_EMPLOYMENT
    );
    setMembershipAndOffer(updatedMembership);
  };

  return (
    <>
      <div className="container mb-5">
        <div className="text-start mt-4">
          <h2 className="text-dark my-5">
            {membershipAndOffer ? (
              isFutureMembership(membershipAndOffer.membership) ? (
                <FormattedMessage id="myMembershipsPage.futureMembership.header" />
              ) : (
                <FormattedMessage id="myMembershipsPage.activeMembership.header" />
              )
            ) : (
              <FormattedMessage id="myMembershipsPage.noActiveMembershipHeader" />
            )}
          </h2>
        </div>
        {membershipAndOffer ? (
          <div className="text-start mt-2">
            <CurrentMembership membershipWrapper={membershipAndOffer} />
            <hr />
            <CancelMembershipForm
              membershipWrapperInput={membershipAndOffer}
              onEndMembershipSuccess={membershipUpdate}
            />
          </div>
        ) : (
          <NoActiveMembershipNote />
        )}
      </div>
    </>
  );
};

export default withPageTemplate(MyMembershipsPage, {
  headerTitle: 'myMembershipsPage.headerTitle',
});
