import { useCallback, useContext, useEffect, useState } from 'react';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import useApi from 'service/api';
import { compareObjectsAsc } from 'util/object/objectUtils';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { cloneDeep } from 'lodash';

export type CompanyType = {
  entityId: string,
  authority: string,
  entityName: string,
};

export type ManagerType = {
  accountId: string,
  email: string,
  firstName: string,
  lastName: string,
  userAuthorityCompanyDetails: CompanyType[],
};

const compareCompanies = compareObjectsAsc.bind(null, 'entityName');

const compareManagers = compareObjectsAsc.bind(null, 'firstName');

export const useCompanyManagers = (fetch = true) => {
  const { user } = useContext(AuthenticationContext);
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [companies, setCompanies] = useState([]);
  const [managers, setManagers] = useState([]);
  const { companyApi } = useApi();

  const fetchCompaniesAndManagers = useCallback(async () => {
    if (!user?.roles?.length || !setCompanies || !setManagers || !companyApi) {
      return;
    }
    const sfAccountCanonicalIds = user.roles.flatMap(obj => obj.entityIds);
    return companyApi
      .fetchCompanies(
        sfAccountCanonicalIds.length
          ? `sfAccountCanonicalIds=${sfAccountCanonicalIds.join(',')}`
          : ''
      )
      .then(companyResponse => {
        setCompanies(companyResponse.sort(compareCompanies));
        const companyIds = companyResponse.map(obj => obj.entityId);
        companyApi
          .fetchManagers(companyIds.length ? `sfAccountCanonicalIds=${companyIds.join(',')}` : '')
          .then(managersResponse => setManagers(managersResponse.sort(compareManagers)))
          .catch(() => setManagers([]));
      })
      .catch(() => {
        setCompanies([]);
        setManagers([]);
      });
  }, [user?.roles, setCompanies, setManagers, companyApi]);

  useEffect(() => {
    if (fetch && fetchCompaniesAndManagers && executeWithSpinner) {
      executeWithSpinner(fetchCompaniesAndManagers());
    }
  }, [fetch, fetchCompaniesAndManagers, executeWithSpinner]);

  const onManagerAdded = manager => {
    if (manager && Array.isArray(managers)) {
      setManagers([manager, ...managers]);
    }
  };

  const onManagerEdited = (manager, updatedRoles) => {
    if (manager && managers?.length) {
      setManagers(updateManagerRoles(manager, managers, updatedRoles));
    }
  };
  return { companies, managers, fetchCompaniesAndManagers, onManagerAdded, onManagerEdited };
};

export const updateManagerRoles = (manager, managers, updatedRoles) => {
  const managersCopy = cloneDeep(managers);
  const existingManager = managersCopy.find(obj => obj.accountId === manager.accountId);
  const managerCopy = { ...existingManager };
  managerCopy.userAuthorityCompanyDetails =
    updatedRoles?.flatMap?.(({ authority, entityIds }) =>
      entityIds.map(entityId => ({ entityId, authority }))
    ) || [];

  for (let i = 0; i < managersCopy.length; i++) {
    if (managersCopy[i].accountId === manager.accountId) {
      managersCopy[i] = managerCopy;
    }
  }

  return managersCopy;
};
