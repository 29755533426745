// @flow
import { formatIban } from 'util/format/ibanFormatter';

const convertDateToIso = (date: string): string => {
  if (date && date.length === 10) {
    return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
  }
  return date;
};

const convertAddress = searchAddress => {
  if (searchAddress) {
    return {
      street: searchAddress.street,
      houseNumber: searchAddress.streetNumber,
      zipCode: searchAddress.zipCode,
      city: searchAddress.city,
    };
  }
  return {};
};

export const convertToRequestBody = (values: any): any => ({
  // Personal Info
  gender: values.gender,
  lastName: values.lastName,
  firstName: values.firstName,
  birthday: convertDateToIso(values.birthday),
  email: values.email,
  employeeIdentifier: values.employeeIdentifier,
  locale: values.locale,
  // Start Date
  membershipStartDate: values.membershipStartDate,
  bankAccountHolderName: values.bankAccountHolderName,
  iban: formatIban(values.iban),
  password: values.password,
  selfSignupPageToken: values.signupPageToken,
  shareLinkToken: values.shareLinkToken,
  offerId: values.offerId,
  paymentDetailsModified: values.isPaymentDetailsModified,
  //User's reviewed details
  reviewedFirstName: values.reviewedFirstName,
  reviewedLastName: values.reviewedLastName,
  reviewedBirthday: convertDateToIso(values.reviewedBirthday),
  reviewedGender: values.reviewedGender,
  workEmail: values.workEmail,
  timeZoneId: values.timeZoneId,
  ...convertAddress(values.searchAddress),
});
