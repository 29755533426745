import { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalStorage } from '../local-storage/localStorage';
import { isDev } from '../../util/env/envUtils';

export const useLocalStorage = (key: string, initialValue?) => {
  const [value, setValue] = useState(initialValue);
  const localStorage = useMemo(() => new LocalStorage(), []);

  const setLocalStorageValue = useCallback(
    newValue => {
      if (key) {
        setValue(newValue);
        localStorage.setValue(key, newValue);
      }
    },
    [key, setValue, localStorage]
  );

  useEffect(() => {
    if (key) {
      try {
        const storedValue = localStorage.readValue(key);
        if (storedValue !== null) {
          setValue(storedValue);
        } else if (initialValue && initialValue !== null) {
          setLocalStorageValue(initialValue);
        }
      } catch (e) {
        if (isDev()) {
          console.log(
            `Could not parse the localStorage item under the key: '${key}'. Fallback to its initial value: '${initialValue}'`
          );
          console.log(e.message);
        }
      }
    }
  }, [key, localStorage, initialValue, setLocalStorageValue]);

  const removeLocalStorageValue = () => {
    setValue(undefined);
    localStorage.removeItem(key);
  };

  return [value, setLocalStorageValue, removeLocalStorageValue];
};
