// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import HeaderNavigation from './HeaderNavigation';

type Props = {
  headerTitle?: string,
  intl: IntlShape,
  isProtected?: boolean,
};

const Header = ({ headerTitle, isProtected = false }: Props) => {
  return (
    <header>
      <HeaderNavigation isProtected={isProtected} />
      <section className="container mt-2">
        <h1
          className="text-center text-white pt-4 dropdown-padding"
          id={headerTitle ? `${headerTitle}.id` : ''}
        >
          {headerTitle && <FormattedMessage className="text-white" id={headerTitle} />}
        </h1>
      </section>
    </header>
  );
};

export default Header;
