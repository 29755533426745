import { useEffect, useState } from 'react';
import useApi from 'service/api';

export const useGymCount = ({ searchFilters }) => {
  const [filters] = useState(searchFilters);
  const [gymsCount, setGymsCount] = useState();
  const { gymFinderApi } = useApi();

  useEffect(() => {
    gymFinderApi
      .fetchGymsCount(filters)
      .then(p => setGymsCount(p))
      .catch(error => setGymsCount(undefined));
  }, [gymFinderApi, setGymsCount, filters]);

  return [gymsCount];
};
