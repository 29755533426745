import { get } from 'service/http/http-client';

export class CompanyApi {
  fetchCompanies = searchFilters => {
    const queryParams = searchFilters;
    return get(`/v1/company?${queryParams}`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET companies returned ' + response.status);
      }
    });
  };

  fetchManagers = searchFilters => {
    const queryParams = searchFilters;
    return get(`/v1/company/user/manager?${queryParams}`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET managers returned ' + response.status);
      }
    });
  };
}
