// @flow
const footerNavigation = {
  title: 'footer.title',
  links: [
    {
      text: 'footer.links.imprint.text',
      url: 'footer.links.imprint.url',
    },
    {
      text: 'footer.links.privacyPolicy.text',
      url: 'footer.links.privacyPolicy.url',
    },
    {
      text: 'footer.links.termsAndConditions.text',
      url: 'footer.links.termsAndConditions.url',
    },
    {
      text: 'footer.links.cookiePolicy.text',
      url: 'footer.links.cookiePolicy.url',
    },
  ],
};

export default footerNavigation;
