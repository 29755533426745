import React, { useContext, useState } from 'react';
import type { MembershipWrapperType } from 'ui/admin/companies/memberships/types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'ui/common/modal/Modal';
import type { IntlShape } from 'react-intl';
import { httpPatch } from 'service/http/http-client';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { FORM_ERROR } from 'final-form';
import { formatDate } from '../../../../service/dateUtil';
import TextFormField from 'ui/common/components/TextFormField';
import PersonalIdNote from './PersonalIdNote';
import { Alert } from '@mui/material';
import Form from 'ui/common/components/Form';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  onEditPersonalIdSuccess: Function,
  membershipWrapper: MembershipWrapperType,
  intl: IntlShape,
};

const EditPersonalIdModal = ({
  isOpen,
  onRequestClose,
  onEditPersonalIdSuccess,
  membershipWrapper,
  intl: { formatMessage },
}: Props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPersonalIdCompliant, setIsPersonalIdCompliant] = useState(true);
  const { executeWithSpinner } = useContext(SpinnerContext);

  const personalIdLabel = membershipWrapper.membershipOfferDto.employeeInternalIdentifierLabel
    ? membershipWrapper.membershipOfferDto.employeeInternalIdentifierLabel
    : '';
  const personalIdValidationRegex =
    membershipWrapper.membershipOfferDto.employeeInternalIdentifierValidationRegex;
  const personalIdValidationDescription =
    membershipWrapper.membershipOfferDto.employeeInternalIdentifierValidationDescription;

  const modalTitle = formatMessage(
    { id: 'editPersonalIdModal.title' },
    { personalId: personalIdLabel }
  );
  const errorMessage = formatMessage({ id: 'editPersonalIdModal.error' });

  const savePersonalId = (values: any) => {
    const patchBody = {
      employeeIdentifier: values.personalId,
      userId: membershipWrapper.membership.userId,
    };
    const membershipUUID = membershipWrapper.membership.uuid;
    const companyId = membershipWrapper.membershipOfferDto.sfAccountCanonicalId;

    if (!companyId) {
      return Promise.reject(errorMessage);
    }

    const patchMembership = async () => {
      try {
        const patchedMembership = await httpPatch(
          `/v1/company/${companyId}/membership/${membershipUUID}`,
          patchBody
        );
        onEditPersonalIdSuccess(patchedMembership);
        onRequestClose();
      } catch (errorMessage) {
        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    };
    return executeWithSpinner(patchMembership());
  };

  const validatePersonalId = (values: any) => {
    const { personalId } = values;

    if (!personalId) {
      setIsPersonalIdCompliant(true);
      setIsButtonDisabled(true);
      return;
    }

    setIsButtonDisabled(false);
    if (!personalIdValidationRegex) {
      return;
    }

    setIsPersonalIdCompliant(new RegExp(personalIdValidationRegex).test(personalId));
  };

  return (
    <Modal
      isOpen={isOpen}
      modalSizeClass="modal-md"
      modalClass="edit-personal-id-modal"
      onRequestClose={onRequestClose}
      modalTitle={modalTitle}
      noFooter={true}
      closeButtonId="editPersonalIdModal.btn.close"
    >
      <Form
        onSubmit={savePersonalId}
        validator={validatePersonalId}
        defaultValues={{
          personalId: membershipWrapper.membership.employeeIdentifier,
        }}
      >
        {({ control, register, formState: { errors, isDirty } }) => (
          <>
            <div className="mb-4">
              <div className="mb-2">
                <FormattedMessage id="editPersonalIdModal.user.name" />
                {`: ${membershipWrapper.user.firstName} ${membershipWrapper.user.lastName}`}
              </div>
              <div className="mb-2">
                <FormattedMessage id="editPersonalIdModal.user.dateOfBirth" />
                {`: ${formatDate(membershipWrapper.user.birthday)}`}
              </div>
            </div>
            <div>
              <TextFormField
                control={control}
                register={{ ...register('personalId') }}
                controlId="personalId"
                required={false}
                placeholder={personalIdLabel}
                controlLabel={personalIdLabel}
                containerClassName="container p-0"
              />
            </div>

            {personalIdValidationDescription && (
              <PersonalIdNote classes="mt-3" validationRule={personalIdValidationDescription} />
            )}

            {!isPersonalIdCompliant && (
              <Alert severity="warning" className="my-3">
                <FormattedMessage id="editPersonalIdModal.regexWarning" />
              </Alert>
            )}

            {errors.length > 0 && (
              <Alert severity="error" className="my-3">
                <FormattedMessage id="editPersonalIdModal.error" />
              </Alert>
            )}

            <div className="d-grid mt-4">
              <button
                id="editPersonalIdModal.btn.savePersonalId"
                type="submit"
                className="btn btn-secondary"
                disabled={isButtonDisabled || !isDirty}
              >
                <FormattedMessage id="editPersonalIdModal.btn.savePersonalId" />
              </button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default injectIntl(EditPersonalIdModal);
