import React, { useEffect, useState } from 'react';
import CloseButton from '../../../common/components/CloseButton';
import { useLocalStorage } from '../../../../service/hooks';
import AuthenticationContext from '../../../common/authentication/AuthenticationContext';
import CompanyUserContext from '../../../common/authentication/CompanyUserContext';
import { FormattedMessage, useIntl } from 'react-intl';

const CompanyAdminFeedbackBanner = () => {
  const intl = useIntl();
  const [showBanner, setShowBanner] = useLocalStorage('SHOW_COMPANY_FEEDBACK_BANNER');
  const [show, setShow] = useState(true);
  const { user } = React.useContext(AuthenticationContext);
  const { selectedCompanyId } = React.useContext(CompanyUserContext);

  useEffect(() => {
    setShow(showBanner === undefined || showBanner === null || showBanner);
  }, [showBanner, setShow]);

  const onCloseFeedBack = e => {
    setShowBanner(false);
  };

  const onSubmit = e => {
    if (user) {
      const queryParams = new URLSearchParams();
      queryParams.append('source', 'web-portal');
      queryParams.append('email', user.email);
      if (selectedCompanyId) {
        queryParams.append('sfaccountid', selectedCompanyId);
      }
      const url = intl.formatMessage({ id: 'feedbackBanner.link' });
      window.open(`${url}#${queryParams.toString()}`);
    }
  };

  return (
    <>
      {show && (
        <div className="d-flex feedback-banner position-relative">
          <div className="position-absolute end-0 pt-1 pe-3">
            <CloseButton
              buttonId="feedbackBanner.btn.close"
              onClick={onCloseFeedBack}
              variant="text-white"
            />
          </div>
          <div className="d-flex flex-row container my-3 ms-3 feedback-container">
            <div className="flex-column">
              <h5 className="text-white fw-bold w-75">
                <FormattedMessage id={'feedbackBanner.headline'} />
              </h5>
              <p className="feedback-p mb-0">
                <FormattedMessage id={'feedbackBanner.body'} />
              </p>
            </div>
            <div className="flex-column align-self-center">
              <button
                className="btn btn-md btn-white"
                onClick={onSubmit}
                id="feedbackBanner.btn.fillSurvey"
              >
                <span className="fw-bold text-nowrap feedback-button">
                  <FormattedMessage id={'feedbackBanner.button'} />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyAdminFeedbackBanner;
