import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withPageTemplate } from 'ui/layout/PageTemplate';

const DoubleOptInFailurePage = () => (
  <div className="container">
    <h2 className="text-center mt-5 mb-3 text-dark" style={{ whiteSpace: 'pre-line' }}>
      <FormattedMessage id="doubleOptInFailurePage.title" />
    </h2>
    <div className="text-start mt-4">
      <FormattedMessage id="doubleOptInFailurePage.description" />
    </div>
    <div className="text-center mt-4 mb-3">
      <a className="btn btn-secondary" href="https://egym-wellpass.com/faq/">
        <FormattedMessage id="doubleOptInFailurePage.contactSupportBtn" />
      </a>
    </div>
  </div>
);

export default withPageTemplate(DoubleOptInFailurePage, {
  headerTitle: 'doubleOptInFailurePage.headerTitle',
});
