import { useCallback, useContext, useEffect, useState } from 'react';
import AuthenticationContext, {
  HAS_PAYMENT_LOCAL_STORAGE_KEY,
} from 'ui/common/authentication/AuthenticationContext';
import useApi from 'service/api';
import { useLocalStorage } from './localStorage';

export const usePayment = (fetch = true) => {
  const { user } = useContext(AuthenticationContext);
  const [hasPayment, setHasPayment, removeHasPayment] = useLocalStorage(
    HAS_PAYMENT_LOCAL_STORAGE_KEY,
    undefined
  );
  const [payment, setPayment] = useState();
  const { paymentApi } = useApi();

  const fetchPayment = useCallback(
    () =>
      paymentApi
        .fetchPayments()
        .then(p => {
          setPayment(p);
          setHasPayment(!!p);
        })
        .catch(() => {
          setPayment(undefined);
          removeHasPayment();
        }),
    [paymentApi, setPayment, setHasPayment, removeHasPayment]
  );

  useEffect(() => {
    if (fetch && user) {
      fetchPayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetch]);

  const updatePayment = useCallback(
    async updatedPayment => {
      if (user) {
        await paymentApi.updatePayment(updatedPayment);
        return setPayment(updatedPayment);
      } else {
        return Promise.reject(new Error('User is not defined'));
      }
    },
    [user, paymentApi, setPayment]
  );

  // return [payment, updatePayment, hasPayment, fetchPayment];
  return {
    payment,
    updatePayment,
    hasPayment,
    fetchPayment,
  };
};
