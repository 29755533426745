// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ReadyForMembershipSection = () => (
  <div>
    <div className="row justify-content-center">
      <div className="mb-1 text-center">
        <h3 className="fw-bold text-dark">
          <FormattedMessage id="welcome.memberships.title" />
        </h3>
      </div>
    </div>
    <div className="row">
      <div className="mt-3 d-flex justify-content-center">
        <button
          id="selfsignup.btn.register.intro"
          className="btn btn-lg btn-primary text-nowrap"
          type="submit"
        >
          <FormattedMessage id="welcome.intro.button" />
        </button>
      </div>
    </div>
  </div>
);

export default injectIntl(ReadyForMembershipSection);
