// @flow
import { FormattedMessage } from 'react-intl';
import * as React from 'react';

import type { ValidationResult } from 'validation/types';

type Props = {
  meta: {
    error: ValidationResult,
    submitError: any,
  },
  translatedControlLabel?: string, // the label should be translated by the calling component
};

const InvalidFieldErrorMessage = ({ meta, translatedControlLabel }: Props) => {
  return (
    <>
      {meta.error ? (
        <span className="invalid-feedback container" id={meta.error.code}>
          <FormattedMessage
            id={meta.error.code}
            values={{ fieldLabel: translatedControlLabel, ...meta.error.args }}
          />
        </span>
      ) : (
        <span className="invalid-feedback container" id={meta.submitError.code}>
          <FormattedMessage
            id={meta.submitError.code}
            values={{ fieldLabel: translatedControlLabel, ...meta.submitError.args }}
          />
        </span>
      )}
    </>
  );
};

export default InvalidFieldErrorMessage;
