// @flow
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import LabeledDropdown from 'ui/common/components/labeled-dropdown/LabeledDropdown.jsx';
import CompanyLogo from 'ui/common/components/company-logo/CompanyLogo';
import RadioButtonSelect from '../../common/components/radioButtonSelect/RadioButtonSelect';

type Props = {
  logo: string,
  dropdownLabel: string,
  linkedSignupPages: [],
};

const MultiEntitySignupInfoSection = ({ logo, dropdownLabel, linkedSignupPages }: Props) => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [dropdownValuesDefault, setDropdownValuesDefault] = useState();
  const [dropdownValuesFree, setDropdownValuesFree] = useState();
  const [selectedToken, setSelectedToken] = useState();
  const [selectedOfferType, setSelectedOfferType] = useState('STANDARD');
  const [showOfferTypeSelect, setShowOfferTypeSelect] = useState(false);

  const translateDropdownLabel = useCallback(
    label => {
      return selectedToken
        ? label
        : formatMessage({ id: 'multiEntitySignupPage.dropdownText' }, { label });
    },
    [formatMessage, selectedToken]
  );

  const handleChange = useCallback(newValue => {
    setSelectedToken(newValue);
  }, []);

  const handleProceed = useCallback(() => {
    if (selectedToken) {
      const searchParams = new URLSearchParams(search);

      searchParams.append('companyToken', selectedToken);
      navigate({ pathname: '/signup', search: searchParams.toString() });
    }
  }, [navigate, search, selectedToken]);

  const handelOfferTypeChange = value => {
    setSelectedToken(null);
    setSelectedOfferType(value);
  };

  useEffect(() => {
    if (linkedSignupPages) {
      let [dropdownValuesStandard, dropdownValuesFree, dropdownValuesOther] =
        linkedSignupPages.reduce(
          (sortedPages, lsp) => {
            if (lsp.type === 'STANDARD') {
              sortedPages[0].push({
                value: lsp.token,
                label: lsp.label,
              });
            } else if (lsp.type === 'FREE') {
              sortedPages[1].push({
                value: lsp.token,
                label: lsp.label,
              });
            } else {
              sortedPages[2].push({
                value: lsp.token,
                label: lsp.label,
              });
            }
            return sortedPages;
          },
          [[], [], []]
        );

      dropdownValuesStandard = dropdownValuesStandard.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      dropdownValuesFree = dropdownValuesFree.sort((a, b) => a.label.localeCompare(b.label));

      dropdownValuesOther = dropdownValuesOther.sort((a, b) => a.label.localeCompare(b.label));

      if (dropdownValuesStandard.length > 0 && dropdownValuesFree.length > 0) {
        setShowOfferTypeSelect(true);
        setDropdownValuesDefault(dropdownValuesStandard);
        setDropdownValuesFree(dropdownValuesFree);
      } else if (dropdownValuesStandard.length > 0) {
        setDropdownValuesDefault(dropdownValuesStandard);
      } else if (dropdownValuesFree.length > 0) {
        setDropdownValuesDefault(dropdownValuesFree);
      } else {
        setDropdownValuesDefault(dropdownValuesOther);
      }
    }
  }, [linkedSignupPages]);

  return !dropdownValuesDefault ? null : (
    <div className="row justify-content-center ie-fix multi-entity-signup-section">
      <div className="col col-md-10 text-center text-white align-self-center margins section-container">
        {logo && <CompanyLogo logo={logo} />}
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="fw-bold text-white text-break welcome-headline">
              <FormattedMessage id="multiEntitySignupPage.headline" />
            </h1>
          </div>
        </div>
        {showOfferTypeSelect && (
          <div className="main-container">
            <h2 className="text-white membership-select-headline justify-content-center row">
              <FormattedMessage id="multiEntitySignupPage.radioButtonSelect.selectHeadline" />
            </h2>
            <RadioButtonSelect
              items={[
                {
                  value: 'STANDARD',
                  label: 'multiEntitySignupPage.radioButtonSelect.standard',
                },
                {
                  value: 'FREE',
                  label: 'multiEntitySignupPage.radioButtonSelect.free',
                  helpText: 'multiEntitySignupPage.radioButtonSelect.tooltip',
                },
              ]}
              onChange={handelOfferTypeChange}
              name={'membershipOfferType'}
              initialValue={'STANDARD'}
            />
          </div>
        )}
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center main-container">
            <div className="labeled-dropdown-container">
              <LabeledDropdown
                label={translateDropdownLabel(dropdownLabel)}
                items={
                  selectedOfferType === 'STANDARD' ? dropdownValuesDefault : dropdownValuesFree
                }
                onChange={handleChange}
                value={selectedToken}
                variant={'light'}
                fillWidth={true}
                height={'48px'}
              />
            </div>
            <div className="button-container">
              <button
                id="selfsignup.btn.register.header"
                className="btn btn-lg btn-primary btn-shadow"
                type="submit"
                disabled={!selectedToken}
                onClick={handleProceed}
              >
                <FormattedMessage id="multiEntitySignupPage.continueButton" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiEntitySignupInfoSection;
