import React from 'react';
import messages from 'config/i18n/messages';
import { detectLocale, getCountryCodeFromLocale } from 'config/i18n/localeDetector';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/de';

const navigatorLocales = [];

if (navigator.languages) {
  navigatorLocales.push(...navigator.languages);
} else {
  navigatorLocales.push(navigator.language);
  navigatorLocales.push(navigator.userLanguage);
}

export const locale = detectLocale(navigatorLocales, messages);
export const localeCountryCode = getCountryCodeFromLocale(navigatorLocales);

type Props = {
  children: any,
};

const WithI18nSupport = ({ children }: Props) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    {children}
  </IntlProvider>
);

export default WithI18nSupport;
