import React from 'react';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import Modal from 'ui/common/modal/Modal';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  errorType: ?string,
  variant: string,
  btnClickAction?: Function,
  showAlert: boolean,
  values: any,
  intl: IntlShape,
};

const AdditionalVerificationInfoModal = ({
  isOpen,
  errorType,
  onRequestClose,
  variant,
  btnClickAction,
  showAlert = true,
  values = {},
  intl: { formatMessage },
}: Props) => {
  const modalTitle = formatMessage({ id: `signupPage.${errorType}.title` });

  const iconlabel = variant === 'error' ? 'info' : 'warning';

  return (
    <Modal
      isOpen={isOpen}
      modalTitle={modalTitle}
      modalSizeClass="modal-md"
      modalClass={'additional-verification-info-modal'}
      onRequestClose={onRequestClose}
      closeButtonId={`${errorType}.modal.btn.close`}
      headerIcon={
        <EdsIcon rounded={true} className={`header-icon ${variant}`}>
          {iconlabel}
        </EdsIcon>
      }
      noFooter={true}
      variant={variant}
      modalId={`additional-verification-${errorType}-modal-id`}
    >
      <>
        <div className="mb-3 message">
          <FormattedMessage id={`signupPage.${errorType}.message`} values={values} />
        </div>

        {showAlert && (
          <EdsAlert type="info" styles={'--eds-alert-font-size:12px'}>
            <FormattedMessage id={`signupPage.${errorType}.alert`} />
          </EdsAlert>
        )}

        <div className="d-grid pt-4 pb-2">
          <button
            id={`${errorType}.btn.close`}
            type="submit"
            className="btn btn-secondary mx-auto close-btn"
            onClick={btnClickAction || onRequestClose}
          >
            <FormattedMessage id={`signupPage.${errorType}.close`} />
          </button>
        </div>
      </>
    </Modal>
  );
};
export default injectIntl(AdditionalVerificationInfoModal);
