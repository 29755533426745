import React, { useContext } from 'react';
import RegistrationContext from '../../RegistrationContext';
import {
  getAvailaleOptionTypes,
  getDropdownOptions,
  getEmployeeNumberDescription,
} from '../../../common/modal/availableOptionsUtil';
import LabeledDropdown from '../../../common/components/labeled-dropdown/LabeledDropdown';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  containerClass?: string,
};

const AdditionalVerificationContainer = ({
  containerClass = 'additional-verification-container-in-modal',
}: Props) => {
  const {
    availableAdditionalVerificationOptions,
    selectedVerificationField,
    setSelectedVerificationField,
    setAdditionalVerification,
    additionalVerification,
  } = useContext(RegistrationContext);

  const inputFieldRef = React.useRef(null);
  const { formatMessage, messages } = useIntl();
  const availableOptionTypes = getAvailaleOptionTypes(availableAdditionalVerificationOptions);
  const availableDropdownOptions = getDropdownOptions(availableOptionTypes);
  const multipleOptionsPresent = availableOptionTypes.length > 1;

  const changeAdditionalVerification = event => {
    setAdditionalVerification({ ...additionalVerification, type: event, value: '' });
    setSelectedVerificationField(event);
    if (inputFieldRef.current) {
      inputFieldRef.current.value = '';
    }
  };

  const controlLabel = `signupPage.field.${selectedVerificationField}.label`;
  const placeholder = `signupPage.field.${selectedVerificationField}.placeholder`;
  const helpTextAfter =
    selectedVerificationField !== 'workEmail'
      ? `signupPage.field.${selectedVerificationField}.helpTextAfter`
      : '';
  const helpTextAfterValue =
    selectedVerificationField === 'employeeNumber'
      ? {
          employeeNumberDescription: getEmployeeNumberDescription(
            availableAdditionalVerificationOptions
          ),
        }
      : '';

  const translatedControlLabel = !!messages[controlLabel]
    ? formatMessage({ id: controlLabel })
    : controlLabel;

  const translatedPlaceholder = !!messages[placeholder]
    ? formatMessage({ id: placeholder })
    : placeholder;

  return (
    <div>
      <div className={`row my-2 ${containerClass}`}>
        <div className="verification-option-label">
          {multipleOptionsPresent && (
            <LabeledDropdown
              dropdownId={'verificationOptions'}
              label={formatMessage({ id: 'additionalVerificationModal.defaultOption.label' })}
              items={availableDropdownOptions.map(option => {
                option.label = formatMessage({ id: option.label });
                return option;
              })}
              onChange={changeAdditionalVerification}
              value={selectedVerificationField}
              fillWidth={true}
              height={'48px'}
              variant={'dropdown-menu-secondary'}
            />
          )}
        </div>
        <div
          className={
            multipleOptionsPresent ? 'verification-option-value' : 'verification-option-value-only'
          }
        >
          {selectedVerificationField && (
            <div className="option-value">
              <div className={`form-floating row'}`}>
                <input
                  id={`${selectedVerificationField}-text`}
                  type={selectedVerificationField === 'employeeNumber' ? 'text' : 'email'}
                  placeholder={placeholder ? translatedPlaceholder : ''}
                  className={'form-control input-pl-1'}
                  aria-describedby={selectedVerificationField + 'HelpTextAfter'}
                  ref={inputFieldRef}
                  value={additionalVerification?.value}
                  onChange={event =>
                    setAdditionalVerification({
                      ...additionalVerification,
                      type: selectedVerificationField,
                      value: event.target.value,
                    })
                  }
                  onBlur={event =>
                    setAdditionalVerification({
                      ...additionalVerification,
                      showField:
                        additionalVerification?.showField ||
                        !!additionalVerification?.value?.length,
                    })
                  }
                />
                <label htmlFor={selectedVerificationField}>{`${translatedControlLabel}`}</label>
                {helpTextAfter && (
                  <div className="ms-4">
                    <small
                      id={selectedVerificationField + 'HelpTextAfter'}
                      className="form-text text-muted"
                    >
                      <FormattedMessage id={helpTextAfter} values={helpTextAfterValue} />
                    </small>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdditionalVerificationContainer;
