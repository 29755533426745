// @flow
import * as React from 'react';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/**
 * This page should be displayed whenever the user tries to access a forbidden page.
 */
const NotAuthorized403Page = () => {
  return (
    <div className="container">
      <h2 className="mb-5 mt-5 text-dark text-center">
        <FormattedMessage id="loginNotAuthorized.headerTitle" />
      </h2>
      <p>
        <FormattedMessage id="loginNotAuthorized.description" />
      </p>
      <div className="mb-sm-5">
        <Link to="/login/success">
          <FormattedMessage id="loginNotAuthorized.linkToProfilePage" />
        </Link>
      </div>
    </div>
  );
};

export default withPageTemplate(NotAuthorized403Page, {
  headerTitle: 'loginNotAuthorized.headerTitle',
});
