import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import GenderDropdownFormField from 'ui/self-signup/components/GenderDropdownFormField';
import DefaultTextFormField from 'ui/self-signup/components/DefaultTextFormField';
import EgymIdBanner from 'ui/common/EgymIdBanner';
import SearchAddress from '../common/components/SearchAddress/SearchAddress';
import { useFormContext } from 'react-hook-form';
import RegistrationContext from './RegistrationContext';
import CustomAlert from '../common/components/CustomAlert';
import DeclarationOfConsentAlertSection from './DeclarationOfConsentAlertSection';
import { getEligibilityStatusError } from './SignupUtil';
import AdditionalVerificationAlertSection from './AdditionalVerificationAlertSection';
import AdditionVerificationInfoModal from './components/hris/AdditionalVerificationInfoModal';
import AdditionalVerificationContainer from './components/hris/AdditionalVerificationContainer';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import { AMERICA } from '../../config/i18n/countryCodes';

type Props = {
  isPartnerSignup: boolean,
  signupPage: any,
  innerRef: React.RefObject,
  signupPageOffer: Object,
  toggleAndCloseModal: Function,
};
const PersonalInformation = ({
  isPartnerSignup,
  signupPage,
  innerRef,
  signupPageOffer,
  toggleAndCloseModal,
}: Props) => {
  const {
    employeeEligibilityStatus,
    setEmployeeEligibilityStatus,
    additionalVerification,
    alertNotification,
    declarationOfConsentDetails,
    setDeclarationOfConsentDetails,
  } = useContext(RegistrationContext);

  const { maskedEmail } = employeeEligibilityStatus;
  const [openAdditionalVerificationModal, setOpenAdditionalVerificationModal] =
    React.useState(false);
  const isUSOffer = signupPageOffer?.sfAccountCountryCode === AMERICA;

  const eligibilityInfo = signupPageOffer?.integrationScopeId
    ? 'corpControlEnabledEligibilityInfo'
    : 'corpControlDisabledEligibilityInfo';

  const MessageElements = {
    strong: chunks => <strong>{chunks}</strong>,
    br: () => <br />,
    ul: chunks => <ul>{chunks}</ul>,
    li: chunks => <li>{chunks}</li>,
  };

  const getMessageValues = id => {
    if (id !== 'matchButAlreadyRegistered') {
      return undefined;
    }
    return {
      strong: chunks => <strong>{chunks}</strong>,
      br: () => <br />,
      maskEmail: maskedEmail,
    };
  };

  const { control } = useFormContext();

  const eligibilityStatusError = getEligibilityStatusError(employeeEligibilityStatus);

  return (
    <div ref={innerRef} id="personalInformationForm" className="container">
      <h3 className={`text-dark my-2`}>
        <FormattedMessage id="signupPage.step1.subTitle" />
      </h3>
      <EgymIdBanner textId={'egymId.banner.signup.newUserText'} />
      <h4 className="mb-3 pt-4 ">
        <FormattedMessage id="signupPage.step1.title" />
      </h4>
      {!isPartnerSignup && (
        <div className="mb-4">
          <EdsAlert type="info">
            <FormattedMessage id={`signupPage.${eligibilityInfo}`} values={MessageElements} />
          </EdsAlert>
        </div>
      )}
      <div className="row my-2">
        <div className="col-12 col-md-6">
          <div className="container">
            <GenderDropdownFormField control={control} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-sm-6">
          <div className="container">
            <DefaultTextFormField control={control} controlId="firstName" />
          </div>
        </div>

        <div className="col-12 my-2 d-md-none" />
        <div className="col-12 col-md-6">
          <div className="container">
            <DefaultTextFormField control={control} controlId="lastName" />
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <div className="col-12 p-0">
          <DefaultTextFormField control={control} controlId="birthday" />
        </div>
      </div>
      <DeclarationOfConsentAlertSection
        alertDetails={alertNotification?.birthday?.warning}
        declarationOfConsentDetails={declarationOfConsentDetails}
        setDeclarationOfConsentDetails={setDeclarationOfConsentDetails}
      />
      {eligibilityStatusError?.displayAlert && (
        <AdditionalVerificationAlertSection
          openModal={openAdditionalVerificationModal}
          setOpenModal={setOpenAdditionalVerificationModal}
          showAlert={!additionalVerification.showField}
        />
      )}
      {!openAdditionalVerificationModal &&
        additionalVerification.showField &&
        !openAdditionalVerificationModal && (
          <div className="container mb-3">
            <div className="col-12 p-0">
              <AdditionalVerificationContainer containerClass="additional-verification-container" />
            </div>
          </div>
        )}

      {eligibilityStatusError?.displayAlert && (
        <div className="mb-4">
          <EdsAlert type="info">
            <FormattedMessage
              id={`additionalVerificationOptionAlert.info.title`}
              values={MessageElements}
            />
          </EdsAlert>
        </div>
      )}

      {alertNotification?.duplicateSignUp?.warning && (
        <CustomAlert
          controlId={'duplicateSignUpWarning'}
          info={alertNotification.duplicateSignUp?.warning}
          className={'warning'}
        />
      )}
      {(isPartnerSignup || isUSOffer) && (
        <SearchAddress
          controlId="searchAddressValidation"
          control={control}
          needTimeZoneId={isUSOffer}
        />
      )}
      <h4 className="pt-4 pb-3">
        <FormattedMessage id="signupPage.step1.titleAccount" />
      </h4>
      <div className="container">
        <DefaultTextFormField
          control={control}
          controlId="email"
          controlType="email"
          helpTextAfter={`signupPage.field.email.${
            isPartnerSignup ? 'plusOneHelpTextAfter' : 'helpTextAfter'
          }`}
          helpTextAfterValue={{ name: signupPage ? signupPage.name : '' }}
        />
      </div>
      <div className="row my-2">
        <div className="col-12 col-sm-6">
          <div className="container">
            <DefaultTextFormField control={control} controlId="password" controlType="password" />
          </div>
        </div>
        <div className="col-12 my-2 d-sm-none" />
        <div className="col-12 col-sm-6">
          <div className="container">
            <DefaultTextFormField
              control={control}
              controlId="repeatPassword"
              controlType="password"
            />
          </div>
        </div>
      </div>
      <p className="text-start text-muted">
        <FormattedMessage id="general.required" />
      </p>

      {eligibilityStatusError && eligibilityStatusError.showModal && eligibilityStatusError.id && (
        <AdditionVerificationInfoModal
          errorType={eligibilityStatusError.id}
          isOpen={eligibilityStatusError.showModal}
          variant={eligibilityStatusError.severity}
          onRequestClose={() =>
            setEmployeeEligibilityStatus({
              ...employeeEligibilityStatus,
              [eligibilityStatusError.id]: false,
            })
          }
          showAlert={eligibilityStatusError.id !== 'matchButAlreadyRegistered'}
          btnClickAction={
            eligibilityStatusError.id === 'matchButAlreadyRegistered'
              ? toggleAndCloseModal
              : undefined
          }
          values={getMessageValues(eligibilityStatusError.id)}
        />
      )}
    </div>
  );
};
export default PersonalInformation;
