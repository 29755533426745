import { autocompleteClasses, outlinedInputClasses } from '@mui/material';
import styles from './AutocompleteTheme.module.scss';

const autocompleteTheme = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        [`& .${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
          padding: '0px',
        },
      },
      popper: {
        padding: '3px 0px',
      },
      noOptions: {
        color: styles.textColor,
        letterSpacing: '.02em',
      },
      clearIndicator: props => ({
        visibility: props.ownerState.slotProps.clearIndicator.visibility,
      }),
      'clearIndicator:hover': {
        backgroundColor: 'unset',
      },
      listbox: {
        padding: '0px',
        [`& hr`]: {
          padding: '0px',
          margin: '0px',
        },
        [`& hr:last-child`]: {
          display: 'none',
        },
        [`& .${autocompleteClasses.option}`]: {
          paddingTop: '12px',
          paddingBottom: '12px',
          minHeight: 'auto',
          color: styles.textColor,
        },
        [`& .${autocompleteClasses.option}[aria-selected="true"]`]: {
          backgroundColor: 'unset',
          fontWeight: '700',
        },
        [`& .${autocompleteClasses.option}[aria-selected="true"].Mui-focused, & .${autocompleteClasses.option}.Mui-focused`]:
          {
            backgroundColor: '#EDEDED',
          },
        [`& .${autocompleteClasses.option}:active, .${autocompleteClasses.option}[aria-selected="true"].Mui-focused:active`]:
          {
            backgroundColor: styles.secondaryColor,
            color: '#fff',
          },
      },
      paper: {
        letterSpacing: '.02em',
        boxShadow: '3px 6px 4px rgba(0, 0, 0, 0.25)',
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
      },
    },
  },
};

export default autocompleteTheme;
