// @flow
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';

type Props = {
  info: {
    title?: string,
    message: string,
    values: args,
    buttonText?: string,
    buttonValue?: Object,
  },
  className?: string,
  controlId: string,
  buttonAction?: Function,
};

const secondaryButtonStyle = {
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  letterSpacing: '0.5px',
  padding: '6px 24px 6px 24px',
};

const CustomAlert = ({ info, className, controlId, buttonAction }: Props) => {
  return (
    <EdsAlert type={`${className ? className : ''}`} styles={'--eds-alert-font-size:12px'}>
      <div id={controlId}>
        {info?.title ? (
          <span className="field-info-message-title">
            <FormattedMessage id={info.title} />
          </span>
        ) : undefined}
        <FormattedMessage id={info.message} values={info?.values} />
      </div>
      {info?.buttonText && (
        <div className="mt-2 d-flex d-md-block justify-content-center">
          <button
            id={`${controlId}-btn`}
            type="button"
            className={`me-4 me-md-0 text-center mw-100 btn btn-custom-outline-${className}`}
            style={secondaryButtonStyle}
            onClick={buttonAction}
          >
            <FormattedMessage id={info.buttonText} values={info?.buttonValue} />
          </button>
        </div>
      )}
    </EdsAlert>
  );
};

export default CustomAlert;
