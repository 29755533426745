// @flow
import * as React from 'react';
import { post } from 'service/http/http-client';
import { FormattedMessage, injectIntl } from 'react-intl';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';

import type { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
type Props = {
  intl: IntlShape,
  isProtected?: boolean,
};

const HeaderNavigation = ({ intl, isProtected }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { isAuthenticated, user, logout } = React.useContext(AuthenticationContext);

  /**
   * Toggles the navigation menu
   */
  const toggle = () => {
    setIsOpen(value => !value);
  };

  /**
   * Logout functionality
   */
  const loggingOut = event => {
    event.preventDefault();
    const postBody = {};
    return executeWithSpinner(
      post('/v1/logout', postBody).then(response => {
        if (response.ok) {
          logout();
        }
      })
    );
  };
  return (
    <div>
      <nav className={`navbar navbar-light navbar-expand-md justify-content-between`}>
        <div className="container-fluid">
          <i className="fas fa-fw spacer d-md-none" />

          <div className="navbar-brand p-3 m-auto m-md-0">
            <div className="logo-image" alt="Wellpass" />
          </div>

          {isProtected &&
            isAuthenticated() && ( // show the navigation menu on protected pages when logged in
              <>
                <button
                  className="navbar-toggler border-0 ps-0 pe-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggle}
                >
                  <i className={`fas fa-fw ${isOpen ? 'fa-times' : 'fa-bars'}`} />
                </button>

                <div
                  className={`collapse navbar-collapse ${isOpen ? 'show' : 'hide'} semi-bold`}
                  id="navbarNav"
                >
                  <ul className="navbar-nav ms-auto px-3">
                    <li role="separator" className="d-md-none mt-2 mb-2 border-bottom" />

                    {user && ( // show the user name if set
                      <>
                        <li className="nav-item">
                          <Link className="nav-link login-info" to="/login">
                            {`${user.firstName} ${user.lastName}`}
                          </Link>
                        </li>
                        <li className="text-white mt-2 logout-separator">.</li>
                      </>
                    )}

                    <li className="nav-item">
                      <a
                        className="nav-link login-info"
                        aria-label="Logout"
                        href="/"
                        onClick={loggingOut}
                      >
                        <FormattedMessage id="header.signOut" />
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
        </div>
      </nav>
    </div>
  );
};

export default injectIntl(HeaderNavigation);
