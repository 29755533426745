// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { MembershipOffer, SignupPage } from 'ui/self-signup/types';
import InvalidFieldErrorMessage from 'ui/self-signup/components/InvalidFieldErrorMessage';
import { useController } from 'react-hook-form';

type Props = {
  controlId: string,
  signupPage: SignupPage,
  signupPageOffer: MembershipOffer,
  intl: {
    formatMessage: Function,
  },
  control: any,
};

/**
 * Renders a checkbox with a help text describing the terms and conditions.
 *
 * Most of the props, such as: helpTextBefore, acceptText, termsAndConditionsUrl and privacyPolicyUrl
 * are not the actual text that will be rendered,
 * instead they are 'message IDs' which will be translated at runtime based on the user's language.
 */
const ConsentCheckboxFormField = ({ controlId, intl: { formatMessage }, control }: Props) => {
  const {
    field,
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController({
    name: controlId,
    control,
  });

  const shouldDisplayError = (isSubmitted && error) || (error && isTouched);
  const controlLabel = `signupPage.field.${controlId}.label`;

  return (
    <div className="mb-3">
      <div className="form-check mt-4">
        <input
          {...field}
          id={controlId}
          type="checkbox"
          className={'form-check-input' + (shouldDisplayError ? ' is-invalid' : '')}
          {...control.register(controlId)}
        />
        <label className="form-check-label" htmlFor={controlId}>
          <FormattedMessage id="signupPage.field.consent.acceptTextPart" />
        </label>
        {shouldDisplayError && (
          <InvalidFieldErrorMessage
            meta={{ error }}
            translatedControlLabel={formatMessage({ id: controlLabel })}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(ConsentCheckboxFormField);
