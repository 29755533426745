// @flow
import * as ibanTools from 'ibantools';
import { VALIDATION_IBAN_INVALID, VALIDATION_REQUIRED_FIELD } from 'validation/common/errorCodes';
import type { ValidationResult } from 'validation/types';

export const validateIban = (value: ?string): ?ValidationResult => {
  if (!value) {
    return { code: VALIDATION_REQUIRED_FIELD };
  }
  value = ibanTools.electronicFormatIBAN(value);
  if (!ibanTools.isValidIBAN(value)) {
    return { code: VALIDATION_IBAN_INVALID };
  }
  return undefined;
};
