import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withSpinner from '../common/spinner/withSpinner';
import { injectIntl } from 'react-intl';
import type { SpinnerProps } from '../common/spinner/types';
import React, { useContext, useEffect, useState } from 'react';
import { extractRequestParameter } from '../../service/http/requestUtil';
import { get, post, put } from '../../service/http/http-client';
import { FORM_ERROR } from 'final-form';
import { formatDate, isUtcDateInPast } from '../../service/dateUtil';
import { withPageTemplate } from '../layout/PageTemplate';
import ErrorPage from '../error/ErrorPage';
import SelfSignupSuccessPage from './SelfSignupSuccessPage';
import SelfSignupFormWizard, { SelfSignupFormWizardStep } from './SelfSignupFormWizard';
import Welcome from '../welcome/Welcome';
import {
  isPaymentDetailsModified,
  validateStep1,
  validateStep2,
  validateStep3,
} from '../../validation/self-signup/formValidator';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { convertToRequestBody } from '../../service/http/self-signup/requestConverter';
import {
  convertErrorResponse,
  convertUserServiceResponse,
} from '../../service/http/responseConverter';
import { getActiveOffer } from '../../service/offerUtils';
import RegistrationContext from './RegistrationContext';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { usePayment } from 'service/hooks';
import { localeCountryCode } from 'config/i18n/WithI18nSupport';
import PaymentDetailsErrorModal from 'ui/self-signup/components/PaymentDetailsErrorModal';
import { merge } from 'lodash';
import { useGymCount } from '../../service/hooks/gymsCount';
import { uploadDeclarationOfConsent } from './SignupUtil';

type Props = {
  ...SpinnerProps,
  match: any,
};

const PartnerSelfSignupComponent = (props: Props) => {
  const { token: referralToken } = useParams();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [error, setError] = useState(0);
  const [generalError, setGeneralError] = useState();
  const [existingPaymentDetails, setExistingPaymentDetails] = useState();
  const [signupPage, setSignupPage] = useState();
  const [signupPageOffer, setSignupPageOffer] = useState();
  const [gymsCount] = useGymCount({ searchFilters: [{ trainingFilter: 'QUALITRAIN_PLUS_ONE' }] });
  const [userData, setUserData] = useState({});
  const [referralMembershipWrapper, setReferralMembershipWrapper] = useState();
  const [step, setStep] = useState(parseInt(extractRequestParameter('step') || '0'));
  const [showStandalone, setShowStandalone] = useState(extractRequestParameter('showStandalone'));
  const [initialStep, setInitialStep] = useState();
  const [countryCode, setCountryCode] = useState(localeCountryCode);
  const [paymentDetailsErrorModalOpen, setPaymentDetailsErrorModalOpen] = useState(false);
  const [errorsSubmit, setErrorsSubmit] = useState(null);

  const authenticationContext = useContext(AuthenticationContext);
  const {
    isNewUser,
    setIsNewUser,
    resolvedAddress,
    existingUser,
    declarationOfConsentDetails,
    setDeclarationOfConsentDetails,
    alertNotification,
  } = useContext(RegistrationContext);
  const { executeWithSpinner } = useContext(SpinnerContext);

  const location = useLocation();
  const navigate = useNavigate();

  const replaceBackslash = () => {
    const { search, pathname } = location;
    if (search && search.substr(-1) === '/') {
      navigate({ search: search.slice(0, -1) }, { replace: true });
    }
    if (pathname.substr(-1) === '/') {
      navigate({ pathname: pathname.slice(0, -1) }, { replace: true });
    }
  };

  const setCurrentStep = () => {
    setStep(parseInt(extractRequestParameter('step') || '0'));
    if (!initialStep) {
      setInitialStep(step);
      if (step > 1) {
        navigate(`${location.pathname}?step=1`, { replace: true });
      }
    }
  };

  useEffect(() => {
    replaceBackslash();
    setCurrentStep();
  });

  const validateResponseStatus = (response, generalErrorId) => {
    if (!response.ok) {
      generalErrorId ? setGeneralError(generalErrorId) : setError(response.status);
    }
    return response.json();
  };

  const initialiseFormFields = () => {
    let initialValues = {};
    return merge(initialValues, {
      offerId: signupPageOffer?.id,
    });
  };

  const sendPartnerSignupRequest = async (values, payload) => {
    const existingUserId = isNewUser ? '' : existingUser.id;
    try {
      const signupResponse = isNewUser
        ? await post('/v1/signup/partner', payload)
        : await put(`/v1/signup/partner/${existingUserId}`, payload);

      if (signupResponse.ok) {
        setShowSuccessDialog(true);
        signupResponse.json().then(response => {
          setUserData({
            firstName: values.firstName,
            email: values.email,
            membershipStartDate: formatDate(values.membershipStartDate),
            doiConfirmed: response.doiConfirmed,
          });
          if (
            declarationOfConsentDetails?.files?.length > 0 &&
            declarationOfConsentDetails.confirmed &&
            response.membershipId
          ) {
            uploadDeclarationOfConsent(
              response.membershipId,
              declarationOfConsentDetails,
              setDeclarationOfConsentDetails,
              alertNotification?.reviewedBirthday?.warning
            );
          }
        });

        if (!isNewUser) {
          authenticationContext.setHasMemberships(true);
        }
      } else if (signupResponse.status === 303) {
        window.location.href = signupResponse.url;
      } else if (signupResponse.status >= 500) {
        return signupResponse.text().then(errorMessage => convertErrorResponse(errorMessage));
      } else {
        return signupResponse.json().then(jsonResponse => {
          const errors = convertUserServiceResponse(jsonResponse);
          setErrorsSubmit(errors);
          if (errors[FORM_ERROR] === 'invalid.paymentData') {
            setPaymentDetailsErrorModalOpen(true);
          } else {
            return errors;
          }
        });
      }
    } catch (signupError) {
      setErrorsSubmit({ [FORM_ERROR]: signupError });
    }
  };

  const onSubmit = values => {
    const userRequest = isNewUser ? 'userSignUpRequest' : 'existingUserSignUpRequest';

    const paymentDetailsModified = isPaymentDetailsModified(
      { ...values },
      { ...existingPaymentDetails }
    );

    const payload = {
      [userRequest]: convertToRequestBody({
        ...values,
        locale: props.intl.locale,
        signupPageToken: signupPage.token,
        isPaymentDetailsModified: paymentDetailsModified,
        searchAddress: resolvedAddress,
      }),
      referralToken: referralToken,
    };

    return executeWithSpinner(sendPartnerSignupRequest(values, payload));
  };

  const shouldFetchPaymentData = step === 3 && !isNewUser && signupPageOffer?.b2cPayment;
  const { payment } = usePayment(shouldFetchPaymentData);
  useEffect(() => {
    setExistingPaymentDetails(payment);
  }, [payment]);

  const onCountryCodeChangeEvent = code => {
    setCountryCode(code);
  };

  useEffect(() => {
    const isReferralMembershipValid = retrievedReferralMembershipWrapper => {
      const { referralMembershipEndTimestamp, partnerExists, referralOfferType } =
        retrievedReferralMembershipWrapper;

      if (
        referralOfferType !== 'STANDARD' ||
        isUtcDateInPast(referralMembershipEndTimestamp) ||
        partnerExists
      ) {
        setGeneralError('partnerSignUp.generalError');
        return false;
      }

      return true;
    };

    const getSignupAndMembershipData = async () => {
      try {
        const generalErrorId = 'partnerSignUp.generalError';
        const retrievedReferralMembershipWrapper = await get(
          `/v1/membership/referral-token/${referralToken}`
        ).then(response => validateResponseStatus(response, generalErrorId));
        if (isReferralMembershipValid(retrievedReferralMembershipWrapper)) {
          setReferralMembershipWrapper(retrievedReferralMembershipWrapper);
        }

        const signupPageResponse = await get(
          `/v1/signup-page/plus-one?countryCode=${countryCode}`
        ).then(validateResponseStatus);
        setSignupPage(signupPageResponse);

        const activeSignupPageOffers = await get(
          '/v2/signup-page/' + signupPageResponse.token + '/offer/active'
        ).then(validateResponseStatus);
        setSignupPageOffer(getActiveOffer(activeSignupPageOffers));
      } catch (signupError) {
        setError(true);
        return { [FORM_ERROR]: signupError.message };
      }
    };
    executeWithSpinner(getSignupAndMembershipData());
  }, [executeWithSpinner, referralToken, countryCode]);

  useEffect(() => {
    setShowStandalone(extractRequestParameter('showStandalone'));
  }, [location]);

  const closePaymentDetailsErrorModal = () => {
    setPaymentDetailsErrorModalOpen(false);
    setIsNewUser(false);
    navigate(`${location.pathname}?step=1`, { replace: true });
  };

  const isPartnerSignup = true;
  const headerTitleKey = 'signupPage.headerTitle';

  if (error > 0) {
    const Component = withPageTemplate(ErrorPage, { headerTitle: headerTitleKey });
    return <Component />;
  }

  if (showSuccessDialog) {
    const Component = withPageTemplate(SelfSignupSuccessPage, {
      headerTitle: headerTitleKey,
    });
    return <Component userData={userData} offer={signupPageOffer} />;
  } else if (signupPage === undefined || signupPageOffer === undefined) {
    return <></>;
  } else {
    return (
      <>
        <SelfSignupFormWizard
          step={step}
          onSubmit={onSubmit}
          initialValues={initialiseFormFields()}
          isPartnerSignup={isPartnerSignup}
          partnerSignupError={generalError}
          showStandalone={showStandalone}
          errorsSubmit={errorsSubmit}
        >
          <SelfSignupFormWizardStep key="step-welcome" isIndependentPage={true}>
            <Welcome
              signupPage={signupPage}
              signupPageOffer={signupPageOffer}
              gymsCount={gymsCount}
              isPartnerSignup={isPartnerSignup}
              countryCode={countryCode}
              onCountryCodeChangeEvent={onCountryCodeChangeEvent}
              showStandalone={showStandalone}
            />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-1"
            validateStep={validateStep1(signupPage, isPartnerSignup, resolvedAddress)}
            isIndependentPage={false}
          >
            <Step1 signupPage={signupPage} isPartnerSignup={isPartnerSignup} />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-2"
            validateStep={validateStep2(signupPage, isPartnerSignup, resolvedAddress)}
            isIndependentPage={false}
          >
            <Step2 signupPage={signupPage} isPartnerSignup={isPartnerSignup} />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-3"
            validateStep={validateStep3(signupPageOffer, { ...existingPaymentDetails })}
            isIndependentPage={false}
          >
            {signupPage && signupPageOffer ? (
              <Step3
                signupPage={signupPage}
                signupPageOffer={signupPageOffer}
                gymsCount={gymsCount}
                isPartnerSignup={isPartnerSignup}
                referralMembershipWrapper={referralMembershipWrapper}
                countryCode={countryCode}
                onCountryCodeChangeEvent={onCountryCodeChangeEvent}
              />
            ) : null}
          </SelfSignupFormWizardStep>
        </SelfSignupFormWizard>

        {paymentDetailsErrorModalOpen && (
          <PaymentDetailsErrorModal
            isOpen={true}
            onRequestClose={() => closePaymentDetailsErrorModal()}
          />
        )}
      </>
    );
  }
};

const PartnerSelfSignupPage = props => <PartnerSelfSignupComponent {...props} />;

export default withSpinner(injectIntl(PartnerSelfSignupPage));
