import React, { useContext } from 'react';
import classNames from 'classnames';
import partnerGymBackgroundImage from 'styles/img/welcome/welcome-header-plus1.jpg';
import wellpassLogo from 'styles/img/wellpass-logo-white.svg';
import { useAnchorTag } from 'service/hooks/anchorTag';
import BackgroundSection from '../../common/components/BackgroundSection';
import { useHeaderBackgroundImage } from '../../common/hooks';
import WelcomeContext from '../WelcomeContext';
import UnlimitedStudioSection from './unlimited-offering/UnlimitedStudioSection';
import OfferingSection from './unlimited-offering/OfferingSection';
import IntroSectionUnlimitedOffering from './IntroSectionUnlimitedOffering';
import GymFinderSection from './GymFinderSection';

import style from './WelcomeUnlimitedOffering.module.scss';

type Props = {
  signupPage: any,
  isPartnerSignup?: boolean,
};

const WelcomeFree = ({ signupPage, isPartnerSignup }: Props) => {
  const topBackgroundSectionImage = useHeaderBackgroundImage();

  useAnchorTag();
  const { gymFinderUrl } = useContext(WelcomeContext);

  return (
    <>
      <BackgroundSection
        className={'header-bg-color-height'}
        backgroundImageUrl={isPartnerSignup ? partnerGymBackgroundImage : topBackgroundSectionImage}
      >
        <IntroSectionUnlimitedOffering
          logo={(signupPage && signupPage.logo) || wellpassLogo}
          name={signupPage && signupPage.name}
        />
      </BackgroundSection>

      <BackgroundSection
        containerClass={classNames('container-fluid', style.welcomeUnlimitedOffering)}
      >
        <OfferingSection />
      </BackgroundSection>

      <BackgroundSection containerClass="container-fluid" className={style.unlimitedStudioSection}>
        <UnlimitedStudioSection />
      </BackgroundSection>

      <BackgroundSection containerClass="container-fluid">
        {gymFinderUrl && <GymFinderSection />}
      </BackgroundSection>
      <BackgroundSection className="section-spacing" />
    </>
  );
};

export default WelcomeFree;
