import * as React from 'react';
import { SpinnerConsumer } from 'ui/common/spinner/SpinnerContext';

/**
 * Injects the spinner props into the wrapped Components.
 */
const withSpinner = Component => {
  const WithSpinner = props => (
    <SpinnerConsumer>
      {({ isSpinnerShown, executeWithSpinner }) => (
        <Component
          {...props}
          isSpinnerShown={isSpinnerShown}
          executeWithSpinner={executeWithSpinner}
        />
      )}
    </SpinnerConsumer>
  );

  WithSpinner.displayName = `withSpinner(${Component.displayName || Component.name})`;
  return WithSpinner;
};

export default withSpinner;
