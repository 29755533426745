import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useController } from 'react-hook-form';
import InvalidFieldErrorMessage from 'ui/self-signup/components/InvalidFieldErrorMessage';

const TextFormField = ({
  intl: { formatMessage, messages },
  register,
  control,
  controlId,
  controlType = 'text',
  controlLabel,
  placeholder,
  containerClassName,
  helpTextAfter,
  helpTextAfterValue = {},
  required = true,
}) => {
  const {
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController({
    name: controlId,
    control,
  });

  const shouldDisplayError = (isSubmitted && error) || (error && isTouched);
  const translatedControlLabel = !!messages[controlLabel]
    ? formatMessage({ id: controlLabel })
    : controlLabel;

  const translatedPlaceholder = !!messages[placeholder]
    ? formatMessage({ id: placeholder })
    : placeholder;

  return (
    <div className={`form-floating ${containerClassName ? containerClassName : 'row'}`}>
      <input
        id={controlId}
        type={controlType}
        placeholder={placeholder ? translatedPlaceholder + (required ? ' *' : '') : ''}
        className={'form-control input-pl-1' + (shouldDisplayError ? ' is-invalid' : '')}
        aria-describedby={controlId + 'HelpTextAfter'}
        {...register}
      />
      <label htmlFor={controlId}>{`${translatedControlLabel}${required ? ' *' : ''}`}</label>
      {helpTextAfter && (
        <small id={controlId + 'HelpTextAfter'} className="form-text text-muted">
          <FormattedMessage id={helpTextAfter} values={helpTextAfterValue} />
        </small>
      )}
      {shouldDisplayError && (
        <InvalidFieldErrorMessage
          meta={{ error }}
          translatedControlLabel={translatedControlLabel}
        />
      )}
    </div>
  );
};

TextFormField.propTypes = {
  intl: PropTypes.object,
  register: PropTypes.any,
  control: PropTypes.any,
  controlId: PropTypes.any,
  controlType: PropTypes.string,
  controlLabel: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  required: PropTypes.bool,
  helpTextAfter: PropTypes.string,
  helpTextAfterValue: PropTypes.any,
  info: PropTypes.string,
  warning: PropTypes.string,
};

export default injectIntl(TextFormField);
