import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import RegistrationContext from './RegistrationContext';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import { get, post } from 'service/http/http-client';
import { useFormContext } from 'react-hook-form';
import { formatDate } from '../../service/dateUtil';
import { DECLARATION_OF_CONSENT_ACTIONS } from './reducers/useDeclarationOfConsent';

const ExistingUserLoginForm = ({ intl, innerRef }: props) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isNewUser,
    setExistingUser,
    setAddressPrediction,
    setResolvedAddress,
    setDeclarationOfConsentDetails,
  } = React.useContext(RegistrationContext);
  const { login } = React.useContext(AuthenticationContext);

  const { setValue } = useFormContext();

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        loginAndProceed();
      }
    };
    const rootNode = document.getElementById('root');
    if (!isNewUser) {
      rootNode.addEventListener('keydown', listener);
    }
    return () => {
      rootNode.removeEventListener('keydown', listener);
    };
  });

  const loginAndProceed = () => {
    setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.RESET_MODAL });
    setAddressPrediction(undefined);
    setResolvedAddress(undefined);
    return executeWithSpinner(
      (async () => {
        const postBody = {
          username: username,
          password: password,
        };

        try {
          const loginResp = await post('/v1/login', postBody);
          if (!loginResp.ok && loginResp.status === 401) {
            setErrorMessage('validation.login.invalidUsernameOrPassword');
            return;
          } else if (!loginResp.ok) {
            setErrorMessage('validation.general.serverError');
            return;
          }

          const userProfileResp = await get('/v1/user-profile');
          if (!userProfileResp.ok) {
            setErrorMessage('validation.login.invalidUsernameOrPassword');
            return;
          }
          const userProfile = await userProfileResp.json();

          setExistingUser(userProfile);
          login(userProfile, undefined);
          initializeUserDetails(userProfile);
          goToReviewStep();
        } catch (error) {
          setErrorMessage('validation.general.serverError');
        }
      })()
    );
  };

  const initializeUserDetails = userProfile => {
    if (!userProfile) {
      return;
    }
    setValue('reviewedFirstName', userProfile.firstName);
    setValue('reviewedLastName', userProfile.lastName);
    setValue('reviewedBirthday', formatDate(userProfile.birthday));
    setValue('reviewedGender', userProfile.gender);
  };

  const goToReviewStep = () => {
    const currentUrlParams = new URLSearchParams(location.search.substring(1));
    currentUrlParams.set('step', `2`);
    navigate(location.pathname + '?' + currentUrlParams.toString());
  };

  const usernamePlaceHolder = intl.formatMessage({ id: 'loginPage.field.username.label' });
  const passwordPlaceHolder = intl.formatMessage({ id: 'loginPage.field.password.label' });

  return (
    <div ref={innerRef} id="existingUserLoginForm">
      <h4 className="mb-3 mt-3">
        <FormattedMessage id="loginPage.title" />
      </h4>
      <div className="container">
        <div className="form-floating mb-3 row">
          <input
            type="email"
            id="username"
            value={username}
            autoComplete="username"
            className="form-control"
            onChange={e => setUsername(e.target.value)}
            placeholder={usernamePlaceHolder}
          />
          <label htmlFor="username" className={username ? 'label-touched' : ''}>
            <FormattedMessage id="loginPage.field.username.label" />
          </label>
        </div>
      </div>
      <div className="container">
        <div className="form-floating mb-3 row">
          <input
            type="password"
            id="loginPassword"
            value={password}
            className="form-control"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            placeholder={passwordPlaceHolder}
          />
          <label htmlFor="loginPassword">
            <FormattedMessage id="loginPage.field.password.label" />
          </label>
        </div>
      </div>
      {errorMessage && (
        <div className="alert alert-danger">
          <FormattedMessage id={errorMessage} />
        </div>
      )}
      <div className="row">
        <div className="col-12 col-sm-4 mb-2 d-grid">
          <button
            id="signupPage.step1.button.loginAndProceed"
            type="button"
            className="btn btn-primary"
            onClick={() => loginAndProceed()}
          >
            <FormattedMessage id="general.button.loginProceed" />
          </button>
        </div>
        <div className="col-12 col-sm-8 text-end">
          <Link to="/password-reset" target="_blank">
            <FormattedMessage id="loginPage.forgotPassword" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ExistingUserLoginForm);
