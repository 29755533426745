// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ReactComponent as ExpandCircle } from '../../../styles/img/icons/expand_circle.svg';
import style from './FAQSection.module.scss';

type Props = {
  gymsCount: number,
  isB2C: boolean,
};

const typographyParagraph = chunks => <Typography>{chunks}</Typography>;

const studiosMapAnchor = chunks => <a href="#studios-map">{chunks}</a>;

const onlineOfferLink = chunks => (
  <a
    href="https://egym-wellpass.com/online-angebot/?utm_source=signup_page"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const FAQSection = ({ gymsCount, isB2C }: Props) => {
  const wrapParagraphs = items => {
    return items.map(item => ({
      id: item.id,
      values: { ...item.values, p: chunks => typographyParagraph(chunks) },
    }));
  };

  const faqItems = wrapParagraphs([
    {
      id: 'howMembershipWorks',
      values: {
        gymCount: gymsCount,
      },
    },
    {
      id: 'availableStudios',
      values: {
        anchor: chunks => studiosMapAnchor(chunks),
        link: chunks => onlineOfferLink(chunks),
      },
    },
    {
      id: 'howCheckInWorks',
      values: {
        anchor: chunks => studiosMapAnchor(chunks),
      },
    },
    {
      id: 'additionalCosts',
    },
    {
      id: 'howSignupWorks',
      values: {
        isB2C: isB2C,
      },
    },
    {
      id: 'howPlusOneWorks',
      values: {
        anchor: chunks => studiosMapAnchor(chunks),
      },
    },
  ]);

  const [expanded, setExpanded] = React.useState(faqItems[0].id);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={`mb-5 ${style.faqSection}`}>
      <div className={style.faqContainer}>
        <h3
          id="faq"
          className={`text-center fw-bold text-break text-dark ${style.faqSectionTitle}`}
        >
          <FormattedMessage id="welcome.faq.title" />
        </h3>
        {faqItems.map((item, idx) => (
          <Accordion
            key={`${item.id}-${idx}`}
            className={style.faqAccordion}
            elevation={0}
            disableGutters={true}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
          >
            <AccordionSummary
              id={`selfsignup.faq.items.${item.id}`}
              className={style.faqAccordionSummary}
              expandIcon={<ExpandCircle className={style.faqNoPointerEvents} />}
            >
              <Typography
                variant="h5"
                className={`fw-bold text-break text-dark ${style.faqNoPointerEvents}`}
              >
                <FormattedMessage id={`welcome.faq.items.${item.id}.question`} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={style.faqAccordionDetails}>
              <FormattedMessage id={`welcome.faq.items.${item.id}.answer`} values={item.values} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
