import React from 'react';
import { FormattedMessage } from 'react-intl';
import PaymentDataModal from 'ui/payment/PaymentDataModal';
import { usePayment } from 'service/hooks';
import PaymentUpdateInfoModal from './PaymentUpdateInfoModal';
import { PAYMENT_UPDATE_MODAL_KEY } from './PaymentConstants';

const PaymentDetailsPage = () => {
  const [updatePaymentModalOpen, setUpdatePaymentModalOpen] = React.useState(false);
  const { payment, updatePayment } = usePayment();
  const [paymentModalCache, setPaymentModalCache] = React.useState(
    JSON.parse(localStorage.getItem(PAYMENT_UPDATE_MODAL_KEY))
  );
  const obscureIban = iban => {
    let result = '';
    if (iban) {
      const obscureLength = iban.length - 4;
      for (let i = 0; i < iban.length; i++) {
        result += i < obscureLength ? '*' : iban[i];
      }
    }
    return result;
  };

  const emptyStringIfNull = text => {
    return text ? text : '';
  };

  const onPaymentInfoModalClose = () => {
    const data = { ...paymentModalCache, show: false };
    localStorage.setItem(PAYMENT_UPDATE_MODAL_KEY, JSON.stringify(data));
    setPaymentModalCache(data);
  };

  return (
    <>
      <h2 id="paymentDetailsPage.headerTitle.id" className="text-dark mb-3">
        <FormattedMessage id="paymentDetailsPage.headerTitle" />
      </h2>
      <div className="text-start my-3">
        <h3 className="text-dark">
          <FormattedMessage id="paymentDetailsPage.headerBody" />
        </h3>
      </div>
      <div className="mt-3">
        <FormattedMessage id="paymentDetailsPage.ibanLabel" />:{' '}
        {payment && obscureIban(payment.ibanNumber)}
      </div>
      <div className="mb-3">
        <FormattedMessage id="paymentDetailsPage.ownerLabel" />:{' '}
        {payment && emptyStringIfNull(payment.ownerName)}
      </div>
      <div className="fw-lighter">
        <button className="btn btn-primary" onClick={() => setUpdatePaymentModalOpen(true)}>
          <FormattedMessage id="paymentDetailsPage.editPaymentData" />
        </button>
      </div>
      {updatePaymentModalOpen && (
        <PaymentDataModal
          isOpen={true}
          payment={payment}
          setPaymentCallback={updatePayment}
          onRequestClose={() => {
            setUpdatePaymentModalOpen(false);
          }}
        />
      )}
      {paymentModalCache && paymentModalCache.show && (
        <PaymentUpdateInfoModal isOpen={true} onModalClose={() => onPaymentInfoModalClose()} />
      )}
    </>
  );
};

export default PaymentDetailsPage;
