import moment from 'moment';
import { Period } from 'js-joda';
import type { MembershipOffer, ReferralMembershipSignUpWrapper } from '../ui/self-signup/types';

export const ServerTimeZone = 'Europe/Berlin';

/**
 * Returns the possible dates for the start of the membership.
 */
export const getValidDates = (
  startDate: moment,
  endDate: moment,
  today: moment,
  signupDeadline: string
): moment[] => {
  const startOfMonth = (startDate ? moment.max(startDate, today) : today).clone().startOf('month');
  const dates = [];

  const count = 4;
  let date = startOfMonth.clone();
  while (dates.length < count && (!endDate || !date || date.isBefore(endDate))) {
    if (!today.isAfter(applyDeadline(date.clone(), signupDeadline))) {
      dates.push(date);
    }

    date = date.clone().add(1, 'month');
  }

  return dates;
};

/**
 * Returns the possible dates for the start of the partner membership.
 */
export const getPartnerSignupValidDates = (
  today: moment,
  offer: MembershipOffer,
  referralMembershipWrapper: ReferralMembershipSignUpWrapper
) => {
  const startDate = offer.availableFrom && moment(offer.availableFrom).clone().startOf('month');
  const endDate = offer.availableTo && moment(offer.availableTo);
  const firstPossibleDate = getPartnerPossibleStartDate(startDate, referralMembershipWrapper);
  const validDates = getValidDates(firstPossibleDate, endDate, today, offer.signupDeadline);
  return validDates.filter(date =>
    isReferralEndTimestampAfterDate(referralMembershipWrapper, date)
  );
};

export const applyDeadline = (date: moment, signupDeadline: string) => {
  //Can't use momentjs durations here.
  let signupDeadlinePeriod = Period.parse(signupDeadline);

  date = date.add(signupDeadlinePeriod.months(), 'month');
  //if adding days rolls over to next month, return end of current month
  return date.clone().add(Math.min(signupDeadlinePeriod.days(), date.clone().daysInMonth()), 'day');
};

export const formatDateWithTimeZoneId = (
  date: ?string,
  timeZoneId = ServerTimeZone,
  dateFormat = 'DD.MM.YYYY'
): string => {
  if (!date) return '';
  return moment(date).tz(timeZoneId).format(dateFormat);
};

export const formatDate = (date: ?string, dateFormat = 'DD.MM.YYYY'): string => {
  if (!date) return '';
  return moment(date).format(dateFormat);
};

const getPartnerPossibleStartDate = (
  startDate: moment,
  referralMembershipWrapper: ReferralMembershipSignUpWrapper
) => {
  const referralStartDate = moment(referralMembershipWrapper.referralMembershipStartTimestamp);

  if (!startDate || startDate.isBefore(referralStartDate, 'day')) {
    return referralStartDate.date() === 1
      ? referralStartDate
      : referralStartDate.clone().add(1, 'month').startOf('month');
  }

  return startDate;
};

const isReferralEndTimestampAfterDate = (
  referralMembershipWrapper: ReferralMembershipSignUpWrapper,
  date: moment
) => {
  const { referralMembershipEndTimestamp } = referralMembershipWrapper;

  if (referralMembershipEndTimestamp) {
    const referralMembershipEndDate = moment(referralMembershipEndTimestamp);
    return (
      referralMembershipEndDate.isAfter(date, 'day') &&
      !referralMembershipEndDate.isSame(date, 'day')
    );
  }

  return true;
};

/**
 * Check is date in UTC in the past
 * @param date
 * @returns validation result
 */
export const isUtcDateInPast = (date: Date): boolean => {
  if (!date) {
    return false;
  }

  return moment().utc().isAfter(moment(date));
};
