export const ALL_VERIFICATION_OPTIONS = [
  {
    id: 'WORK_EMAIL',
    value: 'workEmail',
    label: 'signupPage.field.workEmail.label',
  },
  {
    id: 'PERSONAL_EMAIL',
    value: 'personalEmail',
    label: 'signupPage.field.personalEmail.label',
  },
  {
    id: 'EMPLOYEE_NUMBER',
    value: 'employeeNumber',
    label: 'signupPage.field.employeeNumber.label',
  },
];

export const getAvailaleOptionTypes = availableOptions => {
  return availableOptions?.map(option => option.type);
};

export const getDropdownOptions = availableOptionTypes => {
  return ALL_VERIFICATION_OPTIONS.filter(option => availableOptionTypes?.includes(option.id)).sort(
    (a, b) => ALL_VERIFICATION_OPTIONS.indexOf(a.id) - ALL_VERIFICATION_OPTIONS.indexOf(b.id)
  );
};

export const getEmployeeNumberDescription = availableAdditionalVerificationOptions => {
  return availableAdditionalVerificationOptions?.filter(
    option => option.type === 'EMPLOYEE_NUMBER'
  )[0]?.description;
};
