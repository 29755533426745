import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import {
  applyDeadline,
  getPartnerSignupValidDates,
  getValidDates,
  ServerTimeZone,
} from 'service/dateUtil.js';

import DropdownFormField from 'ui/self-signup/components/DropdownFormField';
import type { MembershipOffer, ReferralMembershipSignUpWrapper } from '../types';
import { Alert } from '@mui/material';
import RegistrationContext from '../RegistrationContext';
import { useFormContext } from 'react-hook-form';
import { AMERICA } from '../../../config/i18n/countryCodes';

const textKeysPrefix = 'signupPage.field.membershipStartDate';

type Props = {
  offer: MembershipOffer,
  referralMembershipWrapper?: ReferralMembershipSignUpWrapper,
  intl: IntlShape,
  control: any,
};

const MembershipStartDropdownFormField = ({
  offer,
  referralMembershipWrapper,
  intl: { formatMessage },
  control,
}: Props) => {
  const { corporateEmployeeDetails, setHasValidMembershipStartDates, resolvedAddress } =
    React.useContext(RegistrationContext);
  const { setValue } = useFormContext();
  const possibleStartDates = corporateEmployeeDetails?.availableMembershipStartDates;

  const getValidDatesFromOffer = useCallback(
    (offer: MembershipOffer, referralMembershipWrapper: ReferralMembershipSignUpWrapper) => {
      if (!offer) return [];
      if (offer.integrationScopeId && possibleStartDates) {
        return possibleStartDates?.map(date => moment(date)) || [];
      }
      const timeZoneId =
        offer.sfAccountCountryCode === AMERICA && resolvedAddress.timeZoneId
          ? resolvedAddress.timeZoneId
          : ServerTimeZone;

      return referralMembershipWrapper
        ? getPartnerSignupValidDates(moment(), offer, referralMembershipWrapper)
        : getValidDates(
            offer.availableFrom && moment(offer.availableFrom),
            offer.availableTo && moment(offer.availableTo),
            moment().tz(timeZoneId),
            offer.signupDeadline
          );
    },
    [possibleStartDates, resolvedAddress?.timeZoneId]
  );

  const validDates = useMemo(
    () => getValidDatesFromOffer(offer, referralMembershipWrapper),
    [offer, referralMembershipWrapper, getValidDatesFromOffer]
  );

  useEffect(() => {
    if (!validDates.length) {
      setHasValidMembershipStartDates(false);
      return;
    }
    setValue('membershipStartDate', validDates[0].format('YYYY-MM-DD'));
    setHasValidMembershipStartDates(true);
  }, [validDates, setValue, setHasValidMembershipStartDates]);

  const dropdownOptions = validDates.map(date => {
    return {
      value: date.format('YYYY-MM-DD'),
      label: date.format('DD.MM.YYYY'),
      key: date.format('YYYY-MM-DD'),
    };
  });

  const helpTextAfter =
    validDates.length > 0
      ? formatMessage(
          { id: `${textKeysPrefix}.helpTextAfter` },
          {
            //We subtract 1 day to get the inclusive date.
            signupDeadline: applyDeadline(validDates[0].clone(), offer.signupDeadline)
              .add(-1, 'day')
              .format('DD.MM.'),
            earliestStartDate: dropdownOptions[0].label,
          }
        )
      : '';

  return (
    <>
      <div className="mx-2">
        <DropdownFormField
          control={control}
          controlLabel={
            dropdownOptions.length ? `${textKeysPrefix}.label` : `${textKeysPrefix}.warningLabel`
          }
          controlId="membershipStartDate"
          helpTextAfter={helpTextAfter}
          options={[...dropdownOptions]}
          showEmptyValue={!dropdownOptions.length}
        />
      </div>
      {!dropdownOptions.length && (
        <div style={{ maxWidth: '40rem' }}>
          <Alert severity="warning">
            <FormattedMessage id="signupPage.step3.noAvailableStartDateWarning" />
          </Alert>
        </div>
      )}
    </>
  );
};

export default injectIntl(MembershipStartDropdownFormField);
