// @flow
import React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ChronologyType, MembershipType } from 'ui/admin/companies/memberships/types';

type Props = {
  membership: MembershipType,
  chronology: ChronologyType,
};

const iconDef = {
  FUTURE: {
    className: 'badge-info',
    messageId: 'companyMemberships.memberInfo.status.startingSoon',
  },
  ACTIVE: {
    className: 'badge-success',
    messageId: 'companyMemberships.memberInfo.status.active',
  },
  ENDING_SOON: {
    className: 'badge-warning',
    messageId: 'companyMemberships.memberInfo.status.endingSoon',
  },
  PAST: {
    className: 'badge-light',
    messageId: 'companyMemberships.memberInfo.status.inactive',
  },
};

const MembershipStatusIcon = ({ membership, chronology }: Props) => {
  const [iconDefinition] = useState(iconDef[chronology]);

  return (
    <>
      {iconDefinition && (
        <span className={`badge ${iconDefinition.className} fw-normal`}>
          <FormattedMessage tagName="small" id={iconDefinition.messageId} />
        </span>
      )}
    </>
  );
};

export default MembershipStatusIcon;
