export const VALIDATION_REQUIRED_FIELD = 'validation.general.requiredField';
export const VALIDATION_INVALID_DATE = 'validation.general.invalidDate';
export const VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL = 'validation.general.dateAfterMinimal';
export const VALIDATION_INVALID_DATE_MUST_BE_IN_PAST = 'validation.general.datePast';
export const VALIDATION_INVALID_MIN_LENGTH = 'validation.general.minLength';
export const VALIDATION_INVALID_MAX_LENGTH = 'validation.general.maxLength';
export const VALIDATION_INVALID_REGEX_MATCH = 'validation.general.invalidRegexMatch';
export const VALIDATION_INVALID_REGEX = 'validation.general.invalidRegex';
export const VALIDATION_INVALID_NAME = 'validation.general.invalidName';
export const VALIDATION_INVALID_NAME_OF_USER = 'validation.general.invalidNameOfUser';

export const VALIDATION_REPEAT_PASSWORD_NOT_MATCH = 'validation.signup.repeatPassword.notMatch';

export const VALIDATION_EMAIL_INVALID = 'validation.signup.email.invalid';
export const VALIDATION_IBAN_INVALID = 'validation.signup.iban.invalid';
export const VALIDATION_UNDER_MINIMUM_SIGNUP_AGE =
  'validation.signup.birthday.underMinimumSignupAge';
export const VALIDATION_ADDRESS_INVALID = 'validation.signup.address.invalid';

export const VALIDATION_TOKEN_TIMEOUT = 'validation.general.token.timeout';
export const VALIDATION_TOKEN_NOTFOUND = 'validation.general.token.notFound';

export const VALIDATION_EMAIL_IS_PRESENT_IN_THE_TABLE = 'companySettings.add.errorAlert';
