// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import footerNavigation from 'config/footer/footerNavigation';

import type { IntlShape } from 'react-intl';

type Props = {
  intl: IntlShape,
  displayDetails: boolean,
};

const Footer = ({ intl: { formatMessage }, displayDetails = true }: Props) => {
  return (
    <>
      <footer className="footer mt-0">
        <div className="container">
          <div className="row border-bottom border-darklight">
            <a
              className="col"
              href="https://egym-wellpass.com/mitarbeitende/?utm_source=signup-page"
            >
              <div className="logo-image mt-4 mb-4" title="Wellpass" />
            </a>
            <div className="col col-md-auto text-uppercase text-end d-flex flex-column justify-content-center">
              <a
                className="text-muted text-nowrap"
                href="#top"
                title={formatMessage({ id: 'general.toTop' })}
              >
                <FormattedMessage id="general.toTop" />
                <i className="ps-2 text-muted fas fa-lg fa-caret-up" />
              </a>
            </div>
          </div>
          {displayDetails && (
            <div>
              <div className="row mt-4 mb-5">
                {footerNavigation.links && (
                  <ul className="nav col navbar-dark  flex-column">
                    {footerNavigation.links.map(link => (
                      <li key={link.text} className="nav-item">
                        <a className="nav-link text-muted" href={formatMessage({ id: link.url })}>
                          <FormattedMessage id={link.text} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="row mt-4 mb-2">
                <div className="col text-muted small align-self-start">
                  <FormattedMessage id="footer.priceVatIncluded" />
                </div>

                <div className="col col-auto pt-2 pb-2 text-muted small align-self-end">
                  <FormattedMessage
                    id="footer.copyright.text"
                    values={{ year: new Date().getFullYear() }}
                  />
                  <br />
                  <a
                    className="text-muted"
                    href={formatMessage({ id: 'footer.copyright.link.url' })}
                    title="EGYM GmbH"
                  >
                    <FormattedMessage id="footer.copyright.link.text" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </footer>
    </>
  );
};

export default injectIntl(Footer);
