// @flow
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { FORM_ERROR } from 'final-form';
import { post, put } from 'service/http/http-client';
import { convertErrorResponse, convertUserServiceResponse } from 'service/http/responseConverter';
import { convertToRequestBody } from 'service/http/self-signup/requestConverter';
import { extractRequestParameter } from 'service/http/requestUtil';
import SelfSignupFormWizard, {
  SelfSignupFormWizardStep,
} from 'ui/self-signup/SelfSignupFormWizard';
import Step1 from 'ui/self-signup/Step1';
import Step2 from 'ui/self-signup/Step2';
import Step3 from 'ui/self-signup/Step3';
import {
  isPaymentDetailsModified,
  validateStep1,
  validateStep2,
  validateStep3,
} from 'validation/self-signup/formValidator';
import SelfSignupSuccessPage from 'ui/self-signup/SelfSignupSuccessPage';
import ErrorPage from 'ui/error/ErrorPage';
import withSpinner from 'ui/common/spinner/withSpinner';
import Welcome from 'ui/welcome/Welcome';
import { formatDate, isUtcDateInPast, ServerTimeZone } from 'service/dateUtil';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import moment from 'moment';
import RegistrationContext from './RegistrationContext';

import { useLocation, useNavigate } from 'react-router-dom';
import { getActiveOffer } from '../../service/offerUtils';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import { useLocalStorage, usePayment } from 'service/hooks';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import PropTypes from 'prop-types';

import useApi from 'service/api';
import PaymentDetailsErrorModal from 'ui/self-signup/components/PaymentDetailsErrorModal';
import ColleagueSignupErrorPage from '../error/ColleagueSignupErrorPage';
import { useGymCount } from 'service/hooks/gymsCount';
import {
  DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX,
  hashSignupData,
} from 'service/duplicateSignupUtil.ts';
import { LocalStorage } from 'service/local-storage/localStorage';
import { uploadDeclarationOfConsent } from './SignupUtil';
import { AMERICA } from '../../config/i18n/countryCodes';

const SelfSignupComponent = props => {
  const [errorsSubmit, setErrorsSubmit] = useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [signupPageToken, setSignupPageToken] = useState(extractRequestParameter('companyToken'));
  const [shareLinkToken] = useState(extractRequestParameter('shareToken'));
  const [showStandalone, setShowStandalone] = useState(extractRequestParameter('showStandalone'));
  const [error, setError] = useState(0);
  const [existingPaymentDetails, setExistingPaymentDetails] = useState();
  const [signupPage, setSignupPage] = useState();
  const [signupPageOffer, setSignupPageOffer] = useState({});
  const [gymsCount] = useGymCount({ searchFilters: [{ trainingFilter: 'QUALITRAIN' }] });
  const [userData, setUserData] = useState({});
  const [step, setStep] = useState(parseInt(extractRequestParameter('step') || '0'));
  const [initialStep, setInitialStep] = useState();
  const [paymentDetailsErrorModalOpen, setPaymentDetailsErrorModalOpen] = useState(false);
  const [userSignupDataExpirationDate, setUserSignupDataExpirationDate] = useState();
  const [hashedUserSignupData, setHashedUserSignupData] = useState();
  const localStorage = useMemo(() => new LocalStorage(), []);
  const { resolvedAddress } = React.useContext(RegistrationContext);
  useLocalStorage(hashedUserSignupData, userSignupDataExpirationDate);

  const authenticationContext = useContext(AuthenticationContext);
  const {
    isNewUser,
    setIsNewUser,
    existingUser,
    declarationOfConsentDetails,
    setDeclarationOfConsentDetails,
    alertNotification,
    corporateEmployeeDetails,
  } = useContext(RegistrationContext);
  const { executeWithSpinner, setSpinnerShown } = useContext(SpinnerContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { signupPageApi } = useApi();

  useEffect(() => {
    setShowStandalone(extractRequestParameter('showStandalone'));
  }, [location]);

  const replaceBackslash = () => {
    const { search } = location;
    if (search && search.substr(-1) === '/') {
      navigate({ search: search.slice(0, -1) }, { replace: true });
    }
  };

  const toStep1 = () => {
    const currentUrlParams = new URLSearchParams(location.search.substring(1));
    currentUrlParams.set('step', '1');
    navigate(location.pathname + '?' + currentUrlParams.toString(), { replace: true });
  };

  const setCurrentStep = () => {
    setStep(parseInt(extractRequestParameter('step') || '0'));
    if (!initialStep) {
      setInitialStep(step);
      //This component should not be inited on step 2, redirect to step 1
      if (step > 2) {
        toStep1();
      }
    }
  };

  useEffect(() => {
    replaceBackslash();
    setCurrentStep();
  });

  useEffect(() => {
    if (signupPageToken) {
      executeWithSpinner(
        signupPageApi
          .fetchSignupPage(signupPageToken)
          .then(signupPageResponse => {
            if (signupPageResponse.expiration && isUtcDateInPast(signupPageResponse.expiration)) {
              throw new Error('Signup page already expired');
            }
            setSignupPage(signupPageResponse);
          })
          .catch(errorMessage => {
            setError(true);
            console.log('GETSIGNUPPAGE ERROR: ', errorMessage);
          }),
        signupPageApi
          .fetchSignupPageOffer(signupPageToken)
          .then(offersResponse => {
            const activeOffer = getActiveOffer(offersResponse);
            if (!activeOffer) {
              throw new Error('Signup page has no active offer');
            }
            setSignupPageOffer(activeOffer);
          })
          .catch(errorMessage => {
            setError(true);
            console.log('GETACTIVEOFFERS ERROR: ', errorMessage);
          })
      );
    }
  }, [executeWithSpinner, signupPageToken, signupPageApi]);

  useEffect(() => {
    if (shareLinkToken && !signupPageToken) {
      setSpinnerShown(true);
      signupPageApi
        .resolveSignupPageByShareToken(shareLinkToken)
        .then(signupPageResponse => {
          if (signupPageResponse.isMultiEntitySignupPage) {
            navigate({
              pathname: `/signup/${signupPageResponse.token}`,
              search: `?shareToken=${shareLinkToken}`,
            });
          } else {
            setSignupPageToken(signupPageResponse.token);
          }
        })
        .catch(error => {
          setSpinnerShown(false);
          setError(error);
          return {
            [FORM_ERROR]: error.message,
          };
        });
    } else if (!shareLinkToken && !signupPageToken) {
      setError(404);
    }
  }, [
    executeWithSpinner,
    signupPageToken,
    shareLinkToken,
    signupPageApi,
    setSpinnerShown,
    navigate,
  ]);

  const shouldFetchPaymentData = step === 3 && !isNewUser && signupPageOffer.b2cPayment;
  const { payment } = usePayment(shouldFetchPaymentData);
  React.useEffect(() => {
    setExistingPaymentDetails(payment);
  }, [payment]);

  const onSubmit = values => {
    const paymentDetailsModified = isPaymentDetailsModified(
      { ...values },
      { ...existingPaymentDetails }
    );

    const timeZoneId =
      signupPageOffer.sfAccountCountryCode === AMERICA && resolvedAddress.timeZoneId
        ? resolvedAddress.timeZoneId
        : ServerTimeZone;

    const payload = convertToRequestBody({
      ...values,
      locale: props.intl.locale,
      signupPageToken: signupPageToken,
      shareLinkToken: shareLinkToken,
      isPaymentDetailsModified: paymentDetailsModified,
      timeZoneId: timeZoneId,
    });
    if (corporateEmployeeDetails?.corporateEmployeeId) {
      payload.corporateEmployeeId = corporateEmployeeDetails.corporateEmployeeId;
    }

    if (signupPageOffer.sfAccountCountryCode === AMERICA) {
      payload.city = resolvedAddress.city;
      payload.street = resolvedAddress.street;
      payload.houseNumber = resolvedAddress.streetNumber;
      payload.zipCode = resolvedAddress.zipCode;
    }

    return executeWithSpinner(
      (isNewUser ? post('/v1/signup', payload) : put(`/v1/signup/user/${existingUser.id}`, payload))
        .then(response => {
          localStorage.cleanupExpiredDuplicateSignupData();
          if (response.ok) {
            setShowSuccessDialog(true);
            response.json().then(signUpResponse => {
              uploadDeclarationOfConsent(
                signUpResponse.membershipId,
                declarationOfConsentDetails,
                setDeclarationOfConsentDetails,
                alertNotification?.birthday?.warning
              );
              setUserData({
                firstName: values.firstName ? values.firstName : values.reviewedFirstName,
                email: values.email ? values.email : existingUser.email,
                membershipStartDate: formatDate(values.membershipStartDate),
                doiConfirmed: signUpResponse.doiConfirmed,
              });
            });
            if (!isNewUser) {
              authenticationContext.setHasMemberships(true);
            }

            const hashedData = hashSignupData(
              `${
                payload.selfSignupPageToken
              },${payload.firstName.toLowerCase()},${payload.lastName.toLowerCase()},${moment(
                payload.birthday
              ).format('DD.MM.YYYY')}`,
              DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX
            );
            setHashedUserSignupData(hashedData);
            setUserSignupDataExpirationDate(moment().add(48, 'hours').format());
          } else if (response.status >= 500) {
            return response.text().then(errorMessage => {
              const errors = convertErrorResponse(errorMessage);
              setErrorsSubmit(errors);
            });
          } else {
            return response.json().then(jsonResponse => {
              const errors = convertUserServiceResponse(jsonResponse);
              if (errors[FORM_ERROR] === 'invalid.paymentData') {
                setPaymentDetailsErrorModalOpen(true);
              } else {
                setErrorsSubmit(errors);
              }
            });
          }
        })
        .catch(errorMessage => {
          setErrorsSubmit({
            [FORM_ERROR]: errorMessage,
          });
        })
    );
  };

  const closePaymentDetailsErrorModal = () => {
    setPaymentDetailsErrorModalOpen(false);
    setIsNewUser(false);
    toStep1();
  };

  const initialiseFormFields = () => {
    let initialValues = {};
    initialValues['offerId'] = signupPageOffer?.id;
    initialValues['integrationScopeId'] = signupPageOffer?.integrationScopeId;
    return initialValues;
  };

  const isMembershipOfferPlusOne = () => {
    if (signupPageOffer && signupPageOffer.type) {
      return signupPageOffer.type === 'PLUS_ONE';
    }
    return false;
  };

  const isInviteColleagueDisabled = error => {
    return error && error.code && error.code === 'colleagueInviteNotEnabled';
  };

  const headerTitleKey = 'signupPage.headerTitle';

  if (isInviteColleagueDisabled(error)) {
    const Component = withPageTemplate(ColleagueSignupErrorPage, {
      headerTitle: headerTitleKey,
    });
    return <Component />;
  } else if (error || isMembershipOfferPlusOne()) {
    const Component = withPageTemplate(ErrorPage, {
      headerTitle: headerTitleKey,
    });
    return <Component />;
  }

  if (showSuccessDialog) {
    const Component = withPageTemplate(SelfSignupSuccessPage, {
      headerTitle: headerTitleKey,
    });
    return <Component userData={userData} offer={signupPageOffer} />;
  }
  if (signupPage === undefined || Object.keys(signupPageOffer).length === 0) {
    return <></>;
  } else {
    return (
      <>
        <SelfSignupFormWizard
          step={step}
          onSubmit={onSubmit}
          initialValues={initialiseFormFields()}
          showStandalone={showStandalone}
          errorsSubmit={errorsSubmit}
        >
          <SelfSignupFormWizardStep key="step-welcome" isIndependentPage={true}>
            <Welcome
              signupPage={signupPage}
              signupPageOffer={signupPageOffer}
              gymsCount={gymsCount}
              showStandalone={showStandalone}
            />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-1"
            validateStep={validateStep1(
              signupPage,
              signupPageOffer.sfAccountCountryCode === AMERICA,
              resolvedAddress
            )}
            isIndependentPage={false}
          >
            <Step1 signupPageOffer={signupPageOffer} signupPage={signupPage} />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-2"
            validateStep={validateStep2(
              signupPage,
              signupPageOffer.sfAccountCountryCode === AMERICA,
              resolvedAddress
            )}
            isIndependentPage={false}
          >
            <Step2 signupPageOffer={signupPageOffer} signupPage={signupPage} />
          </SelfSignupFormWizardStep>
          <SelfSignupFormWizardStep
            key="step-3"
            validateStep={validateStep3(signupPageOffer, { ...existingPaymentDetails })}
            isIndependentPage={false}
          >
            {signupPage && signupPageOffer ? (
              <Step3
                signupPage={signupPage}
                signupPageOffer={signupPageOffer}
                gymsCount={gymsCount}
              />
            ) : null}
          </SelfSignupFormWizardStep>
        </SelfSignupFormWizard>

        {paymentDetailsErrorModalOpen && (
          <PaymentDetailsErrorModal
            isOpen={true}
            onRequestClose={() => closePaymentDetailsErrorModal()}
          />
        )}
      </>
    );
  }
};

SelfSignupComponent.propTypes = {
  intl: PropTypes.object,
};

const SelfSignupPage = props => <SelfSignupComponent {...props} />;

export default withSpinner(injectIntl(SelfSignupPage));
