import { get } from 'service/http/http-client';

export class SignupPageApi {
  fetchSignupPage = singupPageToken => {
    return get(`/v2/signup-page/${singupPageToken}`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET shareLink-method returned ' + response.status);
      }
    });
  };

  resolveSignupPageByShareToken = shareLinkToken => {
    return get(`/v1/signup-page/shared/${shareLinkToken}`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then(jsonResponse => {
          return Promise.reject({
            status: response.status,

            ...jsonResponse,
          });
        });
      }
    });
  };

  fetchSignupPageOffer = signupPagetoken => {
    return get(`/v2/signup-page/${signupPagetoken}/offer/active`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET active offers returned ' + response.status);
      }
    });
  };
}
