import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Modal from 'ui/common/modal/Modal';

const EgymIdModal = ({ isOpen, onRequestClose, intl: { formatMessage } }: Props) => {
  const modalTitle = formatMessage({ id: 'egymId.modal.title' });
  return (
    <Modal
      isOpen={isOpen}
      modalTitle={modalTitle}
      modalSizeClass="modal-md"
      onRequestClose={onRequestClose}
      closeButtonId="egymIdModal.btn.close"
    >
      <div>
        <span>
          <FormattedMessage
            id="egymId.modal.body"
            values={{
              a: chunks => (
                <a href="https://egym-wellpass.com/egym-id" target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />{' '}
        </span>
      </div>
    </Modal>
  );
};

export default injectIntl(EgymIdModal);
