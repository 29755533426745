// @flow
import React, { useEffect } from 'react';
import CloseButton from '../components/CloseButton';

type Props = {
  isOpen: boolean,
  closable?: boolean,
  onRequestClose: Function,
  modalTitle: ?string,
  children: any,
  closeButtonId: string,
  modalClass: ?string,
  modalSizeClass: any,
  noFooter: boolean,
  headerIcon: any,
  variant: string,
  modalId?: string,
};

const Modal = ({
  isOpen = false,
  closable = true,
  onRequestClose,
  modalTitle,
  children,
  closeButtonId,
  modalClass = '',
  modalSizeClass,
  noFooter = false,
  headerIcon = '',
  variant = '',
  modalId = '',
}: Props) => {
  let modalRef: HTMLInputElement;

  useEffect(() => {
    if (!closable) {
      return;
    }

    const handleOutsideClick = e => {
      if (modalRef && !modalRef.contains(e.target)) {
        onRequestClose();
      }
    };
    const rootNode = document.getElementById('root');
    rootNode.addEventListener('click', handleOutsideClick, false);
    return () => {
      rootNode.removeEventListener('click', handleOutsideClick, false);
    };
  }, [modalRef, closable, onRequestClose]);

  const modalClassName = `modal fade ${modalClass} ${isOpen ? 'show' : ''}`;
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    backgroundColor: 'rgba(0,0,0,0.5)',
    overflowX: 'hidden',
    overflowY: 'auto',
  };

  //
  const header = headerIcon ? (
    <div id={modalId} className="modal-header border-0 d-block">
      <div className="d-flex justify-content-between">
        <div></div>
        <span>{headerIcon}</span>
        {closable && (
          <CloseButton
            className="icon-header-close"
            buttonId={closeButtonId}
            onClick={onRequestClose}
          />
        )}
      </div>
      <h5 className={`modal-title text-primary ${variant}`}>{modalTitle}</h5>
    </div>
  ) : (
    <div className="modal-header border-0">
      <h5 className="modal-title text-primary">{modalTitle}</h5>
      {closable && <CloseButton buttonId={closeButtonId} onClick={onRequestClose} />}
    </div>
  );

  return (
    <div className={modalClassName} tabIndex="-1" role="dialog" style={modalStyle}>
      <div className={`modal-dialog modal-dialog-centered ${modalSizeClass}`}>
        <div className={`modal-content`} ref={n => (modalRef = n)}>
          {header}
          <div className="modal-body">{children}</div>
          {!noFooter && <div className="modal-footer border-0" />}
        </div>
      </div>
    </div>
  );
};

export default Modal;
