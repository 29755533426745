// @flow
import React from 'react';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage } from 'react-intl';
import { isDev } from '../../util/env/envUtils';

type ServerFieldError = {
  field: string,
  code: string,
  rejectedValue: string,
  args: Array<string>,
};

type ServerGeneralError = {
  code: string,
  args: Array<string>,
  field?: string,
  defaultMessage?: string,
};

type ServerResponse = {
  userServiceFieldErrors: ?Array<ServerFieldError>,
  userServiceGeneralErrors: ?Array<ServerGeneralError>,
  message?: string,
};

/**
 * Converts the error response that comes from the backend into a "shape" that is expected by FinalForm.
 * Example:
 * The response from the backend contains two lists of errors:
 * <pre>
 * {
 *   userServiceFieldErrors: [
 *     {
 *       field: 'firstName',
 *       code: 'validation.general.requiredField',
 *       args: [],
 *       defaultMessage: 'First Name is mandatory',
 *     },
 *     {
 *       field: 'lastName',
 *       code: 'validation.general.maxLength',
 *       args: [50],
 *       defaultMessage: 'Last Name should not have more than 50 characters',
 *     },
 *   ],
 *   userServiceGeneralErrors: [
 *     {
 *       code: 'validation.general.userAlreadyExists',
 *       args: ['john.doe@egym.de'],
 *       defaultMessage: 'User with email {1} already exists',
 *     }
 *   ],
 * }
 * </pre>
 *
 * FinalForm expects the validation errors in slightly different form.
 * It expects the error object (if any) to have one property for each field that is not valid, and the name of that property should match the name of the field.
 * In addition, there is a field called "FORM_ERROR" that can be used for errors that are not specific to any field (i.e. general error).
 * So, the above response will be converted to something like this:
 * <pre>
 * {
 *   firstName: {
 *     code: 'validation.general.requiredField',
 *     args: [],
 *   },
 *   lastName: {
 *     code: 'validation.general.maxLength',
 *     args: [50],
 *   },
 *   FORM_ERROR: [
 *     {
 *       code: 'validation.general.userAlreadyExists',
 *       args: ['john.doe@egym.de'],
 *       defaultMessage: 'User with email {1} already exists',
 *     },
 *     {
 *       field: 'firstName',
 *       code: 'validation.general.requiredField',
 *       args: [],
 *     },
 *     {
 *       field: 'lastName',
 *       code: 'validation.general.maxLength',
 *       args: [50],
 *     },
 *   ]
 * }
 * </pre>
 * @param serverResponse
 */
export const convertUserServiceResponse = (serverResponse: ServerResponse) => {
  const errors = {};
  if (serverResponse) {
    if (serverResponse.userServiceFieldErrors || serverResponse.userServiceGeneralErrors) {
      if (serverResponse.userServiceGeneralErrors) {
        errors[FORM_ERROR] = [...serverResponse.userServiceGeneralErrors];
      }
      if (serverResponse.userServiceFieldErrors) {
        serverResponse.userServiceFieldErrors.forEach(serverFieldError => {
          // 1. Create a field error for that specific field (shown next to each field)
          errors[serverFieldError.field] = {
            code: serverFieldError.code,
            args: convertArrayToObject(serverFieldError.args),
          };
          // 2. In addition, add an error to the global errors (shown at the bottom of the form)
          if (!errors[FORM_ERROR]) {
            errors[FORM_ERROR] = [];
          }

          errors[FORM_ERROR].push({
            code: serverFieldError.code,
            field: serverFieldError.field,
            args: convertArrayToObject(serverFieldError.args),
          });
        });
      }
    } else {
      // not a validation error. Just take the message from the response
      errors[FORM_ERROR] = serverResponse.message;
    }
  }
  return errors;
};

export const convertArrayToObject = (array: Array<string>): any => {
  if (array) {
    return array.reduce((accumulator, value, index) => ({ ...accumulator, [index]: value }), {});
  }
  return {};
};

export const convertErrorResponse = (errorMessage: string) => {
  if (isDev()) {
    console.log(`Server Error Message: ${errorMessage}`);
  }
  return {
    [FORM_ERROR]: <FormattedMessage id="validation.signup.general.serverError" />,
  };
};
