// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const StudiosSection = () => (
  <>
    <div className="row text-center">
      <div style={{ flex: '1 100%' }}>
        <h3 id="more-than-studio" className="text-dark fw-bold">
          <FormattedMessage id="welcome.studios.moreThanStudioTitle" />
        </h3>

        <div className="mt-3">
          <p className="d-inline-block welcome-title-content mb-4 text-dark">
            <FormattedMessage id="welcome.studios.description" />
          </p>
        </div>
      </div>
    </div>
  </>
);

export default StudiosSection;
