import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from 'ui/common/modal/Modal';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import TextFormField from 'ui/common/components/TextFormField';
import Form from 'ui/common/components/Form';
import ExternalLink from 'ui/self-signup/components/ExternalLink';

import { validateIban } from 'validation/common/ibanValidator';
import { validateRequiredWithLength } from 'validation/common/commonValidators';
import { formatIban } from 'util/format/ibanFormatter';

const validatePayment = values => {
  const errors = {
    ownerName: validateRequiredWithLength(1, 100)(values.ownerName),
    iban: validateIban(values.iban),
  };
  return errors;
};

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  intl: {
    formatMessage: Function,
  },
  payment: Payment,
  setPaymentCallback: Function,
};

const PaymentDataModal = ({
  isOpen,
  onRequestClose,
  intl: { formatMessage },
  payment,
  setPaymentCallback,
}: Props) => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const updatePayment = values => {
    const newPayment = {};
    newPayment.ownerName = values.ownerName;
    newPayment.ibanNumber = formatIban(values.iban);
    newPayment.type = 'sepadirectdebit';

    const putPayment = async () => {
      await setPaymentCallback(newPayment)
        .then(response => {
          onRequestClose(newPayment);
        })
        .catch(error => {
          setErrorMessage('validation.general.serverError');
        });
    };

    return executeWithSpinner(putPayment());
  };

  const initialValues = {
    ownerName: payment?.ownerName ?? '',
    iban: payment?.ibanNumber ?? '',
  };

  return (
    <Modal
      isOpen={isOpen}
      modalSizeClass="modal-lg"
      closeButtonId="paymentDataModal.btn.close"
      onRequestClose={() => onRequestClose(payment)}
    >
      <Form onSubmit={updatePayment} validator={validatePayment} defaultValues={initialValues}>
        {({ register, control, formState: { isDirty, isValid, isSubmitting } }) => (
          <div className="container">
            <h3 className="text-center text-uppercase fw-lighter">
              <FormattedMessage id="changePaymentDataModal.headerTitle" />
            </h3>
            <div className="text-start fw-bold mt-4 mb-4">
              <FormattedMessage id="changePaymentDataModal.headerBody" />
            </div>
            <div className="text-start fw-lighter mt-1 mb-1">
              <FormattedMessage id="changePaymentDataModal.body1_1" />
            </div>
            <div className="text-start fw-lighter mt-1 mb-1">
              <FormattedMessage id="changePaymentDataModal.body1_2" />
            </div>
            <div className="text-start fw-lighter mt-3 mb-3">
              <FormattedMessage id="changePaymentDataModal.body2" />
            </div>
            <div className="text-start fw-lighter mt-1 mb-3">
              <TextFormField
                controlId="ownerName"
                register={{
                  ...register('ownerName'),
                }}
                control={control}
                placeholder="changePaymentDataModal.ownerName"
                controlLabel="changePaymentDataModal.ownerName"
                containerClassName="container p-0"
              />
            </div>
            <div className="text-start fw-lighter mb-3">
              <TextFormField
                controlId="iban"
                register={{
                  ...register('iban'),
                }}
                control={control}
                controlLabel="changePaymentDataModal.iban"
                placeholder="changePaymentDataModal.iban"
                containerClassName="container p-0"
              />
            </div>
            <div className="text-start fw-lighter my-1 small">
              <FormattedMessage
                id="changePaymentDataModal.legalConsent"
                values={{
                  generalTermsAndConditions: (
                    <ExternalLink
                      url={formatMessage({
                        id: 'changePaymentDataModal.generalTermsAndConditions.url',
                      })}
                      text={formatMessage({
                        id: 'changePaymentDataModal.generalTermsAndConditions.text',
                      })}
                    />
                  ),
                }}
              />
            </div>
            {errorMessage ? (
              <>
                <div className="">
                  <div className="alert alert-danger align-content-center text-center">
                    <FormattedMessage id={errorMessage} />
                  </div>
                </div>
              </>
            ) : (
              <div className="align-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || !isDirty || isSubmitting}
                >
                  <FormattedMessage id="changePaymentDataModal.savePayment" />
                </button>
              </div>
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default injectIntl(PaymentDataModal);
