// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CompanyLogo from 'ui/common/components/company-logo/CompanyLogo';
import style from './IntroSection.module.scss';

type Props = {
  logo: ?string,
  name: ?string,
};

const IntroSectionUnlimitedOffering = ({ logo, name }: Props) => (
  <div className="row justify-content-center ie-fix">
    <div className="col-md-10 text-center text-white align-self-center margins">
      {logo && <CompanyLogo logo={logo} />}
      <div className="container justify-content-center welcome-title-container">
        <div className="col-md-12">
          <h1
            className={`fw-bold text-white text-break ${style.headlineMargin}`}
            style={{ whiteSpace: 'pre-line' }}
          >
            <FormattedMessage
              id="welcome.welcomeFree.introSection.headline"
              values={{
                br: () => <br />,
              }}
            />
          </h1>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <div className="welcome-title-content" style={{ whiteSpace: 'pre-line' }}>
              <FormattedMessage
                id="welcome.welcomeFree.introSection.text"
                values={{
                  name: name,
                }}
              />
            </div>
          </div>
          <button
            id="selfsignup.btn.register.header"
            className="btn btn-lg btn-primary btn-shadow"
            type="submit"
          >
            <FormattedMessage id="welcome.welcomeFree.introSection.ctaButton" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default injectIntl(IntroSectionUnlimitedOffering);
