// @flow
import React from 'react';
import TableHeaderCell from 'ui/admin/companies/memberships/TableHeaderCell';
import { useIntl } from 'react-intl';

type Props = {
  showPersonalIdColumn: boolean,
  showMembershipTypeColumn: boolean,
  showEmployeeNumberColumn: boolean,
};

const TableHeaderRow = ({
  showPersonalIdColumn,
  showMembershipTypeColumn,
  showEmployeeNumberColumn,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <tr>
      <TableHeaderCell
        headline={formatMessage({ id: 'companyMemberships.tableHeadline.memberships' })}
      />
      {showPersonalIdColumn && (
        <TableHeaderCell
          headline={formatMessage({ id: 'companyMemberships.tableHeadline.additionalInformation' })}
        />
      )}
      {showEmployeeNumberColumn && (
        <TableHeaderCell
          headline={formatMessage({ id: 'companyMemberships.tableHeadline.employeeNumber.title' })}
          toolTipTextId="companyMemberships.tableHeadline.employeeNumber.tooltip"
        />
      )}
      {showMembershipTypeColumn && (
        <TableHeaderCell
          headline={formatMessage({ id: 'companyMemberships.tableHeadline.plan' })}
        />
      )}
      <TableHeaderCell
        headline={formatMessage({ id: 'companyMemberships.tableHeadline.startDate' })}
      />
      <TableHeaderCell
        headline={formatMessage({ id: 'companyMemberships.tableHeadline.endOfContract' })}
      />
      <TableHeaderCell
        headline={formatMessage({ id: 'companyMemberships.tableHeadline.status' })}
      />
      <TableHeaderCell />
    </tr>
  );
};

export default React.memo<Props>(TableHeaderRow);
