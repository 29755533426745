import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DECLARATION_OF_CONSENT_ACTIONS,
  DECLARATION_OF_CONSENT_ALERT_STATUS,
} from './reducers/useDeclarationOfConsent';
import CustomAlert from '../common/components/CustomAlert';
import DeclarationOfConsentModal from '../common/modal/DeclarationOfConsentModal';

type props = {
  alertDetails: any,
  declarationOfConsentDetails: any,
  setDeclarationOfConsentDetails: Function,
};
const DeclarationOfConsentAlertSection = ({
  alertDetails,
  declarationOfConsentDetails,
  setDeclarationOfConsentDetails,
}: props) => {
  const openDeclarationOfConsentModal = () => {
    setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.OPEN_MODAL });
  };

  const closeDeclarationOfConsentModal = () => {
    if (declarationOfConsentDetails.status === DECLARATION_OF_CONSENT_ALERT_STATUS.WARNING) {
      setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.CLEAR_ALL_FILES });
      setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.RESET_CONFIRMATION });
    } else if (
      declarationOfConsentDetails.status === DECLARATION_OF_CONSENT_ALERT_STATUS.SUCCESS &&
      declarationOfConsentDetails?.files?.length === 0
    ) {
      setDeclarationOfConsentDetails({
        action: DECLARATION_OF_CONSENT_ACTIONS.SET_STATUS,
        payload: DECLARATION_OF_CONSENT_ALERT_STATUS.WARNING,
      });
      setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.RESET_CONFIRMATION });
    }
    setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.CLOSE_MODAL });
  };

  return (
    <>
      {declarationOfConsentDetails.status === DECLARATION_OF_CONSENT_ALERT_STATUS.WARNING &&
        declarationOfConsentDetails?.files?.length === 0 &&
        alertDetails && (
          <CustomAlert
            controlId={`birthdayWarning`}
            info={alertDetails}
            className={'warning'}
            buttonAction={openDeclarationOfConsentModal}
          />
        )}

      {declarationOfConsentDetails.status === DECLARATION_OF_CONSENT_ALERT_STATUS.SUCCESS &&
        declarationOfConsentDetails?.files?.length > 0 &&
        alertDetails && (
          <CustomAlert
            controlId={`birthdaySuccess`}
            info={{
              message: 'declarationOfConsentAlert.success.body',
              buttonText: 'declarationOfConsentAlert.success.button.title',
            }}
            className={'success'}
            buttonAction={openDeclarationOfConsentModal}
          />
        )}

      {declarationOfConsentDetails.modalIsOpen && (
        <DeclarationOfConsentModal
          isOpen={declarationOfConsentDetails.modalIsOpen}
          closable={true}
          onRequestClose={closeDeclarationOfConsentModal}
          modalTitle={<FormattedMessage id="declarationOfConsentModal.title" />}
        />
      )}
    </>
  );
};

export default DeclarationOfConsentAlertSection;
