// @flow
/**
 * Function that can execute arbitrary number of validators on the given value.
 * @param value the value to validate
 * @returns {Function} a function that accepts any number of validators as arguments
 */
const combineValidators = (value: ?string) => {
  return (...validators: Array<Function>) => {
    for (let index = 0; index < validators.length; ++index) {
      const validator = validators[index];
      if (!isFunction(validator)) {
        continue;
      }
      const error = validator(value);
      if (error !== undefined) {
        return error;
      }
    }
    return undefined;
  };
};

const isFunction = variable => {
  return variable && typeof variable === 'function';
};

export default combineValidators;
