import React from 'react';
import { downloadCsvText } from 'util/csv/csvUtils';
import { getPropertyValueOrFallback } from 'util/object/objectUtils';
import type { ChronologyType, MembershipWrapperType } from 'ui/admin/companies/memberships/types';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatDate, formatDateWithTimeZoneId } from 'service/dateUtil';
import { isEmployeeNumberPresent } from 'service/membershipUtil';

type Props = {
  membershipWrappers: Array<MembershipWrapperType>,
  intl: IntlShape,
};

const BYTE_ORDER_MARK = '\ufeff';
const COLUMN_SEPARATOR = ';';
const DELIMINATOR = '"';
const NEW_LINE = '\r\n';

/** Surrounds the values with quotes and escapes all quotes that were in the original value. */
const csvEscape = value => {
  const escapedValue = `${value.toString().replace(/"/g, `\\${DELIMINATOR}`)}`;
  return `${DELIMINATOR}${escapedValue}${DELIMINATOR}`;
};

const MembershipExport = ({ membershipWrappers = [], intl: { formatMessage } }: Props) => {
  const fileName = formatMessage({ id: 'companyMemberships.file.membershipCsv' });

  const getLocalisedChronology = (chronology: ChronologyType) => {
    switch (chronology) {
      case 'FUTURE':
        return formatMessage({ id: 'companyMemberships.memberInfo.status.startingSoon' });
      case 'ACTIVE':
        return formatMessage({ id: 'companyMemberships.memberInfo.status.active' });
      case 'PAST':
        return formatMessage({ id: 'companyMemberships.memberInfo.status.inactive' });
      case 'ENDING_SOON':
        return formatMessage({ id: 'companyMemberships.memberInfo.status.endingSoon' });
      default:
        return '';
    }
  };

  const getLocalisedType = (type: string) => {
    switch (type) {
      case 'STANDARD':
        return formatMessage({ id: 'companyMemberships.membershipOffer.type.standard' });
      case 'FREE':
        return formatMessage({ id: 'companyMemberships.membershipOffer.type.free' });
      default:
        return '';
    }
  };

  const columns = [
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.lastName' }),
      properties: ['user.lastName'],
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.firstName' }),
      properties: ['user.firstName'],
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.dateOfBirth' }),
      properties: ['user.birthday'],
      transform: formatDate,
    },
    {
      header: formatMessage({
        id: 'companyMemberships.exportCsvHeaders.additionalInformationValue',
      }),
      properties: ['membership.employeeIdentifier'],
    },
    {
      header: formatMessage({
        id: 'companyMemberships.exportCsvHeaders.additionalInformationLabel',
      }),
      properties: ['membershipOfferDto.employeeInternalIdentifierLabel'],
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.plan' }),
      properties: ['membershipOfferDto.type'],
      transform: getLocalisedType,
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.startDate' }),
      properties: ['membership.membershipStartTimestamp', 'membership.timeZoneId'],
      transform: formatDateWithTimeZoneId,
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.endDate' }),
      properties: ['membership.membershipEndTimestamp', 'membership.timeZoneId'],
      transform: formatDateWithTimeZoneId,
    },
    {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.status' }),
      properties: ['chronology'],
      transform: getLocalisedChronology,
    },
  ];
  if (isEmployeeNumberPresent(membershipWrappers)) {
    const employeeNumberColumnIndex = 5;
    columns.splice(employeeNumberColumnIndex, 0, {
      header: formatMessage({ id: 'companyMemberships.exportCsvHeaders.employeeNumber' }),
      properties: ['corporateEmployee.employeeNumber'],
    });
  }

  const getDataRows = (membershipWrappers: Array<MembershipWrapperType>): string => {
    let membershipsCsvRows = membershipWrappers.map(membershipWrapper => {
      return columns.reduce((accumulator, { header, properties, transform }, index) => {
        const values = properties.map(property => {
          return getPropertyValueOrFallback(membershipWrapper, property);
        });
        let value = values[0];
        if (transform && typeof transform === 'function') {
          value = transform(...values);
        }
        return index === 0
          ? csvEscape(value)
          : `${accumulator}${COLUMN_SEPARATOR}${csvEscape(value)}`;
      }, '');
    });

    return membershipsCsvRows.join(NEW_LINE);
  };

  const exportMembershipsToCsv = (membershipWrappers: Array<MembershipWrapperType>): string => {
    const headerRow = columns.map(({ header }) => csvEscape(header)).join(COLUMN_SEPARATOR);
    const dataRow = getDataRows(membershipWrappers);
    downloadCsvText(`${BYTE_ORDER_MARK}${headerRow}${NEW_LINE}${dataRow}`, fileName);
  };

  return (
    <>
      <div
        id="membershipExport.btn.exportCsv"
        className="membership-export"
        disabled={membershipWrappers.length === 0}
        onClick={() => exportMembershipsToCsv(membershipWrappers)}
      >
        <FormattedMessage id="companyMemberships.button.exportRecords" />
      </div>
    </>
  );
};

export default injectIntl(MembershipExport);
