// @flow
import React from 'react';

type Props = {
  className?: string,
  containerClass?: string,
  backgroundImageUrl?: string,
  children: any,
};

const BackgroundSection = ({ backgroundImageUrl, children, className, containerClass }: Props) => (
  <div className={'background-section ' + (className ? className : '')}>
    {backgroundImageUrl && (
      <div
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        className={'header-bg-img-underlay'}
      />
    )}
    <section>
      <div className={'header-section ' + (containerClass ? containerClass : 'container')}>
        {children}
      </div>
    </section>
  </div>
);

export default BackgroundSection;
