// @flow
import React from 'react';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import { FormattedMessage } from 'react-intl';

const NewPasswordSuccessPage = () => (
  <div className="container" id="newPasswordPage.successPage.message">
    <h2 className="text-center text-dark my-5">
      <FormattedMessage id="newPasswordPage.successPage.title" />
    </h2>
    <p>
      <FormattedMessage id="newPasswordPage.successPage.text" />
    </p>
  </div>
);

export default withPageTemplate(NewPasswordSuccessPage, {
  headerTitle: 'newPasswordPage.successPage.headerTitle',
});
