import { outlinedInputClasses } from '@mui/material';
import styles from './OutlinedInputTheme.module.scss';

const outlinedInputTheme = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        [`&.${outlinedInputClasses.root}.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#DC3545',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        height: 48,
        borderRadius: 50,
        lineHeight: '1.4rem',
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: styles.borderColor,
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: styles.borderColor,
        },
        [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
          borderWidth: 1,
          borderColor: '#8c8c8c',
        },
      },
      input: ({ ownerState }) => ({
        color: styles.textColor,
        lineHeight: '21px',
        height: '21px',
        '&::placeholder': {
          color: styles.textColor,
          opacity: 1,
        },
        ...((ownerState.formControl.focused || ownerState.formControl.filled) && {
          transform: 'translate(0px, 8px)',
        }),
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 14,
        letterSpacing: '.02em',
        lineHeight: '21px',
        [`&.Mui-error`]: {
          color: 'rgb(0, 0, 0)',
          opacity: 0.6,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        paddingLeft: '10px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
};

export default outlinedInputTheme;
