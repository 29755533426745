// @flow
import { VALIDATION_TOKEN_NOTFOUND, VALIDATION_TOKEN_TIMEOUT } from 'validation/common/errorCodes';

/**
 * Checks whether there is a validation error regarding the token.
 */
export const needsNewPasswordResetToken = submitError =>
  submitError &&
  submitError.constructor === Array &&
  submitError.filter(
    error => error.code === VALIDATION_TOKEN_TIMEOUT || error.code === VALIDATION_TOKEN_NOTFOUND
  ).length > 0;
