import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import styles from '../self-signup/SelfSignupSuccessPage.module.scss';
import qrCode from '../../styles/img/success/wellpass-success-qr-code.png';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { Link } from 'react-router-dom';

const DoubleOptInSuccessPage = () => (
  <div className="container my-4" id="doubleOptInSuccessPage.page">
    <div className="py-5 success-page">
      <div className="d-flex justify-content-center">
        <EdsIcon className="check-circle-icon">check_circle</EdsIcon>
      </div>
      <div className="d-flex justify-content-center">
        <h3 className="text-center header-text mt-3 head-container">
          <FormattedMessage id="doubleOptInSuccessPage.title" />
        </h3>
      </div>

      <div className="d-flex justify-content-center">
        <p className="body-text body-container text-center">
          <FormattedMessage id="doubleOptInSuccessPage.body" />
        </p>
      </div>

      <div className="d-none d-md-block">
        <div className="d-flex justify-content-center mt-2">
          <div className={`large-bold ${styles.qrcodeText}`}>
            <FormattedMessage id="signupSuccessPage.qrCode" />
          </div>
          <img className={styles.qrcodeImage} src={qrCode} alt="qrCode to Wellpass app" />
        </div>
      </div>

      <div className="d-block d-md-none text-center mt-4 mb-5">
        <Link
          to="https://qobe.app.link/I1S7tjO3xQ"
          target="_blank"
          className="btn btn-secondary download-btn"
        >
          <FormattedMessage id="doubleOptInSuccessPage.downloadAppBtn" />
        </Link>
      </div>
    </div>
  </div>
);

export default withPageTemplate(DoubleOptInSuccessPage, {
  headerTitle: 'doubleOptInSuccessPage.headerTitle',
  displayDetails: false,
});
