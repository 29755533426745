import React, { useState } from 'react';
type Props = {
  children: any,
};

const WelcomeContext = React.createContext();

const WelcomeProvider = ({ children }: Props) => {
  const [gymFinderUrl, setGymFinderUrl] = useState();

  return (
    <WelcomeContext.Provider
      value={{
        gymFinderUrl: gymFinderUrl,
        setGymFinderUrl: setGymFinderUrl,
      }}
    >
      {children}
    </WelcomeContext.Provider>
  );
};

const WelcomeConsumer = WelcomeContext.Consumer;
export { WelcomeProvider, WelcomeConsumer };
export default WelcomeContext;
