import { get, put } from 'service/http/http-client';

export class PaymentApi {
  fetchPayments = () => {
    return get('/v1/user/current/payment-method').then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET payment-method returned ' + response.status);
      }
    });
  };

  updatePayment = newPayment => {
    return put('/v1/user/current/payment-method', newPayment).then(response => {
      if (!response.ok) {
        throw new Error('PUT payment-method returned ' + response.status);
      }
    });
  };
}
