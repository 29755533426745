// @flow
import validator from 'validator';
import combineValidators from 'validation/common/combineValidators';
import { validateRequiredWithLength } from 'validation/common/commonValidators';
import { VALIDATION_EMAIL_INVALID } from 'validation/common/errorCodes';
import type { ValidationResult } from 'validation/types';

export const validateEmail = (value: ?string): ?ValidationResult => {
  return combineValidators(value)(validateRequiredWithLength(1, 64), validateEmailInvalid);
};

const validateEmailInvalid = (value: string): ?ValidationResult => {
  if (!validator.isEmail(value)) {
    return { code: VALIDATION_EMAIL_INVALID };
  }
  return undefined;
};
