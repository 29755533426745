import { useEffect } from 'react';

export function useAnchorTag(offset = 0, triger = true) {
  const getOffset = element => {
    return element.id === 'faq' ? element.offsetTop : element.offsetParent.offsetTop;
  };

  useEffect(() => {
    const scrollToHashElement = () => {
      setTimeout(() => {
        const { hash } = window.location;
        const elementToScroll = document.getElementById(hash?.replace('#', ''));

        if (!elementToScroll) return;

        window.scrollTo({
          top: getOffset(elementToScroll) - offset,
          behavior: 'smooth',
        });
      }, 0);
    };

    if (!triger) return;

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [triger, offset]);
}
