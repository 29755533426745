import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import 'styles/components/stepper.scss';

export type StepType = {
  id: number | string,
  label: React.ReactNode,
  content?: React.ReactNode,
  completedContent?: React.ReactNode,
  isSelected: boolean,
  isCompleted: boolean,
  onClick: () => void,
};

export const Step = ({
  isSelected,
  isCompleted,
  content,
  completedContent,
  label,
  onClick,
  id,
}: StepType) => {
  const classes = useMemo(() => {
    if (isSelected) {
      return 'step-is-selected';
    }
    if (isCompleted) {
      return 'step-is-completed';
    }

    return '';
  }, [isSelected, isCompleted]);
  return (
    <Stack
      id={id}
      data-testid={id}
      onClick={onClick}
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={`step-container ${classes}`}
    >
      <span className="step-icon">{isCompleted && !isSelected ? completedContent : content}</span>
      <span className="step-label">{label}</span>
    </Stack>
  );
};

export type StepConnectorType = {
  isCompleted: boolean,
};
export const StepConnector = ({ isCompleted }: StepConnectorType) => {
  const classes = useMemo(() => {
    if (isCompleted) {
      return 'step-connector-is-completed';
    }

    return '';
  }, [isCompleted]);

  return <div className={`step-connector ${classes}`}></div>;
};
