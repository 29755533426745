// @flow
import moment from 'moment-timezone';

/**
 * Formats a ISO Zoned Date Time (YYYY-MM-DD HH:mm:ss z) into a Date (DD-MM-YYYY) in CET timezone.
 *
 * NOTE:
 * Since the conversion from timestamp to date depends on the timezone used,
 * therefore we use the CET timezone here explicitly.
 */
export const formatISOZonedDateTimeToCETDate = (zonedDateTime: ?string): string => {
  if (!zonedDateTime) return '';
  return moment(zonedDateTime).tz('CET').format('DD-MM-YYYY');
};

/**
 * Formats a ISO Date (YYYY-MM-DD) into a Date (DD-MM-YYYY).
 */
export const formatISODateToDate = (date: ?string): string => {
  if (!date) return '';
  // NOTE: We don't want to convert it to UTC time zone. We just want to display the date as it is.
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

/**
 * Formats a ISO Zoned Date Time (YYYY-MM-DD HH:mm:ss z) into a UTC Date Time (DD-MM-YYYY HH:mm:ss z).
 */
export const formatISOZonedDateTimeToUTCDateTime = (dateTime: ?string): string => {
  if (!dateTime) return '';
  return moment(dateTime).tz('UTC').format('DD-MM-YYYY HH:mm:ss z');
};

export const formatISOZonedDateTimeToZonedDateTime = (dateTime: ?string, zone: string): string => {
  if (!dateTime) return '';
  return moment(dateTime).tz(zone).format('DD-MM-YYYY HH:mm:ss z');
};
