const accordionTheme = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        padding: '8px 0px',
        margin: '0px',
        ...(ownerState.expanded && {
          padding: '8px 0 0',
        }),
      }),
      content: {
        margin: '8px 0px',
        pointerEvents: 'none',
      },
      expandIconWrapper: {
        pointerEvents: 'none',
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0 56px 16px 0',
      },
    },
  },
};

export default accordionTheme;
