// @flow
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const LinkToPasswordResetPage = () => (
  <div className="row mt-3">
    <div className="col col-md-6 col-lg-4 d-grid mb-3">
      <Link className="btn btn-lg btn-primary text-uppercase" to="/password-reset">
        <FormattedMessage id="newPasswordPage.errorPage.linkToPasswordResetPage" />
      </Link>
    </div>
  </div>
);

export default LinkToPasswordResetPage;
