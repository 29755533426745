import messagesEn from 'config/i18n/messages_en';
import messagesDe from 'config/i18n/messages_de';
import flatten from 'flat';

const flatMessagesDe = flatten(messagesDe);
const flatMessagesEn = flatten(messagesEn);

const messages = {
  de: flatMessagesDe,
  'de-DE': flatMessagesDe,
  en: flatMessagesEn,
  'en-US': flatMessagesEn,
  'en-UK': flatMessagesEn,
};

export default messages;
