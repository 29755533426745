import React, { useMemo, useState } from 'react';
import { EdsDialog } from '@egym/react-design-system/EdsDialog';
import { EdsIconButton } from '@egym/react-design-system/EdsIconButton';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { FormattedMessage } from 'react-intl';
import 'styles/components/company-settings-page.scss';
import Stack from '@mui/material/Stack';
import { Step, StepConnector } from './Stepper';
import PersonalDetailsStep from './PersonalDetailsStep';
import AssignRoleStep from './AssignRoleStep';
import Form from '../common/components/Form';
import { validateName, validateRequiredWithLength } from 'validation/common/commonValidators';
import { validateEmail } from 'validation/common/emailValidator';
import { CompanyType, ManagerType } from 'service/hooks/company';
import { VALIDATION_INVALID_NAME_OF_USER } from 'validation/common/errorCodes';
import SummaryStep from './SummaryStep';

export type AddCompanyUserDialogProps = {
  opened: boolean,
  isLoading: boolean,
  companies: CompanyType[],
  managers: ManagerType[],
  onSubmit: () => void,
  onClose: () => void,
};

const initialValues = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  companies: [],
  managers: [],
  roles: {},
};

const validateStep1 = () => {
  return values => {
    const errors = {};
    errors.lastName =
      validateRequiredWithLength(1, 50)(values.lastName) ||
      validateName(values.lastName, VALIDATION_INVALID_NAME_OF_USER);
    errors.firstName =
      validateRequiredWithLength(1, 50)(values.firstName) ||
      validateName(values.firstName, VALIDATION_INVALID_NAME_OF_USER);
    errors.email = validateEmail(values.email);

    return errors;
  };
};

const isNextDisabled = (activeStep, isValid, roles) => {
  if (activeStep === 1) {
    return !isValid;
  }
  if (activeStep === 2) {
    return Object.keys(roles).length < 1;
  }
  return false;
};

const dataTestId = 'add_company_user_dialog';
const AddCompanyUserDialog = ({
  opened,
  onSubmit,
  isLoading,
  onClose,
  companies,
  managers,
}: AddCompanyUserDialogProps) => {
  const [activeStep, setActiveStep] = useState(1);

  const handleSubmit = async (values: any) => {
    const isLastStep = activeStep === 3;
    if (isLastStep) {
      return onSubmit(values);
    }
    setActiveStep(previousActiveStep => previousActiveStep + 1);
    return false;
  };
  const headline = useMemo(() => {
    return (
      <Stack
        data-testid={`${dataTestId}_headline`}
        slot="headline"
        className="headline"
        direction="row"
        alignItems="baseline"
      >
        <Stack flex="1">
          <FormattedMessage id="companySettings.add.dialogTitle" />
        </Stack>
        <EdsIconButton onClick={onClose}>
          <EdsIcon>close</EdsIcon>
        </EdsIconButton>
      </Stack>
    );
  }, [onClose]);

  return (
    <Form onSubmit={handleSubmit} validator={validateStep1()} defaultValues={initialValues}>
      {({ formState: { isValid, errors }, getValues }) => {
        const { roles } = getValues();
        return (
          <EdsDialog data-testid={dataTestId} opened={opened} onClose={onClose}>
            {headline}
            <Stack slot="content" className="dialog-content" direction="row" flex={1}>
              <Stack direction="column" alignItems="flex-start" className="steps-container">
                <Step
                  id={`${dataTestId}_step_1`}
                  isSelected={activeStep === 1}
                  isCompleted={activeStep > 1}
                  label={<FormattedMessage id="companySettings.add.step1" />}
                  onClick={() => {}}
                  content={1}
                  completedContent={<EdsIcon>check</EdsIcon>}
                />
                <StepConnector isCompleted={activeStep > 1} />
                <Step
                  id={`${dataTestId}_step_2`}
                  isSelected={activeStep === 2}
                  isCompleted={activeStep > 2}
                  label={<FormattedMessage id="companySettings.add.step2" />}
                  onClick={() => {}}
                  content={2}
                  completedContent={<EdsIcon>check</EdsIcon>}
                />
                <StepConnector isCompleted={activeStep > 2} />
                <Step
                  id={`${dataTestId}_step_3`}
                  isSelected={activeStep === 3}
                  isCompleted={activeStep > 3}
                  label={<FormattedMessage id="companySettings.add.step3" />}
                  onClick={() => {}}
                  content="3"
                />
              </Stack>
              {activeStep === 1 && <PersonalDetailsStep managers={managers} />}
              {activeStep === 2 && <AssignRoleStep companies={companies} />}
              {activeStep === 3 && <SummaryStep companies={companies} />}
            </Stack>
            <div slot="actions" className="actions">
              {activeStep > 1 && (
                <button
                  type="button"
                  className="btn btn-outline-primary px-3"
                  id="companySettings.add.previous"
                  onClick={() => {
                    setActiveStep(previousActiveStep => previousActiveStep - 1);
                  }}
                >
                  <FormattedMessage id="general.button.previous" />
                </button>
              )}

              <button
                type="submit"
                className="btn btn-primary"
                id="companySettings.add.nextOrSubmit"
                disabled={
                  isNextDisabled(activeStep, isValid, roles) ||
                  isLoading ||
                  !!errors?.alertError?.code
                }
              >
                {activeStep < 3 ? (
                  <FormattedMessage id="general.button.continue" />
                ) : (
                  <FormattedMessage id="general.button.submit" />
                )}
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-3"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </EdsDialog>
        );
      }}
    </Form>
  );
};

export default AddCompanyUserDialog;
