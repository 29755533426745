// @flow
import React from 'react';
import { withPageTemplate } from '../layout/PageTemplate';
import ErrorPage from './ErrorPage';

type Props = {
  children: any,
};

type State = {
  hasError: boolean,
};

export default class DefaultErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch(error, info) {
    console.log(`error: ${error}`);
    console.log(`stack trace:`);
    console.log(info);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const ErrorPageWithPageTemplate = withPageTemplate(ErrorPage, {
        headerTitle: 'Wellpass',
      });
      return <ErrorPageWithPageTemplate />;
    } else {
      return children;
    }
  }
}
