import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const RegisterAndLoginToggle = props => {
  const { id, command, messageId } = props;
  return (
    <div className="row mb-4">
      <div className="col col-lg-4 mt-3 d-grid">
        <button id={id} type="button" className="btn btn-lg btn-outline-primary" onClick={command}>
          <FormattedMessage id={messageId} />
        </button>
      </div>
    </div>
  );
};

RegisterAndLoginToggle.propTypes = {
  id: PropTypes.string,
  command: PropTypes.func,
  messageId: PropTypes.string,
};

export default RegisterAndLoginToggle;
