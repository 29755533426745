import * as React from 'react';
import { useIntl } from 'react-intl';
import { COUNTRY_CODES_LIST } from 'config/i18n/countryCodes';

type Props = {
  value: string,
  onChange: Function,
};

const CountryDropdown = ({ value, onChange }: Props) => {
  const { formatMessage } = useIntl();

  const onSelectChange = event => {
    onChange(event.target.value);
  };

  const options = COUNTRY_CODES_LIST.map(code => {
    return {
      key: code,
      label: formatMessage({ id: `countries.${code}` }),
    };
  });

  return (
    <div className="form-floating">
      <select
        id="country-selection-dropdown"
        value={value}
        onChange={onSelectChange}
        className="form-select form-select-padding-left"
        aria-describedby="HelpTextAfter"
      >
        {options.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
      <label htmlFor="country-selection-dropdown">
        {formatMessage({ id: 'countrySelection.label' })}
      </label>
    </div>
  );
};

export default CountryDropdown;
