import React from 'react';
import { ReactComponent as InfoIconSvg } from 'styles/img/icons/Info_outline.svg';
import EgymIdModal from 'ui/common/EgymIdModal';
import { FormattedMessage } from 'react-intl';

const EgymIdBanner = ({ textId }: props) => {
  const [egymIdModalOpen, setEgymIdModalOpen] = React.useState(false);
  return (
    <>
      <div className="egym-id-banner">
        <FormattedMessage
          id={textId}
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />{' '}
        <InfoIconSvg
          onClick={() => setEgymIdModalOpen(true)}
          className="text-primary fill-color"
          role="button"
        />
      </div>

      {egymIdModalOpen && (
        <EgymIdModal isOpen={true} onRequestClose={() => setEgymIdModalOpen(false)} />
      )}
    </>
  );
};

export default EgymIdBanner;
