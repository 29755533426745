import { get, httpPost } from 'service/http/http-client';

export class UserMembershipsAndOffersApi {
  fetchUserActiveMemberships = () => {
    return get(`/v1/user/current/membership-and-offer`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET active membership returned ' + response.status);
      }
    });
  };

  cancelMembership = (membershipId, cancellationReason, cancellationEndDate) => {
    return httpPost(`/v1/user/membership/${membershipId}/cancel`, {
      cancellationReason: cancellationReason,
      endDate: cancellationEndDate,
    }).then(response => {
      return response;
    });
  };

  fetchCorporateEmployee = (userId, integrationScopeId, companyId) => {
    return get(
      `/v1/corporate-employee?egymAccountId=${userId}&integrationScopeId=${integrationScopeId}&companyId=${companyId}`
    ).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET corporate employee returned ' + response.status);
      }
    });
  };
}
