// @flex
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import InfoBox from 'ui/welcome/components/InfoBox';
import activeIcon from 'styles/img/welcome/icons/active.svg';
import enjoyVarietyIcon from 'styles/img/welcome/icons/enjoy-variety.svg';
import exclusiveOfferIcon from 'styles/img/welcome/icons/exclusive-offer.svg';
import stayFlexibleIcon from 'styles/img/welcome/icons/stay-flexible.svg';
import trainDigitalIcon from 'styles/img/welcome/icons/train-digital.svg';
import { formatCurrency } from 'service/currencyUtil';

import type { IntlShape } from 'react-intl';

type Props = {
  price: ?number,
  currency: ?string,
  gymsCount: ?number,
  name: ?string,
  isPartnerSignup?: boolean,
  intl: IntlShape,
};

const QualitrainInfoSection = ({
  price,
  currency,
  gymsCount,
  name = '',
  isPartnerSignup,
  intl: { locale },
}: Props) => (
  <div className="row justify-content-center mt-5 mb-5">
    <InfoBox
      imageUrl={activeIcon}
      title={<FormattedMessage id="welcome.wellpassInfo.beActive.title" />}
      description={<FormattedMessage id="welcome.wellpassInfo.beActive.description" />}
    />
    <InfoBox
      imageUrl={enjoyVarietyIcon}
      title={<FormattedMessage id="welcome.wellpassInfo.enjoyVariety.title" />}
      description={
        <FormattedMessage
          id="welcome.wellpassInfo.enjoyVariety.description"
          values={{ number: gymsCount ? gymsCount : '>3000' }}
        />
      }
    />
    {!!price && (
      <InfoBox
        imageUrl={exclusiveOfferIcon}
        title={<FormattedMessage id="welcome.wellpassInfo.exclusiveOffer.title" />}
        description={
          <FormattedMessage
            id={`welcome.wellpassInfo.exclusiveOffer.${
              isPartnerSignup ? 'partnerDescription' : 'description'
            }`}
            values={{ price: formatCurrency(locale, price, currency), name: name }}
          />
        }
      />
    )}
    <InfoBox
      imageUrl={trainDigitalIcon}
      title=<FormattedMessage id="welcome.wellpassInfo.workoutEverywhere.title" />
      description=<FormattedMessage id="welcome.wellpassInfo.workoutEverywhere.description" />
    />
    <InfoBox
      imageUrl={stayFlexibleIcon}
      title=<FormattedMessage id="welcome.wellpassInfo.flexibility.title" />
      description=<FormattedMessage id="welcome.wellpassInfo.flexibility.description" />
    />
  </div>
);

export default injectIntl(QualitrainInfoSection);
