import React, { MouseEventHandler } from 'react';
import { ReactComponent as CrossIconSvg } from 'styles/img/icons/cross.svg';

type Props = {
  onClick: MouseEventHandler<T>,
  className?: string,
  buttonId: string,
  variant?: string,
};

const CloseButton = ({ onClick, className, buttonId, variant = 'dark-icon' }: Props) => {
  return (
    <button
      id={buttonId}
      type="button"
      className={`btn btn-link bg-transparent p-0 ${className}`}
      aria-label="Close"
      onClick={onClick}
    >
      <CrossIconSvg className={variant} style={{ fill: 'currentColor', pointerEvents: 'none' }} />
    </button>
  );
};

export default CloseButton;
