import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { withSelfSignupStepTemplate } from 'ui/self-signup/SelfSignupStepTemplate';
import PersonalInformation from './PersonalInformation';
import ExistingUserSection from './ExistingUserSection';
import RegisterAndLoginToggle from './RegisterAndLoginToggle';
import RegistrationContext from './RegistrationContext';

import type { MembershipOffer, SignupPage } from 'ui/self-signup/types';

type Props = {
  signupPage: SignupPage,
  isPartnerSignup?: boolean,
  signupPageOffer: MembershipOffer,
};

const Step1 = ({ signupPageOffer, signupPage, isPartnerSignup }: Props) => {
  const { isNewUser, setIsNewUser, setEmployeeEligibilityStatus } = useContext(RegistrationContext);
  const personalInformationForm = useRef(null);
  const existingUserLoginForm = useRef(null);

  const slideDown = element => (element.style.height = `${element.scrollHeight}px`);
  const slideDownAuto = element => (element.style.height = `auto`);
  const slideUp = element => (element.style.height = 0);

  const toggleIsNewUser = () => {
    setIsNewUser(!isNewUser);
  };

  const toggleAndCloseModal = () => {
    toggleIsNewUser();
    setEmployeeEligibilityStatus({ matchButAlreadyRegistered: false, maskedEmail: '' });
  };

  const selectDisplay = useCallback(isNew => {
    if (isNew) {
      personalInformationForm.current && slideDown(personalInformationForm.current);
      existingUserLoginForm.current && slideUp(existingUserLoginForm.current);
      setTimeout(() => {
        personalInformationForm.current && slideDownAuto(personalInformationForm.current);
      }, 600);
    } else {
      personalInformationForm.current && slideUp(personalInformationForm.current);
      existingUserLoginForm.current && slideDown(existingUserLoginForm.current);
      setTimeout(() => {
        existingUserLoginForm.current && slideDownAuto(existingUserLoginForm.current);
      }, 600);
    }
  }, []);

  useEffect(() => {
    const currentState = isNewUser;
    setIsNewUser(currentState);
    selectDisplay(currentState);
  }, [setIsNewUser, isNewUser, selectDisplay]);

  return (
    <>
      <ExistingUserSection toggleIsNewUser={toggleIsNewUser} innerRef={existingUserLoginForm} />
      <hr />

      <PersonalInformation
        signupPageOffer={signupPageOffer}
        signupPage={signupPage}
        isPartnerSignup={isPartnerSignup}
        innerRef={personalInformationForm}
        toggleAndCloseModal={toggleAndCloseModal}
      />

      {!isNewUser && (
        <RegisterAndLoginToggle
          id="signupPage.step1.toggle.register"
          command={toggleIsNewUser}
          messageId={'general.button.renderRegister'}
        />
      )}
    </>
  );
};
export default withSelfSignupStepTemplate(Step1);
