import React from 'react';
import { FormattedMessage } from 'react-intl';

const ErrorPage = () => (
  <React.Fragment>
    <h2 id="errorPage.title" className="text-center mb-5 mt-5 text-dark">
      <FormattedMessage id={`errorPage.title`} />
    </h2>
    {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && (
      <div className="alert alert-info text-center mt-4" style={{ whiteSpace: 'pre-line' }}>
        <strong>For developers only</strong>
        <p>
          You might be seeing this error because there is no companyToken parameter in the URL.{' '}
          <br />
          Just click the link below: <br />
          <a href="/?companyToken=Muster-XPAY">http://localhost:3000?companyToken=Muster-XPAY</a>
        </p>
      </div>
    )}
    <div className="container-fluid">
      <h3 className="text-center mb-5 mt-5" style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={`errorPage.invalidLinkTitle`} />
      </h3>
      <div
        className="text-center mb-5 mt-5 row justify-content-md-center"
        style={{ whiteSpace: 'pre-line' }}
      >
        <div className="col col-lg-8">
          <FormattedMessage id={`errorPage.invalidLinkTip`} />
        </div>
      </div>
      <h3 className="text-center mb-5 mt-5" style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={`errorPage.alreadyMemberTitle`} />
      </h3>
      <div
        className="text-center mb-5 mt-5 row justify-content-md-center"
        style={{ whiteSpace: 'pre-line' }}
      >
        <div className="col col-lg-8">
          <FormattedMessage id={`errorPage.alreadyMemberTip`} />
          <a href="https://app.egym-wellpass.com/login">
            <FormattedMessage id={`errorPage.alreadyMemberLink`} />
          </a>
        </div>
      </div>
      <h3 className="text-center mb-5 mt-5" style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={`errorPage.callToActionTitle`} />
      </h3>
      <div
        className="text-center mb-5 mt-5 row justify-content-md-center"
        style={{ whiteSpace: 'pre-line' }}
      >
        <div className="col col-lg-8">
          <FormattedMessage id={`errorPage.callToActionTip`} />
          <a href="https://egym-wellpass.com/">
            <FormattedMessage id={`errorPage.callToActionLink`} />
          </a>
        </div>
      </div>
      <h3 className="text-center mb-5 mt-5" style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={`errorPage.helpTitle`} />
      </h3>
      <div
        className="text-center mb-5 mt-5 row justify-content-md-center"
        style={{ whiteSpace: 'pre-line' }}
      >
        <div className="col col-lg-8">
          <FormattedMessage id={`errorPage.helpTip`} />
          <a href="https://egym-wellpass.com/faq/">
            <FormattedMessage id={`errorPage.helpLink`} />
          </a>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default ErrorPage;
