import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import type { IntlShape } from 'react-intl';

type Props = {
  submitError: {
    code: string,
    args: any,
    defaultMessage: string,
  },
  intl: IntlShape,
  gotourl: string,
};

const SelfSignupSubmitErrors = ({ submitError, intl: { formatMessage }, gotourl }: Props) => {
  const isArray = submitError.constructor === Array;
  if (isArray) {
    return (
      <ul className="list-group pb-2">
        {/* eslint-disable-next-line react/prop-types */}
        {submitError.map(error => {
          const values = { ...error.args };
          if (error.field) {
            values.fieldLabel = formatMessage({ id: `signupPage.field.${error.field}.label` });
          }
          const key = error.field ? `${error.code}-${error.field}` : error.code;
          return (
            <li id={key} key={key} className="list-group-item list-group-item-danger">
              <FormattedMessage
                id={error.code}
                values={{
                  ...values,
                  loginstep: chunks => {
                    return <a href={gotourl}>{chunks}</a>;
                  },
                }}
                defaultMessage={error.defaultMessage}
              />
            </li>
          );
        })}
      </ul>
    );
  } else {
    // fixme bobi 2018-11-19: [QT-236] This would happen if an unhandled exception is thrown on the server.
    return <div className="alert alert-danger">{submitError}</div>;
  }
};

export default injectIntl(SelfSignupSubmitErrors);
