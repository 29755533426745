import * as React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import InvalidFieldErrorMessage from 'ui/self-signup/components/InvalidFieldErrorMessage';

const DropDownFormField = ({
  controlId,
  controlLabel,
  helpTextAfter,
  options,
  required = true,
  intl: { formatMessage },
  control,
  showEmptyValue = true,
  disabled = false,
}) => {
  const {
    field,
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController({
    name: controlId,
    control,
  });
  const shouldDisplayError = (isSubmitted && error) || (error && isTouched);

  return (
    <div className={`${field.value ? 'form-floating' : ''} row mb-2`}>
      <select
        id={controlId}
        className={
          'form-select form-select-padding-left' + (shouldDisplayError ? ' is-invalid' : '')
        }
        aria-describedby={controlId + 'HelpTextAfter'}
        disabled={!options.length > 0 || disabled}
        {...control.register(controlId)}
      >
        {showEmptyValue && (
          <option value="" key={controlLabel}>
            {formatMessage({ id: controlLabel })}
          </option>
        )}
        {options.map(({ value, key, label, hidden }) => (
          <option key={key} value={value} hidden={hidden} id={`${controlId}.${value}`}>
            {label}
          </option>
        ))}
      </select>
      {field.value && (
        <label htmlFor={controlId}>
          <FormattedMessage id={controlLabel} />
          {required ? ' *' : ''}
        </label>
      )}
      {helpTextAfter && (
        <small id={controlId + 'HelpTextAfter'} className="form-text text-muted">
          {helpTextAfter}
        </small>
      )}
      {shouldDisplayError && (
        <InvalidFieldErrorMessage
          meta={{ error }}
          translatedControlLabel={formatMessage({ id: controlLabel })}
        />
      )}
    </div>
  );
};

DropDownFormField.propTypes = {
  intl: PropTypes.object,
  controlId: PropTypes.string,
  controlLabel: PropTypes.string,
  helpTextAfter: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.any,
  options: PropTypes.array,
  control: PropTypes.any,
  showEmptyValue: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default injectIntl(DropDownFormField);
