import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  selectedFiles: File[],
  setSelectedFiles: Function,
  setError: Function,
};

const FilePicker = ({ selectedFiles, setSelectedFiles, setError }: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const filePickerInputElement = useRef(null);
  const MAX_FILE_SIZE_MB = 15;

  useEffect(() => {
    if (selectedFiles.length < 2) {
      setIsDisabled(false);
    }
  }, [selectedFiles, setIsDisabled]);

  const handleFileChange = event => {
    const files = event.target.files;
    let isValidSize = true;

    const newFiles = Array.from(files).slice(0, 2);
    newFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        isValidSize = false;
        setError('declarationOfConsentModal.uploadSizeExceedError');
      }
    });
    if (!isValidSize) {
      return;
    }
    const updatedFiles = [...selectedFiles, ...newFiles];
    setSelectedFiles(updatedFiles);
    if (updatedFiles.length >= 2) {
      setIsDisabled(true);
    }
    setError(undefined);
    filePickerInputElement.current.value = null;
  };

  return (
    <div>
      <label
        className={`btn btn-primary px-4 ${isDisabled ? 'disabled' : ''}`}
        style={{ minWidth: '150px' }}
      >
        <FormattedMessage id="declarationOfConsentModal.button.uploadFile" />
        <input
          ref={filePickerInputElement}
          type="file"
          onChange={handleFileChange}
          accept=".jpg, .jpeg, .png, .heic, .pdf"
          className={'d-none'}
        />
      </label>
    </div>
  );
};

export default FilePicker;
