import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { put } from 'service/http/http-client';

const VerificationTanSection = () => {
  const { formatMessage } = useIntl();
  const [verificationTan, setVerificationTan] = React.useState({});
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const formatTan = val => {
    return val
      .replace(/[^\dA-Z0-9]/g, '')
      .replace(/(.{3})/g, '$1 ')
      .trim();
  };

  const retrieveVerificationTan = async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await put('/v1/user/current/verification-tan');
      if (response.ok) {
        const tan = await response.json();
        setVerificationTan({
          value: formatTan(tan.verificationTan),
          expirationTime: tan.expirationTimestamp,
        });
        setLoading(false);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <h4 className="fw-bold mb-3">
        <FormattedMessage id="accountSettingsPage.verification.title" />
      </h4>

      <p className="mb-3">
        <FormattedMessage id="accountSettingsPage.verification.description" />
      </p>

      {!verificationTan.value && (
        <button
          id="accountSettingsPage.btn.retrieveVerificationTan"
          className="btn btn-outline-primary d-flex align-items-center px-3 mb-3"
          type="button"
          onClick={retrieveVerificationTan}
        >
          <FormattedMessage id="accountSettingsPage.verification.btn" />
          {loading && (
            <span
              className="spinner-border spinner-border-sm ms-3"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      )}

      {verificationTan.value && (
        <>
          <div className="col col-sm-6 col-md-3">
            <input
              type="text"
              id="verification-tan"
              value={verificationTan.value}
              className="form-control bg-white px-4"
              readOnly
            />
          </div>
          <p className="text-muted mb-3">
            <FormattedMessage
              id="accountSettingsPage.verification.expirationText"
              values={{
                expirationTime: moment(verificationTan.expirationTime).format(
                  formatMessage({
                    id: 'accountSettingsPage.verification.expirationTimeFormat',
                  })
                ),
              }}
            />
          </p>
        </>
      )}

      <p className="text-muted small pb-3">
        <FormattedMessage
          id="accountSettingsPage.verification.legalText"
          values={{
            link: (
              <a
                href={formatMessage({ id: 'accountSettingsPage.privacyPolicyLink.url' })}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted text-underlined"
              >
                <FormattedMessage id="accountSettingsPage.privacyPolicyLink.text" />
              </a>
            ),
          }}
        />
      </p>

      {error && (
        <div className="alert alert-danger pb-3">
          <FormattedMessage id="accountSettingsPage.verification.error" />
        </div>
      )}
    </>
  );
};

export default VerificationTanSection;
