import '@egym/wc-design-system/style.css';
import 'styles/custom.scss';
import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import DefaultErrorBoundary from 'ui/error/DefaultErrorBoundary';
import WithI18nSupport from 'config/i18n/WithI18nSupport';
import SelfSignupPage from 'ui/self-signup/SelfSignupPage';
import PartnerSelfSignupPage from 'ui/self-signup/PartnerSelfSignupPage';
import MultiEntitySignupPage from 'ui/multi-entity-signup/MultiEntitySignupPage';
import DoubleOptInSuccessPage from 'ui/double-opt-in/DoubleOptInSuccessPage';
import DoubleOptInFailurePage from 'ui/double-opt-in/DoubleOptInFailurePage';
import { SpinnerProvider } from 'ui/common/spinner/SpinnerContext';
import Spinner from 'ui/common/spinner/Spinner';
import Login from 'ui/login/Login';
import LoginWelcome from 'ui/login/welcome/LoginWelcome';
import { AuthenticationProvider } from 'ui/common/authentication/AuthenticationContext';
import { CompanyUserProvider } from 'ui/common/authentication/CompanyUserContext';
import ProtectedRoute from 'ui/common/authentication/ProtectedRoute';
import NewPasswordPage from './ui/password-reset/NewPasswordPage';
import PasswordResetPage from './ui/password-reset/PasswordResetPage';
import LoginNoEmailVerification from 'ui/login/LoginNoEmailVerification';
import CompanyMembershipsPage from 'ui/admin/companies/memberships/CompanyMembershipsPage';
import PaymentDetailsPage from 'ui/payment/PaymentDetailsPage';
import AccountSettingsPage from 'ui/account/AccountSettingsPage';
import {
  COMPANY_ADMIN,
  COMPANY_EDITOR,
  QUALITRAIN_OPS_ADMIN,
} from 'ui/common/authentication/authenticationConstants';
import NotAuthorized403Page from 'ui/login/NotAuthorized403Page';
import NewPasswordSuccessPage from 'ui/password-reset/NewPasswordSuccessPage';

import browserUpdate from 'browser-update';
import { browserConfig } from 'config/browser-update/browser-config';

import { RegistrationProvider } from 'ui/self-signup/RegistrationContext';
import PropTypes from 'prop-types';
import MyMembershipsPage from './ui/membership/MyMembershipsPage';
import { WelcomeProvider } from './ui/welcome/WelcomeContext';
import { ThemeProvider } from '@mui/material';
import muiTheme from './theme/theme';
import SignupPageRedirect from './ui/self-signup/SelfSignupPageRedirect';
import CompanySettingsPage from 'ui/company-settings/CompanySettingsPage';
import { PageTemplate } from 'ui/layout/PageTemplateWithNavigationDrawer';

browserUpdate(browserConfig);

const App = () => (
  <ThemeProvider theme={muiTheme}>
    <WithI18nSupport>
      <DefaultErrorBoundary>
        <AuthenticationProvider>
          <CompanyUserProvider>
            <SpinnerProvider>
              <RegistrationProvider>
                <WelcomeProvider>
                  <div className="theme-wellpass">
                    <Router>
                      <Routes>
                        <Route
                          path="/signup/referral/:token?/*"
                          element={<PartnerSelfSignupPage />}
                        />
                        <Route path="/signup/:slug/*" element={<MultiEntitySignupPage />} />
                        <Route path="/signup" element={<SelfSignupPage />} />
                        <Route path="/password-reset" element={<PasswordResetPage />} />
                        <Route path="/new-password/*" element={<NewPasswordPage />} />
                        <Route
                          path="/new-password/success/*"
                          element={<NewPasswordSuccessPage />}
                        />
                        <Route path="/double-opt-in-success" element={<DoubleOptInSuccessPage />} />
                        <Route path="/double-opt-in-failure" element={<DoubleOptInFailurePage />} />
                        <Route path="/login/*" element={<Login />} />
                        <Route
                          path="/login/success/*"
                          element={<ProtectedRoute component={LoginWelcome} />}
                        />
                        <Route
                          path="/login/not-authorized/*"
                          element={<ProtectedRoute component={NotAuthorized403Page} />}
                        />
                        <Route
                          path="/login/no-email-verification/*"
                          element={<ProtectedRoute component={LoginNoEmailVerification} />}
                        />
                        <Route
                          path="/nav/*"
                          element={
                            <PageTemplate>
                              <Routes>
                                <Route
                                  path="admin/companies"
                                  element={
                                    <ProtectedRoute
                                      allowedRoles={[
                                        QUALITRAIN_OPS_ADMIN,
                                        COMPANY_ADMIN,
                                        COMPANY_EDITOR,
                                      ]}
                                      component={CompanyMembershipsPage}
                                    />
                                  }
                                />
                                <Route
                                  path="account-settings"
                                  element={<ProtectedRoute component={AccountSettingsPage} />}
                                />
                                <Route
                                  path="payment-details"
                                  element={<ProtectedRoute component={PaymentDetailsPage} />}
                                />
                                <Route
                                  path="/admin/company-settings"
                                  element={
                                    <ProtectedRoute
                                      allowedRoles={[COMPANY_ADMIN, COMPANY_EDITOR]}
                                      component={CompanySettingsPage}
                                    />
                                  }
                                />
                              </Routes>
                            </PageTemplate>
                          }
                        />
                        <Route
                          path="/my-memberships/*"
                          element={<ProtectedRoute component={MyMembershipsPage} />}
                        />
                        <Route
                          path="/admin/companies/*"
                          element={<Navigate to="/nav/admin/companies" />}
                        />
                        <Route
                          path="/payment-details/*"
                          element={<Navigate to="/nav/payment-details" />}
                        />
                        <Route
                          path="/account-settings/*"
                          element={<Navigate to="/nav/account-settings" />}
                        />
                        <Route path="/" element={<SignupPageRedirect />} />
                      </Routes>
                    </Router>
                    <Spinner />
                  </div>
                </WelcomeProvider>
              </RegistrationProvider>
            </SpinnerProvider>
          </CompanyUserProvider>
        </AuthenticationProvider>
      </DefaultErrorBoundary>
    </WithI18nSupport>
  </ThemeProvider>
);

App.propTypes = {
  location: PropTypes.object,
};

export default App;
