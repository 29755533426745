import { VALIDATION_REPEAT_PASSWORD_NOT_MATCH } from 'validation/common/errorCodes';
import {
  validateAddress,
  validateBirthday,
  validateName,
  validateRequired,
  validateRequiredWithLength,
  validateWithRegex,
} from 'validation/common/commonValidators';
import { validateEmail } from 'validation/common/emailValidator';
import { validateIban } from 'validation/common/ibanValidator';

export const validateStep1 = (signupPage, verifyAddress, resolvedAddress) => {
  return values => {
    const errors = {};
    errors.gender = validateRequired(values.gender);
    errors.lastName =
      validateRequiredWithLength(1, 50)(values.lastName) || validateName(values.lastName);
    errors.firstName =
      validateRequiredWithLength(1, 50)(values.firstName) || validateName(values.firstName);
    errors.birthday = validateBirthday(values.birthday);
    errors.email = validateEmail(values.email);
    errors.password = validateRequiredWithLength(8, 72)(values.password);
    errors.repeatPassword = validateRequiredWithLength(8, 72)(values.repeatPassword);
    errors.searchAddressValidation = verifyAddress && validateAddress(resolvedAddress);
    if (values.password && values.repeatPassword) {
      if (values.password !== values.repeatPassword) {
        errors.repeatPassword = { code: VALIDATION_REPEAT_PASSWORD_NOT_MATCH };
      }
    }

    return errors;
  };
};

export const validateStep3 = (signupPageOffer, existingPaymentDetails) => {
  return values => {
    const errors = {};

    errors.membershipStartDate = validateRequired(values.membershipStartDate);
    if (signupPageOffer && signupPageOffer.b2cPayment) {
      errors.bankAccountHolderName = validateRequiredWithLength(
        1,
        100
      )(values.bankAccountHolderName);
      errors.iban = isPaymentDetailsModified(values, existingPaymentDetails)
        ? validateIban(values.iban)
        : undefined;
      errors.consent = validateRequired(values.consent);
    }
    if (signupPageOffer && signupPageOffer.employeeInternalIdentifierLabel) {
      errors.employeeIdentifier = validateWithRegex(
        signupPageOffer.employeeInternalIdentifierValidationRegex
      )(values.employeeIdentifier);
    }
    return errors;
  };
};

export const validateStep2 = (signupPage, verifyAddress, resolvedAddress) => {
  return values => {
    const errors = {};
    errors.reviewedGender = validateRequired(values.reviewedGender);
    errors.reviewedLastName =
      validateRequiredWithLength(1, 50)(values.reviewedLastName) ||
      validateName(values.reviewedLastName);
    errors.reviewedFirstName =
      validateRequiredWithLength(1, 50)(values.reviewedFirstName) ||
      validateName(values.reviewedFirstName);
    errors.reviewedBirthday = validateBirthday(values.reviewedBirthday);
    errors.searchAddressValidation = verifyAddress && validateAddress(resolvedAddress);
    return errors;
  };
};

export const isPaymentDetailsModified = (values, existingPaymentDetails) => {
  if (!existingPaymentDetails) {
    return true;
  }
  return (
    values.bankAccountHolderName !== existingPaymentDetails.ownerName ||
    (existingPaymentDetails.ibanNumber &&
      values.iban !== `****${existingPaymentDetails.ibanNumber.slice(4)}`)
  );
};
