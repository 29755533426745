// @flow
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from './Modal';
import FilePicker from '../components/FilePicker';
import RegistrationContext from '../../self-signup/RegistrationContext';
import { bytesToKilobytes, truncateName } from '../../../service/commonUtil';
import { ReactComponent as DeleteIconSvg } from 'styles/img/icons/delete.svg';
import { DECLARATION_OF_CONSENT_ACTIONS } from '../../self-signup/reducers/useDeclarationOfConsent';
import { Alert } from '@mui/material';
import styles from './consentDeclarationModal.module.scss';

type Props = {
  isOpen: boolean,
  closable: boolean,
  onRequestClose: Function,
  modalTitle: ?string,
  modalClass: ?string,
  modalSizeClass: any,
  noFooter: boolean,
  proceedAction: Function,
};

const DeclarationOfConsentModal = ({
  isOpen = false,
  closable = true,
  onRequestClose,
  modalTitle,
  modalClass = 'declaration-of-consent-modal',
  modalSizeClass,
  noFooter = true,
}: Props) => {
  const { declarationOfConsentDetails, setDeclarationOfConsentDetails } =
    React.useContext(RegistrationContext);
  const [error, setError] = React.useState(null);

  const { formatMessage } = useIntl();
  const handleFileDelete = index => {
    const updatedFiles = [...declarationOfConsentDetails.files];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const setSelectedFiles = files => {
    setDeclarationOfConsentDetails({
      action: DECLARATION_OF_CONSENT_ACTIONS.ADD_FILES,
      payload: files,
    });
  };

  const handleCheckBoxChange = () => {
    setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.INVERT_CONFIRMATION });
  };

  return (
    <Modal
      modalTitle={modalTitle}
      modalClass={modalClass}
      modalSizeClass={modalSizeClass}
      isOpen={isOpen}
      closable={closable}
      onRequestClose={onRequestClose}
      noFooter={noFooter}
    >
      <div>
        <hr className=" my-0 border-top d-sm-block d-md-none black-10-color" />
        <div className={styles.bodyContainer}>
          <div className="mx-0 px-0  d-flex flex-column flex-md-row">
            <div className={styles.descriptionContainer}>
              <FormattedMessage
                id="declarationOfConsentModal.body"
                values={{
                  strong: chunks => <strong>{chunks}</strong>,
                  br: () => <br />,
                  ol: chunks => <ol className="olList">{chunks}</ol>,
                  li: chunks => <li className="pb-2 liList">{chunks}</li>,
                  documentUrl: (
                    <a
                      href={formatMessage({
                        id: 'declarationOfConsentModal.document.url',
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-muted text-underlined primary-text-important"
                    >
                      <FormattedMessage id="declarationOfConsentModal.document.text" />
                    </a>
                  ),
                }}
              />
            </div>
            <div className={`ms-0 ms-md-2 ${styles.uploadContainer}`}>
              <div className="p-4">
                <div className="text-center pb-2">
                  <FilePicker
                    selectedFiles={declarationOfConsentDetails.files}
                    setSelectedFiles={setSelectedFiles}
                    setError={setError}
                  />
                </div>
                <label className="form-check-label">
                  <p className={`mx-3 my-1 text-center ${styles.smallText}`}>
                    <FormattedMessage id="declarationOfConsentModal.uploadConstraints" />
                  </p>
                </label>
              </div>
            </div>
          </div>

          {error && (
            <div className="row row-cols-md-2 mx-0">
              <div className={`col-md-6 ps-0 pe-0 pe-md-2`}></div>
              <div className={`col-md-6 ps-md-2 pe-0`}>
                <Alert severity="error" className={'py-0'}>
                  <FormattedMessage id="declarationOfConsentModal.uploadSizeExceedError" />
                </Alert>
              </div>
            </div>
          )}

          {declarationOfConsentDetails?.files?.length > 0 && (
            <div>
              <div className="pt-2">
                <FormattedMessage id="declarationOfConsentModal.uploadedDocument" />
              </div>
              <div className="row row-cols-md-2 mx-0">
                {declarationOfConsentDetails?.files.map((file, index) => (
                  <div
                    className={`col-md-6 py-2 ${
                      index === 0 ? 'ps-0 pe-0 pe-md-2' : 'ps-0 ps-md-2 pe-0'
                    }`}
                    key={index}
                  >
                    <div className={`col-md-12 ${styles.uploadDocumentContainer}`} key={index}>
                      <div className="d-flex justify-content-between m-3">
                        <div>
                          <p className={`m-0 ${styles.headerText}`}>
                            {truncateName(file.name, 36)}
                          </p>
                          <p className={`m-0 ${styles.defaultText}`}>
                            {file.name.split('.').pop().toUpperCase()} file -{' '}
                            {bytesToKilobytes(file.size)} KB
                          </p>
                        </div>
                        <div>
                          <button
                            className={`border-0 bg-white ${styles.deleteBtn}`}
                            onClick={() => handleFileDelete(index)}
                          >
                            <DeleteIconSvg
                              className={'dark-icon'}
                              style={{ fill: 'currentColor', pointerEvents: 'none' }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <hr className=" my-2 border-top black-10-color" />
        <div className="px-4">
          <div className="py-2">
            <div className="form-check">
              <input
                checked={declarationOfConsentDetails.confirmed}
                onChange={handleCheckBoxChange}
                id={'checkbox'}
                type="checkbox"
                className={'form-check-input'}
              />
              <label className="form-check-label" htmlFor={'checkbox'}>
                <FormattedMessage
                  id="declarationOfConsentModal.declarationConfirmation"
                  values={{
                    p: chunks => <p className={styles.smallText}>{chunks}</p>,
                  }}
                />
              </label>
            </div>
          </div>
          <div className="py-4 d-md-flex justify-content-end">
            <div className="col-12 col-md-3">
              <button
                onClick={() => {
                  setDeclarationOfConsentDetails({
                    action: DECLARATION_OF_CONSENT_ACTIONS.SET_STATUS,
                    payload: 'success',
                  });
                  setDeclarationOfConsentDetails({
                    action: DECLARATION_OF_CONSENT_ACTIONS.CLOSE_MODAL,
                  });
                }}
                type="button"
                className="btn btn-primary w-100"
                disabled={
                  !declarationOfConsentDetails.confirmed ||
                  declarationOfConsentDetails?.files?.length === 0
                }
              >
                <FormattedMessage id="declarationOfConsentModal.button.continue" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeclarationOfConsentModal;
