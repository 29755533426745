import partnerGymBackgroundImage from '../../../styles/img/welcome/welcome-header-plus1.jpg';
import BackgroundSection from '../../common/components/BackgroundSection';
import IntroSection from './IntroSection';
import wellpassLogo from '../../../styles/img/wellpass-logo-white.svg';
import QualitrainInfoSection from './QualitrainInfoSection';
import StudiosSection from './StudiosSection';
import GymMapSection from './GymMapSection';
import MoodCollectionSection from './MoodCollectionSection';
import MembershipCardComponent from './membership-card/MembershipCardComponent';
import CountrySelection from '../../country-selection/CountrySelection';
import ReadyForMembershipSection from './ReadyForMembershipSection';
import * as React from 'react';
import { useContext } from 'react';
import WelcomeContext from '../WelcomeContext';
import { useHeaderBackgroundImage } from '../../common/hooks';
import FAQSection from './FAQSection';
import { useAnchorTag } from 'service/hooks/anchorTag';

const WelcomeMain = ({
  signupPage,
  signupPageOffer,
  gymsCount,
  isPartnerSignup,
  countryCode,
  onCountryCodeChangeEvent,
}: Props) => {
  const topBackgroundSectionImage = useHeaderBackgroundImage();

  useAnchorTag();
  const { gymFinderUrl } = useContext(WelcomeContext);

  return (
    <>
      <BackgroundSection
        className={'header-bg-color-height'}
        backgroundImageUrl={isPartnerSignup ? partnerGymBackgroundImage : topBackgroundSectionImage}
      >
        <IntroSection
          logo={(signupPage && signupPage.logo) || wellpassLogo}
          name={signupPage && signupPage.name}
          price={signupPageOffer && signupPageOffer.priceAmount}
          currency={signupPageOffer && signupPageOffer.priceCurrency}
          customDescription={signupPage && signupPage.customDescription}
          isPartnerSignup={isPartnerSignup}
        />
      </BackgroundSection>
      <BackgroundSection containerClass={'container-fluid'}>
        <QualitrainInfoSection
          price={signupPageOffer && signupPageOffer.priceAmount}
          currency={signupPageOffer && signupPageOffer.priceCurrency}
          gymsCount={gymsCount}
          name={signupPage && signupPage.name}
          isPartnerSignup={isPartnerSignup}
        />
      </BackgroundSection>
      <BackgroundSection containerClass="container-fluid" className="section-spacing">
        <StudiosSection />
      </BackgroundSection>
      <BackgroundSection containerClass={'container-fluid'}>
        {gymFinderUrl && <GymMapSection />}
      </BackgroundSection>
      {isPartnerSignup && (
        <>
          <MoodCollectionSection />
          <BackgroundSection className="section-spacing">
            <MembershipCardComponent
              membershipOffer={signupPageOffer}
              isPartnerSignup={isPartnerSignup}
            />
            <CountrySelection
              countryCode={countryCode}
              onCountryCodeChange={code => onCountryCodeChangeEvent(code)}
              styleOptions={{ wrapperClassName: 'text-center' }}
            />
          </BackgroundSection>
        </>
      )}
      <BackgroundSection className="section-spacing">
        <ReadyForMembershipSection />
      </BackgroundSection>
      {!isPartnerSignup && <FAQSection gymCount={gymsCount} isB2C={signupPageOffer.b2cPayment} />}
    </>
  );
};

export default WelcomeMain;
