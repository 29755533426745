// @flow
import * as React from 'react';
import classNames from 'classnames';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';

/**
 * Spinner Component that will be shown on top of the screen while an async action is executed.
 */
const Spinner = () => {
  const { isSpinnerShown } = React.useContext(SpinnerContext);

  let classname = classNames(
    'spinner-wrapper fixed-top w-100 h-100 justify-content-center align-items-center bg-white',
    { 'd-flex': isSpinnerShown },
    { 'd-none': !isSpinnerShown }
  );
  return (
    <div className={classname}>
      <div className="spinner" />
    </div>
  );
};

export default Spinner;
