import React from 'react';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import TextFormField from 'ui/common/components/TextFormField';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import Form from 'ui/common/components/Form';
import ErrorPage from 'ui/error/ErrorPage';
import { convertUserServiceResponse } from 'service/http/responseConverter';
import { extractRequestParameter } from 'service/http/requestUtil';
import { post } from 'service/http/http-client';
import { validateEmail } from 'validation/common/emailValidator';

const validatePasswordReset = values => {
  const errors = {
    email: validateEmail(values.email),
  };
  return errors;
};

const PasswordResetPage = () => {
  const { formatMessage } = useIntl();
  const [error, setError] = React.useState(0);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  React.useEffect(() => {
    const requestEmail = extractRequestParameter('email-address');
    const errorCode = validateEmail(requestEmail);

    if (requestEmail && typeof errorCode === 'undefined') {
      setInitialValues({ email: requestEmail });
    }
  }, []);

  const supportMailOnClick = event => {
    window.open('mailto:' + formatMessage({ id: 'general.wellpassSupportMail' }), 'Mail');
    event.preventDefault();
  };

  const onSubmit = values => {
    const postBody = { ...values };
    return executeWithSpinner(
      post('/v1/password-reset', postBody)
        .then(response => {
          if (response.ok) {
            setError(0);
            setShowSuccessDialog(true);
          } else if (response.status === 422) {
            return response.json().then(jsonResponse => convertUserServiceResponse(jsonResponse));
          } else {
            setError(response.status);
          }
        })
        .catch(errorMessage => {
          setError(true);
          return {
            [FORM_ERROR]: errorMessage,
          };
        })
    );
  };

  if (error > 0) {
    return <ErrorPage />;
  } else if (showSuccessDialog) {
    return (
      <div className="container" id="passwordResetPage.successBody">
        <h4 className="text-uppercase mb-2 mt-4">
          <FormattedMessage id="passwordResetPage.receivedEmailSuccessHeader" />
        </h4>
        <div className="text-start">
          <FormattedMessage id="passwordResetPage.receivedEmailSuccessBody" />
        </div>
        <h4 className="text-uppercase mb-2 mt-4">
          <FormattedMessage id="passwordResetPage.noEmailSuccessHeader" />
        </h4>
        <div className="text-start mb-3">
          <FormattedMessage
            id="passwordResetPage.noEmailSuccessBody"
            values={{
              egymPasswordReset: (
                <a href="https://www.egym.com/web/requestpassword" target="_blank" rel="noreferrer">
                  <FormattedMessage id="passwordResetPage.egymPasswordReset" />
                </a>
              ),
              wellpassSupportMail: (
                <a
                  onClick={supportMailOnClick}
                  href={'mailto:' + formatMessage({ id: 'general.wellpassSupportMail' })}
                >
                  <FormattedMessage id="general.wellpassSupportMail" />
                </a>
              ),
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <Form onSubmit={onSubmit} defaultValues={initialValues} validator={validatePasswordReset}>
        {({ register, control, formState }) => (
          <div className="container">
            <div className="text-start mt-4">
              <FormattedMessage id="passwordResetPage.body1" />
            </div>
            <div className="text-start mt-4 mb-3">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextFormField
                    controlId="email"
                    register={{
                      ...register('email'),
                    }}
                    control={control}
                    controlLabel="general.field.email.label"
                    placeholder="general.field.email.placeholder"
                    containerClassName="container p-0"
                  />
                </div>
                <div className="col-12 col-sm-6" />
              </div>
            </div>
            <div className="submit-btn">
              <button
                id="passwordResetPage.resetPasswordBtn"
                type="submit"
                className="btn btn-primary"
                disabled={formState.isSubmitting || !formState.isValid}
              >
                <FormattedMessage id="passwordResetPage.resetPasswordBtn" />
              </button>
            </div>
            <div className="text-start mt-4 mb-3">
              <FormattedMessage
                id="passwordResetPage.body2"
                values={{
                  a: chunks => (
                    <a
                      href="https://www.egym.com/web/requestpassword"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        )}
      </Form>
    );
  }
};

export default withPageTemplate(PasswordResetPage, {
  headerTitle: 'passwordResetPage.headerTitle',
});
