// @flow
import {
  VALIDATION_REQUIRED_FIELD,
  VALIDATION_INVALID_MIN_LENGTH,
  VALIDATION_INVALID_MAX_LENGTH,
  VALIDATION_INVALID_DATE,
  VALIDATION_INVALID_DATE_MUST_BE_IN_PAST,
  VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL,
  VALIDATION_UNDER_MINIMUM_SIGNUP_AGE,
  VALIDATION_INVALID_REGEX_MATCH,
  VALIDATION_INVALID_REGEX,
  VALIDATION_INVALID_NAME,
  VALIDATION_ADDRESS_INVALID,
} from 'validation/common/errorCodes';
import type { ValidationResult } from 'validation/types';
import moment from 'moment';

export const validateRequired = (value: string): ?ValidationResult => {
  if (!value) {
    return { code: VALIDATION_REQUIRED_FIELD, args: {} };
  }
  return undefined;
};

export const validateRequiredWithLength = (minLength: number, maxLength: number): Function => {
  return (value: string): ?ValidationResult => {
    if (!value) {
      return { code: VALIDATION_REQUIRED_FIELD, args: {} };
    }

    if (value.length < minLength) {
      return { code: VALIDATION_INVALID_MIN_LENGTH, args: { '0': minLength } };
    }

    if (value.length > maxLength) {
      return { code: VALIDATION_INVALID_MAX_LENGTH, args: { '0': maxLength } };
    }
    return undefined;
  };
};

export const validateWithRegex = (regex: string): Function => {
  return (value: string): ?ValidationResult => {
    if (!value) {
      return { code: VALIDATION_REQUIRED_FIELD, args: {} };
    }

    try {
      if (regex && new RegExp(regex).test(value) === false) {
        return { code: VALIDATION_INVALID_REGEX_MATCH, args: {} };
      }
    } catch (e) {
      return { code: VALIDATION_INVALID_REGEX, args: {} };
    }

    return undefined;
  };
};

export const validateBirthday = (value: string): ?ValidationResult => {
  if (!value) {
    return { code: VALIDATION_REQUIRED_FIELD };
  }
  if (!moment(value, 'DD.MM.YYYY', true).isValid()) {
    return { code: VALIDATION_INVALID_DATE };
  }
  if (!moment(value, 'DD.MM.YYYY', true).isAfter('1900-01-01')) {
    return { code: VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL };
  }
  if (moment().diff(moment(value, 'DD.MM.YYYY')) < 0) {
    return { code: VALIDATION_INVALID_DATE_MUST_BE_IN_PAST };
  }

  const isUserUnderMinimumSignupAge = checkIfDateIsUnderTheMinimumSignupAge(value);
  if (isUserUnderMinimumSignupAge) {
    return { code: VALIDATION_UNDER_MINIMUM_SIGNUP_AGE };
  }

  return undefined;
};

export const validateName = (
  value: string,
  errorCode = VALIDATION_INVALID_NAME
): ?ValidationResult => {
  if (!value) {
    return { code: VALIDATION_REQUIRED_FIELD };
  }

  value = value.replaceAll(/([ .])/g, '');

  if (new RegExp('^([^0-9@_]){2,}$').test(value) === false) {
    return { code: errorCode, args: {} };
  }

  return undefined;
};

/**
 * Validates whether the given date is under the minimum signup age (exclusive)
 *
 * @param date
 *    the users date of birth
 * @return boolean
 */
export const checkIfDateIsUnderTheMinimumSignupAge = (date: string): ?ValidationResult => {
  const minimumSignupAge = moment().clone().subtract(13, 'years').startOf('day');

  return moment(date, 'DD.MM.YYYY').isAfter(minimumSignupAge, 'day');
};

export const validateAddress = searchAddress => {
  if (
    validateRequired(searchAddress) ||
    validateRequired(searchAddress.street) ||
    validateRequired(searchAddress.streetNumber) ||
    validateRequired(searchAddress.zipCode) ||
    validateRequired(searchAddress.city)
  ) {
    return { code: VALIDATION_ADDRESS_INVALID };
  }
};
