// @flow
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { formatDate, formatDateWithTimeZoneId } from 'service/dateUtil';
import { ReactComponent as EditIconSvg } from 'styles/img/icons/edit_icon.svg';
import MembershipStatusIcon from 'ui/admin/companies/memberships/MembershipStatusIcon';

import {
  checkIfMembershipTerminationIsEditable,
  companyMembershipCancellationDeadline,
} from 'service/membershipUtil';

import type {
  ChronologyType,
  CorporateEmployeeSummaryType,
  MembershipOfferType,
  MembershipType,
  UserType,
} from 'ui/admin/companies/memberships/types';
import MembershipOfferPlan from './MembershipOfferType';
import moment from 'moment';

type Props = {
  membership: MembershipType,
  membershipOffer: MembershipOfferType,
  chronology: ChronologyType,
  user: UserType,
  corporateEmployee: CorporateEmployeeSummaryType,
  showPersonalIdColumn: boolean,
  showMembershipTypeColumn: boolean,
  showEmployeeNumberColumn: boolean,
  onEndMembershipClick: Function,
  onEditPersonalIdClick: Function,
};

const TableRow = ({
  membership,
  membershipOffer,
  chronology,
  user,
  corporateEmployee,
  showPersonalIdColumn,
  showMembershipTypeColumn,
  showEmployeeNumberColumn,
  onEndMembershipClick,
  onEditPersonalIdClick,
}: Props) => {
  const rowClassName = classNames({ 'text-muted': chronology === 'PAST' });
  const pastMembership = chronology === 'PAST';
  const isCancellationDateChangeAllowed =
    !membership.membershipEndTimestamp ||
    !checkIfMembershipTerminationIsEditable(
      moment(),
      moment(membership.membershipEndTimestamp).endOf('day'),
      companyMembershipCancellationDeadline
    );

  return (
    <tr className={rowClassName} key={membership.uuid}>
      <td className="text-start align-middle mw-20">
        <div>
          <strong>
            {user.lastName || user.firstName ? (
              `${user.lastName}, ${user.firstName}`
            ) : (
              <FormattedMessage id="companyMemberships.memberInfo.name.notAvailable" />
            )}
          </strong>
        </div>
        <div>
          <span>
            <FormattedMessage id="companyMemberships.memberInfo.dateOfBirth" />
            {`: ${formatDate(user.birthday)}`}
          </span>
        </div>
      </td>
      {showPersonalIdColumn && (
        <td className="text-start align-middle personal-id mw-20">
          <div
            className="personal-id-wrapper"
            role="button"
            onClick={() =>
              onEditPersonalIdClick({
                membership,
                membershipOfferDto: membershipOffer,
                user,
                chronology,
              })
            }
          >
            {membershipOffer.employeeInternalIdentifierLabel && (
              <>
                <div className="personal-id-text text-break">
                  <div>
                    <strong>{membership.employeeIdentifier}</strong>
                  </div>
                  <div>
                    <span>({membershipOffer.employeeInternalIdentifierLabel})</span>
                  </div>
                </div>
                <EditIconSvg className="edit-icon" />
              </>
            )}
          </div>
        </td>
      )}
      {showEmployeeNumberColumn && (
        <td className="text-start align-middle employee-no">{corporateEmployee?.employeeNumber}</td>
      )}
      {showMembershipTypeColumn && (
        <td className="text-start align-middle">
          <MembershipOfferPlan membershipOfferType={membershipOffer.type} />
        </td>
      )}
      <td className="text-start align-middle">
        {formatDateWithTimeZoneId(membership.membershipStartTimestamp, membership.timeZoneId)}
      </td>
      <td className="text-start align-middle">
        {membership.membershipEndTimestamp
          ? formatDateWithTimeZoneId(membership.membershipEndTimestamp, membership.timeZoneId)
          : '-'}
      </td>
      <td className="text-start align-middle">
        <MembershipStatusIcon membership={membership} chronology={chronology} />
      </td>
      <td className="text-end align-middle">
        {!pastMembership && isCancellationDateChangeAllowed && (
          <button
            id="membershipsListview.btn.cancelMembership"
            type="button"
            className="btn btn-outline-secondary border border-secondary text-nowrap"
            onClick={() =>
              onEndMembershipClick({
                membership,
                membershipOfferDto: membershipOffer,
                user,
                chronology,
              })
            }
          >
            <FormattedMessage id="companyMemberships.memberInfo.endMembershipButton" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default React.memo<Props>(TableRow);
