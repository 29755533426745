import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import DataSharingOptInDropdown from 'ui/account/DataSharingOptInDropdown';
import { get, post } from 'service/http/http-client';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';

const OPT_IN_TYPE = 'QT_DATA_SHARE_WITH_COMPANY';
const ERROR_TYPE_GET = 'get';
const ERROR_TYPE_SAVE = 'save';

const DataPrivacySection = () => {
  const { formatMessage } = useIntl();
  const { user } = React.useContext(AuthenticationContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [dataSharingOptIn, setDataSharingOptIn] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetchOptIns = async () => {
      try {
        const response = await get(`/v1/user/current/account/opt-in`);
        if (response.ok) {
          const optIns = (await response.json()) || [];
          const existingOptIn = optIns.find(optIn => optIn.type === OPT_IN_TYPE);
          if (existingOptIn) {
            setDataSharingOptIn(existingOptIn.action);
            setError('');
          }
        } else {
          setError(ERROR_TYPE_GET);
        }
      } catch (e) {
        setError(ERROR_TYPE_GET);
      }
    };

    executeWithSpinner(fetchOptIns());
  }, [user.id, executeWithSpinner]);

  const saveOptIns = event => {
    const optIn = {
      accountId: user.id,
      type: OPT_IN_TYPE,
      action: event.target.value,
      dataSource: 'USER',
    };

    return executeWithSpinner(
      (async () => {
        try {
          const response = await post('/v1/user/current/account/opt-in', [optIn]);
          if (response.ok) {
            setDataSharingOptIn(optIn.action);
            setError('');
          } else {
            setError(ERROR_TYPE_SAVE);
          }
        } catch (e) {
          setError(ERROR_TYPE_SAVE);
        }
      })()
    );
  };

  return (
    <>
      <h4 className="fw-bold my-3">
        <FormattedMessage id="accountSettingsPage.privacy.title" />
      </h4>
      <div className="my-3 col-md-7 col-lg-5">
        <DataSharingOptInDropdown value={dataSharingOptIn} onChange={saveOptIns} disabled={error} />
      </div>
      <p className="text-muted small">
        <FormattedMessage
          id="accountSettingsPage.privacy.legalText.part1"
          values={{
            link: (
              <a
                href={formatMessage({ id: 'accountSettingsPage.privacyPolicyLink.url' })}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted text-underlined"
              >
                <FormattedMessage id="accountSettingsPage.privacyPolicyLink.text" />
              </a>
            ),
          }}
        />
      </p>
      <p className="text-muted small">
        <FormattedMessage id="accountSettingsPage.privacy.legalText.part2" />
      </p>

      {error && (
        <div className="alert alert-danger">
          <FormattedMessage
            id="accountSettingsPage.privacy.error.text"
            values={{
              action: formatMessage({ id: `accountSettingsPage.privacy.error.${error}` }),
            }}
          />
        </div>
      )}
    </>
  );
};

export default DataPrivacySection;
