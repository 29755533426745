// @flow
import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { httpGet } from 'service/http/http-client';
import CompanyMembershipsTable from 'ui/admin/companies/memberships/CompanyMembershipsTable';
import MembershipExport from 'ui/admin/companies/memberships/MembershipExport';
import MembershipNote from 'ui/admin/companies/memberships/MembershipNote';
import {
  ACTIVE_MEMBERS_FILTER_VALUE,
  ALL_MEMBERS_FILTER_VALUE,
  CancellationReason,
  ENDING_MEMBERSHIP_FILTER_VALUE,
  getMembershipChronology,
  membershipComparator,
  membershipFilters,
  NEW_REGISTRATIONS_FILTER_VALUE,
  replaceCancellationReason,
} from 'service/membershipUtil';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import CompanyUserContext from 'ui/common/authentication/CompanyUserContext';
import {
  getAccessibleCompaniesForCompanyUser,
  isCompanyUser,
} from 'ui/common/authentication/authenticationUtil';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import moment from 'moment';
import EndMembershipModal from 'ui/admin/companies/memberships/EndMembershipModal';
import type { MembershipWrapperType } from './types';
import SearchField from 'ui/admin/companies/memberships/SearchField';
import CompanyAdminFeedbackBanner from './CompanyAdminFeedbackBanner';
import Dropdown from 'ui/common/components/dropdown/Dropdown';
import EditPersonalIdModal from './EditPersonalIdModal';
import { Alert, Snackbar } from '@mui/material';
import CompanySelectionDropdown from 'ui/layout/CompanySelectionDropdown';

type Props = {
  intl: IntlShape,
};

const CompanyMembershipsPage = ({ intl: { formatMessage } }: Props) => {
  const [membershipWrappers, setMembershipWrappers] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState([ALL_MEMBERS_FILTER_VALUE]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [effectiveSearchTerm, setEffectiveSearchTerm] = React.useState('');
  const [error, setError] = React.useState(undefined);

  const [endMembershipModalOpen, setEndMembershipModalOpen] = React.useState(false);
  const [endMembershipSuccess, setEndMembershipSuccess] = React.useState(false);
  const [editPersonalIdModalOpen, setEditPersonalIdModalOpen] = React.useState(false);
  const [membershipToEnd, setMembershipToEnd] = React.useState(undefined);
  const [membershipToEdit, setMembershipToEdit] = React.useState(undefined);
  const { user } = React.useContext(AuthenticationContext);
  const { selectedCompanyId } = React.useContext(CompanyUserContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const filteredMemberships = React.useMemo(() => {
    // mw stands for membershipWrapper in all the filters
    const companyFilter = mw =>
      selectedCompanyId
        ? mw.membershipOfferDto && mw.membershipOfferDto.sfAccountCanonicalId === selectedCompanyId
        : true;

    const activeMembershipsFilter = mw =>
      selectedFilter.includes(ACTIVE_MEMBERS_FILTER_VALUE) ? mw.chronology === 'ACTIVE' : true;

    const newRegistrationsFilter = mw =>
      selectedFilter.includes(NEW_REGISTRATIONS_FILTER_VALUE) ? mw.chronology === 'FUTURE' : true;

    const endingSoonMembershipFilter = mw =>
      selectedFilter.includes(ENDING_MEMBERSHIP_FILTER_VALUE)
        ? mw.chronology === 'ENDING_SOON'
        : true;

    const effectiveSearchTermFilter = mw => {
      const term = effectiveSearchTerm.toLowerCase().trim();

      return !term
        ? true
        : (mw.user.firstName && mw.user.firstName.toLowerCase().startsWith(term)) ||
            (mw.user.lastName && mw.user.lastName.toLowerCase().startsWith(term)) ||
            (!mw.user.firstName &&
              !mw.user.lastName &&
              formatMessage({ id: 'companyMemberships.memberInfo.name.notAvailable' }).startsWith(
                term
              )) ||
            (mw.membership.employeeIdentifier &&
              mw.membership.employeeIdentifier.toLowerCase().startsWith(term));
    };

    const filteredMemberships = membershipWrappers => {
      const filtersMap = {
        [ACTIVE_MEMBERS_FILTER_VALUE]: activeMembershipsFilter,
        [NEW_REGISTRATIONS_FILTER_VALUE]: newRegistrationsFilter,
        [ENDING_MEMBERSHIP_FILTER_VALUE]: endingSoonMembershipFilter,
      };

      const selectedFilters = Object.keys(filtersMap)
        .filter(key => selectedFilter.includes(key))
        .map(key => filtersMap[key]);

      const applySelectedFilters = item =>
        selectedFilters.length === 0 || selectedFilters.some(filterFunc => filterFunc(item));

      return membershipWrappers.filter(
        wrapper =>
          companyFilter(wrapper) &&
          applySelectedFilters(wrapper) &&
          effectiveSearchTermFilter(wrapper)
      );
    };

    return membershipWrappers ? filteredMemberships(membershipWrappers) : [];
  }, [membershipWrappers, selectedCompanyId, selectedFilter, effectiveSearchTerm, formatMessage]);

  const membershipFilterItems = membershipFilters.map(m => ({
    ...m,
    label: formatMessage({ id: m.textKey }),
  }));
  const handleFilterChange = newFilter => {
    if (
      selectedFilter.includes(ALL_MEMBERS_FILTER_VALUE) ||
      newFilter === ALL_MEMBERS_FILTER_VALUE
    ) {
      setSelectedFilter([newFilter]);
      return;
    }
    if (selectedFilter.includes(newFilter)) {
      const remainingSelectedFilter = selectedFilter.filter(f => f !== newFilter);
      setSelectedFilter(
        remainingSelectedFilter.length === 0 ? [ALL_MEMBERS_FILTER_VALUE] : remainingSelectedFilter
      );
      return;
    }
    setSelectedFilter([...selectedFilter, newFilter]);
  };

  React.useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      setEffectiveSearchTerm(searchTerm);
    } else {
      setEffectiveSearchTerm('');
    }
  }, [searchTerm]);

  const onEndMembershipClick = (membershipWrapper: MembershipWrapperType) => {
    setEndMembershipModalOpen(true);
    setMembershipToEnd(
      replaceCancellationReason(
        membershipWrapper,
        CancellationReason.API_END_OF_EMPLOYMENT,
        CancellationReason.END_OF_EMPLOYMENT
      )
    );
  };

  const onEditPersonalIdClick = (membershipWrapper: MembershipWrapperType) => {
    setEditPersonalIdModalOpen(true);
    setMembershipToEdit(membershipWrapper);
  };

  const onEndMembershipSuccess = patchedMembership => {
    const updatedMemberships =
      patchedMembership.membership.membershipCancellationReason ===
      CancellationReason.REJECT_MEMBERSHIP
        ? membershipWrappers.filter(mv => mv.membership.uuid !== patchedMembership.membership.uuid)
        : membershipWrappers.map(mv =>
            mv.membership.uuid === patchedMembership.membership.uuid
              ? { ...patchedMembership, chronology: mv.chronology }
              : { ...mv }
          );

    setMembershipWrappers(updatedMemberships);
    setEndMembershipSuccess(true);
  };

  const onEditPersonalIdSuccess = patchedMembership => {
    const updatedMemberships = membershipWrappers.map(mv =>
      mv.membership.uuid === patchedMembership.membership.uuid
        ? {
            ...patchedMembership,
            membership: { ...patchedMembership.membership },
          }
        : { ...mv }
    );
    setMembershipWrappers(updatedMemberships);
  };

  // Fetches memberships for all companies for which the user is COMPANY_ADMIN.
  React.useEffect(() => {
    setMembershipWrappers([]);
    setError(undefined);
    const getMembershipsFromAllCompanies = async () => {
      try {
        let membershipWrappers = [];
        const companyIds = getAccessibleCompaniesForCompanyUser(user);
        // execute all requests in parallel
        await Promise.all(
          companyIds.map(async companyId => {
            const membershipsForOneCompany = await httpGet(`/v2/company/${companyId}/membership`);
            membershipWrappers.push(...membershipsForOneCompany);
          })
        );

        // add chronology to each membership: Future, Active or Past.
        const now = moment(new Date());
        membershipWrappers = membershipWrappers
          .map(mw => ({
            ...mw,
            chronology: getMembershipChronology(mw.membership, now),
          }))
          .sort(membershipComparator);

        setMembershipWrappers(membershipWrappers);
      } catch (error) {
        setError(error);
      }
    };
    executeWithSpinner(getMembershipsFromAllCompanies());
  }, [user, executeWithSpinner]);

  return (
    <>
      <CompanyAdminFeedbackBanner />
      <h2 id="companyMemberships.headerTitle.id" className="text-dark mb-3">
        <FormattedMessage id="companyMemberships.headerTitle" />
      </h2>
      <div className="container company-memberships-container">
        <div className="d-flex flex-wrap mb-4 align-items-center mt-2 gap-3">
          <div className="d-flex flex-wrap gap-3">
            {isCompanyUser(user) && (
              <div className="membership-control">
                <CompanySelectionDropdown />
              </div>
            )}
            <div className="membership-control">
              <SearchField
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setEffectiveSearchTerm={setEffectiveSearchTerm}
                placeholder={formatMessage({ id: 'companyMemberships.searchField.placeholder' })}
              />
            </div>
          </div>

          <div className="d-flex flex-wrap gap-3 justify-content-between flex-fill">
            <div className="membership-control">
              <FormattedMessage id="companyMemberships.filter.status.label" />
              <Dropdown
                dropdownId="companyAdmin.membershipFilters.dropdown"
                className="filter-select d-inline-block membership-dropdown"
                items={membershipFilterItems}
                onChange={handleFilterChange}
                selectedItems={selectedFilter}
              />
            </div>

            <div className="d-flex align-items-center">
              <MembershipExport membershipWrappers={filteredMemberships} />
            </div>
          </div>
        </div>

        {error && <Alert severity="error">{error && error.message}</Alert>}
        <CompanyMembershipsTable
          membershipWrappers={filteredMemberships}
          onEndMembershipClick={onEndMembershipClick}
          onEditPersonalIdClick={onEditPersonalIdClick}
        />
        <MembershipNote />
        {endMembershipModalOpen && (
          <EndMembershipModal
            isOpen={true}
            onRequestClose={() => setEndMembershipModalOpen(false)}
            membershipWrapper={membershipToEnd}
            onEndMembershipSuccess={onEndMembershipSuccess}
          />
        )}
        {editPersonalIdModalOpen && (
          <EditPersonalIdModal
            isOpen={true}
            onRequestClose={() => setEditPersonalIdModalOpen(false)}
            membershipWrapper={membershipToEdit}
            onEditPersonalIdSuccess={onEditPersonalIdSuccess}
          />
        )}

        <Snackbar
          open={endMembershipSuccess}
          autoHideDuration={6000}
          onClose={() => setEndMembershipSuccess(false)}
        >
          <Alert
            severity="success"
            sx={{
              padding: '12px 20px',
            }}
          >
            <FormattedMessage id="companyMemberships.endMembershipSuccessfulNote" />
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default injectIntl(CompanyMembershipsPage);
