import React, { useMemo } from 'react';
import { EdsDialog } from '@egym/react-design-system/EdsDialog';
import { EdsIconButton } from '@egym/react-design-system/EdsIconButton';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { FormattedMessage } from 'react-intl';
import 'styles/components/company-settings-page.scss';
import Stack from '@mui/material/Stack';
import AssignRoleStep from './AssignRoleStep';
import Form from '../common/components/Form';
import { CompanyType } from 'service/hooks/company';
import { Alert } from '@mui/material';

export type EditCompanyUserDialogProps = {
  opened: boolean,
  isLoading: boolean,
  companies: CompanyType[],
  // expecting key-values where the key is the companyId and the value is the role
  // note: it's not a Map, but a simple object
  initialRoles: Object,
  onSubmit: () => void,
  onClose: () => void,
};

const isSubmitDisabled = (initialRoles, newRoles) => {
  // performance is not a concern because we are dealing with small objects
  return JSON.stringify(initialRoles) === JSON.stringify(newRoles);
};

const dataTestId = 'edit_company_user_dialog';
const EditCompanyUserDialog = ({
  opened,
  onSubmit,
  isLoading,
  onClose,
  companies,
  initialRoles,
}: EditCompanyUserDialogProps) => {
  const handleSubmit = async (values: any) => {
    return onSubmit(values);
  };

  const headline = useMemo(() => {
    return (
      <Stack
        data-testid={`${dataTestId}_headline`}
        slot="headline"
        className="headline"
        direction="row"
        alignItems="baseline"
      >
        <Stack flex="1">
          <FormattedMessage id="companySettings.edit.dialogTitle" />
        </Stack>
        <EdsIconButton onClick={onClose}>
          <EdsIcon>close</EdsIcon>
        </EdsIconButton>
      </Stack>
    );
  }, [onClose]);

  const infoAlert = useMemo(
    () => (
      <Alert
        data-testid={`${dataTestId}_info_alert`}
        severity="info"
        sx={{
          padding: '12px 20px',
          margin: 0,
        }}
      >
        <FormattedMessage id="companySettings.edit.infoAlert" />
      </Alert>
    ),
    []
  );
  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={{
        roles: initialRoles,
      }}
    >
      {({ getValues }) => {
        const { roles } = getValues();
        return (
          <EdsDialog data-testid={dataTestId} opened={opened} onClose={onClose}>
            {headline}
            <Stack slot="content" className="dialog-content" direction="column" flex={1}>
              {infoAlert}
              <AssignRoleStep companies={companies} />
            </Stack>
            <div slot="actions" className="actions">
              <button
                type="submit"
                className="btn btn-primary"
                id="companySettings.edit.confirm"
                disabled={isSubmitDisabled(initialRoles, roles) || isLoading}
              >
                <FormattedMessage id="general.button.confirm" />
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-3"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </EdsDialog>
        );
      }}
    </Form>
  );
};

export default EditCompanyUserDialog;
