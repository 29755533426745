// @flow
export const getCookie = (name: string): ?string => {
  const namePrefix = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  const filteredCookies = cookiesArray
    .map(cookie => trimLeft(cookie))
    .filter(cookie => cookie.indexOf(namePrefix) === 0);

  return filteredCookies && filteredCookies.length > 0
    ? filteredCookies[0].substring(namePrefix.length)
    : undefined;
};

const trimLeft = (s: string): string => {
  if (!s) {
    return '';
  }
  let index = 0;
  while (index < s.length && s.charAt(index) === ' ') {
    ++index;
  }
  if (index === s.length) {
    return '';
  }
  return s.substring(index);
};
