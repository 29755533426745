import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EdsProfilePicture } from '@egym/react-design-system/EdsProfilePicture';
import { EdsLabel } from '@egym/react-design-system/EdsLabel';
import { COMPANY_ADMIN, COMPANY_EDITOR } from '../common/authentication/authenticationConstants';
import { FormattedMessage } from 'react-intl';
import { CompanyType } from 'service/hooks/company';

export type SummaryStepType = {
  companies: CompanyType[],
};

const dataTestId = 'summary_step';
const SummaryStep = ({ companies }: SummaryStepType) => {
  const { getValues } = useFormContext();

  const { firstName, lastName, email, roles } = getValues();

  return (
    <Stack data-testid={dataTestId} direction="column" className="gap-3 px-3" flex={1}>
      <Stack direction="row" alignItems="flex-start" className="gap-3 py-1">
        <EdsProfilePicture
          firstName={firstName}
          lastName={lastName}
          type="monogram"
          size="medium"
        ></EdsProfilePicture>
        <Stack direction="column">
          <Typography
            data-testid={`${dataTestId}_first_name`}
            variant="body1"
            className="summary-step-name"
          >
            {`${firstName || ''} ${lastName || ''}`}
          </Typography>
          <Typography
            data-testid={`${dataTestId}_last_name`}
            variant="body1"
            className="summary-step-email"
          >
            {email || ''}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" className="gap-2">
        {Object.keys(roles).map(entityId => (
          <Stack
            data-testid={`${dataTestId}_${entityId}`}
            key={entityId}
            direction="row"
            justifyContent="space-between"
            className="py-2"
          >
            <Typography variant="body1">
              {companies.find(company => company.entityId === entityId)?.entityName || ''}
            </Typography>
            {roles[entityId] === COMPANY_EDITOR && (
              <div className="editor-chip">
                <EdsLabel type="info">
                  <FormattedMessage id="roles.editor" />
                </EdsLabel>
              </div>
            )}
            {roles[entityId] === COMPANY_ADMIN && (
              <EdsLabel type="info">
                <FormattedMessage id="roles.admin" />
              </EdsLabel>
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SummaryStep;
