import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CountrySelectionModal from './CountrySelectionModal';

const defaultStyles = {
  boldCountryName: false,
};

type Props = {
  countryCode: string,
  onCountryCodeChange: Function,
  styleOptions?: {
    boldCountryName?: boolean,
    wrapperClassName?: string,
  },
};

const CountrySelection = ({ countryCode, onCountryCodeChange, styleOptions }: Props) => {
  const [showCountrySelectionModal, setShowCountrySelectionModal] = useState(false);
  const [options] = useState(styleOptions ? styleOptions : defaultStyles);

  const onCountrySelectionModalClose = code => {
    onCountryCodeChange(code);
    setShowCountrySelectionModal(false);
  };

  return (
    <>
      <div className={options.wrapperClassName}>
        <span className={options.boldCountryName ? 'fw-bold' : ''}>
          <FormattedMessage id={`countries.${countryCode}`} />
        </span>
        &nbsp;(
        <button
          onClick={() => setShowCountrySelectionModal(true)}
          type="button"
          className="btn btn-link font-size-inherit p-0 text-underlined"
        >
          <FormattedMessage id="countrySelection.changeCountryLink" />
        </button>
        )
      </div>
      {showCountrySelectionModal && (
        <CountrySelectionModal
          isOpen={true}
          countryCode={countryCode}
          onRequestClose={onCountrySelectionModalClose}
        />
      )}
    </>
  );
};

export default injectIntl(CountrySelection);
