import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  validationRule: string,
  classes: string,
};

function PersonalIdNote({ validationRule, classes }: Props) {
  return (
    <div className={classes ? classes : ''}>
      <small>
        <b>
          <FormattedMessage id={'editPersonalIdModal.validationRule.headline'} />
        </b>

        <div>{validationRule}</div>
      </small>
    </div>
  );
}

export default PersonalIdNote;
