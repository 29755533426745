export const downloadCsvText = (csv: string, fileName: string) => {
  const blobCsv = new Blob([csv], { type: 'text/csv' });

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blobCsv, fileName);
  } else {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blobCsv);
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};
