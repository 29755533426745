import React from 'react';
import style from './CompanyLogo.module.scss';

type Props = {
  logo: ?string,
};

const CompanyLogo = ({ logo }: Props) => (
  <div className="row justify-content-center">
    <div className={style.logoContainer}>
      <img className={style.companyLogo} src={logo} alt="Company Logo" />
    </div>
  </div>
);

export default CompanyLogo;
