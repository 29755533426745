import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'ui/common/modal/Modal';

type Props = {
  isOpen: boolean,
  onModalClose: Function,
  intl: { formatMessage: Function },
};

const PaymentDataModal = ({ isOpen, onModalClose, intl: { formatMessage } }: Props) => {
  const modalTitle = formatMessage({ id: 'updatePaymentInfoModal.headerTitle' });
  return (
    <Modal
      isOpen={isOpen}
      modalTitle={modalTitle}
      modalSizeClass="modal-lg"
      closeButtonId="paymentUpdateInfoModal.btn.close"
      onRequestClose={() => onModalClose()}
    >
      <div className="text-start fw-lighter mt-1 mb-1">
        <FormattedMessage id="updatePaymentInfoModal.message" />
      </div>
    </Modal>
  );
};

export default injectIntl(PaymentDataModal);
