// @flow
import * as React from 'react';
import type { AuthenticatedUser } from 'ui/common/authentication/types';
import type { MinifiedMembershipWrapperType } from 'ui/admin/companies/memberships/types';
import { get } from 'service/http/http-client';
import { useLocalStorage } from '../../../service/hooks';

export const CSRF_TOKEN_COOKIE_NAME = 'XSRF-TOKEN';
export const CSRF_TOKEN_HEADER_NAME = 'X-XSRF-TOKEN';
export const USER_LOCAL_STORAGE_KEY = 'USER';
export const MEMBERSHIPS_LOCAL_STORAGE_KEY = 'MEMBERSHIPS';
export const HAS_PAYMENT_LOCAL_STORAGE_KEY = 'HAS_PAYMENT';

type State = {
  user: ?AuthenticatedUser,
  memberships: Array<MinifiedMembershipWrapperType>,
};

const AuthenticationContext = React.createContext<State>({ user: undefined });

type Props = {
  children: any,
};

const AuthenticationProvider = ({ children }: Props) => {
  const [user, setUser, removeUser] = useLocalStorage(USER_LOCAL_STORAGE_KEY, undefined);
  /* eslint-disable no-unused-vars */
  const [value, setShowBanner, removeShowBanner] = useLocalStorage(
    'SHOW_COMPANY_FEEDBACK_BANNER',
    undefined
  );
  /* eslint-enable no-unused-vars */
  //indicates if user has/had/will have membership of any type
  //at the moment we dont need any other membership information,
  //but in future might make sense to store membership details here
  const [hasMemberships, setHasMemberships, removeHasMemberships] = useLocalStorage(
    MEMBERSHIPS_LOCAL_STORAGE_KEY,
    undefined
  );
  // eslint-disable-next-line no-unused-vars
  const [hasPayment, setHasPayment, removeHasPayment] = useLocalStorage(
    HAS_PAYMENT_LOCAL_STORAGE_KEY,
    undefined
  );

  const login = React.useCallback(
    (user: Object, hasMembership: boolean) => {
      setUser(user);
      setHasMemberships(hasMembership);
      setShowBanner(true);
    },
    [setUser, setHasMemberships, setShowBanner]
  );

  React.useEffect(() => {
    const recheckEmailVerification = async () => {
      // In order to have smoother user experience:
      // get user-profile from server if the user is logged-in but there is no email confirmation yet.
      // This way, after confirming the email, the user can only refresh the page instead of doing logout and login again.
      if (user && !user.verified) {
        const response = await get('/v1/user-profile');
        if (response.ok) {
          const newUser = await response.json();
          // [QT-1039]
          if (newUser.verified) {
            setUser(newUser);
          }
        }
      }
    };
    recheckEmailVerification();
  }, [user, setUser]);

  const logout = () => {
    removeUser();
    removeHasMemberships();
    removeHasPayment();
    removeShowBanner();
  };

  const isAuthenticated = () => {
    return user !== undefined;
  };

  const isEmailVerified = () => {
    return user && user.verified;
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user: user,
        hasMemberships: hasMemberships,
        isAuthenticated: isAuthenticated,
        login: login,
        setHasMemberships: setHasMemberships,
        logout: logout,
        isEmailVerified: isEmailVerified,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationProvider };
export default AuthenticationContext;
