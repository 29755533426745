import React, { useEffect, useMemo, useState } from 'react';
import type {
  EvaluatedCorporateEmployeeType,
  MembershipWrapperType,
} from 'ui/admin/companies/memberships/types';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'ui/common/modal/Modal';
import { httpPost } from 'service/http/http-client';
import {
  adminCancellationReasons,
  CancellationReason,
  companyMembershipCancellationDeadline,
  defaultLabelForMyMembershipsPage,
  generateEndDateOptions,
  systemCancellationReasons,
} from 'service/membershipUtil';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { formatDate } from '../../../../service/dateUtil';
import SystemCancellationNote from './SystemCancellationNote';
import { Alert } from '@mui/material';
import Form from '../../../common/components/Form';
import DropdownFormField from '../../../self-signup/components/DropdownFormField';
import useApi from '../../../../service/api';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  onEndMembershipSuccess: Function,
  membershipWrapper: MembershipWrapperType,
  intl: IntlShape,
};

const EndMembershipModal = ({
  isOpen,
  onRequestClose,
  onEndMembershipSuccess,
  membershipWrapper: { membership, membershipOfferDto, user },
  intl: { formatMessage },
}: Props) => {
  const { userMembershipsApi } = useApi();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSystemCancellation, setIsSystemCancellation] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [corporateEmployee: EvaluatedCorporateEmployeeType, setCorporateEmployee] =
    React.useState(null);
  const [loadingCorporateEmployee, setLoadingCorporateEmployee] = React.useState(true);
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const membershipStartTimestampMoment = moment(membership.membershipStartTimestamp);

  useEffect(() => {
    if (isOpen && user.id && membershipOfferDto && membershipOfferDto.integrationScopeId) {
      userMembershipsApi
        .fetchCorporateEmployee(
          user.id,
          membershipOfferDto.integrationScopeId,
          membershipOfferDto.sfAccountCanonicalId
        )
        .then(corporateEmployeeResponse => {
          setCorporateEmployee(corporateEmployeeResponse);
          setLoadingCorporateEmployee(false);
        });
    } else {
      setLoadingCorporateEmployee(false);
    }
  }, [user.id, userMembershipsApi, membershipOfferDto, isOpen]);

  const isStillRejectable = moment().isBefore(
    moment()
      .date(companyMembershipCancellationDeadline)
      .month(membershipStartTimestampMoment.month() - 1)
      .year(membershipStartTimestampMoment.year())
      .endOf('day')
  );

  const cancellationReasons = isStillRejectable
    ? adminCancellationReasons
    : adminCancellationReasons.filter(
        reason => reason.value !== CancellationReason.REJECT_MEMBERSHIP
      );

  const modalTitle = formatMessage({ id: 'endMembershipModal.title' });

  const dateFormat = formatMessage({ id: 'endMembershipModal.dateFormat' });

  const endDateDefaultLabel = 'endMembershipModal.endOfMembership.default.label';

  const errorMessage = formatMessage({ id: 'endMembershipModal.error' });

  const rejectMembershipEndDateOption = {
    key: membership.membershipStartTimestamp,
    value: membership.membershipStartTimestamp,
    label: formatMessage({
      id: 'endMembershipModal.rejectMembership.endDateLabel',
    }),
  };

  const endDateOptions = generateEndDateOptions({
    timeZoneId: membership.timeZoneId,
    dateFormat,
    defaultLabel: endDateDefaultLabel,
    presetValue: membership.membershipEndTimestamp,
    startDate: moment.max(moment(membership.membershipStartTimestamp), moment()),
    endDate: corporateEmployee ? corporateEmployee.eligibility.effectiveTo : null,
  });

  const initialiseFormFields = useMemo(() => {
    let initialValues = {};

    const cancellationReason = membership.membershipCancellationReason;
    if (cancellationReason && cancellationReason !== CancellationReason.EMPTY) {
      const isAdminCancellationReason = adminCancellationReasons.some(
        r => r.value === cancellationReason
      );

      initialValues['membershipCancellationReason'] = isAdminCancellationReason
        ? cancellationReason
        : CancellationReason.OTHER;
    }

    if (membership.membershipEndTimestamp) {
      initialValues['membershipEndDate'] = membership.membershipEndTimestamp;
    }

    if (membership.membershipCancellationReason === CancellationReason.REJECT_MEMBERSHIP) {
      initialValues['membershipEndDate'] = rejectMembershipEndDateOption.value;
    }

    return initialValues;
  }, [membership, rejectMembershipEndDateOption.value]);

  const endMembership = values => {
    const companyId = membershipOfferDto.sfAccountCanonicalId;
    if (!companyId) {
      return Promise.reject(errorMessage);
    }

    const { membershipCancellationReason, membershipEndDate } = values;
    const endDate = membershipEndDate ? formatDate(membershipEndDate, 'YYYY-MM-DD') : null;
    const requestBody = {
      cancellationReason: membershipCancellationReason,
      endDate: endDate || undefined,
    };

    const cancelMembership = async () => {
      try {
        const cancelledMembership = await httpPost(
          `/company/v1/company/${companyId}/membership/${membership.uuid}/cancel`,
          requestBody
        );
        onEndMembershipSuccess(cancelledMembership);
        onRequestClose();
      } catch (errorMessage) {
        setSubmitError(true);
        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    };
    return executeWithSpinner(cancelMembership());
  };

  const isCancelledByInternalAdmin = useMemo(
    () => systemCancellationReasons.includes(membership.membershipCancellationReason),
    [membership.membershipCancellationReason]
  );

  useEffect(() => {
    setIsSystemCancellation(isCancelledByInternalAdmin);
  }, [isCancelledByInternalAdmin]);

  const validateEndMembership = (values: any) => {
    const { membershipCancellationReason, membershipEndDate } = values;

    if (membershipCancellationReason && membershipEndDate) {
      const isAdminCancellationReason = adminCancellationReasons.some(
        r => r.value === membership.membershipCancellationReason
      );

      const hasCancellationChanged =
        (isAdminCancellationReason &&
          membershipCancellationReason !== membership.membershipCancellationReason) ||
        (!isAdminCancellationReason && membershipCancellationReason !== CancellationReason.OTHER) ||
        formatDate(membershipEndDate, 'YYYY-MM-DD') !==
          formatDate(membership.membershipEndTimestamp, 'YYYY-MM-DD');

      setIsButtonDisabled(!hasCancellationChanged || isCancelledByInternalAdmin);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const updateCancellationEndDate = (watchField, setValue) => {
    if (watchField === CancellationReason.REJECT_MEMBERSHIP) {
      setValue('membershipEndDate', rejectMembershipEndDateOption.value, {
        shouldValidate: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      modalSizeClass="modal-md"
      modalClass="end-membership-modal"
      onRequestClose={onRequestClose}
      modalTitle={modalTitle}
      noFooter={true}
      closeButtonId="endMembershipModal.btn.close"
    >
      <Form
        onSubmit={endMembership}
        defaultValues={initialiseFormFields}
        validator={validateEndMembership}
        customDependantFieldUpdate={updateCancellationEndDate}
        dependantFieldWatch={'membershipCancellationReason'}
      >
        {({ register, control, watch }) => {
          const watchCancellationReason = watch('membershipCancellationReason', '');

          const endDateDropdownDisabled =
            isSystemCancellation ||
            ((!membership.membershipCancellationReason ||
              membership.membershipCancellationReason === CancellationReason.EMPTY) &&
              !watchCancellationReason) ||
            watchCancellationReason === CancellationReason.REJECT_MEMBERSHIP ||
            loadingCorporateEmployee;

          return (
            <div>
              <div className="mb-4">
                <div className="mb-2">
                  <span>
                    <FormattedMessage id="endMembershipModal.nameTitle" />
                  </span>
                  {user.lastName || user.firstName ? (
                    ` ${user.firstName} ${user.lastName}`
                  ) : (
                    <FormattedMessage id="companyMemberships.memberInfo.name.notAvailable" />
                  )}
                </div>
                <div className="mb-2">
                  <FormattedMessage id="endMembershipModal.birthdayTitle" />
                  <span>{' ' + moment(user.birthday).format(dateFormat)}</span>
                </div>
                {isSystemCancellation && <SystemCancellationNote />}
                {membership.employeeIdentifier && (
                  <div className="mb-2">
                    <span>{membershipOfferDto.employeeInternalIdentifierLabel + ': '}</span>
                    <span>{membership.employeeIdentifier}</span>
                  </div>
                )}
              </div>
              <div className="mb-3 pt-2">
                <DropdownFormField
                  controlId="membershipCancellationReason"
                  register={{ ...register('membershipCancellationReason') }}
                  control={control}
                  controlLabel={defaultLabelForMyMembershipsPage}
                  options={cancellationReasons.map(({ value, labelId }) => ({
                    key: value,
                    value: value,
                    label: formatMessage({ id: labelId }),
                  }))}
                />
              </div>
              <div className="mb-3">
                <DropdownFormField
                  controlId="membershipEndDate"
                  register={{ ...register('membershipEndDate') }}
                  control={control}
                  controlLabel={endDateDefaultLabel}
                  options={
                    watchCancellationReason !== CancellationReason.REJECT_MEMBERSHIP
                      ? endDateOptions
                      : [rejectMembershipEndDateOption]
                  }
                  disabled={endDateDropdownDisabled}
                />
              </div>

              {watchCancellationReason === CancellationReason.REJECT_MEMBERSHIP && (
                <Alert severity="warning">
                  <FormattedMessage id="endMembershipModal.rejectMembership.note" />
                </Alert>
              )}

              <Alert severity="info">
                <FormattedMessage id="endMembershipModal.info" />
              </Alert>

              {submitError ? (
                <>
                  <Alert severity="error">
                    <FormattedMessage id="endMembershipModal.error" />
                  </Alert>

                  <div className="d-grid mt-4">
                    <button className="btn btn-secondary" onClick={onRequestClose}>
                      <FormattedMessage id="endMembershipModal.button.cancelButton" />
                    </button>
                  </div>
                </>
              ) : (
                <div className="d-grid mt-4">
                  <button
                    id="endMembershipModal.btn.endMembershipNow"
                    type="submit"
                    className="btn btn-secondary"
                    disabled={isButtonDisabled}
                  >
                    <FormattedMessage id="endMembershipModal.button.endButton" />
                  </button>
                </div>
              )}
            </div>
          );
        }}
      </Form>
    </Modal>
  );
};

export default injectIntl(EndMembershipModal);
