// @flow
import React from 'react';
import type { MembershipWrapperType } from 'ui/admin/companies/memberships/types';
import TableHeaderRow from 'ui/admin/companies/memberships/TableHeaderRow';
import TableRow from 'ui/admin/companies/memberships/TableRow';
import {
  isMultipleTypePresent,
  isPersonalIdLabelPresent,
  isEmployeeNumberPresent,
} from 'service/membershipUtil';
import { FormattedMessage } from 'react-intl';

type Props = {
  membershipWrappers: Array<MembershipWrapperType>,
  onEndMembershipClick: Function,
  onEditPersonalIdClick: Function,
};

const CompanyMembershipsTable = ({
  membershipWrappers = [],
  onEndMembershipClick,
  onEditPersonalIdClick,
}: Props) => {
  const showPersonalIdColumn = isPersonalIdLabelPresent(membershipWrappers);
  const showMembershipTypeColumn = isMultipleTypePresent(membershipWrappers);
  const showEmployeeNumberColumn = isEmployeeNumberPresent(membershipWrappers);

  return (
    <div className="table-responsive-lg company-memberships-table">
      <table className="table table-sm">
        <thead className="table-light">
          <TableHeaderRow
            showPersonalIdColumn={showPersonalIdColumn}
            showMembershipTypeColumn={showMembershipTypeColumn}
            showEmployeeNumberColumn={showEmployeeNumberColumn}
          />
        </thead>
        <tbody>
          {membershipWrappers && membershipWrappers.length ? (
            membershipWrappers.map(
              ({ membership, membershipOfferDto, user, chronology, corporateEmployee }) => (
                <TableRow
                  key={membership.uuid}
                  membership={membership}
                  membershipOffer={membershipOfferDto}
                  user={user}
                  chronology={chronology}
                  corporateEmployee={corporateEmployee}
                  showPersonalIdColumn={showPersonalIdColumn}
                  showMembershipTypeColumn={showMembershipTypeColumn}
                  showEmployeeNumberColumn={showEmployeeNumberColumn}
                  onEndMembershipClick={onEndMembershipClick}
                  onEditPersonalIdClick={onEditPersonalIdClick}
                />
              )
            )
          ) : (
            <tr className="p-2 text-center">
              <td colSpan="100%" className="p-2 text-center">
                <FormattedMessage id="companyMemberships.emptySet" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

// NOTE: React.memo() is used here for optimization.
//       The table will only be re-rendered when there is a change in the membershipWrappers which are passed as props.
export default React.memo<Props>(CompanyMembershipsTable);
