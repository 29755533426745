import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Alert, AlertTitle } from '@mui/material';

const YEAR_LIMIT_SUBTRACT = 3;

const MembershipNote = () => (
  <Alert severity="info">
    <AlertTitle>
      <FormattedMessage id="companyMemberships.membershipsNote.headline" />
    </AlertTitle>
    <FormattedMessage
      id="companyMemberships.membershipsNote.body"
      values={{ year: new Date().getFullYear() - YEAR_LIMIT_SUBTRACT }}
    />
  </Alert>
);

export default injectIntl(MembershipNote);
