// @flow
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import { isAuthorized } from 'ui/common/authentication/authenticationUtil';
type Props = {
  component: any,
  allowedRoles?: Array<string>,
};

const ProtectedRoute = ({ component: Component, allowedRoles }: Props) => {
  const { isAuthenticated, isEmailVerified, user } = React.useContext(AuthenticationContext);

  const location = useLocation();

  if (!isAuthenticated()) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          // Pass the current location when doing the redirect.
          // This way, after login, the user can be redirected back to the requested page.
        }}
        state={location}
      />
    );
  } else if (!isAuthorized(user, allowedRoles)) {
    return <Navigate to="/login/not-authorized" />;
  } else if (!isEmailVerified() && location.pathname !== '/login/no-email-verification') {
    return <Navigate to="/login/no-email-verification" />;
  } else if (isEmailVerified() && location.pathname === '/login/no-email-verification') {
    return <Navigate to="/login/success" />;
  } else {
    return <Component {...location} isProtected={true} />;
  }
};

export default ProtectedRoute;
