// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  membershipOfferType: string,
};

const MembershipOfferPlan = ({ membershipOfferType }: Props) => (
  <>
    {membershipOfferType === 'STANDARD' && (
      <FormattedMessage id="companyMemberships.membershipOffer.type.standard" />
    )}
    {membershipOfferType === 'FREE' && (
      <FormattedMessage id="companyMemberships.membershipOffer.type.free" />
    )}
  </>
);

export default MembershipOfferPlan;
