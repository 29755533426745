import { useReducer } from 'react';

export const DECLARATION_OF_CONSENT_ACTIONS = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  INVERT_CONFIRMATION: 'INVERT_CONFIRMATION',
  RESET_CONFIRMATION: 'RESET_CONFIRMATION',
  SET_STATUS: 'SET_STATUS',
  ADD_FILES: 'ADD_FILES',
  CLEAR_ALL_FILES: 'CLEAR_ALL_FILES',
  RESET_MODAL: 'RESET_MODAL',
};

export const DECLARATION_OF_CONSENT_ALERT_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
};

const declarationOfConsentReducer = (state, modal) => {
  switch (modal.action) {
    case DECLARATION_OF_CONSENT_ACTIONS.OPEN_MODAL:
      return { ...state, modalIsOpen: true };
    case DECLARATION_OF_CONSENT_ACTIONS.CLOSE_MODAL:
      return { ...state, modalIsOpen: false };
    case DECLARATION_OF_CONSENT_ACTIONS.INVERT_CONFIRMATION:
      return { ...state, confirmed: !state.confirmed };
    case DECLARATION_OF_CONSENT_ACTIONS.RESET_CONFIRMATION:
      return { ...state, confirmed: false };
    case DECLARATION_OF_CONSENT_ACTIONS.SET_STATUS:
      return { ...state, status: modal.payload };
    case DECLARATION_OF_CONSENT_ACTIONS.ADD_FILES:
      return { ...state, files: modal.payload };
    case DECLARATION_OF_CONSENT_ACTIONS.CLEAR_ALL_FILES:
      return { ...state, files: [] };
    case DECLARATION_OF_CONSENT_ACTIONS.RESET_MODAL:
      return {
        ...state,
        modalIsOpen: false,
        confirmed: false,
        status: DECLARATION_OF_CONSENT_ALERT_STATUS.WARNING,
        files: [],
      };
    default:
      return state;
  }
};
export const useDeclarationOfConsent = initialState =>
  useReducer(declarationOfConsentReducer, initialState);
