import autocompleteTheme from './Autocomplete/AutocompleteTheme';
import outlinedInputTheme from './OutlinedInput/OutlinedInputTheme';
import inputLabelTheme from './InputLabel/InputLabelTheme';
import { createTheme } from '@mui/material';
import accordionTheme from './Accordion/AccordionTheme';
import alertTheme from './Alert/AlertTheme';

const themeDef = {
  typography: {
    allVariants: {
      fontFamily: 'Helvetica Now Display',
      fontSize: 16,
    },
  },
  components: {
    ...autocompleteTheme,
    ...outlinedInputTheme,
    ...inputLabelTheme,
    ...accordionTheme,
    ...alertTheme,
  },
};

const muiTheme = createTheme(themeDef);

export default muiTheme;
