export const browserConfig = {
  required: {
    e: 17,
    i: 11,
    f: 52,
    o: -3,
    s: 11.1,
    c: 68.0,
    samsung: 8.2,
    vivaldi: 1.2,
  },
  insecure: true,
  text_de: {
    msg: 'Den Browser den Du verwendest hat eine veraltete Version und weshalb unsere Seite nicht angezeigt werden kann. Bitte aktualisiere Deinen Browser um diese Seite zu sehen.',
  },
  text_en: {
    msg: 'The browser you are using runs on a quite old version and therefore not supported. Please update your browser version to view this page. Thank you!',
  },
};
