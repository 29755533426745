import { useReducer } from 'react';
export const useAlertNotification = init =>
  useReducer((state, { type, name, data }) => {
    if (name === 'birthday') {
      if (type === 'warning') {
        return {
          ...state,
          birthday: {
            ...state?.birthday,
            warning: data?.warning,
          },
        };
      }
    } else if (name === 'reviewedBirthday') {
      if (type === 'warning') {
        return {
          ...state,
          reviewedBirthday: {
            ...state?.reviewedBirthday,
            warning: data?.warning,
          },
        };
      }
    } else if (name === 'duplicateSignUp') {
      if (type === 'warning') {
        return {
          ...state,
          duplicateSignUp: {
            ...state?.duplicateSignUp,
            warning: data?.warning,
          },
        };
      }
    } else {
      return state;
    }
  }, init);
