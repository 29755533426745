// @flow
import moment from 'moment';

/**
 * Validates whether the given date of birth is between thirteen (inclusive) and eighteen (exclusive)
 *
 * @param dateOfBirth
 *    the users date of birth
 * @return boolean
 */
export const validateWhetherTheUserIsMinor = (dateOfBirth: string): boolean => {
  const now = moment();
  const birthday = moment(dateOfBirth, 'DD.MM.YYYY', true);

  const upperInclusiveDate = now.clone().subtract(13, 'years').startOf('day');

  const lowerExclusiveDate = now.clone().subtract(18, 'years').startOf('day');

  return birthday.isBetween(lowerExclusiveDate, upperInclusiveDate, 'days', '(]');
};
