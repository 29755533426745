import React from 'react';
import { EdsRadio } from '@egym/react-design-system/EdsRadio';
import { EdsLabeledField } from '@egym/react-design-system/EdsLabeledField';
import { FormattedMessage } from 'react-intl';
import InfoToolTip from '../InfoToolTip';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';

type RadioButtonItem = {
  label: string,
  value: string,
  helpText?: string,
};

type Props = {
  items: RadioButtonItem[],
  onChange?: Function,
  name: string,
  initialValue?: string,
};

const RadioButtonSelect = ({ items, onChange, name, initialValue }: Props) => {
  const [currentValue, setCurrentValue] = React.useState(initialValue);
  return (
    <div className={`row justify-content-center radio-button-label`} role="radiogroup">
      <div className={'d-flex justify-content-center col-md-12 radio-button-field-container'}>
        {items.map(item => {
          return (
            <div key={item.value} className={'radio-button-field'}>
              <EdsLabeledField className={'w-auto'}>
                <EdsRadio
                  id={item.value}
                  name={name}
                  touch-target="wrapper"
                  value={item.value}
                  color={'contrast'}
                  checked={currentValue === item.value}
                  label={item.label}
                  onChange={() => {
                    setCurrentValue(item.value);
                    if (onChange) {
                      onChange(item.value);
                    }
                  }}
                ></EdsRadio>
                {!!item.label && (
                  <FormattedMessage
                    slot="label"
                    htmlFor="radio-example"
                    style={{
                      color: 'var(--eds-colors-neutral-black-0)',
                    }}
                    id={item.label}
                  />
                )}
              </EdsLabeledField>
              {item.helpText && (
                <InfoToolTip text={item.helpText}>
                  <EdsIcon
                    style={{
                      '--eds-icon-color': '#ffffff',
                      '--eds-icon-size': `20px`,
                      '--eds-icon-weight': 400,
                    }}
                  >
                    info
                  </EdsIcon>
                </InfoToolTip>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtonSelect;
