// @flow
import { GERMANY, AVAILABLE_COUNTRY_CODES } from 'config/i18n/countryCodes';

export const DEFAULT_LOCALE = 'de-DE';

export const detectLocale = (
  navigatorLocales: ?Array<?string>,
  availableLocales: Object
): string => {
  // 1. if no navigator locales are defined, return the DEFAULT_LOCALE
  if (!navigatorLocales) {
    return DEFAULT_LOCALE;
  }

  // 2. check if the exact locale is available
  const supportedLocales = navigatorLocales.filter(locale =>
    availableLocales.hasOwnProperty(locale)
  );
  let detectedLocale = supportedLocales && supportedLocales[0];

  // 3. if no exact locale, then check only the language (without the country code)
  if (!detectedLocale) {
    const navigatorLanguages = navigatorLocales
      .map(locale => (locale && locale.length >= 2 && locale.substring(0, 2)) || undefined)
      .filter(Boolean);
    detectedLocale = navigatorLanguages.find(language => availableLocales.hasOwnProperty(language));
  }

  // 4. If no language was found, return the DEFAULT_LOCALE
  return detectedLocale || DEFAULT_LOCALE;
};

export const getCountryCodeFromLocale = (navigatorLocales: ?Array<?string> = []): string => {
  // Fallback to the country code for Germany
  let foundCountryCode = GERMANY;

  const availableCountryCodes = Object.entries(AVAILABLE_COUNTRY_CODES);
  for (const [locale, countryCode] of availableCountryCodes) {
    if (navigatorLocales.indexOf(locale) !== -1) {
      foundCountryCode = countryCode;
      break;
    }
  }

  return foundCountryCode;
};
