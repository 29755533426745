// @flow
/**
 * Extracts the value for the given request parameter.
 * In addition, the function removes any leading/trailing whitespace characters.
 *
 * @param parameterName the name of the parameter
 * @returns {string} the value of the parameter (trimmed), or null if there is no such request parameter
 */
export const extractRequestParameter = (parameterName: string): ?string => {
  const urlParams = new URLSearchParams(window.location.search);
  let parameterValue = urlParams.get(parameterName);
  if (parameterValue) {
    parameterValue = parameterValue.trim();
  }
  return parameterValue;
};
