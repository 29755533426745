import React, { useMemo } from 'react';
import { EdsProfilePicture } from '@egym/react-design-system/EdsProfilePicture';
import TableHeaderRow from './TableHeaderRow';
import type { ManagerType } from 'service/hooks/company';
import { COMPANY_ADMIN, COMPANY_EDITOR } from '../common/authentication/authenticationConstants';
import { FormattedMessage } from 'react-intl';
import { EdsLabel } from '@egym/react-design-system/EdsLabel';
import { EdsIconButton } from '@egym/react-design-system/EdsIconButton';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import TableHeaderCell from '../admin/companies/memberships/TableHeaderCell';

type Props = {
  companies: [],
  managers: ManagerType[],
  setManagerToEdit: (manager: ManagerType) => void,
};

const ManagerTable = ({ companies, managers, setManagerToEdit }: Props) => {
  const isLessThan1024 = useMediaQuery('(max-width:1024px)');

  const leftTableHeaderCell = useMemo(() => {
    if (isLessThan1024) {
      return (
        <>
          <TableHeaderCell className="left-column-th align-middle" />
          <TableHeaderCell className="align-middle" />
        </>
      );
    } else {
      return <TableHeaderCell className="left-column-th" />;
    }
  }, [isLessThan1024]);

  const rightTableHeaderCell = useMemo(() => {
    return <TableHeaderCell className="right-column-th" />;
  }, []);

  return (
    <div className="table-responsive-lg table-wrapper">
      <table className="table table-sm mb-0">
        <thead className="table-light position-sticky top-0 thead">
          <TableHeaderRow
            companies={companies}
            left={leftTableHeaderCell}
            right={rightTableHeaderCell}
            numberOfRightColumns={1}
          />
        </thead>
        <tbody>
          {!!managers?.length
            ? managers.map(manager => (
                <tr data-testid={manager.email} key={manager.accountId}>
                  {isLessThan1024 ? (
                    <>
                      <td className="position-sticky start-0 left-and-right-column-td align-middle">
                        <EdsProfilePicture
                          firstName={manager.firstName || ''}
                          lastName={manager.lastName || ''}
                          type="monogram"
                          size="small"
                        ></EdsProfilePicture>
                      </td>
                      <td className="ps-0">
                        <Stack direction="column">
                          <Typography variant="body1" className="summary-step-name">
                            {`${manager.firstName || ''} ${manager.lastName || ''}`}
                          </Typography>
                          <Typography variant="body1" className="summary-step-email">
                            {manager.email || ''}
                          </Typography>
                        </Stack>
                      </td>
                    </>
                  ) : (
                    <td className="position-sticky start-0 left-and-right-column-td align-middle email-cell">
                      <Stack direction="row" alignItems="center" className="gap-3 py-1">
                        <EdsProfilePicture
                          firstName={manager.firstName || ''}
                          lastName={manager.lastName || ''}
                          type="monogram"
                          size="small"
                        ></EdsProfilePicture>
                        <Stack direction="column">
                          <Typography variant="body1" className="summary-step-name">
                            {`${manager.firstName || ''} ${manager.lastName || ''}`}
                          </Typography>
                          <Typography variant="body1" className="summary-step-email">
                            {manager.email || ''}
                          </Typography>
                        </Stack>
                      </Stack>
                    </td>
                  )}

                  {companies.map(company => {
                    return (
                      <td
                        key={company.entityId}
                        className={`align-middle cell-width ${
                          companies?.length > 1 ? 'text-center' : ''
                        }`}
                      >
                        {manager.userAuthorityCompanyDetails
                          .filter(auth => auth.entityId === company.entityId)
                          .map(auth => {
                            if (auth.authority === COMPANY_EDITOR) {
                              return (
                                <span
                                  data-testid={`${company.entityId}_${COMPANY_EDITOR}_${auth.authority}`}
                                  key={`${company.entityId}_${auth.authority}`}
                                  className="editor-chip"
                                >
                                  <EdsLabel type="info">
                                    <FormattedMessage id="roles.editor" />
                                  </EdsLabel>
                                </span>
                              );
                            }
                            if (auth.authority === COMPANY_ADMIN) {
                              return (
                                <span
                                  data-testid={`${company.entityId}_${COMPANY_ADMIN}_${auth.authority}`}
                                  key={`${company.entityId}_${auth.authority}`}
                                >
                                  <EdsLabel type="info">
                                    <FormattedMessage id="roles.admin" />
                                  </EdsLabel>
                                </span>
                              );
                            }
                            return null;
                          })}
                      </td>
                    );
                  })}
                  <td className="actions left-and-right-column-td">
                    <EdsIconButton
                      data-testid={`${manager.email}_edit`}
                      onClick={() => setManagerToEdit(manager)}
                      disabled={!companies?.some?.(obj => obj.authority === COMPANY_ADMIN)}
                    >
                      <EdsIcon size="medium">edit</EdsIcon>
                    </EdsIconButton>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerTable;
