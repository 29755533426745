import { get } from 'service/http/http-client';
import { generateQueryParams } from '../commonUtil';

export class GymFinderApi {
  fetchGymsCount = searchFilters => {
    const queryParams = generateQueryParams(searchFilters);
    return get(`/v1/gym/count?${queryParams}`).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('GET gyms count returned ' + response.status);
      }
    });
  };
}
