import React from 'react';
import { FormattedMessage } from 'react-intl';
import ExistingUserLoginForm from './ExistingUserLoginForm';
import RegisterAndLoginToggle from './RegisterAndLoginToggle';
import RegistrationContext from './RegistrationContext';
import EgymIdBanner from 'ui/common/EgymIdBanner';

type Props = {
  toggleIsNewUser: Function,
  innerRef: React.RefObject,
};

const ExistingUserSection = ({ toggleIsNewUser, innerRef }: Props) => {
  const { isNewUser } = React.useContext(RegistrationContext);
  return (
    <>
      <h3 className="text-dark">
        <FormattedMessage id="signupPage.step1.welcomeBack" />
      </h3>
      <EgymIdBanner textId={'egymId.banner.signup.existingUserText'} />

      {isNewUser && (
        <RegisterAndLoginToggle
          id="signupPage.step1.toggle.login"
          command={toggleIsNewUser}
          messageId={'general.button.renderLogin'}
        />
      )}

      <ExistingUserLoginForm innerRef={innerRef} />
    </>
  );
};

export default ExistingUserSection;
