// @flow
import React, { useEffect } from 'react';
import { ReactComponent as ArrowIconSvg } from 'styles/img/icons/arrow_down.svg';
import type { DropdownItem } from 'ui/common/components/dropdown/types';

type Props = {
  items: DropdownItem[],
  onChange?: Function,
  value?: Function,
  placeholder?: string,
  className?: string,
  fillWidth?: boolean,
  variant?: string,
  height?: string,
  label?: string,
  dropdownId?: string,
  disabled?: boolean,
};

const LabeledDropdown = ({
  items,
  onChange,
  value,
  placeholder,
  className,
  label,
  fillWidth = false,
  variant = 'normal',
  height = 'auto',
  dropdownId = '',
  disabled = false,
}: Props) => {
  const onClick = item => {
    onChange && onChange(item);
  };

  const selectedValue = items.find(item => item.value === value);

  useEffect(() => {
    if (items.length === 1) {
      onChange?.(items[0].value);
    }
  }, [items, onChange]);

  return (
    <div
      className={`labeled-dropdown justify-content-center ${className ? className : ''} ${
        fillWidth ? 'w-100' : ''
      }`}
    >
      {label && <label className={`label ${value ? 'selected' : ''}`}>{label}</label>}
      <button
        id={dropdownId}
        className={`btn  btn-verification-outline dropdown-toggle ${variant} bg-white ${
          fillWidth ? 'w-100' : ''
        }`}
        style={{ height }}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="true"
        disabled={disabled || !items.length}
      >
        <div className={`text ${label && value ? 'selected' : ''}`}>
          {selectedValue ? selectedValue.label : placeholder}
        </div>

        <div className="toggle-icon">
          <ArrowIconSvg />
        </div>
      </button>

      <div
        className={`dropdown-menu ${variant} ${fillWidth ? 'w-100' : ''}`}
        id={dropdownId ? `${dropdownId}.dropdown` : ''}
      >
        {items.map((item, index) => (
          <div key={item.value}>
            <button
              id={dropdownId ? `${dropdownId}.${item.value}` : ''}
              className={`${
                value && item.value === value ? 'semi-bold' : ''
              } dropdown-item btn-default-outline`}
              type="button"
              disabled={!item.value}
              onClick={() => onClick(item.value)}
            >
              <div className={'double-line'}>{item.label}</div>
            </button>
            {index < items.length - 1 && <div className="dropdown-divider" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabeledDropdown;
