// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import style from './UnlimitedStudioSection.module.scss';
import { useNavigate } from 'react-router-dom';

const UnlimitedStudioSection = () => {
  const navigate = useNavigate();

  return (
    <div className={style.mainContainer}>
      <div className="row text-center">
        <div>
          <h3 id="more-than-studio" className="text-dark fw-bold">
            <FormattedMessage id="welcome.welcomeFree.sportSearch.title" />
          </h3>

          <div className="mt-3">
            <p className="d-inline-block welcome-title-content mb-4 text-dark">
              <FormattedMessage id="welcome.welcomeFree.sportSearch.body" />
            </p>
          </div>
        </div>
      </div>

      <EdsAlert type="info">
        <FormattedMessage id="welcome.welcomeFree.sportSearch.infoAlertTitle">
          {text => <p className={style.alertInfo}>{text}</p>}
        </FormattedMessage>
        <FormattedMessage
          id="welcome.welcomeFree.sportSearch.infoAlert"
          values={{
            button: text => (
              <button onClick={() => navigate(-1)} className={'link-button'}>
                {text}
              </button>
            ),
          }}
        />
      </EdsAlert>
    </div>
  );
};

export default injectIntl(UnlimitedStudioSection);
