import { COMPANY_ADMIN } from '../common/authentication/authenticationConstants';

export const prepareEditUserRolePayloads = (userAuthorityCompanyDetails, roles) => {
  const companyRoles = Array.isArray(userAuthorityCompanyDetails)
    ? userAuthorityCompanyDetails
    : [];
  const rolesObject =
    typeof roles === 'object' && !Array.isArray(roles) && roles !== null ? roles : {};

  const deletePayload = prepareEditUserRoleDeletePayload(companyRoles, rolesObject);
  const addPayload = prepareEditUserRoleAddPayload(companyRoles, rolesObject);

  return { deletePayload, addPayload };
};

const prepareEditUserRoleAddPayload = (companyRoles, rolesObject) => {
  const companyRolesObj = companyRoles.reduce((acc, cur) => {
    acc[cur.entityId] = cur.authority;
    return acc;
  }, {});
  const companyRolesToAdd = Object.keys(rolesObject)
    .map(entityId => ({
      entityId,
      authority: rolesObject[entityId],
    }))
    .filter(({ entityId, authority }) => authority !== companyRolesObj[entityId]);

  const addPayloadMap = companyRolesToAdd.reduce((acc, cur) => {
    if (!acc[cur.authority]) {
      acc[cur.authority] = [];
    }
    acc[cur.authority].push(cur.entityId);

    return acc;
  }, []);

  return Object.keys(addPayloadMap)
    .map(authority => ({
      authority,
      entityIds: addPayloadMap[authority],
    }))
    .sort(obj => (obj.authority === COMPANY_ADMIN ? -1 : 1));
};

const prepareEditUserRoleDeletePayload = (companyRoles, rolesObject) => {
  const companyRolesToRemove = companyRoles.filter(
    ({ entityId, authority }) => authority !== rolesObject[entityId]
  );

  const deletePayloadMap = companyRolesToRemove.reduce((acc, cur) => {
    if (!acc[cur.authority]) {
      acc[cur.authority] = [];
    }
    acc[cur.authority].push(cur.entityId);

    return acc;
  }, []);

  return Object.keys(deletePayloadMap)
    .map(authority => ({
      authority,
      entityIds: deletePayloadMap[authority],
    }))
    .sort(obj => (obj.authority === COMPANY_ADMIN ? -1 : 1));
};
