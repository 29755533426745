// @flow
import type { AuthenticatedUser, UserRole } from 'ui/common/authentication/types';
import { COMPANY_ADMIN, COMPANY_EDITOR } from 'ui/common/authentication/authenticationConstants';

export const isCompanyUser = (user: ?AuthenticatedUser) => user?.roles.some(isCompanyRole) || false;

export const getAccessibleCompaniesForCompanyUser = (user: AuthenticatedUser) => {
  const entityIds = user.roles.reduce((acc, role) => {
    if (isCompanyRole(role)) {
      acc.push(...role.entityIds);
    }
    return acc;
  }, []);

  return [...new Set(entityIds)];
};

/**
 * Tells if the user is Authorized to view a certain resource/page.
 * In order to view the page, the User needs to have at least one of the Allowed Roles.
 */
export const isAuthorized = (user: AuthenticatedUser, allowedRoles: ?Array<string>) => {
  if (!allowedRoles || allowedRoles.length === 0) {
    return true;
  }
  const filteredRoles = user.roles.filter(
    role => allowedRoles && allowedRoles.indexOf(role.authority) >= 0
  );
  return !!(filteredRoles && filteredRoles.length > 0);
};

export const isCompanyRole = (role: UserRole) =>
  role.authority === COMPANY_ADMIN || role.authority === COMPANY_EDITOR;
