// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatCurrency } from 'service/currencyUtil';
import CompanyLogo from 'ui/common/components/company-logo/CompanyLogo';
import style from './IntroSection.module.scss';

type Props = {
  logo: ?string,
  name: ?string,
  price: ?number,
  currency: ?string,
  intl: {
    locale: string,
  },
  customDescription: ?string,
  isPartnerSignup?: boolean,
};

function createMarkup(input) {
  return { __html: input };
}

const IntroSection = ({
  logo,
  name,
  price,
  currency,
  intl: { locale },
  customDescription,
  isPartnerSignup,
}: Props) => (
  <div className="row justify-content-center ie-fix">
    <div className="col-md-10 text-center text-white align-self-center margins">
      {logo && <CompanyLogo logo={logo} />}
      <div className="container justify-content-center welcome-title-container">
        <div className="col-md-12">
          <h1
            className={`fw-bold text-white text-break ${style.headlineMargin}`}
            style={{ whiteSpace: 'pre-line' }}
          >
            <FormattedMessage id="welcome.intro.title" />
          </h1>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <div className="welcome-title-content" style={{ whiteSpace: 'pre-line' }}>
              {price ? (
                <FormattedMessage
                  id={`welcome.intro.${
                    isPartnerSignup ? 'plusOneDescriptionHtmlWithPrice' : 'descriptionHtmlWithPrice'
                  }`}
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    name: name,
                    price: formatCurrency(locale, price, currency),
                  }}
                />
              ) : (
                <FormattedMessage
                  id={`welcome.intro.${
                    isPartnerSignup
                      ? 'plusOneDescriptionHtmlWithoutPrice'
                      : 'descriptionHtmlWithoutPrice'
                  }`}
                  values={{
                    name: name,
                  }}
                />
              )}
            </div>
          </div>
          {customDescription ? (
            <div className="alert custom-offer">
              <div dangerouslySetInnerHTML={createMarkup(customDescription)} />
            </div>
          ) : null}
          <button
            id="selfsignup.btn.register.header"
            className="btn btn-lg btn-primary btn-shadow"
            type="submit"
          >
            <FormattedMessage id="welcome.intro.button" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default injectIntl(IntroSection);
