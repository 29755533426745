// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { withPageTemplate } from 'ui/layout/PageTemplate';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import { get, post } from 'service/http/http-client';
import EgymIdBanner from 'ui/common/EgymIdBanner';
import PaymentUpdateInfoModal from '../payment/PaymentUpdateInfoModal';
import { useEffect } from 'react';
import { PAYMENT_MODAL_QUERY_PARAM, PAYMENT_UPDATE_MODAL_KEY } from '../payment/PaymentConstants';

const DEFAULT_LOGIN_LANDING_PAGE = '/login/success';

const Login = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const { isAuthenticated, login } = React.useContext(AuthenticationContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [paymentModalCache, setPaymentModalCache] = React.useState(
    JSON.parse(localStorage.getItem(PAYMENT_UPDATE_MODAL_KEY))
  );
  const location = useLocation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const locationState = location.state;
    if (
      locationState &&
      locationState.redirectUrl &&
      locationState.redirectUrl.pathname === '/payment-details' &&
      locationState.redirectUrl.search.includes(PAYMENT_MODAL_QUERY_PARAM)
    ) {
      const chargePeriod = new URLSearchParams(locationState.redirectUrl.search).get(
        PAYMENT_MODAL_QUERY_PARAM
      );
      if (!paymentModalCache || paymentModalCache.chargePeriod !== chargePeriod) {
        const data = { chargePeriod: chargePeriod, show: true };
        localStorage.setItem(PAYMENT_UPDATE_MODAL_KEY, JSON.stringify(data));
        setPaymentModalCache(data);
      }
    }
  }, [location, paymentModalCache]);

  const onPaymentInfoModalClose = () => {
    const data = { ...paymentModalCache, show: false };
    localStorage.setItem(PAYMENT_UPDATE_MODAL_KEY, JSON.stringify(data));
    setPaymentModalCache(data);
  };

  const tryToLogin = event => {
    event.preventDefault();

    return executeWithSpinner(
      (async () => {
        const postBody = {
          username: username,
          password: password,
        };

        try {
          const loginResp = await post('/v1/login', postBody);
          if (!loginResp.ok && loginResp.status === 401) {
            setErrorMessage('validation.login.invalidUsernameOrPassword');
            return;
          } else if (!loginResp.ok) {
            setErrorMessage('validation.general.serverError');
            return;
          }

          const userProfileResp = await get('/v1/user-profile');
          if (!userProfileResp.ok) {
            setErrorMessage('validation.login.invalidUsernameOrPassword');
            return;
          }
          const userProfile = await userProfileResp.json();

          const membershipsResp = await get('/v1/user/current/membership-and-offer');
          if (!membershipsResp.ok) {
            setErrorMessage('validation.general.serverError');
            return;
          }
          const memberships = await membershipsResp.json();

          login(userProfile, !!memberships.length);
        } catch (error) {
          setErrorMessage('validation.general.serverError');
        }
      })()
    );
  };

  // If already authenticated, go to the welcome page or the page that was originally requested
  if (isAuthenticated()) {
    const { pathname } = location.state || {
      pathname: DEFAULT_LOGIN_LANDING_PAGE,
    };
    const { search } = location.state || {
      search: '',
    };

    return <Navigate to={pathname + search} />;
  }

  return (
    <div className="container">
      <h2 className="mb-3 mt-5 text-dark">
        <FormattedMessage id="loginPage.title" />
      </h2>
      <div className="mb-3">
        <EgymIdBanner textId={'egymId.banner.loginPageText'} />
      </div>
      <form onSubmit={tryToLogin}>
        <div className="form-floating mb-3">
          <input
            type="text"
            id="username"
            value={username}
            placeholder={formatMessage({ id: 'loginPage.field.username.label' })}
            autoComplete="username"
            className="form-control"
            onChange={e => setUsername(e.target.value)}
          />
          <label htmlFor="username" className={username ? 'label-touched' : ''}>
            <FormattedMessage id="loginPage.field.username.label" />
          </label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="password"
            id="password"
            value={password}
            placeholder={formatMessage({ id: 'loginPage.field.password.label' })}
            className="form-control"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <label htmlFor="password" className={password ? 'label-touched' : ''}>
            <FormattedMessage id="loginPage.field.password.label" />
          </label>
        </div>
        {errorMessage && (
          <div className="alert alert-danger">
            <FormattedMessage id={errorMessage} />
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-4 mb-4 d-grid">
            <button type="submit" className="btn btn-primary" id="loginPage.loginButton">
              <FormattedMessage id="loginPage.loginButton" />{' '}
              <i className="ms-2 fas fa-arrow-right" />
            </button>
          </div>
          <div className="col-12 col-sm-8 text-end">
            <Link id="loginPage.forgotPassword" to="/password-reset">
              <FormattedMessage id="loginPage.forgotPassword" />
            </Link>
          </div>
        </div>
        {paymentModalCache && paymentModalCache.show && (
          <PaymentUpdateInfoModal isOpen={true} onModalClose={() => onPaymentInfoModalClose()} />
        )}
      </form>
    </div>
  );
};

export default injectIntl(withPageTemplate(Login, { headerTitle: 'loginPage.headerTitle' }));
