import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import phoneImage from 'styles/img/success/wellpass-success-phone.png';
import qrCode from 'styles/img/success/wellpass-success-qr-code.png';
import theme from '../../theme/theme';
import styles from './SelfSignupSuccessPage.module.scss';
import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import { Link } from 'react-router-dom';

type UserData = {
  membershipStartDate: string,
  firstName: string,
  email: string,
  doiConfirmed?: boolean,
};

type Props = {
  userData: UserData,
  offer: Object,
};

const SelfSignupSuccessPage = ({
  userData: { firstName, email, membershipStartDate, doiConfirmed },
  offer,
}: Props) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="container container-max-width success-page mb-4" ref={topRef}>
      <div className="mt-5">
        <EdsAlert type="success">
          <FormattedMessage id={`signupSuccessPage.successAlert`} />
        </EdsAlert>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-8">
          <div className="my-4">
            <div>
              <h3 id="signupSuccessPage.title" className="header-text mt-4 mb-3">
                <FormattedMessage id="signupSuccessPage.title" values={{ firstName: firstName }} />
              </h3>
            </div>
            <div className="border p-4 overview-container">
              <h4 className="overview-title">
                <FormattedMessage id={`signupSuccessPage.overViewCard.title`} />
              </h4>
              <div>
                <FormattedMessage id={`signupSuccessPage.overViewCard.membership`} />
              </div>
              <span className="semi-bold primary-text">{membershipStartDate}</span>
              <div className="mt-2">
                <FormattedMessage id={`signupSuccessPage.overViewCard.egymId`} />
              </div>
              <span className="semi-bold primary-text overflow-wrap">{email}</span>
              {!doiConfirmed && (
                <div className="mt-3">
                  <EdsAlert type="info">
                    <FormattedMessage
                      id={`signupSuccessPage.infoAlert`}
                      values={{
                        a: chunks => (
                          <a
                            className="anchor-text"
                            href={'mailto:' + formatMessage({ id: 'general.wellpassInfoMail' })}
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </EdsAlert>
                </div>
              )}
            </div>
            {offer?.type !== 'FREE' && (
              <div className="mt-4">
                <h3 className="header-text">
                  <FormattedMessage id={`signupSuccessPage.steps.headline`} />
                </h3>
                <div className="my-4 ms-4">
                  <div className="d-flex ">
                    <div className="numberCircle">1</div>
                    <div className="d-flex align-items-center body-text ms-3">
                      <FormattedMessage id={`signupSuccessPage.steps.step1`} />
                    </div>
                  </div>
                  <div className="vertical-line ms-3"></div>
                  <div className="d-flex ">
                    <div className="numberCircle">2</div>
                    <div className="d-flex align-items-center body-text ms-3">
                      <FormattedMessage id={`signupSuccessPage.steps.step2`} />
                    </div>
                  </div>
                  <div className="vertical-line ms-3"></div>
                  <div className="d-flex ">
                    <div className="numberCircle">3</div>
                    <div className="d-flex align-items-center body-text ms-3">
                      <FormattedMessage id={`signupSuccessPage.steps.step3`} />
                    </div>
                  </div>
                  <div className="vertical-line ms-3"></div>
                  <div className="d-flex ">
                    <div className="numberCircle">4</div>
                    <div className="d-flex align-items-center body-text ms-3">
                      <FormattedMessage id={`signupSuccessPage.steps.step4`} />
                    </div>
                  </div>
                </div>
                <p className="body-text pb-md-4">
                  <FormattedMessage id={`signupSuccessPage.feesInfo`} />
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="d-flex justify-content-center">
            <img className={`mb-2 ${styles.mobileImage}`} src={phoneImage} alt="app" />
          </div>
          {isMobile ? (
            <div className="d-block d-md-none text-center mb-5">
              <Link
                to="https://qobe.app.link/I1S7tjO3xQ"
                className="btn btn-secondary download-btn"
                target="_blank"
              >
                <FormattedMessage id="signupSuccessPage.button" />
              </Link>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-2">
              <div className={`large-bold ${styles.qrcodeText}`}>
                <FormattedMessage id="signupSuccessPage.qrCode" />
              </div>
              <img className={styles.qrcodeImage} src={qrCode} alt="qrCode to Wellpass app" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfSignupSuccessPage;
