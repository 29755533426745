// @flow
import * as React from 'react';
import Header from 'ui/layout/Header';
import Footer from 'ui/layout/Footer';

type PageTemplateProps = {
  headerTitle: string,
};

type Props = {
  children: any,
  headerTitle?: string,
  isProtected?: boolean,
  hasCompanyDropdown?: boolean,
  displayDetails?: boolean,
};

const PageTemplate = ({
  children,
  headerTitle,
  isProtected = false,
  hasCompanyDropdown = false,
  displayDetails = true,
}: Props) => (
  <div className="d-flex flex-column min-vh-100">
    {headerTitle && (
      <Header
        headerTitle={headerTitle}
        isProtected={isProtected}
        hasCompanyDropdown={hasCompanyDropdown}
      />
    )}
    <main className="flex-grow-1">{children}</main>
    <Footer displayDetails={displayDetails} />
  </div>
);

export const withPageTemplate = (
  Component: any,
  pageTemplateProps: PageTemplateProps,
  Header?: any
) => {
  const Wrapper = (props: any) => (
    <>
      {Header && <Header />}
      <PageTemplate {...pageTemplateProps} {...props}>
        <Component {...props} />
      </PageTemplate>
    </>
  );

  Wrapper.displayName = `withPageTemplate(${Component.displayName || Component.name})`;

  return Wrapper;
};
