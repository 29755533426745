// @flow
import * as React from 'react';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import { FormattedMessage } from 'react-intl';

const LoginNoEmailVerification = () => {
  const { user } = React.useContext(AuthenticationContext);
  return (
    <div className="container mt-5">
      <h2 className="my-5 text-dark">
        <FormattedMessage id="loginNoEmailVerification.title" />
      </h2>
      <p>
        <FormattedMessage
          id="loginNoEmailVerification.welcomeText"
          values={{ firstName: user.firstName, lastName: user.lastName }}
        />
      </p>
      <p>
        <FormattedMessage id="loginNoEmailVerification.description" />
      </p>
    </div>
  );
};

export default withPageTemplate(LoginNoEmailVerification, {
  headerTitle: 'loginNoEmailVerification.headerTitle',
});
