import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TextFormField from 'ui/common/components/TextFormField';
import { withPageTemplate } from 'ui/layout/PageTemplate';
import { extractRequestParameter } from 'service/http/requestUtil';
import { FORM_ERROR } from 'final-form';
import { httpPost, post } from 'service/http/http-client';
import { VALIDATION_REPEAT_PASSWORD_NOT_MATCH } from 'validation/common/errorCodes';
import { validateRequiredWithLength } from 'validation/common/commonValidators';
import { convertUserServiceResponse } from 'service/http/responseConverter';
import SubmitErrors from 'ui/common/CommonSubmitErrors';
import SpinnerContext from 'ui/common/spinner/SpinnerContext';
import NewPasswordErrorPage from 'ui/password-reset/NewPasswordErrorPage';
import LinkToPasswordResetPage from 'ui/password-reset/LinkToPasswordResetPage';
import EgymIdBanner from 'ui/common/EgymIdBanner';
import PropTypes from 'prop-types';
import Form from 'ui/common/components/Form';

/**
 * Checks whether there is a validation error regarding the token.
 */
const needsNewPasswordResetToken = submitError => {
  return (
    submitError &&
    submitError.find(
      error =>
        error.code === 'validation.general.token.timeout' ||
        error.code === 'validation.general.token.notFound'
    )
  );
};

const NewPasswordPage = () => {
  const [passwordResetToken] = React.useState(extractRequestParameter('token'));
  const [submitError, setSubmitError] = React.useState(0);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const validateNewPassword = values => {
    const errors = {};
    errors.newPassword = validateRequiredWithLength(8, 72)(values.newPassword);
    errors.repeatPassword = validateRequiredWithLength(8, 72)(values.repeatPassword);
    if (values.newPassword && values.repeatPassword) {
      if (values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = { code: VALIDATION_REPEAT_PASSWORD_NOT_MATCH };
      }
    }
    return errors;
  };

  /**
   * Checks whether the password reset token is valid when opening the page.
   *
   * If the token is not valid, we don't show the fields for setting a new password.
   * Instead, we just show an error and a link to generate new token.
   */
  React.useEffect(() => {
    const checkTokenValidity = async () => {
      const postBody = { token: passwordResetToken };
      try {
        await httpPost(`/v1/new-password/check-token`, postBody);
        // do nothing here if token is valid (i.e. just let the user change her password)
      } catch (serverResponse) {
        const tokenCheckError = convertUserServiceResponse(serverResponse);
        setSubmitError(tokenCheckError[FORM_ERROR]);
      }
    };
    executeWithSpinner(checkTokenValidity());
  }, [executeWithSpinner, passwordResetToken]);

  const onSubmit = values => {
    const postBody = { newPassword: values.newPassword, tempToken: passwordResetToken };
    return executeWithSpinner(
      post('/v1/new-password', postBody)
        .then(response => {
          if (response.ok) {
            navigate('/new-password/success');
          } else if (response.status === 422) {
            return response.json().then(jsonResponse => {
              return convertUserServiceResponse(jsonResponse);
            });
          } else {
            return response.text().then(errorResponse => {
              return { [FORM_ERROR]: errorResponse };
            });
          }
        })
        .catch(errorMessage => {
          return { [FORM_ERROR]: errorMessage };
        })
    );
  };

  if (submitError) {
    return <NewPasswordErrorPage error={submitError} />;
  } else {
    return (
      <Form onSubmit={onSubmit} validator={validateNewPassword}>
        {({ register, control, formState: { errors, isSubmitting, isDirty } }) => (
          <div className="container">
            <h5 className="text-start mb-4 mt-4">
              <FormattedMessage id="newPasswordPage.aboveBody" />
            </h5>

            <div className="text-start mt-4">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextFormField
                    register={{ ...register('newPassword') }}
                    control={control}
                    controlId="newPassword"
                    autoComplete="new-password"
                    controlType="password"
                    controlLabel="general.field.newPassword.label"
                    placeholder="general.field.newPassword.placeholder"
                    containerClassName="container p-0 mb-3"
                  />
                </div>
                <div className="col-12 col-sm-6" />
                <div className="col-12 col-sm-6">
                  <TextFormField
                    register={{ ...register('repeatPassword') }}
                    control={control}
                    controlId="repeatPassword"
                    autoComplete="new-password"
                    controlType="password"
                    controlLabel="general.field.repeatPassword.label"
                    placeholder="general.field.repeatPassword.placeholder"
                    containerClassName="container p-0"
                  />
                </div>
                <div className="col-12 col-sm-6" />
              </div>
            </div>
            {errors.length > 0 && <SubmitErrors submitError={errors} />}
            {needsNewPasswordResetToken(submitError) ? (
              <LinkToPasswordResetPage />
            ) : (
              <>
                <div className="text-start mt-2">
                  <small>
                    <FormattedMessage id="newPasswordPage.passwordRules" />
                  </small>
                </div>

                <p className="mt-2 mb-0">
                  <FormattedMessage
                    id="accountSettingsPage.privacy.reference.text"
                    values={{
                      link: (
                        <a
                          href={formatMessage({ id: 'accountSettingsPage.privacyPolicyLink.url' })}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-muted text-underlined"
                        >
                          <FormattedMessage id="accountSettingsPage.privacyPolicyLink.text" />
                        </a>
                      ),
                    }}
                  />
                </p>

                <div className="text-start mb-4">
                  <EgymIdBanner textId={'egymId.banner.newPasswordPageText'} />
                </div>
                <div className="submit-btn mb-3">
                  <button
                    type="submit"
                    id="newPasswordPage.resetPasswordBtn"
                    className="btn btn-primary"
                    disabled={isSubmitting || !isDirty}
                  >
                    <FormattedMessage id="newPasswordPage.resetPasswordBtn" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </Form>
    );
  }
};

NewPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withPageTemplate(NewPasswordPage, { headerTitle: 'newPasswordPage.headerTitle' });
