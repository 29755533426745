import React from 'react';
import Menu from '@mui/material/Menu';
import 'styles/components/profile-card.scss';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import { EdsProfilePicture } from '@egym/react-design-system/EdsProfilePicture';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { FormattedMessage } from 'react-intl';

export type ProfileCardProps = {
  anchorEl: HTMLElement | null,
  handleClose: () => void,
  onLogout: () => void,
};
const ProfileCard = ({ anchorEl, handleClose, onLogout }: ProfileCardProps) => {
  const { user } = React.useContext(AuthenticationContext);
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      id="profile-card"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            borderRadius: '24px',
            // copied from figma
            boxShadow:
              '0px 4px 8px 3px rgba(168, 168, 168, 0.15), 0px 1px 3px 0px rgba(168, 168, 168, 0.30)',
            mt: {
              xs: 4,
              sm: 1,
              md: 1,
              lg: 1,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className="theme-wellpass">
        <div id="profileCardContainer" className="profile-card-container">
          <EdsProfilePicture
            type="monogram"
            size="large"
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
          />
          <div className="profile-card-text-container">
            <h4 className="profile-card-name">{`${user?.firstName || ''} ${
              user?.lastName || ''
            }`}</h4>
            <span className="profile-card-email">{user?.email || ''}</span>
          </div>

          <button
            id="profileCardContainer.logout"
            className="btn btn-outline-dark d-flex align-items-center gap-1"
            type="button"
            onClick={onLogout}
          >
            <EdsIcon className="logout-button-icon">logout</EdsIcon>
            <FormattedMessage id="logout" />
          </button>
        </div>
      </div>
    </Menu>
  );
};

export default ProfileCard;
