// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CommonSubmitErrors from 'ui/common/CommonSubmitErrors';
import LinkToPasswordResetPage from 'ui/password-reset/LinkToPasswordResetPage';
import { needsNewPasswordResetToken } from 'ui/password-reset/passwordResetUtil';

type Props = {
  error: any,
};

const NewPasswordErrorPage = ({ error }: Props) => (
  <div className="container" id="newPasswordPage.errorPage.message">
    <h2 className="text-center text-dark my-5">
      <FormattedMessage id="newPasswordPage.errorPage.title" />
    </h2>
    <CommonSubmitErrors submitError={error} />
    {needsNewPasswordResetToken(error) && <LinkToPasswordResetPage />}
  </div>
);

export default NewPasswordErrorPage;
