import React from 'react';
import { TableHeaderCellWithTruncation } from '../admin/companies/memberships/TableHeaderCell';

type Props = {
  companies: [],
  left: React.ReactNode,
  right: React.ReactNode,
};

const TableHeaderRow = ({ companies, left, right }: Props) => {
  if (companies?.length > 1) {
    return (
      <tr>
        {left}
        {companies.map(company => (
          <TableHeaderCellWithTruncation
            id={company.entityId}
            key={company.entityId}
            text={company.entityName}
          />
        ))}
        {right}
      </tr>
    );
  }
  return null;
};

export default TableHeaderRow;
