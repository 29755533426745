// @flow
import React from 'react';

type Props = {
  url: string,
  text?: string,
  id?: string,
  styleClass?: string,
};

/**
 * Renders an HTML Anchor tag which will open the link in a new browser tab.
 */
const ExternalLink = ({ url, text = url, id = '', styleClass }: Props) => (
  <a className={styleClass} href={url} target="_blank" rel="noopener noreferrer" id={id}>
    {text}
  </a>
);

export default ExternalLink;
