// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import GymFinderSection from './GymFinderSection';

const GymMapSection = () => (
  <div>
    <h3 id="studios-map" className="text-center fw-bold text-break text-dark mt-5">
      <FormattedMessage id="welcome.gymMap.title" />
    </h3>
    <GymFinderSection />
  </div>
);

export default injectIntl(GymMapSection);
