import React, { useState } from 'react';
import { useAlertNotification } from './reducers/useAlertNotification';
import {
  DECLARATION_OF_CONSENT_ALERT_STATUS,
  useDeclarationOfConsent,
} from './reducers/useDeclarationOfConsent';

type Props = {
  children: any,
};

const RegistrationContext = React.createContext();

const RegistrationProvider = ({ children }: Props) => {
  const [isNewUser, setIsNewUser] = useState(true);
  const [duplicateSignUpWarning, setDuplicateSignupWarning] = useState();
  const [registerAndProceedToggle, setRegisterAndProceedToggle] = useState(true);
  const [existingUser, setExistingUser] = useState({});
  const [isUserDetailsModified, setIsUserDetailsModified] = useState(false);
  const [addressPrediction, setAddressPrediction] = useState(undefined);
  const [resolvedAddress, setResolvedAddress] = useState(undefined);
  const [employeeEligibilityStatus, setEmployeeEligibilityStatus] = useState({});
  const [workEmail, setWorkEmail] = useState();
  const [corporateEmployeeDetails, setCorporateEmployeeDetails] = useState();
  const [availableAdditionalVerificationOptions, setAvailableAdditionalVerificationOptions] =
    useState([{ type: 'WORK_EMAIL', description: '' }]);
  const [additionalVerification, setAdditionalVerification] = useState({
    type: '',
    value: '',
    showField: false,
  });
  const [hasValidMembershipStartDates, setHasValidMembershipStartDates] = useState(false);
  const [selectedVerificationField, setSelectedVerificationField] = useState();
  const [declarationOfConsentDetails, setDeclarationOfConsentDetails] = useDeclarationOfConsent({
    modalIsOpen: false,
    files: [],
    confirmed: false,
    status: DECLARATION_OF_CONSENT_ALERT_STATUS.WARNING,
  });
  const [alertNotification, setAlertNotification] = useAlertNotification({
    birthday: {
      warning: undefined,
    },
    reviewedBirthday: {
      warning: undefined,
    },
    duplicateSignUp: {
      warning: undefined,
    },
  });

  return (
    <RegistrationContext.Provider
      value={{
        isNewUser: isNewUser,
        setIsNewUser: setIsNewUser,
        existingUser: existingUser,
        setExistingUser: setExistingUser,
        isUserDetailsModified: isUserDetailsModified,
        setIsUserDetailsModified: setIsUserDetailsModified,
        addressPrediction: addressPrediction,
        setAddressPrediction: setAddressPrediction,
        employeeEligibilityStatus: employeeEligibilityStatus,
        setEmployeeEligibilityStatus: setEmployeeEligibilityStatus,
        registerAndProceedToggle: registerAndProceedToggle,
        setRegisterAndProceedToggle: setRegisterAndProceedToggle,
        resolvedAddress: resolvedAddress,
        setResolvedAddress: setResolvedAddress,
        alertNotification: alertNotification,
        setAlertNotification: setAlertNotification,
        workEmail: workEmail,
        setWorkEmail: setWorkEmail,
        declarationOfConsentDetails: declarationOfConsentDetails,
        setDeclarationOfConsentDetails: setDeclarationOfConsentDetails,
        duplicateSignUpWarning: duplicateSignUpWarning,
        setDuplicateSignupWarning: setDuplicateSignupWarning,
        corporateEmployeeDetails: corporateEmployeeDetails,
        setCorporateEmployeeDetails: setCorporateEmployeeDetails,
        hasValidMembershipStartDates: hasValidMembershipStartDates,
        setHasValidMembershipStartDates: setHasValidMembershipStartDates,
        availableAdditionalVerificationOptions: availableAdditionalVerificationOptions,
        setAvailableAdditionalVerificationOptions: setAvailableAdditionalVerificationOptions,
        selectedVerificationField: selectedVerificationField,
        setSelectedVerificationField: setSelectedVerificationField,
        additionalVerification: additionalVerification,
        setAdditionalVerification: setAdditionalVerification,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

const RegistrationConsumer = RegistrationContext.Consumer;
export { RegistrationProvider, RegistrationConsumer };
export default RegistrationContext;
