import { useRef } from 'react';
import { CancellationReason } from 'service/membershipUtil';

export const useAutoPopulateCancelMembershipForm = ({
  immediateEffectValue,
  defaultCancellationReason,
}) => {
  const membershipEndDate = useRef();
  const membershipCancellationReasonRef = useRef(defaultCancellationReason);

  return (watchField, setValue) => {
    const membershipEndDateWatched = watchField[0];
    const membershipCancellationReasonWatched = watchField[1];

    if (
      membershipEndDateWatched === immediateEffectValue &&
      membershipEndDate.current !== membershipEndDateWatched
    ) {
      setValue('membershipCancellationReason', CancellationReason.B2C_REJECT_MEMBERSHIP, {
        shouldValidate: true,
      });
    } else if (
      membershipEndDateWatched !== immediateEffectValue &&
      membershipEndDate.current === immediateEffectValue &&
      membershipCancellationReasonRef.current === CancellationReason.B2C_REJECT_MEMBERSHIP
    ) {
      setValue('membershipCancellationReason', undefined, {
        shouldValidate: true,
      });
    }

    if (
      membershipCancellationReasonWatched === CancellationReason.B2C_REJECT_MEMBERSHIP &&
      membershipCancellationReasonRef.current !== membershipCancellationReasonWatched
    ) {
      setValue('membershipEndDate', immediateEffectValue, {
        shouldValidate: true,
      });
    } else if (
      membershipCancellationReasonWatched !== CancellationReason.B2C_REJECT_MEMBERSHIP &&
      membershipCancellationReasonRef.current === CancellationReason.B2C_REJECT_MEMBERSHIP &&
      membershipEndDate.current === immediateEffectValue
    ) {
      setValue('membershipEndDate', undefined, {
        shouldValidate: true,
      });
    }

    membershipEndDate.current = membershipEndDateWatched;
    membershipCancellationReasonRef.current = membershipCancellationReasonWatched;
  };
};
