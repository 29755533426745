// @flow
export const isDev = () => {
  return process.env.NODE_ENV !== 'production';
};

export function findAndAppendQueryParameterToUrl(prevUrl, queryParameterName, queryParameterValue) {
  let splitUrl = prevUrl.split('?');
  let urlWithoutQueryString = splitUrl[0];
  let newQueryString = `${queryParameterName}=${queryParameterValue}`;

  if (splitUrl[1] && splitUrl[1].length > 0) {
    let queryString = splitUrl[1];
    newQueryString = `${removeLastCharacterIfReserved(queryString)}&${newQueryString}`;
  }

  return `${removeLastCharacterIfReserved(urlWithoutQueryString)}?${newQueryString}`;
}

function removeLastCharacterIfReserved(str) {
  let lastCharacter = str.slice(-1);
  if (lastCharacter === '?' || lastCharacter === '/' || lastCharacter === '&') {
    return str.slice(0, -1);
  }
  return str;
}
