import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import 'styles/components/company-settings-page.scss';
import AddCompanyUserDialog from './AddCompanyUserDialog';
import { post } from 'service/http/http-client';
import { Alert, Snackbar } from '@mui/material';
import { useCompanyManagers } from 'service/hooks/company';
import { COMPANY_ADMIN } from '../common/authentication/authenticationConstants';
import ManagerTable from './ManagerTable';
import EditCompanyUserDialog from './EditCompanyUserDialog';
import AuthenticationContext from '../common/authentication/AuthenticationContext';
import { prepareEditUserRolePayloads } from './utils';

const SNACKBAR_AUTO_HIDE_DURATION = 6000;

const CompanySettingsPage = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [managerToEdit, setManagerToEdit] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [emailOfAddedUser, setEmailOfAddedUser] = useState(null);
  const [emailOfEditedUser, setEmailOfEditedUser] = useState(null);
  const [hasApiError, setHasApiError] = useState(false);
  const { companies, managers, onManagerAdded, onManagerEdited } = useCompanyManagers();
  const { user } = useContext(AuthenticationContext);

  const displayError = () => {
    setHasApiError(true);
    setIsAddModalOpen(false);
  };

  const onSubmitCreateNewUser = async values => {
    setIsSubmitLoading(true);
    setEmailOfAddedUser(null);
    setHasApiError(false);
    try {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        groupedUserAuthorities: Object.keys(values.roles).map(entityId => ({
          authority: values.roles[entityId],
          entityIds: [entityId],
        })),
      };

      const response = await post('/v1/company/user', payload);

      if (response.ok) {
        setIsAddModalOpen(false);
        setEmailOfAddedUser(values.email);
        response
          .json()
          .then(json => onManagerAdded(json))
          .catch(() => {});
      } else {
        displayError();
      }
    } catch (e) {
      displayError();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const onSubmitEditUserRoles = async values => {
    setIsSubmitLoading(true);
    setEmailOfEditedUser(null);
    setHasApiError(false);
    try {
      const { roles } = values;
      const { userAuthorityCompanyDetails } = managerToEdit;

      const { deletePayload, addPayload } = prepareEditUserRolePayloads(
        userAuthorityCompanyDetails,
        roles
      );

      const payload = {
        groupedUserAuthoritiesToRemove: deletePayload,
        groupedUserAuthoritiesToAdd: addPayload,
      };
      const response = await post(`/v1/user/${managerToEdit.accountId}/role/bulk/update`, payload);

      setIsAddModalOpen(false);
      if (response.ok) {
        setEmailOfEditedUser(managerToEdit.email);
        onManagerEdited(managerToEdit, await response.json());
        setManagerToEdit(null);
      } else {
        displayError();
      }
    } catch (e) {
      displayError();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const isNotAdminForCompanies = useMemo(() => {
    if (!companies?.length) {
      return true;
    }
    return companies.filter(company => company.authority === COMPANY_ADMIN).length === 0;
  }, [companies]);

  return (
    <>
      <h2 id="companySettings.headerTitle.id" className="text-dark mb-4">
        <FormattedMessage id="companySettings.headerTitle" />
      </h2>
      <div className="mb-4 d-flex justify-content-between">
        {!isNotAdminForCompanies && (
          <h3 className="text-dark mb-0">
            <FormattedMessage id="companySettings.titles.managers" />
          </h3>
        )}

        <button
          id="companySettings.add.triggerButton"
          className="btn btn-outline-primary d-flex align-items-center px-4 gap-2"
          type="button"
          disabled={isNotAdminForCompanies}
          onClick={() => setIsAddModalOpen(true)}
        >
          <FormattedMessage id="companySettings.add.triggerButton" />
          <EdsIcon>add</EdsIcon>
        </button>
      </div>
      {isAddModalOpen && (
        <AddCompanyUserDialog
          opened={true}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmitCreateNewUser}
          isLoading={isSubmitLoading}
          companies={companies}
          managers={managers}
        />
      )}
      {!!managerToEdit && (
        <EditCompanyUserDialog
          opened={true}
          onClose={() => setManagerToEdit(null)}
          onSubmit={onSubmitEditUserRoles}
          isLoading={isSubmitLoading}
          companies={companies.map(company => ({
            ...company,
            isDisabled:
              (managerToEdit.accountId === user?.id &&
                managers.filter(manager =>
                  manager.userAuthorityCompanyDetails.find(
                    obj => obj.entityId === company.entityId && obj.authority === COMPANY_ADMIN
                  )
                ).length) === 1,
          }))}
          initialRoles={managerToEdit.userAuthorityCompanyDetails.reduce((acc, cur) => {
            acc[cur.entityId] = cur.authority;
            return acc;
          }, {})}
        />
      )}
      {!!(companies.length > 0 && managers.length > 0) && (
        <ManagerTable
          companies={companies}
          managers={managers}
          setManagerToEdit={setManagerToEdit}
        />
      )}
      <Snackbar
        open={!!emailOfAddedUser}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={() => setEmailOfAddedUser(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="success"
          sx={{
            padding: '12px 20px',
          }}
        >
          <FormattedMessage
            id="companySettings.add.successSnackbar"
            values={{
              email: emailOfAddedUser,
            }}
          />
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!hasApiError}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={() => setHasApiError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="error"
          sx={{
            padding: '12px 20px',
          }}
        >
          <FormattedMessage id="validation.general.genericError" />
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!emailOfEditedUser}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={() => setEmailOfEditedUser(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="success"
          sx={{
            padding: '12px 20px',
          }}
        >
          <FormattedMessage
            id="companySettings.edit.successSnackbar"
            values={{
              email: emailOfEditedUser,
            }}
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanySettingsPage;
