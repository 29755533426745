import React from 'react';
import { useIntl } from 'react-intl';
type Props = {
  text: string,
  children?: React.ReactNode,
};

const InfoToolTip = ({ text, children }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <div
      data-text={formatMessage({ id: text })}
      className={`justify-content-center tool-tip-container top`}
    >
      {children}
    </div>
  );
};

export default InfoToolTip;
