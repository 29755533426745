const messages = {
  welcome: {
    intro: {
      title: 'Eine Mitgliedschaft.\nUnzählige Möglichkeiten.',
      descriptionHtmlWithPrice:
        'Für monatlich <b>nur {price}</b> unbegrenzt Schwimmen, Fitness, Klettern, Yoga und mehr.\n' +
        'Ein exklusives Angebot für dich von {name}.',
      descriptionHtmlWithoutPrice:
        'Jetzt anmelden und unbegrenzt Schwimmen, Fitness, Klettern, Yoga und mehr.\n' +
        'Ein exklusives Angebot für dich von {name}.',
      plusOneDescriptionHtmlWithPrice:
        'Für monatlich <b>nur {price}</b> unbegrenzt Schwimmen, Fitness, Klettern, Yoga und mehr.\n' +
        'Ein exklusives Angebot für dich als eingeladenes Plus1 eines Wellpass Mitglieds.',
      plusOneDescriptionHtmlWithoutPrice:
        'Jetzt anmelden und unbegrenzt Schwimmen, Fitness, Klettern, Yoga und mehr.\n' +
        'Ein exklusives Angebot für dich als eingeladenes Plus1 eines Wellpass Mitglieds.',
      button: 'Jetzt anmelden',
      customOfferHeader: 'Sonderangebot',
      viewStudio: 'Studios finden',
    },
    wellpassInfo: {
      beActive: {
        title: 'Sei aktiv',
        description: 'Unbegrenzte Anzahl an Check-ins',
      },
      enjoyVariety: {
        title: 'Genieße Vielfalt',
        description: '{number} Studios in unserem Partnernetzwerk',
      },
      exclusiveOffer: {
        title: 'Exklusives Angebot',
        description: 'nur {price} monatlich, exklusiv für {name}',
        partnerDescription: '{price} monatlich, nur für Plus1 Mitglieder',
      },
      workoutEverywhere: {
        title: 'Trainiere überall',
        description: '+4000 Online Kurse für Fitness und Meditation',
      },
      flexibility: { title: 'Bleib flexibel', description: 'Monatlich kündbare Mitgliedschaft' },
    },
    studios: {
      moreThanStudioTitle: 'Mehr als nur ein klassisches Fitnessstudio',
      fitness: 'Fitness\nStudios',
      pool: 'Schwimm\nBäder',
      boulder: 'Boulder\nHallen',
      yoga: 'Yoga\nStudios',
      description:
        'Aufgrund der Vielfalt an Sporteinrichtungen, Sportangeboten und Kursen deckt Wellpass die individuellen sportlichen Bedürfnisse und Fitnesslevel aller Teilnehmer ab. Egal ob Fitness, Schwimmen, Yoga, Klettern oder Bouldern. Du entscheidest, was für dich das Richtige ist. Sollte dein Wunsch-Studio noch nicht dabei sein, schreib uns einfach an und wir kümmern uns darum.',
      search: 'zur Studio-suche',
    },
    gymMap: {
      title: 'Sport- & Fitnessstudios in deiner Nähe',
    },
    memberships: {
      title: 'Bereit für deine Wellpass Mitgliedschaft?',
    },
    card: {
      button: 'Jetzt anmelden',
      benefitsTitle: 'Vorteile',
      partnerOfferCard: {
        headline:
          'Die Plus1 Mitgliedschaft – <span>Trainiere überall und so oft du möchtest</span>',
        type: 'Plus1',
        typeTitle: 'Mitgliedschaft',
        price: 'nur <span>{price}</span> /Monat',
        benefits:
          '<span class="offer-card-benefit-title">Exklusiv für eingeladene Plus1 Mitglieder</span>Durch die Einladung eines Wellpass Firmenmitglieds, kannst nun auch du Wellpass nutzen, sofern dein Nachname oder Hauptwohnsitz mit dem des einladenden Firmenmitglieds übereinstimmt.\n' +
          '<span class="offer-card-benefit-title">Besuche alle Plus1 Standorte</span>Alle teilnehmenden Plus1 Standorte findest du in der Studiosuche.\n' +
          '<span class="offer-card-benefit-title">Unbegrenzte Check-ins</span>\n' +
          '<span class="offer-card-benefit-title">Wellpass App</span>Studio-Check-in Funktion, Check-in-Historie und das Online Angebot findest du in der App.\n' +
          '<span class="offer-card-benefit-title">Online Kurse und Meditationsangebot</span>\n' +
          '<span class="offer-card-benefit-title">Persönliches Einführungstraining für 44€</span>Direkt zahlbar beim ersten Studiobesuch.\n' +
          '<span class="offer-card-benefit-title">Monatlich kündbar</span>',
      },
    },
    faq: {
      title: 'Häufig gestellte Fragen',
      items: {
        howMembershipWorks: {
          question: 'Wie funktioniert die Wellpass Mitgliedschaft?',
          answer:
            '<p>Mit der EGYM Wellpass Mitgliedschaft kannst du alle {gymCount} Fitness- und Wellnesseinrichtungen besuchen, unser Online Angebot nutzen und dich durch Fitness Challenges motivieren lassen. EGYM Wellpass wird als Mitarbeiterbenefit von Unternehmen angeboten, die die Gesundheit und Fitness ihrer Mitarbeiter fördern möchten. Registriere dich über den Button ‘Jetzt anmelden’ auf dieser Seite und lade dir anschließend die Wellpass App herunter und tue etwas Gutes für deine Gesundheit. Es gibt keine Mindestlaufzeit und deine Wellpass Mitgliedschaft ist monatlich bis zum 15. zum Ende des Monats kündbar, falls sich deine Bedürfnisse ändern.</p>',
        },
        availableStudios: {
          question: 'Welche Studios darf ich besuchen?',
          answer:
            '<p>Du kannst alle Studios, die mit uns eine Partnerschaft eingegangen sind, besuchen. Mit deiner Wellpass Mitgliedschaft legst du dich nicht auf ein Studio fest. Wenn du willst, kannst du heute in ein Fitnessstudio gehen, morgen zum Schwimmen und am Wochenende bei einer Massage entspannen. Alle diese Studios findest du in unserer <anchor>Studiosuche</anchor> auf dieser Seite oder in unserer App. Wenn du gerne Zuhause trainierst, kannst du auch unsere <link>digitalen Angebote</link> nutzen. Klicke dazu in unserer App auf “Online Angebote” und nimm z.B. bei einer Runde Yoga teil oder nutze die Premium-Version unserer Partner Apps 7mind, Fitnessraum oder Lifesum.</p>',
        },
        howCheckInWorks: {
          question: 'Wie funktioniert der Check-in in den Studios?',
          answer:
            '<p>Bei jedem Besuch in einem Partner Studio musst du dich mit der Wellpass App einchecken. Hierfür scannst du einfach den Wellpass QR Code, der an der Theke steht und zeigst dem Studiopersonal das Ticket, das  auf deinem Smartphone erscheint. Manche Studios bieten zusätzlich Alternativen an, die auf der Studioprofilseite in der <anchor>Studiosuche</anchor> beschrieben sind (z.B. Aushändigung einer Studiokarte).</p>',
        },
        additionalCosts: {
          question: 'Gibt es zusätzliche Kosten?',
          answer:
            '<p>Um gewährleisten zu können, dass Fitnessgeräte ideal auf dich eingestellt sind und du mit Hilfe eines Trainingsplans deine Ziele erreichst, fällt in vielen Fitnessstudios eine Einweisungsgebühr an. Diese musst du in der Regel nur ein einziges Mal bei deinem ersten Fitnessstudiobesuch mit Wellpass bezahlen. Das Einweisungstraining kostet in der Regel 44€ inkl. MwSt. und ist bei einer regulären Studiomitgliedschaft normalerweise deutlich teurer.</p>' +
            '<p>In ca. 98% der Studios sind alle Leistungen durch deinen monatlichen Mitgliedsbeitrag abgedeckt. Bei vereinzelten hochpreisigen Angeboten musst du jedoch eine kleine finanzielle Zuzahlung tätigen. Dies ist z.B. bei Massage oder EMS Studios der Fall. Um herauszufinden, welche Leistungen zuzahlungspflichtig sind, klicke auf das Studio deiner Wahl. Unter der Studiobeschreibung (Wellpass Konditionen) erfährst du, welche Leistungen du kostenfrei nutzen kannst (s. Inklusivleistungen) und welche Leistungen zuzahlungspflichtig sind (s. Zuzahlung).</p>',
        },
        howSignupWorks: {
          question: 'Wie funktioniert die Anmeldung zu Wellpass?',
          answer:
            '<p>Nachdem du auf den Button “Jetzt anmelden” geklickt hast, werden wir dich bitten eine EGYM ID zu erstellen. Dazu benötigen wir deinen vollständigen Namen, dein Geburtsdatum, deine E-Mail-Adresse sowie ein Passwort. {isB2C, select, true {Um deinen Mitgliedschaftsbeitrag via SEPA Lastschriftverfahren abbuchen zu können, bitten wir dich zusätzlich deine Zahlungsdaten anzugeben.} other {}} Anschließend kannst du dir unsere Wellpass App im Apple oder Google Store herunterladen und dich mit deiner E-Mail Adresse und deinem Passwort, welches du im Anmeldeprozess festgelegt hast, einloggen. </p>' +
            '<p>Bevor deine Mitgliedschaft startet, wird deine Teilnahmeberechtigung i.d.R. von deiner Personalabteilung geprüft. Du erhältst kurz vor Mitgliedschaftsstart eine Willkommensmail, in der die wichtigsten Informationen für einen idealen Start mit Wellpass zusammengefasst sind. Deiner Personalabteilung werden lediglich dein Name, dein Geburtsdatum und das Startdatum deiner Mitgliedschaft angezeigt.</p>',
        },
        howPlusOneWorks: {
          question:
            'Können sich andere Personen für Wellpass anmelden, die nicht in meiner Firma arbeiten?',
          answer:
            '<p>Du kannst eine weitere Person außerhalb deines Unternehmens dazu einladen, Wellpass für 49,90€ im Monat zu nutzen. Diese weitere Person nennen wir Plus1. Teilnahmevorraussetzung ist, dass diese Person entweder denselben Nachnamen oder Hauptwohnsitz wie du hat.</p>' +
            '<p>Genau wie du, kann dein Plus1 unbegrenzt oft in unserem Wellpass Netzwerk trainieren. Über 90% unserer Studios bieten Zugang für Plus1 Mitglieder. Um herauszufinden, welche Studios dies sind, kannst du in der <anchor>Studiosuche</anchor> den Filter Plus1 aktivieren (s. “Sport- & Fitnessstudios in deiner Nähe” auf dieser Seite). Die Plus1 Mitgliedschaft ist ebenfalls monatlich bis zum 15. zum Ende des Monats kündbar. Wenn du eine Person als dein Plus1 dazu einladen möchtest, kannst du nach deiner Anmeldung über unsere App eine Online-Einladung versenden.</p>',
        },
      },
    },
    welcomeFree: {
      introSection: {
        headline: 'Kostenlose Kursvideos <br></br> und Challenges',
        text: 'Ein exklusives Angebot für dich von {name}',
        ctaButton: 'Jetzt anmelden',
      },
      offers: {
        unlimitedCheckIns: 'Unlimitierte Studio Check-ins',
        partnerApps: 'Partner Apps (Lifesum, 7mind, …)',
        liveOnlineCourses: 'Live Online-Kurse',
        onlinePrevention: 'Digitale Präventions- und Fitnesskurse',
        challenges: 'Challenges',
        free: {
          badge: 'KOSTENLOS',
          title: 'Basis Online Angebot',
          perMonth: '/Monat',
        },
        recommended: {
          badge: 'EMPFOHLEN',
          title: 'Unlimited Angebot',
          subtitle: 'Geringe monatliche Mitgliedschaftsgebühr',
          description:
            'Entdecke den exklusiven Preis deines Arbeitgebers indem du <button>zurück gehst</button> und “Unlimited Angebot” auswählst.',
        },
      },
      sportSearch: {
        title: 'Sport- & Wellnessangebote in deiner Nähe',
        body: 'Das Basis Online Angebot reicht für deine Fitnessbedürfnisse nicht aus? Entdecke unser Partner-Netzwerk, das nur im Unlimited Angebot verfügbar ist.',
        infoAlertTitle:
          'Check-ins in den Partnerstudios sind nur mit unserem Unlimited Angebot möglich. ',
        infoAlert:
          'Um mehr zu erfahren, <button>gehe zurück</button> zur Seite mit der Angebotsauswahl und wähle das Unlimited Angebot.',
      },
    },
  },
  multiEntitySignupPage: {
    headline: 'Willkommen bei EGYM Wellpass',
    dropdownText: '{label} auswählen',
    continueButton: 'Weiter',
    radioButtonSelect: {
      selectHeadline: 'Bitte auswählen',
      standard: 'Unlimited Angebot',
      free: 'Basis Online Angebot ',
      tooltip:
        'Beinhaltet digitale Fitness- und Präventionskurse. Enthält nicht den Zugang zum Studiopartner-Netzwerk.',
    },
  },
  signupPage: {
    headerTitle: 'Wellpass Anmeldung',
    corpControlEnabledEligibilityInfo:
      '<strong>Zur Prüfung der Teilnahmeberechtigung, vergleichen wir deine persönlichen Angaben mit den Daten eures HR Systems.</strong>' +
      '<br></br>Bitte stelle sicher, dass dein Vorname, Nachname und Geburtsdatum mit den Angaben im HR System deines Arbeitgebers übereinstimmen.',
    corpControlDisabledEligibilityInfo:
      '<strong>Deine persönlichen Angaben werden mit deiner Personalabteilung geteilt.</strong>' +
      '<br></br>Bitte stelle sicher, dass dein Vorname, Nachname und Geburtsdatum mit den Angaben in dem HR System deines Arbeitgebers übereinstimmen.',
    matchButNotEligible: {
      title: 'Leider müssen wir deine Anmeldung verweigern.',
      message:
        'Laut den hinterlegten Regeln, bist du auf dieser Anmeldeseite leider nicht teilnahmeberechtigt. Bei Fragen wende dich bitte an deine Personalabteilung.',
      alert:
        'Falls deine Firma mehrere Tochterunternehmen oder Standorte hat, stelle sicher, dass du auf der richtigen Anmeldeseite bist.',
      close: 'Okay',
    },
    multipleMatches: {
      title: 'Wir konnten deine Teilnahmeberechtigung noch nicht überprüfen',
      message:
        'Wir konnten dich noch nicht in eurem HR System finden. Wenn du denkst, dass ein Fehler vorliegt, kontaktiere uns via info@egym-wellpass.com',
      alert:
        'Falls deine Firma mehrere Tochterunternehmen oder Standorte hat, stelle sicher, dass du auf der richtigen Anmeldeseite bist.',
      close: 'Okay',
    },
    noExactMatch: {
      title: 'Wir konnten deine Teilnahmeberechtigung noch nicht überprüfen',
      message:
        'Wir konnten dich noch nicht in eurem HR System finden. Wenn du denkst, dass ein Fehler vorliegt, kontaktiere uns via info@egym-wellpass.com',
      alert:
        'Falls deine Firma mehrere Tochterunternehmen oder Standorte hat, stelle sicher, dass du auf der richtigen Anmeldeseite bist.',
      close: 'Okay',
    },
    matchButAlreadyRegistered: {
      title: 'Du hast angeblich schon eine EGYM ID',
      message:
        'Bitte log dich mit deiner EGYM ID E-Mail Adresse <strong>{maskEmail}</strong> ein. <br></br>Falls du keinen Zugriff mehr auf diese E-Mail Adresse hast, kontaktiere uns via info@egym-wellpass.com.',
      alert: '',
      close: 'Login',
    },
    step1: {
      title: 'Persönliche Angaben',
      titleAccount: 'Zugangsdaten',
      welcomeBack: 'Willkommen zurück',
      subTitle: 'Ich bin neu hier',
      nextbutton: 'Anmelden & weiter',
      sharedInfo:
        'Dein Name und dein Geburtsdatum wird mit deiner <strong>Personalabteilung geteilt</strong>.',
    },
    step2: {
      title: 'Stelle sicher, dass deine persönlichen Informationen korrekt und vollständig sind',
      nextbutton: 'Speichern & weiter',
    },
    step3: {
      title: 'Start deiner Mitgliedschaft',
      additional: 'Zusätzliche Angaben',
      preCountrySelectionText: 'Du hast ausgewählt, dass du Wellpass hauptsächlich in',
      postCountrySelectionText: ' nutzen wirst.',
      noAvailableStartDateWarning:
        'Du kannst aktuell keine Mitgliedschaft abschließen, da sie sich mit deiner Beschäftigungdauer überschneiden muss. Bei Fragen wende dich bitte an deine Personalabteilung.',
      warnAboutOnlineOffering:
        '<b>Du meldest dich für unser "Basis Online Angebot" an:</b> <ul><li><done></done> Du kannst unsere digitalen Präventionskurse nutzen</li> <li><done></done> Du kannst unsere Kursvideos nutzen</li> <li><b><close></close> Du kannst unser großes Partnernetzwerk mit {gymCount} Studios nicht besuchen</b></li> </ul> Wenn du deine Auswahl ändern möchtest, <button>gehe zurück</button> zur ersten Seite und wähle das Unlimited Angebot aus.',
    },
    field: {
      zipCode: {
        label: 'PLZ',
        placeholder: 'PLZ',
      },
      city: {
        label: 'Ort',
        placeholder: 'Ort',
      },
      iban: {
        label: 'IBAN',
        placeholder: 'IBAN',
      },
      email: {
        label: 'E-Mail (privat)',
        placeholder: 'E-Mail (privat)',
        helpTextAfter:
          'Wir senden dir wichtige Informationen via E-Mail bevor deine Mitgliedschaft startet. Diese E-Mail Adresse wird nicht mit {name} geteilt.',
        plusOneHelpTextAfter:
          'An diese E-Mail Adresse werden wir dir einen Link zur Bestätigung deiner Zugangsdaten senden. Stelle sicher, dass du uneingeschränkten Zugriff auf diesen E-Mail Account hast.',
      },
      gender: {
        label: 'Anrede',
        options: {
          empty: {
            label: 'Bitte auswählen',
          },
          male: {
            label: 'Herr',
          },
          female: {
            label: 'Frau',
          },
          divers: {
            label: 'Divers',
          },
        },
      },
      street: {
        label: 'Straße',
        placeholder: 'Straße',
      },
      consent: {
        label: 'Zustimmung',
        helpText:
          'Hiermit versichere ich, dass meine Angaben wahrheitsgemäß sind und die Berechtigung habe, an Wellpass teilzunehmen. Die Mitgliedschaft verlängert sich automatisch und kann monatlich bis zum 15. des laufenden Monats zum Monatsende {cancellationUrl} gekündigt werden. Es gelten die {termsAndConditionsUrl} der EGYM Wellpass GmbH. Einzelheiten zur Verarbeitung deiner personenbezogenen Daten findest du in unseren {privacyPolicyUrl}.',
        acceptTextPart:
          'Ich bin mit der monatlichen Zahlung des Mitgliedsbeitrags via SEPA Lastschriftverfahren einverstanden.',
        termsAndConditionsUrl:
          'https://egym-wellpass.com/agb-mitgliedschaft/?utm_source=referral&utm_medium=signup-page',
        termsAndConditionsText: 'Allgemeinen Geschäftsbedingungen',
        privacyPolicyUrl:
          'https://egym-wellpass.com/datenschutz/?utm_source=referral&utm_medium=signup-page',
        privacyPolicyText: 'Datenschutzhinweisen',
        cancellationUrl: 'https://egym-wellpass.com/kuendigung',
        cancellationText: 'hier',
      },
      lastName: {
        label: 'Nachname',
        placeholder: 'Nachname',
      },
      birthday: {
        label: 'Geburtstag: TT.MM.JJJJ',
        placeholder: 'Geburtstag: TT.MM.JJJJ',
      },
      workEmail: {
        label: 'Berufliche E-Mail',
        placeholder: 'Berufliche E-Mail',
      },
      personalEmail: {
        label: 'Persönliche E-Mail',
        placeholder: 'Persönliche E-Mail',
        helpTextAfter:
          'Bitte gib die persönliche E-Mail Adresse ein, die du bei deinem Arbeitgeber hinterlegt hast.',
      },
      employeeNumber: {
        label: 'Personalnummer',
        placeholder: 'Personalnummer',
        helpTextAfter: '{employeeNumberDescription}',
      },
      password: {
        label: 'Passwort',
        placeholder: 'Passwort',
      },
      firstName: {
        label: 'Vorname',
        placeholder: 'Vorname',
      },
      houseNumber: {
        label: 'Hausnummer',
        placeholder: 'Hausnummer',
      },
      repeatPassword: {
        label: 'Passwort wiederholen',
        placeholder: 'Passwort wiederholen',
      },
      paymentDetails: {
        title: 'Zahlungsdaten',
        paymentAddress:
          'EGYM Wellpass GmbH, Einsteinstraße 172, 81677 München\n' +
          'Gläubiger-ID:  DE43ZZZ00000585851\n',
        sepaPayment: 'SEPA-Lastschriftmandat für wiederkehrende Zahlungen:\n',
        paymentPermission:
          'Ich ermächtige hiermit die EGYM Wellpass GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der EGYM Wellpass GmbH auf mein Konto gezogenen Lastschriften einzulösen.\n',
        refundClaim:
          'Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.\n',
        part2WithPrice:
          'Für nur {price} monatlich kannst du bei {gymCount} hochwertigen Sporteinrichtungen so viel trainieren wie du möchtest. Ein exklusives Angebot für dich von {name}. Für deine persönliche Ersteinweisung in einem Partner Fitnessstudio deiner Wahl können einmalige Kosten anfallen, Diese betragen normalerweise {briefingFee} und sind direkt an das Studio zu entrichten. Diese Einweisung soll dir den Start im Studio erleichtern und dir helfen deine Trainingsziele zu erreichen.\n\n' +
          '<b>Du bist bereits Mitglied bei einem unserer Partner Fitnessstudios?</b> In diesem Fall kann die Gebühr für die Ersteinweisung nach Absprache mit dem Studio entfallen. Du kannst auch deine Mitgliedschaft für die Dauer deiner Wellpass Mitgliedschaft pausieren. Bitte kontaktiere hierfür möglichst frühzeitig dein Fitnessstudio, um deine Mitgliedschaft zu pausieren. \n',
        part2WithoutPrice:
          'Mit deiner Wellpass Mitgliedschaft kannst du bei {gymCount} hochwertigen Sporteinrichtungen so viel trainieren wie du möchtest. Ein exklusives Angebot für dich von {name}. Für deine persönliche Ersteinweisung in einem Partner Fitnessstudio deiner Wahl können einmalige Kosten anfallen, Diese betragen normalerweise {briefingFee} und sind direkt an das Studio zu entrichten. Diese Einweisung soll dir den Start im Studio erleichtern und dir helfen deine Trainingsziele zu erreichen.\n\n' +
          '<b>Du bist bereits Mitglied bei einem unserer Partner Fitnessstudios?</b> In diesem Fall kann die Gebühr für die Ersteinweisung nach Absprache mit dem Studio entfallen. Du kannst auch deine Mitgliedschaft für die Dauer deiner Wellpass Mitgliedschaft pausieren. Bitte kontaktiere hierfür möglichst frühzeitig dein Fitnessstudio, um deine Mitgliedschaft zu pausieren. \n',
        plusOnePart2WithPrice:
          'Für nur {price} monatlich kannst du bei {gymCount} hochwertigen Sporteinrichtungen so viel trainieren wie du möchtest. Für deine persönliche Ersteinweisung in einem Partner Fitnessstudio deiner Wahl können einmalige Kosten anfallen, Diese betragen normalerweise {briefingFee} und sind direkt an das Studio zu entrichten. Diese Einweisung soll dir den Start im Studio erleichtern und dir helfen deine Trainingsziele zu erreichen.\n',
        plusOneSpecialCondition:
          '<b>Sonderkonditionen für Plus1 Mitglieder:</b> \n ' +
          '<ul>' +
          '<li>Entweder dein <b>Nachname oder deine Hauptwohnsitz-Adresse muss mit den Daten des einladenden Firmenmitglieds übereinstimmen.</b> Sollten die Teilnahmevoraussetzungen nicht erfüllt sein, beenden wir deine Mitgliedschaft fristlos. Falls wir dich und das einladende Mitglied zum Identitätsnachweis auffordern, ist dieser binnen 5 Werktagen nachzukommen.</li>' +
          '<li>Manche Studios können nur mit einer Wellpass Firmenfitness-Mitgliedschaft besucht werden und sind bei der Plus1 Mitgliedschaft ausgeschlossen. Die <b>exkludierten Studios</b> sind auf der Studiosuche markiert.</li>' +
          '<li>Deine <b>aktive Fitnessstudio</b> Mitgliedschaft kann für die Dauer deiner Wellpass Mitgliedschaft <b>nicht pausiert</b> werden.</li>' +
          '<li>Deine <b>Plus1 Mitgliedschaft ist direkt an die Mitgliedschaft des Wellpass Mitglieds geknüpft</b>, welches dich eingeladen hat. Wenn dessen Mitgliedschaft endet, endet auch deine Plus1 Mitgliedschaft.</li>' +
          '</ul>',
        plusOnePart2WithoutPrice:
          'Mit deiner Wellpass Mitgliedschaft kannst du bei {gymCount} hochwertigen Sporteinrichtungen so viel trainieren wie du möchtest. Für deine persönliche Ersteinweisung in einem Partner Fitnessstudio deiner Wahl können einmalige Kosten anfallen, Diese betragen normalerweise {briefingFee} und sind direkt an das Studio zu entrichten. Diese Einweisung soll dir den Start im Studio erleichtern und dir helfen deine Trainingsziele zu erreichen.\n\n',
        part2GymCountPlaceHolder: 'mehr als 3.000',
        part3: {
          b2c: 'Bitte überprüfe deine Zahlungsdaten sorgfältig. Der monatliche Mitgliedschaftsbeitrag in Höhe von {price} wird von diesem Konto abgebucht.',
          nonB2cWithPrice:
            'Den monatlichen EGYM Wellpass Mitgliedschaftsbeitrag in Höhe von {price} behält dein Arbeitgeber direkt von deinem Gehalt ein.',
          nonB2cWithoutPrice:
            'Den monatlichen EGYM Wellpass Mitgliedschaftsbeitrag behält dein Arbeitgeber direkt von deinem Gehalt ein.',
        },
      },
      bankAccountHolderName: {
        label: 'Kontoinhaber',
        placeholder: 'Kontoinhaber',
      },
      employeeIdentifier: {
        label: 'Personalnummer',
        placeholder: 'Personalnummer',
      },
      membershipStartDate: {
        label: 'Anmeldung zum',
        warningLabel: 'Kein verfügbares Startdatum',
        helpTextAfter:
          'Du kannst dich noch bis zum {signupDeadline} anmelden, um deine Mitgliedschaft am {earliestStartDate} zu starten.',
        options: {
          empty: {
            label: 'Wählen...',
          },
        },
      },
      reviewedGender: {
        label: 'Anrede',
        options: {
          empty: {
            label: 'Bitte auswählen',
          },
          male: {
            label: 'Herr',
          },
          female: {
            label: 'Frau',
          },
          divers: {
            label: 'Divers.',
          },
        },
      },
      reviewedFirstName: {
        label: 'Vorname',
        placeholder: 'Vorname',
      },
      reviewedLastName: {
        label: 'Nachname',
        placeholder: 'Nachname',
      },
      reviewedBirthday: {
        label: 'Geburtstag: TT.MM.JJJJ',
        placeholder: 'Geburtstag: TT.MM.JJJJ',
      },
    },
    paymentDetailsModal: {
      title: 'Ungültige Zahldaten',
      body: 'Deine EGYM ID wurde erfolgreich erstellt, aber wir konnten keine Wellpass Mitgliedschaft für dich anlegen. Bitte log dich mit deiner E-Mail und Passwort ein, die du gerade gesetzt hast und gib andere Zahldaten an.',
      button: 'Login',
    },
  },
  signupSuccessPage: {
    title: 'Bereit zum Training, {firstName}?',
    successAlert: 'Deine Anmeldung für eine Wellpass-Mitgliedschaft war erfolgreich',
    infoAlert:
      'Vergiss nicht, deine E-Mail-Adresse zu verifizieren, indem du auf den Link klickst, den wir dir gerade geschickt haben. Falls du einen Tippfehler in deiner E-Mail entdeckst, kontaktiere uns bitte via <a>info@egym-wellpass.com.</a>',
    overViewCard: {
      title: 'Kurzer Überblick',
      membership: 'Beginn deiner Mitgliedschaft',
      egymId: 'Deine EGYM ID',
    },
    steps: {
      headline: 'Nächste Schritte',
      step1: 'Lade die Wellpass-App herunter',
      step2: 'Finde deine Lieblingsstudios mit unserer Studiosuche',
      step3: 'Checke in einem Partnerstudio ein',
      step4: 'Repeat 🔁',
    },
    feesInfo:
      'Einige Fitnessstudios erheben eine einmalige Gebühr von üblicherweise 44€ für dein erstes Einweisungstraining, es sei denn, dein Arbeitgeber hat dich anders informiert.',
    qrCode: 'Scanne und lade dir unsere Wellpass-App herunter →',
    button: 'App herunterladen',
  },
  signupErrorPage: {
    invalidCompanyToken: {
      title: 'Etwas ist schief gelaufen.',
      description:
        'Bitte wende dich an den Wellpass Ansprechpartner bei deinem Arbeitgeber um einen gültigen Link für die Wellpass Anmeldeseite zu erhalten.',
    },
  },
  passwordResetPage: {
    headerTitle: 'Passwort vergessen?',
    resetPasswordBtn: 'Passwort zurücksetzen',
    body1:
      'Kein Problem! Gib einfach deine Email Adresse ein, damit wir dir Informationen zum Zurücksetzen deines Passwortes zusenden können.',
    body2:
      'Wenn du noch kein Wellpass Nutzer bist, aber schon einen EGYM Account besitzt, dann benutze bitte die <a>EGYM Passwort zurücksetzen Funktion.</a>',
    receivedEmailSuccessHeader:
      'Vielen Dank! Wir haben dir gerade eine E-Mail zum Zurücksetzen des Passworts geschickt.',
    receivedEmailSuccessBody:
      'Du solltest in den nächsten Minuten eine E-Mail mit einem Link zum Zurücksetzen des Passworts erhalten. Bitte aktualisiere deinen E-Mail-Posteingang und überprüfe auch deinen Spam-Ordner.',
    noEmailSuccessHeader: 'Keine E-Mail erhalten?',
    noEmailSuccessBody:
      'Wenn du mit dieser E-Mail-Adresse noch nie eine Wellpass-Mitgliedschaft hattest, können wir dir hier leider keine E-Mail zum Zurücksetzen des Passworts schicken. Bitte verwende stattdessen die {egymPasswordReset} Anschließend kannst du dich mit der EGYM ID einloggen, um eine Wellpass-Mitgliedschaft abzuschließen. Falls du auch hier keine E-Mail erhältst, wende dich bitte an unser Support-Team via {wellpassSupportMail}',
    egymPasswordReset: 'EGYM Passwort zurücksetzen Funktion.',
  },
  newPasswordPage: {
    headerTitle: 'Setze dir jetzt dein Passwort',
    resetPasswordBtn: 'Neues Passwort speichern',
    clickHere: 'Klicke hier',
    clickHereTo: ' um eine neue "Passwort zurücksetzen" E-Mail anzufordern.',
    passwordRules: 'Dein Passwort muss mindestens 8 Zeichen lang sein.',
    aboveBody: 'Bitte setze dir jetzt dein neues EGYM ID Passwort',
    successPage: {
      headerTitle: 'DANKE',
      title: 'DEIN NEUES PASSWORT WURDE ERFOLGREICH GESPEICHERT',
      text: 'Du kannst dich jetzt mit deiner E-Mail Adresse und deinem neuen Passwort einloggen.',
    },
    errorPage: {
      title: 'Etwas ist schief gelaufen',
      linkToPasswordResetPage: 'Neues Passwort erstellen',
    },
  },
  errorPage: {
    title: 'Die gesuchte Seite konnte leider nicht gefunden werden ',
    invalidLinkTitle: 'Dein Anmeldelink funktioniert nicht?',
    invalidLinkTip:
      'Bitte überprüfe deinen Anmeldelink und versuche ihn mit einem anderen Browser zu öffnen oder lasse dir diesen erneut von dem Wellpass Ansprechpartner deiner Firma zusenden.',
    alreadyMemberTitle: 'Du bist bereits Wellpass Mitglied?',
    alreadyMemberTip: 'Logge dich hier in deinen Account ein: ',
    alreadyMemberLink: 'https://app.egym-wellpass.com/login',
    callToActionTitle: 'Was ist Wellpass?',
    callToActionTip:
      'Wellpass bietet dir unbegrenzt Schwimmen, Fitness, Klettern, Yoga und mehr. Ein exklusives Angebot für dich von deinem Arbeitgeber. Alles was du über Wellpass wissen musst findest du auf unserer Website: ',
    callToActionLink: 'https://egym-wellpass.com/',
    helpTitle: 'Du hast eine andere Frage?',
    helpTip:
      'Dann besuche unseren Hilfe-Bereich für weitere Informationen und unser Kontaktformular: ',
    helpLink: 'https://egym-wellpass.com/faq/',
  },
  colleagueSignupErrorPage: {
    title: 'Wir freuen uns, dass du dich bei EGYM Wellpass anmelden möchtest!',

    part1:
      'Da die Verwaltung der Wellpass Anmeldungen ausschließlich über deinen Arbeitgeber erfolgt, können wir dir den direkten Anmeldelink für eine Wellpass Mitgliedschaft leider nicht zur Verfügung stellen.',

    part2:
      'Um eine Wellpass Mitgliedschaft abzuschließen, wende dich bitte direkt an die zuständige Person in deinem Unternehmen. Diese findest du in der Regel in deiner HR/ Personalabteilung.',
  },
  partnerSignUp: {
    generalError:
      'Ups, dieser Link scheint nicht mehr gültig oder fehlerhaft zu sein. Bitte frage das Wellpass Mitglied, das dich eingeladen hat, dir den Einlade-Link erneut zuzusenden.',
  },
  loginPage: {
    headerTitle: 'Login',
    title: 'Log in',
    field: {
      username: {
        label: 'Email Adresse',
        placeholder: 'Email Adresse',
      },
      password: {
        label: 'Passwort',
        placeholder: 'Passwort',
      },
    },
    loginButton: 'Log in',
    forgotPassword: 'Passwort vergessen?',
    welcome: {
      headerTitle: 'Willkommen',
      title: 'Account Übersicht',
      tile: {
        memberManagementHTML: 'Verwaltung der Mitgliedschaften',
        magazineHTML: 'Magazin',
        paymentDetailsHTML: 'Meine Zahlungsdaten',
        marketingMaterialHTML: 'Marketing Materialien',
        accountSettingsHTML: 'Meine Account-einstellungen',
      },
    },
  },
  loginNoEmailVerification: {
    headerTitle: 'Willkommen',
    title: 'E-Mail Bestätigung erforderlich!',
    welcomeText: 'Willkommen {firstName} {lastName}',
    description:
      'Bitte bestätige deine Email Adresse indem du auf den Link in der Email klickst, die wir dir gerade geschickt haben.',
  },
  loginNotAuthorized: {
    headerTitle: 'Access Denied',
    title: '403 Forbidden',
    description: 'Es fehlen Berechtigungen um diese Seite anzuzeigen.',
    linkToProfilePage: 'Zum Profil',
  },
  general: {
    field: {
      email: {
        label: 'E-Mail',
        placeholder: 'E-Mail',
        helper: 'Es wird eine {egymId} für diesen Nutzer erstellt.',
      },
      newPassword: {
        label: 'Neues Passwort',
        placeholder: 'Neues Passwort',
      },
      repeatPassword: {
        label: 'Passwort wiederholen',
        placeholder: 'Passwort wiederholen',
      },
      firstName: {
        label: 'Vorname',
        placeholder: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
        placeholder: 'Nachname',
      },
    },
    button: {
      next: 'Nächster Schritt',
      continue: 'Weiter',
      previous: 'Zurück',
      submit: 'Absenden',
      confirm: 'Bestätigen',
      renderLogin: 'Login',
      loginProceed: 'Login & weiter',
      renderRegister: 'Anmelden',
    },
    required: '* Pflichtfeld',
    toTop: 'nach oben',
    wellpassSupportMail: 'support@egym-wellpass.com',
    wellpassInfoMail: 'info@egym-wellpass.com',
  },
  validation: {
    general: {
      requiredField: '{fieldLabel} ist ein Pflichtfeld',
      invalid: 'Ungültige Eingabe für {fieldLabel}',
      minLength: '{fieldLabel} muss mindestens {0} Zeichen haben',
      maxLength: 'Für {fieldLabel} sind maximal {0} Zeichen erlaubt',
      datePast: '{fieldLabel} muss in der Vergangenheit liegen',
      dateAfterMinimal: 'Bitte gib ein gültiges Geburtsdatum an',
      dateFuture: '{fieldLabel} muss in der Zukunft liegen',
      invalidEmail: 'Ungültige Email Adresse',
      invalidDate: 'Ungültiges Datum. Bitte gib dein Geburtsdatum im Format TT.MM.YYYY an.',
      invalidRegexMatch:
        'Deine Eingabe ist ungültig. Bitte überprüfe das Format deiner Eingabe oder wende dich an deine Personalabteilung.',
      invalidRegex:
        'Es gibt ein Problem auf unserer Seite und deine Anmeldung konnte nicht gespeichert werden. Bitte kontaktiere uns umgehend via info@egym-wellpass.com und melde dieses Problem "Ungültige Validierungsregel".',
      invalidSize: '{fieldLabel} muss zwischen {1} und {0} Zeichen haben',
      invalidName:
        'Bitte gib deinen vollständigen Namen an. Zahlen sowie Sonderzeichen sind nicht erlaubt und dein Name muss mindestens zwei Buchstaben enthalten.',
      serverError:
        'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es in wenigen Minuten noch einmal.',
      requestError: 'Fehlerhafte Anfrage. Bitte überprüfe deine Eingaben.',
      token: {
        notFound:
          'Bitte setze dein Passwort erneut zurück. Der verwendete Link ist leider ungültig.',
        timeout:
          'Der verwendete Link ist leider nicht mehr gültig. Bitte fordere hier eine neue E-Mail an um dein Passwort zu setzen.',
      },
      invalidNameOfUser:
        'Bitte gib den vollständigen Namen an. Zahlen sowie Sonderzeichen sind nicht erlaubt und der Name muss mindestens zwei Buchstaben enthalten.',
      genericError: 'Oh, etwas ist schiefgelaufen. Bitte versuche es noch einmal.',
    },
    signup: {
      general: {
        userAlreadyExists:
          'Es besteht bereits ein Account mit der E-Mail Adresse {0}. <loginstep>Nutze bitte den Login</loginstep>',
        serverError:
          'Ups, etwas ist schief gelaufen. Wir haben aktuell leider ein Problem auf unserer Seite. Bitte versuche es ein bisschen später noch einmal. Falls es dann immer noch nicht funktioniert kontaktiere uns bitte via info@egym-wellpass.com.',
      },
      duplicateSignup: {
        title: 'Hast du dich kürzlich bei uns angemeldet? Wenn ja, fahre nicht hier fort.',
        infoText:
          'Um einen Fehler in deinen Daten zu korrigieren (z. B. einen Tippfehler in deiner E-Mail-Adresse), wende dich bitte an unser Support-Team via {wellpassSupportMail}, das dir gerne weiterhilft. Wenn du dich erneut anmeldest, legst du eine zweite Mitgliedschaft an und wir müssen dich doppelt belasten.',
      },
      email: {
        invalid: 'Ungültige E-Mail-Adresse',
      },
      iban: {
        invalid: 'Ungültige IBAN-Nummer',
      },
      password: {
        invalid: 'Ungültiges Passwort',
      },
      repeatPassword: {
        notMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
      },
      companyToken: {
        invalid:
          'Etwas ist schief gelaufen. Bitte wende dich an den Wellpass Ansprechpartner bei deinem Arbeitgeber um einen gültigen Link für die Wellpass Anmeldeseite zu erhalten.',
        expired:
          'Bitte wende dich an den Wellpass Ansprechpartner bei deinem Arbeitgeber um einen gültigen Link für die Wellpass Anmeldeseite zu erhalten. Weitere Informationen über Wellpass findest du auf egym-wellpass.com.',
      },
      birthday: {
        underMinimumSignupAge:
          'Du musst mindestens 13 Jahre alt sein, um eine Wellpass Mitgliedschaft abzuschließen.',
      },
      address: {
        invalid: 'Bitte gib deine vollständige Adresse an (PLZ, Stadt, Straße, Hausnr.)',
      },
    },
    user: {
      membership: {
        overlappingMemberships:
          'Das ausgewählte Mitgliedschaftsstartdatum überlappt mit der anderen Wellpass Mitgliedschaft die deinem Account zugeordnet ist. Bitte wähle ein anderes Startdatum aus.',
      },
    },
    login: {
      invalidUsernameOrPassword: 'Ungültiger Benutzername oder Passwort',
    },
  },
  doubleOptInSuccessPage: {
    headerTitle: 'Willkommen bei Wellpass',
    title: 'Vielen Dank! Deine E-Mail Adresse wurde verifiziert.',
    body: 'Diese E-Mail Adresse ist deine EGYM ID. Du kannst dich damit in der Wellpass App und in allen anderen EGYM Produkten einloggen.',
    downloadAppBtn: 'App herunterladen',
  },
  doubleOptInFailurePage: {
    headerTitle: 'Etwas ist schief gelaufen',
    title: 'Leider konnten wir deine Anmeldung nicht verifizieren',
    description:
      'Leider ist etwas schief gelaufen. Wir konnten deine Anmeldung nicht verifizieren. Bitte versuche es später noch einmal. Falls das Problem weiterhin auftritt, melde dich bitte bei unserem Support:',
    contactSupportBtn: 'Kontakt zum Support',
  },
  companyMemberships: {
    headerTitle: 'Verwaltung der Mitgliedschaften',
    title: 'Mitgliedschaften',
    companyDropdownLabel: 'Firma',
    filter: {
      allMembers: 'Alle Mitgliedschaften',
      activeMembers: 'Aktive Mitgliedschaften',
      newRegistrations: 'Bald startende Mitgliedschaften',
      endedMemberships: 'Bald endende Mitgliedschaften',
      status: {
        label: 'Nach Status filtern :',
      },
    },
    searchField: {
      placeholder: 'Mitglied suchen ...',
    },
    tableHeadline: {
      memberships: 'Mitglieder',
      additionalInformation: 'Zusatzinformation',
      employeeNumber: {
        title: 'Personalnummer',
        tooltip: 'Dieser Datenpunkt stammt aus dem HR-System.',
      },
      plan: 'Tarif',
      startDate: 'Beginn',
      endOfContract: 'Vertragsende',
      status: 'Status',
    },
    memberInfo: {
      dateOfBirth: 'Geburtstag',
      status: {
        active: 'Aktiv',
        startingSoon: 'Beginnt bald',
        endingSoon: 'Endet bald',
        inactive: 'Inaktiv',
      },
      endMembershipButton: 'Mitgliedschaft beenden',
      name: {
        notAvailable: 'gelöscht / nicht verfügbar',
      },
    },
    membershipOffer: {
      type: {
        standard: 'Standard',
        free: 'Free',
      },
    },
    button: {
      exportRecords: 'Übersicht als CSV exportieren',
    },
    exportCsvHeaders: {
      lastName: 'Nachname',
      firstName: 'Vorname',
      dateOfBirth: 'Geburtstag',
      additionalInformationLabel: 'Zusatzinformation (Feld)',
      additionalInformationValue: 'Zusatzinformation (Wert)',
      employeeNumber: 'Personalnummer',
      startDate: 'Beginn',
      endDate: 'Vertragsende',
      cancellationReason: 'Kündigungsgrund',
      status: 'Status',
      plan: 'Tarif',
    },
    file: {
      membershipCsv: 'mitgliedschaften.csv',
    },
    emptySet:
      'Es wurde keine Person mit diesem Namen gefunden. Bitte überprüfe ob möglicherweise noch Filter aktiv sind.',
    membershipsNote: {
      headline: 'Wichtiger Hinweis',
      body: 'Nach gesetzlichen Vorgaben können Mitgliedschaftsdaten mit einem Enddatum vor {year} nicht mehr ausgewiesen werden. Bitte prüfen sie mögliche interne Dokumentationspflichten rechtzeitig und legen sie bei Bedarf eine Übersicht der Wellpass Mitgliedschaften regelmäßig lokal ab.',
    },
    endMembershipSuccessfulNote:
      'Die Änderung wurde erfolgreich gespeichert. Das Mitglied wurde via E-Mail informiert.',
  },
  header: {
    logo: {
      url: 'https://egym-wellpass.com',
    },
    welcomeUser: 'Willkommen {userName}',
    signOut: 'Abmelden',
  },
  footer: {
    logo: {
      url: 'https://egym-wellpass.com',
    },
    title: 'Wellpass',
    links: {
      title: 'Rechtliche Links',
      imprint: {
        text: 'Impressum',
        url: 'https://egym-wellpass.com/impressum/',
      },
      privacyPolicy: {
        text: 'Datenschutz',
        url: 'https://egym-wellpass.com/datenschutz/',
      },
      termsAndConditions: {
        text: 'Allgemeine Geschäftsbedingungen',
        url: 'https://egym-wellpass.com/agb-mitgliedschaft/',
      },
      cookiePolicy: {
        text: 'Cookies',
        url: 'https://egym-wellpass.com/cookie-richtlinie/',
      },
    },
    priceVatIncluded: 'Alle Preise inklusive der gesetzlichen Mehrwertsteuer.',
    copyright: {
      text: '@{year} EGYM Wellpass GmbH',
      link: {
        text: 'An EGYM group company',
        url: 'https://egym.de/de',
      },
    },
  },
  declarationOfConsentAlert: {
    warning: {
      body: 'Wenn du noch nicht volljährig bist, musst du die von deinen Erziehungsberechtigten unterzeichnete {documentUrl} hochladen, um mit der Anmeldung fortzufahren.',
      button: {
        title: 'Einverständniserklärung hochladen',
      },
    },
    success: {
      body: 'Du hast die Einverständniserklärung erfolgreich hochgeladen.',
      button: {
        title: 'Einverständniserklärung bearbeiten',
      },
    },
  },
  additionalVerificationOptionAlert: {
    multipleOptionWarning: {
      title: 'Wir konnten dich noch nicht in eurem HR System finden.',
      body:
        '<ul><li>Wenn du einen Fehler in deinen persönlichen Daten entdeckst, korrigiere ihn bitte und versuche es erneut.</li>' +
        '<li>Du kannst immer noch nicht fortfahren? Dann füge eine Verifizierungsoption hinzu.</li></ul>',
      button: {
        title: 'Verifizierungsoption hinzufügen',
      },
    },
    singleOptionWarning: {
      title: 'Wir konnten dich noch nicht in eurem HR System finden.',
      body:
        '<ul><li>Wenn du einen Fehler in deinen persönlichen Daten entdeckst, korrigiere ihn bitte und versuche es erneut.</li>' +
        '<li>Du kannst immer noch nicht fortfahren? Dann füge deine {verificationOption} hinzu, um deine Eignung zu überprüfen</li></ul>',
      button: {
        title: '{verificationOption} hinzufügen',
      },
    },
    info: {
      title:
        'Falls deine Firma mehrere Tochterunternehmen oder Standorte hat, stelle sicher, dass du auf der richtigen Anmeldeseite bist.',
    },
  },
  additionalVerificationModal: {
    title: 'Verifizierungsoption hinzufügen',
    body: 'Wähle eine Option, die uns erlaubt, dich in eurem HR-System zu finden.',
    button: {
      continue: 'Weiter',
    },
    defaultOption: {
      label: 'Verifizierungsoption',
    },
  },
  declarationOfConsentModal: {
    title: 'Einverständniserklärung hochladen',
    button: {
      uploadFile: 'Dokument hochladen',
      continue: 'Weiter',
    },
    uploadedDocument: 'Hochgeladene Dokumente',
    document: {
      url: 'https://storage.googleapis.com/qualitrain-user-frontend-assets/signup_consent_form_underage_de.pdf',
      text: 'Einverständniserklärung',
    },
    uploadConstraints:
      'Die zulässigen Formate sind PDF, JPG, PNG oder HEIC. Du kannst bis zu zwei Dateien hochladen, die jeweils bis zu 15MB groß sein dürfen.',
    uploadSizeExceedError: 'Die Datei ist zu groß (max 15MB).',
    body:
      '<ol><li>{documentUrl} herunterladen</li>' +
      '<li>Ausdrucken und von deinen Erziehungsberechtigten ausfüllen lassen</li>' +
      '<li>Scan oder Fotos des unterzeichneten Dokuments hochladen</li></ol>',
    declarationConfirmation:
      '<p> Ich bestätige, dass die hochgeladene Einverständniserklärung wahrheitsgemäß und vollständig von meinen Erziehungsberechtigten ausgefüllt wurde.</p>',
  },
  endMembershipModal: {
    title: 'Mitgliedschaft beenden für:',
    nameTitle: 'Name:',
    birthdayTitle: 'Geburtstag:',
    button: {
      endButton: 'Mitgliedschaft beenden',
      cancelButton: 'Schliessen',
    },
    dateFormat: 'DD.MM.YYYY',
    info: 'Der Mitarbeiter wird automatisch via E-Mail über die Beendigung der Mitgliedschaft informiert.',
    error:
      'Die Mitgliedschaft konnte nicht beendet werden. Bitte kontaktiere unser Support-Team: info@egym-wellpass.com',
    errorAfterParent:
      'Deine Mitgliedschaft kann nicht später enden, als die Mitgliedschaft des dich einladenden Wellpass Mitglieds.',
    cancellationReasons: {
      default: {
        label: 'Sonstiger Grund',
      },
    },
    endOfMembership: {
      default: {
        label: 'Ende der Mitgliedschaft',
      },
    },
    systemCancellationNote: {
      header: 'Diese Kündigung kann nicht geändert werden',
      body: 'Diese Kündigung erfolgte durch einen internen Wellpass Prozess und kann nicht geändert werden. Bei Fragen wende dich gerne an info@egym-wellpass.com',
    },
    rejectMembership: {
      note: 'Die Stornierung ist unwiderruflich und wird sofort wirksam. Diese Mitgliedschaft wird nicht mehr in der Liste angezeigt.',
      endDateLabel: 'Mit sofortiger Wirkung',
    },
  },
  editPersonalIdModal: {
    title: '{personalId} bearbeiten',
    error: 'Oh, etwas ist schiefgelaufen. Bitte versuche es noch einmal.',
    regexWarning:
      'Die Eingabe entspricht nicht den auf der Anmeldeseite hinterlegten Validierungsregeln.',
    user: {
      name: 'Name',
      dateOfBirth: 'Geburtstag',
    },
    personalId: 'Personalnummer',
    validationRule: {
      headline: 'Auf der Anmeldeseite angezeigte Validierungsregel',
      body: '',
    },
    btn: {
      savePersonalId: 'Änderung speichern',
    },
  },
  paymentDetailsPage: {
    headerTitle: 'Zahlungsdaten',
    headerBody: 'Meine Bankverbindung',
    editPaymentData: 'Zahlungsdaten ändern',
    ibanLabel: 'IBAN',
    ownerLabel: 'Kontoinhaber',
  },
  updatePaymentInfoModal: {
    headerTitle: 'Bitte überprüfe deine Zahlungsinformationen',
    message:
      'Die Bezahlmethode die du gerade eben ausgewählt hast, wird nicht automatisch für deine kommenden Zahlungen verwendet. Bitte logge dich ein und aktualisiere bei Bedarf deine Zahlungsinformationen.',
  },
  changePaymentDataModal: {
    headerTitle: 'Zahlungsdaten ändern',
    headerBody: 'SEPA-Lastschriftmandat für wiederkehrende Zahlungen:',
    body1_1: 'EGYM Wellpass GmbH, Einsteinstraße 172, 81677 München',
    body1_2: 'Gläubiger-ID:  DE43ZZZ00000585851',
    body2: 'Bitte überprüfe deine Zahlungsdaten sorgfältig.',
    ownerName: 'Kontoinhaber',
    iban: 'IBAN',
    legalConsent:
      'Ich ermächtige hiermit die EGYM Wellpass GmbH, Zahlungen von meinem Konto mittels ' +
      'Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der EGYM Wellpass GmbH auf ' +
      'mein Konto gezogenen Lastschriften einzulösen. ' +
      'Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des ' +
      'belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. ' +
      'Es gelten die {generalTermsAndConditions} der EGYM Wellpass GmbH.',
    savePayment: 'Zahlungsdaten ändern',
    generalTermsAndConditions: {
      text: 'Allgemeinen Geschäftsbedingungen',
      url: 'https://egym-wellpass.com/agb-mitgliedschaft/',
    },
  },
  accountSettingsPage: {
    headerTitle: 'Accounteinstellungen',
    titles: {
      account: 'Mein Account',
      privacy: 'Datenschutzeinstellungen',
    },
    verification: {
      title: 'Verifizierungs-TAN',
      description:
        'Einige Partnerstudios haben ein automatisiertes Zugangssystem. Um eine Zugangskarte beim Studio zu erhalten, mit der du als Wellpass Mitglied einchecken kannst, muss das Studio deine Verifizierungs TAN erfassen und ein Profil für dich in ihrem Mitgliederverwaltungssystem anlegen.',
      btn: 'Verifizierungs-TAN aufrufen',
      expirationText: 'Diese TAN ist bis zum {expirationTime} gültig.',
      expirationTimeFormat: 'DD.MM.YYYY HH:mm',
      legalText:
        'Deine persönlichen Angaben (Name, E-Mail-Adresse, Geschlecht) werden zusammen mit deiner Verifizierungs-TAN in der Mitgliederverwaltung des Studios erfasst und dabei mit deinem Wellpass Account verknüpft, um dir zukünftig als Wellpass-Mitglied den Zugang zum Studio via Zugangskarte/-Bändchen zu ermöglichen und damit das Studio dich optimal betreuen kann. Wir nutzen die EGYM-Technologie im Rahmen einer Auftragsverarbeitung, um den Wellpass Check-in über die vom Studio vergebene Zugangskarte/-Bändchen zu ermöglichen. Weitere Informationen findest du in unserer {link}.',
      error:
        'Ups, etwas ist schief gelaufen und deine Verifizierungs-TAN konnten nicht aufgerufen werden. Bitte versuche es später noch einmal oder kontaktiere unser Support Team via info@egym-wellpass.com.',
    },
    egymId: {
      title: 'Meine EGYM ID',
      label: 'E-Mail:',
      description:
        'Die EGYM ID ermöglicht es dir, auch sämtliche andere Produkte von EGYM mit deinen gewählten Logindaten zu nutzen. {link} erfährst du mehr über die EGYM ID.',
      link: {
        text: 'Hier',
        url: 'https://egym-wellpass.com/egym-id/?utm_source=login',
      },
    },
    privacy: {
      title: 'Datenverarbeitung',
      subtitle: 'Freigabe von anonymisierten und aggregierten Daten',
      legalText: {
        part1:
          'Wir stellen deinem Arbeitgeber Kennzahlen zur Verfügung, die auf aggregierten und anonymisierten Trainingsdaten von dir und deinen Kollegen basieren. Mit diesen Kennzahlen möchten wir nachweisen, dass es sich lohnt die Wellpass Mitgliedschaft anzubieten und zu bezuschussen. Wir stellen sicher, dass ein Rückschluss auf dich als Individuum niemals möglich ist. Du kannst deine Meinung jederzeit ändern und die Einstellung hier ändern. Weitere Informationen, Details und deine Rechte findest du in unseren {link}.',
        part2:
          'In einzelnen Fällen fordert dein Arbeitgeber eine nicht-anonymisierte Einsicht in deine Check-ins. Auf solche Zusatzvereinbarungen wird während der Anmeldung für die Wellpass Mitgliedschaft hingewiesen und sie bleiben von den Einstellungen auf dieser Seite unberührt.',
      },
      options: {
        empty: 'Bitte auswählen...',
        employer: 'An meinen Arbeitgeber',
        nobody: 'Mit niemandem',
      },
      error: {
        text: 'Ups, etwas ist schief gelaufen und deine Datenschutzeinstellungen konnten nicht gespeichert werden. Bitte versuche es später noch einmal oder kontaktiere unser Support Team via info@egym-wellpass.com.',
      },
      reference: {
        text: 'Einzelheiten zur Verarbeitung deiner personenbezogenen Daten findest du in unserer {link}.',
      },
    },
    privacyPolicyLink: {
      text: 'Datenschutzerklärung.',
      url: 'https://egym-wellpass.com/datenschutz/',
    },
  },
  membershipsManagement: {
    filterMemberships: {
      multipleSelection: 'Mehrere ausgewählt',
    },
  },
  myMembershipsPage: {
    headerTitle: 'Meine Mitgliedschaften',
    activeMembership: {
      header: 'Aktive Mitgliedschaft',
      monthlyFee: 'Monatlicher Mitgliedschaftsbeitrag: ',
      vat: '{price} inkl. MwSt.',
      startDate: 'Startdatum: ',
      noPriceInfoAvailable: 'k. A.',
    },
    membershipTermination: {
      header: 'Mitgliedschaftskündigung',
      membershipRejectionInformation:
        '<strong>Du kannst deine zukünftige Mitgliedschaft hier bis zum 15. des Vormonats stornieren. Um von deinem gesetzlichen Widerrufsrecht nach diesem Datum Gebrauch zu machen, sende uns bitte eine E-Mail an <a>info@egym-wellpass.com</a>.</strong>',
      winBackText:
        'Wir finden es äußerst schade, dass du deine Wellpass Mitgliedschaft kündigen möchtest.' +
        ' Wir verbessern ständig unser Angebot, um allen Wünschen unserer Mitglieder gerecht zu werden.',
      howContactOps:
        'Falls es ein Problem mit deiner Kündigung gibt, melde dich gerne bei uns via E-Mail an <a>info@egym-wellpass.com</a>',
      endDate: 'Enddatum',
      cancellationReason: 'Kündigungsgrund',
      terminateButton: 'Mitgliedschaft kündigen',
      stopCancelButton: 'Kündigung zurückziehen',
      successMessage:
        'Deine Kündigung wurde erfolgreich gespeichert.' +
        ' Wir verbessern ständig unser Angebot und hoffen, dich bald wieder als Wellpass Mitglied begrüßen zu dürfen.',
      successStayingMessage:
        'Deine Kündigung wurde erfolgreich storniert. Vielen Dank, dass du bei wellpass bleibst.',
      immediateEffectLabel: 'Mit sofortiger Wirkung',
    },
    noActiveMembershipHeader: 'Mitgliedschaften',
    noActiveMembership: 'Du hast im Moment keine aktive oder zukünftige Mitgliedschaft.',
    futureMembership: {
      header: 'Zukünftige Mitgliedschaft',
    },
  },
  egymId: {
    modal: {
      title: 'Was ist die EGYM ID?',
      body: 'Die EGYM ID erlaubt dir mit deinen Zugangsdaten alle Produkte des Wellpass Mutterunternehmens EGYM zu nutzen. Dein Vorteil hierbei ist, dass du dir einerseits nur einmal E-Mail und Passwort merken musst und dass du ein noch besseres Nutzererlebnis erfährst. Z.B. kannst du an EGYM Kraftgeräten trainieren und deine Trainingseinheit wird automatisch für die Challenge in der Wellpass App erfasst. Weitere Informationen hierzu findest du <a>hier.</a>',
    },
    banner: {
      signup: {
        newUserText:
          'Erstelle deine EGYM ID, um eine Wellpass Mitgliedschaft abschließen zu können.',
        existingUserText:
          'Melde dich mit deiner EGYM ID an, um eine Wellpass Mitgliedschaft abschließen zu können.',
      },
      loginPageText: 'Melde dich mit deiner EGYM ID an.',
      newPasswordPageText:
        'Du kannst dein neues Passwort zusammen mit deiner Login-E-Mail Adresse für alle EGYM Produkte verwenden, nicht nur für EGYM Wellpass. Manche Studios erlauben Wellpass Mitgliedern auch, ihre <strong>Studio-App</strong> zu nutzen z.B. um darüber Kurse buchen. Diese Apps basieren oftmals ebenfalls auf der EGYM ID und du kannst dich deshalb auch dort mit deinem neuen Passwort einloggen.',
    },
  },
  membership: {
    cancellationReasons: {
      uponEmployeesRequest: {
        label: 'Auf Wunsch des Mitarbeiters',
      },
      endOfEmployment: {
        label: 'Beschäftigungsende',
      },
      otherReason: {
        label: 'Sonstiger Grund',
      },
      networkNotAttractiveEnough: {
        label: 'Studionetzwerk nicht attraktiv',
      },
      companyContractEnded: {
        label: 'Firmenvertrag endete',
      },
      plus1RefusedDataNotMatching: {
        label: 'Plus1 abgelehnt, keine Datenübereinstimmung',
      },
      plus1IdCheckInvalid: {
        label: 'Plus1, unzureichender Identitätsnachweis',
      },
      fraud: {
        label: 'Kündigung durch Wellpass',
      },
      notEntitledForParticipation: {
        label: 'Nicht mehr teilnahmeberechtigt',
      },
      notEnoughTime: {
        label: 'Keine Zeit / Motivation für Sport',
      },
      preferOutdoorWorkout: {
        label: 'Sport zu Hause / draußen bevorzugt',
      },
      tooExpensive: {
        label: 'Zu teuer',
      },
      healthRelatedReasons: {
        label: 'Gesundheitliche Gründe',
      },
      endOfReferrerMembership: {
        label: 'Mitgliedschaftsende des Einladenden',
      },
      b2cPaymentChargeback: {
        label: 'Mitgliedschaftsgebühr nicht bezahlt',
      },
      badExperience: {
        label: 'Schlechte Erfahrung mit Wellpass',
      },
      b2cPaymentRefused: {
        label: 'Mitgliedschaftsgebühr nicht bezahlt',
      },
      movedToNewOffer: {
        label: 'Wechsel auf neue Konditionen',
      },
      reject: {
        label: 'Stornierung der Mitgliedschaft',
      },
      b2cReject: {
        label: 'Stornierung der Mitgliedschaft',
      },
      lostEligibility: {
        label: 'Nicht mehr teilnahmeberechtigt',
      },
      default: {
        label: 'Wähle deinen Kündigungsgrund',
      },
    },
  },
  countrySelection: {
    changeCountryLink: 'Land ändern',
    label: 'Land',
    modal: {
      header: 'Ändere dein Land',
      text: 'Bitte wähle das Land aus, in dem du Wellpass hauptsächlich nutzen wirst. Deine Auswahl kann sich auf die Konditionen deiner Mitgliedschaft auswirken.',
    },
  },
  countries: {
    DE: 'Deutschland',
    AT: 'Österreich',
  },
  feedbackBanner: {
    headline: '🔥 NEU: Automatisiere die Prüfung der Wellpass An- und Abmeldungen!',
    body: 'Verbinde hierzu einfach euer HR-System mit Wellpass. Integrationen sind jetzt mit SAP Successfactors, Workday, Personio und Sage verfügbar.',
    button: 'Mehr dazu',
    link: 'https://egym.typeform.com/to/qVzUJMvJ',
  },
  searchAddress: {
    headline: 'Deine aktuelle Hauptwohnsitz Adresse',
    helperText: 'Deine Angaben müssen wahrheitsgemäß sein und werden systematisch überprüft.',
    noOptions: 'Die Adresse konnte nicht gefunden werden. Bitte versuche es erneut!',
    input: {
      label: 'Hauptwohnsitz Adresse',
      placeholder: 'Suche deine Adresse',
    },
  },
  navigationDrawer: {
    companySection: {
      title: 'Firmenportal',
      membershipManagement: 'Verwaltung der Mitgliedschaften',
      marketingMaterials: 'Marketing Materialien',
      companySettings: 'Firmeneinstellungen',
      helpCenter: 'Help Center',
    },
    accountSection: {
      title: 'Persönlicher Account',
      accountSettings: 'Accounteinstellungen',
      paymentData: 'Zahlungsdaten',
    },
  },
  logout: 'Abmelden',
  roles: {
    admin: 'Admin',
    adminDescription: 'Kann auf firmenbezogene Funktionen zugreifen und andere Manager hinzufügen.',
    editor: 'Editor',
    editorDescription: 'Kann auf firmenbezogene Funktionen zugreifen.',
  },
  companySettings: {
    headerTitle: 'Firmeneinstellungen',
    titles: {
      managers: 'Managers',
    },
    add: {
      triggerButton: 'Hinzufügen',
      dialogTitle: 'Manager hinzufügen',
      step1: 'Persönliche Daten',
      step2: 'Rolle zuweisen',
      step3: 'Übersicht',
      companyCounter: '{selected} von {total} ausgewählt',
      successSnackbar: '{email} hat jetzt Manager-Rechte und wurde via E-Mail darüber informiert.',
      errorAlert:
        'Dieser Benutzer ist bereits ein Manager. Um seine Berechtigungen zu ändern, bearbeiten Sie sie bitte in der Manager-Tabelle. ',
    },
    edit: {
      dialogTitle: 'Manager bearbeiten',
      infoAlert: 'Du kannst Berechtigungen nur für Firmen bearbeiten, wo du Admin-Rechte hast.',
      successSnackbar:
        'Die Berechtigungen für {email} wurden aktualisiert. Wir haben eine E-Mail geschickt, um diese Person über die Änderungen zu informieren.',
      tooltip: 'Es muss mindestens einen Admin pro Firma geben.',
    },
  },
};

export default messages;
