// @flow
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { EdsWebHeader } from '@egym/react-design-system/EdsWebHeader';
import { EdsNavigationDrawer } from '@egym/react-design-system/EdsNavigationDrawer';
import { EdsProfilePicture } from '@egym/react-design-system/EdsProfilePicture';
import {
  EdsNavigationList,
  EdsNavigationListHeadline,
  EdsNavigationListItem,
} from '@egym/react-design-system/EdsNavigationList';
import styles from './PageTemplateWithNavigationDrawer.module.scss';

import { EdsIconButton } from '@egym/react-design-system/EdsIconButton';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import AuthenticationContext from 'ui/common/authentication/AuthenticationContext';
import { isCompanyUser } from 'ui/common/authentication/authenticationUtil';
import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { usePayment } from 'service/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import wellpassLogo from 'styles/img/wellpass-logo-color.svg';
import ProfileCard from 'ui/profile-card/ProfileCard';

export type PageTemplateProps = {
  intl: IntlShape,
  children: React.ReactNode,
};

const PageTemplateWithIntl = ({ children, intl: { formatMessage } }: PageTemplateProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user, hasMemberships, logout } = React.useContext(AuthenticationContext);
  const { hasPayment } = usePayment();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(window.innerWidth > 840);
  const profilePictureRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleProfileCardOpen = () => {
    setAnchorEl(profilePictureRef.current);
  };

  const handleProfileCardClose = () => {
    setAnchorEl(null);
  };
  const route = location.pathname;

  const adminSection = useMemo(() => {
    if (isCompanyUser(user)) {
      return (
        <>
          <EdsNavigationListHeadline>
            <FormattedMessage id={'navigationDrawer.companySection.title'} />
          </EdsNavigationListHeadline>
          <EdsNavigationListItem
            onClick={() => navigate('/nav/admin/companies')}
            type="button"
            selected={route?.includes('/admin/companies')}
          >
            <FormattedMessage id={'navigationDrawer.companySection.membershipManagement'} />
            <EdsIcon slot="start">group</EdsIcon>
          </EdsNavigationListItem>
          <EdsNavigationListItem
            onClick={() => navigate('/nav/admin/company-settings')}
            type="button"
            selected={route?.includes('/admin/company-settings')}
          >
            <FormattedMessage id={'navigationDrawer.companySection.companySettings'} />
            <EdsIcon slot="start">settings</EdsIcon>
          </EdsNavigationListItem>
          <EdsNavigationListItem
            type="link"
            href="https://egym-wellpass.com/download/?utm_source=web_login"
            target="_blank"
          >
            <FormattedMessage id={'navigationDrawer.companySection.marketingMaterials'} />
            <EdsIcon slot="start">storefront</EdsIcon>
            <EdsIcon slot="end">open_in_new</EdsIcon>
          </EdsNavigationListItem>
          <EdsNavigationListItem
            type="link"
            href="https://egym-wellpass.com/help-center-firmenkunden/?utm_source=web_login"
            target="_blank"
          >
            <FormattedMessage id={'navigationDrawer.companySection.helpCenter'} />
            <EdsIcon slot="start">help_center</EdsIcon>
            <EdsIcon slot="end">open_in_new</EdsIcon>
          </EdsNavigationListItem>
        </>
      );
    }

    return null;
    // eslint complains because user is passed to isCompanyUser,
    // but we want to rerun the hook when the roles array changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.roles, route, navigate]);

  const accountSection = useMemo(() => {
    if (!hasMemberships && !hasPayment) {
      return null;
    }
    return (
      <>
        <EdsNavigationListHeadline>
          <FormattedMessage id={'navigationDrawer.accountSection.title'} />
        </EdsNavigationListHeadline>
        {hasMemberships && (
          <EdsNavigationListItem
            type="link"
            onClick={() => navigate('/nav/account-settings')}
            selected={route?.includes('/account-settings')}
          >
            <FormattedMessage id={'navigationDrawer.accountSection.accountSettings'} />
            <EdsIcon slot="start">manage_accounts</EdsIcon>
          </EdsNavigationListItem>
        )}
        {hasPayment && (
          <EdsNavigationListItem
            type="link"
            onClick={() => navigate('/nav/payment-details')}
            selected={route?.includes('/payment-details')}
          >
            <FormattedMessage id={'navigationDrawer.accountSection.paymentData'} />
            <EdsIcon slot="start">credit_card</EdsIcon>
          </EdsNavigationListItem>
        )}
      </>
    );
  }, [hasPayment, route, hasMemberships, navigate]);

  return (
    <>
      <ProfileCard anchorEl={anchorEl} onLogout={logout} handleClose={handleProfileCardClose} />
      <EdsWebHeader>
        <EdsIconButton
          slot="start"
          onClick={() => setIsDrawerOpen(previousIsDrawerOpen => !previousIsDrawerOpen)}
        >
          <EdsIcon>menu</EdsIcon>
        </EdsIconButton>

        <img className={styles.logo} src={wellpassLogo} alt="Company Logo" />
        <EdsProfilePicture
          ref={profilePictureRef}
          slot="end"
          type="monogram"
          size="small"
          firstName={user?.firstName || ''}
          lastName={user?.lastName || ''}
          onClick={handleProfileCardOpen}
        />
      </EdsWebHeader>
      <EdsNavigationDrawer
        id="navigation-drawer"
        expanded={isDrawerOpen}
        onExpandedChanged={event => setIsDrawerOpen(event.details)}
      >
        <div className={styles.asideContainer}>
          <EdsNavigationList>
            {adminSection}
            {accountSection}
          </EdsNavigationList>
          <EdsNavigationList>
            <Accordion
              elevation={0}
              disableGutters={true}
              expanded={isExpanded}
              onChange={() => setIsExpanded(!isExpanded)}
              sx={{
                backgroundColor: 'inherit',
              }}
            >
              <AccordionSummary
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: '0px',
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                className={styles.accordionSummary}
              >
                <FormattedMessage id="footer.links.title" />
              </AccordionSummary>

              <AccordionDetails className={styles.accordionDetails}>
                <a
                  className={`nav-link small ${styles.link}`}
                  href={formatMessage({ id: 'footer.links.privacyPolicy.url' })}
                >
                  <FormattedMessage id={'footer.links.privacyPolicy.text'} />
                </a>
                <a
                  className={`nav-link small ${styles.link}`}
                  href={formatMessage({ id: 'footer.links.termsAndConditions.url' })}
                >
                  <FormattedMessage id={'footer.links.termsAndConditions.text'} />
                </a>
                <a
                  className={`nav-link small ${styles.link}`}
                  href={formatMessage({ id: 'footer.links.cookiePolicy.url' })}
                >
                  <FormattedMessage id={'footer.links.cookiePolicy.text'} />
                </a>
                <a
                  className={`nav-link small ${styles.link}`}
                  href={formatMessage({ id: 'footer.links.imprint.url' })}
                >
                  <FormattedMessage id={'footer.links.imprint.text'} />
                </a>
              </AccordionDetails>
            </Accordion>
          </EdsNavigationList>
        </div>
        <div className={`vstack ${styles.content}`} slot="content">
          {children}
        </div>
      </EdsNavigationDrawer>
    </>
  );
};

export const PageTemplate = injectIntl(PageTemplateWithIntl);
