// @flow
import * as React from 'react';
import CompanyUserContext from 'ui/common/authentication/CompanyUserContext';
import LabeledDropdown from '../common/components/labeled-dropdown/LabeledDropdown';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

type Props = {
  intl: IntlShape,
};

const CompanySelectionDropdown = ({ intl: { formatMessage } }: Props) => {
  const { companies, selectedCompanyId, setSelectedCompanyId } =
    React.useContext(CompanyUserContext);

  return (
    <LabeledDropdown
      dropdownId={'companySelection.dropdown'}
      className="company-dropdown"
      label={formatMessage({ id: 'companyMemberships.companyDropdownLabel' })}
      items={companies.map(c => ({
        value: c.CanonicalId__c,
        label: c.Name,
      }))}
      onChange={setSelectedCompanyId}
      value={selectedCompanyId}
      fillWidth={true}
      height={'48px'}
    />
  );
};

export default injectIntl(CompanySelectionDropdown);
