import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import { Grid, InputAdornment, Stack, TextField } from '@mui/material';
import useApi from '../../../../service/api';
import InvalidFieldErrorMessage from '../../../self-signup/components/InvalidFieldErrorMessage';
import RegistrationContext from '../../../self-signup/RegistrationContext';
import { formatValue } from '../../../../service/commonUtil';
import { ReactComponent as SearchIcon } from 'styles/img/icons/search.svg';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { useController } from 'react-hook-form';

type Props = {
  intl: IntlShape,
  control: any,
  controlId: string,
  needTimeZoneId?: boolean,
};

let sessionToken = undefined;

const SearchAddress = ({ intl: { formatMessage }, control, controlId, needTimeZoneId }: Props) => {
  const [isFreeText, setIsFreeText] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [isOptionsPopupOpen, setIsOptionsPopupOpen] = useState(false);
  const { addressPrediction, setAddressPrediction, resolvedAddress, setResolvedAddress } =
    React.useContext(RegistrationContext);
  const [addressPredictionInternal, setAddressPredictionInternal] = useState({});
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const { geocodingPlacesApi } = useApi();

  useEffect(() => {
    if (addressPrediction) {
      setAddressPredictionInternal(addressPrediction);
    }
  }, [addressPrediction]);

  const resolveAddress = useCallback(
    (event, value, reason) => {
      setIsOptionsPopupOpen(false);

      if (reason === 'clear') {
        setPredictions([]);
        setAddressPrediction({});
        setResolvedAddress(undefined);
        return;
      }

      geocodingPlacesApi
        .getPlaceDetails(value.placeId, sessionToken, needTimeZoneId)
        .then(response => {
          sessionToken = undefined;
          setAddressPrediction(value);
          setResolvedAddress(response);
        });
    },
    [geocodingPlacesApi, setAddressPrediction, setResolvedAddress, needTimeZoneId]
  );

  const fetchAddressPredictions = debounce((inputValue, reason) => {
    if (!inputValue || inputValue.length < 5) {
      setPredictions([]);
      return;
    }

    if (reason === 'input') {
      geocodingPlacesApi
        .getAutocompletePlacePredictions(inputValue, sessionToken)
        .then(response => {
          sessionToken = response.sessionToken;
          setPredictions(response.predictions || []);
        });
    }
  }, 500);

  const searchAddress = useCallback(
    (event, inputValue, reason) => {
      if (inputValue && inputValue.length > 0) {
        setIsFreeText(true);
      } else {
        setIsFreeText(false);
      }
      fetchAddressPredictions(inputValue, reason);
    },
    [fetchAddressPredictions]
  );

  const {
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController({
    name: controlId,
    control,
  });

  const shouldDisplayError = (isSubmitted && error) || (error && (isTouched || touched));

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '16px', marginTop: '6px' }}>
        <span className="residenceAddressHeader">
          <FormattedMessage id="searchAddress.headline" />
        </span>
      </Grid>
      <Grid item xs={12} md={6} pr={{ xs: 0, md: 1.5 }}>
        <Autocomplete
          id="searchAddressAutocomplete"
          className={shouldDisplayError ? ' is-invalid' : ''}
          freeSolo={predictions.length > 0}
          options={predictions}
          disableClearable={!isFreeText}
          forcePopupIcon={false}
          filterOptions={x => x}
          onChange={(event, value, reason) => {
            resolveAddress(event, value, reason);
          }}
          slotProps={{ clearIndicator: { visibility: isFreeText ? 'visible' : 'hidden' } }}
          onBlur={() => {
            setFocused(false);
            setTouched(true);
          }}
          onFocus={() => setFocused(true)}
          onClose={() => setIsOptionsPopupOpen(false)}
          value={addressPredictionInternal}
          onInputChange={searchAddress}
          getOptionLabel={o => o.description || ''}
          renderOption={(props, option) => (
            <React.Fragment key={option.placeId}>
              <li {...props}>{option.description}</li>
              <hr />
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={
                !focused
                  ? `${formatMessage({ id: 'searchAddress.input.placeholder' })} *`
                  : undefined
              }
              label={
                focused || resolvedAddress || isFreeText
                  ? `${formatMessage({ id: 'searchAddress.input.label' })} *`
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
                notched: false,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon width={18} height={18} />
                  </InputAdornment>
                ),
              }}
              helperText={formatMessage({ id: 'searchAddress.helperText' })}
              error={shouldDisplayError}
            />
          )}
          noOptionsText={formatMessage({ id: 'searchAddress.noOptions' })}
          open={isFreeText && isOptionsPopupOpen}
          onOpen={() => setIsOptionsPopupOpen(true)}
        />
        <input
          type="hidden"
          id="searchAddressValidation"
          className={shouldDisplayError ? 'is-invalid' : ''}
        />
        {shouldDisplayError && <InvalidFieldErrorMessage meta={{ error }} />}
      </Grid>

      {resolvedAddress && (
        <Grid
          item
          sx={{ backgroundColor: '#EEEEEE', borderRadius: '8px', marginTop: '16px' }}
          xs={12}
        >
          <Stack
            sx={{
              padding: '20px',
              fontWeight: '700',
              letterSpacing: '0.02em',
              lineHeight: '150%',
            }}
          >
            <div id="resolvedAddressId">{`${formatValue(resolvedAddress.street)} ${formatValue(
              resolvedAddress.streetNumber
            )}`}</div>
            <div>{`${formatValue(resolvedAddress.zipCode)}${
              resolvedAddress.zipCode ? ',' : ''
            } ${formatValue(resolvedAddress.city)}`}</div>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(SearchAddress);
