import moment from 'moment';
import { DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX } from 'service/duplicateSignupUtil.ts';
export class LocalStorage {
  readValue = (key: string) => {
    return JSON.parse(localStorage.getItem(key));
  };

  setValue = (key: string, value: string) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  cleanupExpiredDuplicateSignupData = () => {
    const localStorageKeys = Object.keys(localStorage);
    for (var index in localStorageKeys) {
      const key = localStorageKeys[index];
      if (
        key.includes(DUPLICATE_SIGNUP_LOCAL_STORAGE_PREFIX) &&
        moment(this.readValue(key)).isBefore(moment())
      ) {
        localStorage.removeItem(key);
      }
    }
  };
}
