import React, { useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { COMPANY_ADMIN, COMPANY_EDITOR } from '../common/authentication/authenticationConstants';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';
import { EdsLabel } from '@egym/react-design-system/EdsLabel';
import { EdsTooltip } from '@egym/react-design-system/EdsTooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import type { CompanyType } from 'service/hooks/company';
import classNames from 'classnames';

export type AssignRoleStepType = {
  companies: CompanyType[],
};

type RoleSelectionType = {
  company: CompanyType,
  roles: any,
  handleRoleSelected: () => void,
};

const dataTestId = 'assign_role_step';
const noop = () => {};
const RoleSelection = ({ company, roles, handleRoleSelected }: RoleSelectionType) => (
  <div className="d-flex py-2">
    {company.isDisabled && (
      <EdsTooltip position="bottom">
        <FormattedMessage id="companySettings.edit.tooltip" />
      </EdsTooltip>
    )}
    <FormControl className={classNames({ 'opacity-30': company.isDisabled })}>
      <RadioGroup aria-labelledby={company.entityId} value={roles[company.entityId] || null}>
        <div
          data-testid={`${dataTestId}_${company.entityId}_${COMPANY_ADMIN}`}
          id="company_admin_option"
          className="mb-2"
        >
          <FormControlLabel
            value={COMPANY_ADMIN}
            control={
              <Radio
                className="py-0"
                disableRipple
                sx={{
                  color: 'var(--eds-colors-neutral-black-80)',
                  '&.Mui-checked': {
                    color: 'var(--eds-colors-theme-primary-70)',
                  },
                }}
                disabled={company.isDisabled}
                onClick={() => handleRoleSelected(company.entityId, COMPANY_ADMIN)}
              />
            }
            label={
              <Typography variant="body1">
                <FormattedMessage id="roles.admin" />
              </Typography>
            }
          />
          <Typography variant="body2" className="body2 role-helper-text">
            <FormattedMessage id="roles.adminDescription" />
          </Typography>
        </div>

        <div
          data-testid={`${dataTestId}_${company.entityId}_${COMPANY_EDITOR}`}
          id="company_editor_option"
          className="mt-2"
        >
          <FormControlLabel
            value={COMPANY_EDITOR}
            control={
              <Radio
                disableRipple
                className="py-0"
                sx={{
                  color: 'var(--eds-colors-neutral-black-80)',
                  '&.Mui-checked': {
                    color: 'var(--eds-colors-theme-primary-70)',
                  },
                }}
                disabled={company.isDisabled}
                onClick={() => handleRoleSelected(company.entityId, COMPANY_EDITOR)}
              />
            }
            label={
              <Typography variant="body1">
                <FormattedMessage id="roles.editor" />
              </Typography>
            }
          />
          <Typography variant="body2" className="body2 role-helper-text">
            <FormattedMessage id="roles.editorDescription" />
          </Typography>
        </div>
      </RadioGroup>
    </FormControl>
  </div>
);

const AssignRoleStep = ({ companies }: AssignRoleStepType) => {
  const { watch, setValue } = useFormContext();
  const [expanded, setExpanded] = useState('');

  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const companiesWhereActorIsAdmin = useMemo(() => {
    if (!companies?.length) {
      return [];
    }
    return companies.filter(obj => obj.authority === COMPANY_ADMIN);
  }, [companies]);

  const roles = watch('roles');

  const handleCheckbox = entityId => {
    const rolesCopy = {
      ...roles,
    };
    if (rolesCopy[entityId]) {
      delete rolesCopy[entityId];
    } else {
      rolesCopy[entityId] = COMPANY_EDITOR;
    }
    setValue('roles', rolesCopy);
    setExpanded(entityId);
  };

  const handleRoleSelected = (entityId, role) => {
    let rolesCopy = {
      ...roles,
    };
    if (roles[entityId] === role) {
      delete rolesCopy[entityId];
    } else {
      rolesCopy[entityId] = role;
    }

    setValue('roles', rolesCopy);
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      flex={1}
      className="assign-role-container"
      data-testid={dataTestId}
    >
      {companiesWhereActorIsAdmin.length === 1 && (
        <RoleSelection
          company={companiesWhereActorIsAdmin[0]}
          roles={roles}
          handleRoleSelected={handleRoleSelected}
        />
      )}
      {companiesWhereActorIsAdmin.length > 1 && (
        <>
          <Typography
            variant="body1"
            className="assign-role-selected-companies"
            data-testid={`${dataTestId}_companySettings.add.companyCounter`}
          >
            <FormattedMessage
              id="companySettings.add.companyCounter"
              values={{
                selected: Object.keys(roles).length,
                total: companiesWhereActorIsAdmin.length,
              }}
            />
          </Typography>
          <Stack direction="column" alignItems="flex-start" className="assign-role-company-list">
            {companiesWhereActorIsAdmin.map(obj => (
              <span
                key={obj.entityId}
                data-testid={`${dataTestId}_${obj.entityId}`}
                className={classNames('w-100 d-flex assign-role-accordion-summary-content gap-0')}
              >
                <input
                  role="button"
                  type="checkbox"
                  className={classNames('form-check-input checkbox', {
                    'opacity-30': obj.isDisabled,
                  })}
                  onChange={() => handleCheckbox(obj.entityId)}
                  checked={!!roles[obj.entityId]}
                  disabled={obj.isDisabled}
                />
                <Accordion
                  data-testid={`${dataTestId}_accordion`}
                  sx={{
                    '&:before': { height: '0px' },
                    '&.Mui-disabled': { opacity: 0.3, backgroundColor: 'inherit' },
                  }}
                  className="w-100"
                  elevation={0}
                  disableGutters={true}
                  expanded={expanded === obj.entityId}
                  onChange={obj.isDisabled ? noop : handleExpand(obj.entityId)}
                  disabled={obj.isDisabled}
                >
                  <AccordionSummary
                    id={obj.entityId}
                    className="p-0 assign-role-accordion-summary gap-2"
                    classes={{ content: 'align-items-center justify-content-between' }}
                    expandIcon={<EdsIcon>keyboard_arrow_down</EdsIcon>}
                  >
                    <Typography variant="body1">{obj.entityName}</Typography>
                    {roles[obj.entityId] === COMPANY_EDITOR && (
                      <div className="editor-chip">
                        <EdsLabel type="info">
                          <FormattedMessage id="roles.editor" />
                        </EdsLabel>
                      </div>
                    )}
                    {roles[obj.entityId] === COMPANY_ADMIN && (
                      <EdsLabel type="info">
                        <FormattedMessage id="roles.admin" />
                      </EdsLabel>
                    )}
                  </AccordionSummary>
                  <AccordionDetails
                    data-testid={`${dataTestId}_accordion_details`}
                    className="d-flex flex-column p-0"
                    role="radiogroup"
                  >
                    <RoleSelection
                      company={obj}
                      roles={roles}
                      handleRoleSelected={handleRoleSelected}
                    />
                  </AccordionDetails>
                </Accordion>
              </span>
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default AssignRoleStep;
