// @flow
/**
 * Returns the formatted string for the price.
 * @param locale the locale of the user
 * @param price the amount to be formatted
 * @param currencyCode the code of the currency
 * @returns {string} the formatted string for the price
 */
export const formatCurrency = (locale: string, price: number, currencyCode: string): string => {
  if (locale && price && currencyCode) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: price % 1 > 0 ? 2 : 0,
      maximumFractionDigits: 2,
    }).format(price);
  }
  return price + ' ' + currencyCode;
};
