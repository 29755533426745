// @flow
import * as React from 'react';

type Props = {
  imageUrl: string,
  title: string,
  description: string,
};

const InfoBox = ({ imageUrl, title, description }: Props) => (
  <div className="col-12 col-md-2 text-center mb-3 mt-1">
    <div className="info-image-block">
      <img className="img-fluid" src={imageUrl} alt={description} />
      <div className="fw-bolder info-title">{title}</div>
    </div>
    <div className="info-description">{description}</div>
  </div>
);

export default InfoBox;
