import React, { useEffect } from 'react';
import { Alert, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextFormField from '../common/components/TextFormField';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { VALIDATION_EMAIL_IS_PRESENT_IN_THE_TABLE } from 'validation/common/errorCodes';
import type { ManagerType } from 'service/hooks/company';

const dataTestId = 'personal_details_step';

export type PersonalDetailsStepType = {
  intl: IntlShape,
  managers?: ManagerType[],
};
const PersonalDetailsStep = ({ intl: { formatMessage }, managers }: PersonalDetailsStepType) => {
  const {
    control,
    setError,
    watch,
    formState: { errors },
  } = useFormContext();

  const email = watch('email');

  useEffect(() => {
    if (managers?.some(manager => manager.email === email)) {
      setError('alertError', { code: VALIDATION_EMAIL_IS_PRESENT_IN_THE_TABLE });
    } else {
      setError('alertError', undefined);
    }
  }, [email, managers, setError]);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      flex={1}
      className="personal-details-container"
      data-testid={dataTestId}
    >
      <div className="form-floating w-100">
        <TextFormField
          data-testid={`${dataTestId}_first_name`}
          controlId="firstName"
          controlLabel={`general.field.firstName.label`}
          placeholder={`general.field.firstName.placeholder`}
          containerClassName={'row m-0'}
          register={{
            ...control.register('firstName'),
          }}
        />
      </div>
      <div className="form-floating w-100">
        <TextFormField
          data-testid={`${dataTestId}_last_name`}
          controlId="lastName"
          controlLabel={`general.field.lastName.label`}
          placeholder={`general.field.lastName.placeholder`}
          containerClassName={'row m-0'}
          register={{
            ...control.register('lastName'),
          }}
        />
      </div>
      <div className="form-floating w-100">
        <TextFormField
          data-testid={`${dataTestId}_email`}
          controlId="email"
          controlLabel={`general.field.email.label`}
          placeholder={`general.field.email.placeholder`}
          containerClassName={'row m-0'}
          register={{
            ...control.register('email'),
          }}
        />
        <div data-testid={`${dataTestId}_email_helper`} className="email-helper">
          <FormattedMessage
            id="general.field.email.helper"
            values={{
              egymId: (
                <a
                  target="_blank"
                  href={formatMessage({ id: 'accountSettingsPage.egymId.link.url' })}
                  rel="noreferrer"
                >
                  EGYM ID
                </a>
              ),
            }}
          />
        </div>
      </div>
      {!!errors?.alertError?.code && (
        <Alert
          data-testid={`${dataTestId}_alert`}
          severity="error"
          sx={{
            padding: '8px 12px 8px 16px',
            margin: '0px',
          }}
        >
          <FormattedMessage id={errors?.alertError?.code} />
        </Alert>
      )}
    </Stack>
  );
};

export default injectIntl(PersonalDetailsStep);
