// @flow
import * as React from 'react';

type Props = {
  children: any,
};

const SelfSignupStepTemplate = ({ children }: Props) => (
  <>
    <div className="pt-4" />

    {children}
  </>
);

export const withSelfSignupStepTemplate = (Component: any) => {
  const Wrapper = (props: any) => (
    <SelfSignupStepTemplate>
      <Component {...props} />
    </SelfSignupStepTemplate>
  );

  Wrapper.displayName = `withSelfSignupStepTemplate(${Component.displayName || Component.name})`;

  return Wrapper;
};
