import { useContext } from 'react';
import WelcomeContext from '../WelcomeContext';
import Iframe from 'react-iframe';
import * as React from 'react';
import style from './GymFinderStandalone.module.scss';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowBackIconSvg } from '../../../styles/img/icons/arrow_back.svg';
import { useNavigate } from 'react-router-dom';

const GymFinderStandalone = () => {
  const { gymFinderUrl } = useContext(WelcomeContext);
  const navigate = useNavigate();

  return (
    <div className={style.gymFinderStandaloneContainer}>
      <div className={style.gymFinderStandaloneBanner}>
        <span onClick={() => navigate(-1)} className={style.gymFinderStandaloneBannerBackIcon}>
          <ArrowBackIconSvg />
        </span>
        <button
          id="welcome.standalone.banner.submit"
          className={`btn btn-lg btn-primary ${style.gymFinderStandaloneBannerSignupButton}`}
          type="submit"
        >
          <FormattedMessage id="welcome.intro.button" />
        </button>
      </div>
      <div className={style.gymFinderStandaloneGymFinder}>
        <Iframe
          loading={'lazy'}
          url={gymFinderUrl}
          id="gymFinderIframe"
          className={style.gymFinderIframe}
          allow="geolocation"
        />
      </div>
    </div>
  );
};

export default GymFinderStandalone;
