/**
 * Gets a property (possibly deeply nested) of an object.
 *
 * Example usage: getPropertyValue(membership, 'company.price.currency').
 *
 * In case any of the intermediate objects is undefined, the function will return the fallback value.
 * The fallback argument is optional, and the default value is an empty string.
 */
export const getPropertyValueOrFallback = (object: any, property: string, fallback = ''): any => {
  const propertiesArray = property.split('.');
  const propertyValue = propertiesArray.reduce(
    (accumulator, currentValue) => accumulator && accumulator[currentValue],
    object
  );
  return propertyValue ? propertyValue : fallback;
};

export const compareObjectsAsc = (key, a, b) => {
  if (!a?.[key] && !b?.[key]) {
    return 0;
  }
  if (!a?.[key]) {
    return -1;
  }
  if (!b?.[key]) {
    return 1;
  }

  if (typeof a[key] === 'string' && typeof b[key] === 'string') {
    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
  }
  if (a[key] > b[key]) {
    return 1;
  }
  if (a[key] < b[key]) {
    return -1;
  }

  return 0;
};
