import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import customResolver from 'validation/common/customResolver';

type Props = {
  children: ReactNode,
  onSubmit: Function,
  mode: 'all' | 'onBlur' | 'onChange',
  defaultValues: { [key: string]: string | number | undefined },
  validator?: Function,
  customDependantFieldUpdate?: Function,
  dependantFieldWatch?: string[] | string,
  shouldKeepValues?: boolean,
};

const Form = ({
  children,
  onSubmit,
  mode = 'all',
  defaultValues,
  validator,
  customDependantFieldUpdate,
  dependantFieldWatch = [],
  shouldKeepValues = true,
}: Props) => {
  const { register, control, handleSubmit, formState, watch, setValue, reset, getValues, ...rest } =
    useForm({
      mode,
      defaultValues,
      resolver: validator ? customResolver(validator) : undefined,
    });

  const watchFields = watch(dependantFieldWatch);

  React.useEffect(() => {
    if (customDependantFieldUpdate && typeof customDependantFieldUpdate === 'function') {
      customDependantFieldUpdate(watchFields, setValue);
    }
  }, [customDependantFieldUpdate, watchFields, setValue]);

  React.useEffect(() => {
    if (shouldKeepValues) {
      reset(defaultValues, { keepValues: true });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, shouldKeepValues]);

  if (typeof children === 'function') {
    return (
      <FormProvider
        register={register}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        watch={watch}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        {...rest}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {children({
            register,
            control,
            formState,
            watch,
            setValue,
            getValues,
          })}
        </form>
      </FormProvider>
    );
  }

  return null;
};

export default Form;
