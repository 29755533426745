import CustomAlert from '../common/components/CustomAlert';
import React, { useContext } from 'react';
import VerificationOptionModal from './components/hris/VerificationOptionModal';
import RegistrationContext from './RegistrationContext';
import { ALL_VERIFICATION_OPTIONS, getDropdownOptions } from '../common/modal/availableOptionsUtil';
import { useIntl } from 'react-intl';

type Props = {
  openModal: boolean,
  setOpenModal: Function,
  showAlert: boolean,
};
const AdditionalVerificationAlertSection = ({ openModal, setOpenModal, showAlert }: Props) => {
  const {
    availableAdditionalVerificationOptions,
    additionalVerification,
    setAdditionalVerification,
    setSelectedVerificationField,
  } = useContext(RegistrationContext);
  const { formatMessage } = useIntl();
  const availableOptionTypes = availableAdditionalVerificationOptions.map(option => option.type);

  const availableDropdownOptions = getDropdownOptions(availableOptionTypes);
  const openOptionsModal = () => {
    if (availableOptionTypes.length <= 1) {
      setSelectedVerificationField(availableDropdownOptions[0].value);
      setAdditionalVerification({ ...additionalVerification, showField: true });
    } else {
      setOpenModal(true);
    }
  };
  return (
    <div>
      <div className={'my-3'}>
        {showAlert && (
          <CustomAlert
            controlId={`additionalVerificationWarning`}
            info={generateInfoDetails(availableOptionTypes, formatMessage)}
            className={'warning'}
            buttonAction={openOptionsModal}
          />
        )}
      </div>
      <VerificationOptionModal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
      ></VerificationOptionModal>
    </div>
  );
};

const generateInfoDetails = (availableOptions, formatMessage) => {
  const optionMap = ALL_VERIFICATION_OPTIONS.reduce((acc, option) => {
    acc[option.id] = formatMessage({ id: option.label });
    return acc;
  }, {});

  const defaultOption = 'WORK_EMAIL';

  const selectedOption = availableOptions.length === 1 ? availableOptions[0] : defaultOption;

  const buttonValue = { verificationOption: optionMap[selectedOption] };

  const values = {
    strong: chunks => <strong>{chunks}</strong>,
    br: () => <br />,
    ul: chunks => <ul className="mb-1 olList">{chunks}</ul>,
    li: chunks => <li className="liList">{chunks}</li>,
    verificationOption: optionMap[selectedOption],
  };

  return {
    title:
      availableOptions.length > 1
        ? 'additionalVerificationOptionAlert.multipleOptionWarning.title'
        : 'additionalVerificationOptionAlert.singleOptionWarning.title',
    message:
      availableOptions.length > 1
        ? 'additionalVerificationOptionAlert.multipleOptionWarning.body'
        : 'additionalVerificationOptionAlert.singleOptionWarning.body',
    values,
    buttonText:
      availableOptions.length > 1
        ? 'additionalVerificationOptionAlert.multipleOptionWarning.button.title'
        : 'additionalVerificationOptionAlert.singleOptionWarning.button.title',
    buttonValue,
  };
};

export default AdditionalVerificationAlertSection;
