// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SubmitErrors from 'ui/self-signup/SelfSignupSubmitErrors';
import Header from 'ui/layout/Header';
import Footer from 'ui/layout/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import RegistrationContext from './RegistrationContext';
import SignupFormListener from './SignupFormListener';
import { post, httpGet } from 'service/http/http-client';
import moment from 'moment';
import { convertUserServiceResponse } from '../../service/http/responseConverter';
import { FORM_ERROR } from 'final-form';
import SpinnerContext from '../common/spinner/SpinnerContext';
import Form from 'ui/common/components/Form';
import { DECLARATION_OF_CONSENT_ACTIONS } from './reducers/useDeclarationOfConsent';

/** One step of the multi-step wizard. */
type StepProps = {
  validateStep?: Function,
  isIndependentPage: boolean,
  children: any,
};

export const SelfSignupFormWizardStep = ({ children }: StepProps) => children;

/** The form-wizard. */
type Props = {
  children: React.ChildrenArray<React.Element<typeof SelfSignupFormWizardStep>>,
  initialValues?: any,
  step: number,
  onSubmit: Function,
  isPartnerSignup?: boolean,
  partnerSignupError?: string,
  showStandalone?: boolean,
  errorsSubmit?: any,
};

const SelfSignupFormWizard = ({
  children,
  initialValues,
  step,
  onSubmit,
  isPartnerSignup,
  partnerSignupError,
  showStandalone,
  errorsSubmit,
}: Props) => {
  const {
    isNewUser,
    setIsNewUser,
    setEmployeeEligibilityStatus,
    setAddressPrediction,
    setResolvedAddress,
    registerAndProceedToggle,
    setRegisterAndProceedToggle,
    setWorkEmail,
    declarationOfConsentDetails,
    alertNotification,
    setDeclarationOfConsentDetails,
    setCorporateEmployeeDetails,
    hasValidMembershipStartDates,
    setAvailableAdditionalVerificationOptions,
    additionalVerification,
  } = React.useContext(RegistrationContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const location = useLocation();
  const navigate = useNavigate();

  /** Goes to the next wizard step. */
  const next = (scale: Number) => {
    const steps = React.Children.toArray(children);
    const nextStep = Math.min(step + scale, steps.length - 1);
    const currentUrlParams = new URLSearchParams(location.search.substring(1));
    currentUrlParams.set('step', nextStep.toString());
    navigate(location.pathname + '?' + currentUrlParams.toString());
    window.scrollTo(0, 0); // scroll to the top of the page
  };

  // resets cache state if you jump between steps
  const resetCachedState = () => {
    setAddressPrediction(undefined);
    setResolvedAddress(undefined);
    setWorkEmail(undefined);
    setIsNewUser(true);
    setDeclarationOfConsentDetails({ action: DECLARATION_OF_CONSENT_ACTIONS.RESET_MODAL });
  };

  // handles cache for a plus1 signup
  const handleCache = (values?) => {
    if (step === 2) {
      resetCachedState();
    }
  };

  /** Goes to the previous wizard step. */
  const previous = values => {
    handleCache(values);
    navigate(-1);
    window.scrollTo(0, 0); //scroll to the top of the page
  };

  /** Validates the current wizard step. */
  const validateStep = (values: any) => {
    const activeStep = React.Children.toArray(children)[step];
    return activeStep.props.validateStep ? activeStep.props.validateStep(values) : {};
  };

  const getStep1Url = () => {
    const currentUrlParams = new URLSearchParams(location.search.substring(1));
    currentUrlParams.set('step', `1`);
    return location.pathname + '?' + currentUrlParams.toString();
  };

  const buildEmployeeEligibilityRequest = values => {
    let requestBody = {
      offerId: values.offerId,
    };
    if (additionalVerification.value) {
      requestBody[`${additionalVerification.type}`] = additionalVerification.value;
    } else {
      requestBody.firstName = values.firstName || values.reviewedFirstName;
      requestBody.lastName = values.lastName || values.reviewedLastName;
      const birthday = values.birthday || values.reviewedBirthday;
      requestBody.birthday = moment(birthday, 'DD.MM.YYYY').format('YYYY-MM-DD');
    }
    return requestBody;
  };

  const handleUserServiceErrors = (step, values, error) => {
    switch (error?.code) {
      case 'validation.signup.general.userNotFound':
        setEmployeeEligibilityStatus({
          noExactMatch: true,
          showErrorModal: additionalVerification.showField,
        });
        break;
      case 'validation.signup.general.userNotEligible':
        setEmployeeEligibilityStatus({ matchButNotEligible: true, showErrorModal: true });
        break;
      case 'validation.signup.general.multipleUsersFound':
        setEmployeeEligibilityStatus({ multipleMatches: true, showErrorModal: false });
        break;
      default:
        break;
    }
  };

  const isUserEligible = async (step, values) => {
    try {
      const requestBody = buildEmployeeEligibilityRequest(values);
      setRegisterAndProceedToggle(!registerAndProceedToggle);

      const userEligibilityResponse = await post(
        `v1/signup/corporate-employee/verify`,
        requestBody
      );
      if (userEligibilityResponse.ok) {
        const data = await userEligibilityResponse.json();
        setCorporateEmployeeDetails({
          corporateEmployeeId: data?.corporateEmployeeId,
          availableMembershipStartDates: data?.availableMembershipStartDates,
        });
        if (step === 1 && data?.egymAccountEmail) {
          setEmployeeEligibilityStatus({
            matchButAlreadyRegistered: true,
            maskedEmail: data.egymAccountEmail,
          });
          return false;
        }
      } else if (userEligibilityResponse.status === 422) {
        const userEligibilityData = await userEligibilityResponse.json();

        if (values?.integrationScopeId) {
          await httpGet(
            `/v1/additional-verification?integrationScopeId=${values.integrationScopeId}`
          )
            .then(additionalVerifications => {
              if (additionalVerifications?.verificationOptions?.length > 0) {
                setAvailableAdditionalVerificationOptions(
                  additionalVerifications?.verificationOptions
                );
              }
            })
            .catch(error => {
              console.log(error);
            });
        }

        const errors = convertUserServiceResponse(userEligibilityData);
        handleUserServiceErrors(step, values, errors?.[FORM_ERROR]?.[0]);
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Called whenever the React Final Form is 'submitted'.
   *
   * The form is 'submitted' whenever a button with type===submit is clicked.
   * Here we have two such buttons: Next and Submit.
   *
   * @param values the values for all form fields, which are stored by ReactFinalForm in its state.values field.
   */
  const handleSubmitInWizard = async (values: any) => {
    const steps = React.Children.toArray(children);
    const isLastStep = step === steps.length - 1;
    if (isLastStep) {
      return onSubmit(values);
    }
    if (step !== 1 && step !== 2) {
      return next(1);
    }
    if (values.integrationScopeId && !(await executeWithSpinner(isUserEligible(step, values)))) {
      return;
    }
    const scale = step === 1 ? 2 : 1;
    return next(scale);
  };

  const steps = React.Children.toArray(children);
  const numberOfSteps = steps.length;
  const isLastStep = step === numberOfSteps - 1;
  const activeStep = steps[step];
  const isIndependentPage = activeStep.props.isIndependentPage;
  const isSignupButtonVisible = isPartnerSignup ? hasValidMembershipStartDates : true;
  const isLoginFormHidden = isNewUser || step > 1;
  const isNextButtonVisible = isPartnerSignup
    ? !partnerSignupError && isLoginFormHidden
    : isLoginFormHidden;
  const prevButtonName = () => {
    return isLastStep
      ? 'selfsignup.btn.previous.onmembershipcreation'
      : 'selfsignup.btn.previous.onaccountcreation';
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        {!isIndependentPage && <Header headerTitle="signupPage.headerTitle" />}

        <main className="flex-grow-1" id="main-content">
          <Form
            onSubmit={handleSubmitInWizard}
            validator={validateStep}
            defaultValues={initialValues}
          >
            {({ formState: { isSubmitting, isValid } }) => {
              return (
                <>
                  {isIndependentPage && activeStep}
                  {!isIndependentPage && (
                    <div className="container container-max-width">
                      {isPartnerSignup && partnerSignupError && (
                        <div
                          id="partnersignup.error.message"
                          className="alert alert-danger text-center mt-4"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          <FormattedMessage id={`${partnerSignupError}`} />
                        </div>
                      )}
                      {activeStep}
                      {isLastStep &&
                        errorsSubmit && ( // CHECK GENERAL ERROR
                          <SubmitErrors
                            submitError={errorsSubmit?.[FORM_ERROR]}
                            gotourl={getStep1Url()}
                          />
                        )}
                      <div className="container mb-4">
                        <div className="row">
                          <div className="col-3 col-lg-1 mt-3">
                            {isLoginFormHidden && (
                              <button
                                type="button"
                                id={prevButtonName()}
                                className="btn btn-link text-decoration-none ps-0"
                                onClick={() => previous()}
                              >
                                <FormattedMessage id="general.button.previous">
                                  {txt => txt}
                                </FormattedMessage>
                              </button>
                            )}
                          </div>
                          <div className="col-9 col-lg-4 mt-3 d-grid">
                            {!isLastStep && isNextButtonVisible && (
                              <button
                                id="selfsignup.btn.next"
                                type="submit"
                                className="btn btn-lg btn-primary"
                                disabled={
                                  !declarationOfConsentDetails.confirmed &&
                                  (alertNotification?.birthday?.warning ||
                                    alertNotification?.reviewedBirthday?.warning)
                                }
                              >
                                <FormattedMessage id={`signupPage.step${step}.nextbutton`}>
                                  {txt => txt}
                                </FormattedMessage>
                              </button>
                            )}
                            {isLastStep && isSignupButtonVisible && (
                              <button
                                id="selfsignup.btn.submit"
                                type="submit"
                                className="btn btn-lg btn-primary"
                                disabled={isSubmitting || !isValid}
                              >
                                <FormattedMessage id="general.button.submit">
                                  {txt => txt}
                                </FormattedMessage>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <SignupFormListener />
                </>
              );
            }}
          </Form>
        </main>
        {!showStandalone && <Footer />}
      </div>
    </React.Fragment>
  );
};
export default SelfSignupFormWizard;
