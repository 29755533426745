export const AUSTRIA = 'AT';
export const GERMANY = 'DE';
export const AMERICA = 'US';

export const COUNTRY_CODES_LIST = [AUSTRIA, GERMANY];

// Sorted by most likely option
export const AVAILABLE_COUNTRY_CODES = {
  'de-AT': AUSTRIA,
  'en-AT': AUSTRIA,
  'de-DE': GERMANY,
  'en-DE': GERMANY,
};
