import React from 'react';
import type { MembershipOfferType } from 'ui/admin/companies/memberships/types.js.flow';
import wellpassLogo from 'styles/img/wellpass-logo-color.svg';
import { Parser } from 'html-to-react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import BenefitItem from './BenefitItem';
import { formatCurrency } from 'service/currencyUtil';

type Props = {
  intl: IntlShape,
  membershipOffer: MembershipOfferType,
  isPartnerSignup: boolean,
};
/**
 * IMPORTANT:
 * MembershipCardComponent should be wrapped into manager component when it would be necessary to
 * show more than one card on signup page.
 * In current case according to ticket QT-4262 (https://egym.atlassian.net/browse/QT-4262) we
 * should show only one MembershipCardComponent and only for partner signup page.
 */
const MembershipCardComponent = ({ intl, membershipOffer, isPartnerSignup }: Props) => {
  const benefits = intl.formatMessage({ id: 'welcome.card.partnerOfferCard.benefits' }).split('\n');
  const htmlToReactParser = new Parser();

  return (
    <div>
      <div className="row justify-content-center">
        <h3 className="text-dark fw-bold cards-section-header">
          <FormattedMessage
            id="welcome.card.partnerOfferCard.headline"
            values={{
              span: chunks => <span>{chunks}</span>,
            }}
          />
        </h3>
      </div>
      <div className="row justify-content-center">
        <div className="offer-card card-border mb-5">
          <div className="offer-card-body">
            <div className="text-center mb-4">
              <img className="img-fluid" src={wellpassLogo} alt="Wellpass" />
            </div>
            {isPartnerSignup && (
              <h5 className="plus-one-offer-card-title">
                <FormattedMessage id="welcome.card.partnerOfferCard.type" />
              </h5>
            )}
            <h5 className="offer-card-title">
              {isPartnerSignup ? (
                <FormattedMessage id="welcome.card.partnerOfferCard.typeTitle" />
              ) : (
                membershipOffer && membershipOffer.type
              )}
            </h5>
            {membershipOffer &&
              membershipOffer.priceAmount !== undefined &&
              membershipOffer.priceAmount !== null && (
                <div className="row text-center mb-4">
                  <h6 className="offer-card-price-text small">
                    <FormattedMessage
                      id="welcome.card.partnerOfferCard.price"
                      values={{
                        span: chunks => <span className="offer-card-price">{chunks}</span>,
                        price: formatCurrency(
                          intl.locale,
                          membershipOffer.priceAmount,
                          membershipOffer.priceCurrency
                        ),
                      }}
                    />
                  </h6>
                </div>
              )}
            <div className="container text-center mb-5">
              <button id="selfsignup.btn.register.card" className="btn btn-primary" type="submit">
                <FormattedMessage id="welcome.card.button" />
              </button>
            </div>
            <h6 className="offer-card-subtitle mb-2">
              <FormattedMessage id="welcome.card.benefitsTitle" />
            </h6>
            {benefits.map((benefit, idx) => (
              <BenefitItem key={idx} description={htmlToReactParser.parse(benefit)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MembershipCardComponent);
