import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import MembershipStartDropdownFormField from 'ui/self-signup/components/MembershipStartDropdownFormField';
import DefaultTextFormField from 'ui/self-signup/components/DefaultTextFormField';
import ConsentCheckboxFormField from 'ui/self-signup/components/ConsentCheckboxFormField';
import { withSelfSignupStepTemplate } from 'ui/self-signup/SelfSignupStepTemplate';
import { formatCurrency } from 'service/currencyUtil';
import type { MembershipOffer, SignupPage } from 'ui/self-signup/types';
import type { ReferralMembershipSignUpWrapper } from './types';
import CountrySelection from 'ui/country-selection/CountrySelection';
import { useFormContext } from 'react-hook-form';
import RegistrationContext from './RegistrationContext';
import { usePayment } from '../../service/hooks';
import { useNavigate } from 'react-router-dom';

import { EdsAlert } from '@egym/react-design-system/EdsAlert';
import { EdsIcon } from '@egym/react-design-system/EdsIcon';

import style from './Step3.module.scss';
import ExternalLink from './components/ExternalLink';

type Props = {
  signupPage: SignupPage,
  signupPageOffer: MembershipOffer,
  gymsCount: number,
  intl: IntlShape,
  referralMembershipWrapper?: ReferralMembershipSignUpWrapper,
  isPartnerSignup?: boolean,
  countryCode?: string,
  onCountryCodeChangeEvent?: Function,
};

const Step3 = ({
  signupPage,
  signupPageOffer,
  gymsCount,
  referralMembershipWrapper,
  isPartnerSignup,
  countryCode,
  onCountryCodeChangeEvent,
  intl: { formatMessage, locale },
}: Props) => {
  const navigate = useNavigate();
  const { control, setValue, unregister } = useFormContext();
  const { isNewUser } = useContext(RegistrationContext);
  const shouldFetchPaymentData = !isNewUser && signupPageOffer?.b2cPayment;
  const { payment } = usePayment(shouldFetchPaymentData);
  const isSignupPageWithPrice =
    signupPageOffer.type !== 'FREE' && signupPageOffer.priceAmount && signupPageOffer.priceCurrency;

  const getPaymentDetailsId = () => {
    if (signupPageOffer.b2cPayment) {
      return 'signupPage.field.paymentDetails.part3.b2c';
    } else if (signupPageOffer.priceAmount && signupPageOffer.priceCurrency) {
      return 'signupPage.field.paymentDetails.part3.nonB2cWithPrice';
    } else if (signupPageOffer.priceAmount === 0) {
      return ' ';
    } else {
      return 'signupPage.field.paymentDetails.part3.nonB2cWithoutPrice';
    }
  };

  useEffect(() => {
    if (isNewUser) {
      return;
    }
    if (payment) {
      setValue('bankAccountHolderName', payment.ownerName);
      setValue('iban', `****${payment.ibanNumber.slice(4)}`);
    }
    unregister(['firstName', 'lastName', 'birthday', 'gender', 'email', 'password']);
  }, [isNewUser, payment, setValue, unregister]);

  return (
    <>
      <h3 className="text-dark pt-2 pb-3">
        <FormattedMessage id="signupPage.step3.title" />
      </h3>
      <div className="mb-4">
        <div className="row">
          <div className="col-12 membership-start-parent">
            <MembershipStartDropdownFormField
              offer={signupPageOffer}
              referralMembershipWrapper={referralMembershipWrapper}
              control={control}
            />
          </div>
        </div>

        {isPartnerSignup && (
          <div className="row">
            <div className="col small text-muted ms-2">
              <FormattedMessage id="signupPage.step3.preCountrySelectionText" />
              &nbsp;
              <CountrySelection
                countryCode={countryCode}
                onCountryCodeChange={code => onCountryCodeChangeEvent(code)}
                styleOptions={{ boldCountryName: true, wrapperClassName: 'd-inline-block' }}
              />
              <FormattedMessage id="signupPage.step3.postCountrySelectionText" />
            </div>
          </div>
        )}
      </div>
      {isSignupPageWithPrice ? (
        <div style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage
            id={`signupPage.field.paymentDetails.${
              isPartnerSignup ? 'plusOnePart2WithPrice' : 'part2WithPrice'
            }`}
            values={{
              b: chunks => <b>{chunks}</b>,
              price: formatCurrency(
                locale,
                signupPageOffer.priceAmount,
                signupPageOffer.priceCurrency
              ),
              gymCount: gymsCount
                ? gymsCount
                : formatMessage({
                    id: 'signupPage.field.paymentDetails.part2GymCountPlaceHolder',
                  }),
              briefingFee: formatCurrency(locale, 44, 'EUR'),
              name: signupPage.name,
            }}
          />
        </div>
      ) : (
        <div style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage
            id={`signupPage.field.paymentDetails.${
              isPartnerSignup ? 'plusOnePart2WithoutPrice' : 'part2WithoutPrice'
            }`}
            values={{
              b: chunks => <b>{chunks}</b>,
              briefingFee: formatCurrency(locale, 44, 'EUR'),
              gymCount: gymsCount
                ? gymsCount
                : formatMessage({
                    id: 'signupPage.field.paymentDetails.part2GymCountPlaceHolder',
                  }),
              name: signupPage.name,
            }}
          />
        </div>
      )}

      {/*Monthly payment price deduction info*/}
      {signupPageOffer.type !== 'FREE' && (
        <div className="mt-2 mb-2">
          <strong>
            <FormattedMessage
              id={getPaymentDetailsId()}
              values={{
                price: formatCurrency(
                  locale,
                  signupPageOffer.priceAmount,
                  signupPageOffer.priceCurrency
                ),
              }}
            />
          </strong>
        </div>
      )}

      {/*Special condition for PlusOne*/}
      {isPartnerSignup && (
        <div className="mt-4">
          <EdsAlert type="info">
            <FormattedMessage
              id={`signupPage.field.paymentDetails.plusOneSpecialCondition`}
              values={{
                b: chunks => <b>{chunks}</b>,
                ul: chunks => <ul className="special-conditions">{chunks}</ul>,
                li: chunks => <li>{chunks}</li>,
              }}
            />
          </EdsAlert>
        </div>
      )}

      {/*Payment details block*/}
      <div>
        {signupPageOffer.b2cPayment && (
          <h3 className="text-dark pt-4 pb-2">
            <FormattedMessage id="signupPage.field.paymentDetails.title" />
          </h3>
        )}
        {signupPageOffer.type === 'FREE' ? (
          <EdsAlert>
            <FormattedMessage
              id="signupPage.step3.warnAboutOnlineOffering"
              values={{
                br: () => <br />,
                b: chunks => <b>{chunks}</b>,
                ul: chunks => <ul className={style.onlineOfferWarningList}>{chunks}</ul>,
                li: chunks => <li>{chunks}</li>,
                button: text => (
                  <button onClick={() => navigate(-3)} className={'link-button'}>
                    {text}
                  </button>
                ),
                done: () => (
                  <EdsIcon filled className={style.icon}>
                    done
                  </EdsIcon>
                ),
                close: () => (
                  <EdsIcon filled className={style.icon}>
                    close
                  </EdsIcon>
                ),
                gymCount: gymsCount
                  ? gymsCount
                  : formatMessage({
                      id: 'signupPage.field.paymentDetails.part2GymCountPlaceHolder',
                    }),
              }}
            />
          </EdsAlert>
        ) : (
          <div className="row">
            <div className="col">
              <div style={{ whiteSpace: 'pre-line' }}>
                {signupPageOffer.b2cPayment && (
                  <div>
                    <p>
                      <FormattedMessage id="signupPage.field.paymentDetails.paymentAddress" />
                    </p>
                    <p>
                      <FormattedMessage id="signupPage.field.paymentDetails.sepaPayment" />
                    </p>
                    <p>
                      <FormattedMessage id="signupPage.field.paymentDetails.paymentPermission" />
                    </p>
                    <p>
                      {' '}
                      <FormattedMessage id="signupPage.field.paymentDetails.refundClaim" />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {signupPageOffer.b2cPayment && (
          <div>
            <div className="row my-3">
              <div className="col-12 col-sm-6">
                <div className="container">
                  <DefaultTextFormField control={control} controlId="bankAccountHolderName" />
                </div>
              </div>
              <div className="col-12 my-2 d-sm-none" />
              <div className="col-12 col-sm-6">
                <div className="container">
                  <DefaultTextFormField control={control} controlId="iban" />
                </div>
              </div>
            </div>
            <ConsentCheckboxFormField
              control={control}
              controlId="consent"
              signupPage={signupPage}
              signupPageOffer={signupPageOffer}
            />
          </div>
        )}
      </div>

      {/*Additional Information block*/}
      <div>
        {signupPageOffer &&
          (signupPageOffer.employeeInternalIdentifierLabel ||
            signupPageOffer.customTermsDescription) && (
            <>
              <h3 className="text-dark pt-4 pb-2">
                <FormattedMessage id="signupPage.step3.additional" />
              </h3>
              {signupPageOffer.employeeInternalIdentifierLabel && (
                <div className="container">
                  <DefaultTextFormField
                    control={control}
                    controlId="employeeIdentifier"
                    controlLabel={signupPageOffer.employeeInternalIdentifierLabel}
                    placeholder={signupPageOffer.employeeInternalIdentifierLabel}
                    helpTextAfter={signupPageOffer.employeeInternalIdentifierValidationDescription}
                  />
                </div>
              )}
              {signupPageOffer.customTermsDescription && (
                <div className="mt-4">
                  <EdsAlert type="info">{signupPageOffer.customTermsDescription}</EdsAlert>
                </div>
              )}
            </>
          )}

        <p className="text-start text-muted mt-4">
          <FormattedMessage id="general.required" />
        </p>

        <p className="form-text text-muted small mt-4">
          <FormattedMessage
            id="signupPage.field.consent.helpText"
            values={{
              name: signupPage.name,
              termsAndConditionsUrl: (
                <ExternalLink
                  url={formatMessage({ id: 'signupPage.field.consent.termsAndConditionsUrl' })}
                  text={formatMessage({ id: 'signupPage.field.consent.termsAndConditionsText' })}
                  id="signupPage.consent.externalLink.termsAndConditions.helpText"
                  styleClass="unset-font-size"
                />
              ),
              cancellationUrl: (
                <ExternalLink
                  url={formatMessage({ id: 'signupPage.field.consent.cancellationUrl' })}
                  text={formatMessage({ id: 'signupPage.field.consent.cancellationText' })}
                  id="signupPage.consent.externalLink.termsAndConditions.helpText"
                  styleClass="unset-font-size"
                />
              ),
              privacyPolicyUrl: (
                <ExternalLink
                  url={formatMessage({ id: 'signupPage.field.consent.privacyPolicyUrl' })}
                  text={formatMessage({
                    id: 'signupPage.field.consent.privacyPolicyText',
                  })}
                  id="signupPage.consent.externalLink.privacyPolicy.helpText"
                  styleClass="unset-font-size"
                />
              ),
            }}
          />
        </p>
      </div>
    </>
  );
};

export default withSelfSignupStepTemplate(injectIntl(Step3));
