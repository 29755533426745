// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';

type Props = {
  headline?: string,
  textAlign?: 'left' | 'center' | 'right',
  toolTipTextId?: any,
  className?: string,
  intl: IntlShape,
};

const TableHeaderCell = ({
  headline,
  textAlign = 'left',
  toolTipTextId,
  className,
  intl: { formatMessage },
}: Props) => (
  <th
    className={`position-sticky border-0 fw-normal align-middle text-${textAlign} ${className} th-max-width`}
  >
    <div className={`d-flex align-items-center`}>
      {headline && <span>{headline}</span>}
      {toolTipTextId && (
        <Tooltip title={formatMessage({ id: toolTipTextId })} PopperProps={ToolTipProperties}>
          <IconButton>
            <InfoOutlinedIcon style={InfoIconStyle} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  </th>
);

type TableHeaderCellWithTruncationProps = {
  text: string,
  id: string,
};
export const TableHeaderCellWithTruncation = ({ text, id }: TableHeaderCellWithTruncationProps) => (
  <th
    data-testid={id}
    className={`position-sticky border-0 fw-normal align-middle text-left cell-width px-3`}
  >
    <div className={`d-flex align-items-center justify-content-center`}>
      <Tooltip title={text} PopperProps={ToolTipProperties} className="truncate">
        {text}
      </Tooltip>
    </div>
  </th>
);
export default injectIntl(TableHeaderCell);

const InfoIconStyle = {
  width: '16px',
  height: '16px',
  marginLeft: '-5px',
  color: '#000000',
};

export const ToolTipProperties = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -5],
      },
    },
  ],
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#3D3D3D',
    },
  },
};
