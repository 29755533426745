import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'ui/common/modal/Modal';
import CountryDropdown from './CountryDropdown';

type Props = {
  isOpen: boolean,
  countryCode: string,
  onRequestClose: Function,
};

const CountrySelectionModal = ({ isOpen, onRequestClose, countryCode }: Props) => {
  const onCountryChange = code => {
    onRequestClose(code ? code : countryCode);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onCountryChange(undefined)}
      modalSizeClass="modal-lg"
      modalTitle=""
      closeButtonId="countrySelectionModal.btn.close"
    >
      <div className="container">
        <h3 className="text-center text-uppercase fw-lighter">
          <FormattedMessage id="countrySelection.modal.header" />
        </h3>
        <div className="text-start fw-lighter mt-1 mb-1">
          <FormattedMessage id="countrySelection.modal.text" />
        </div>
        <div className="text-start fw-lighter mt-1 mb-1">
          <CountryDropdown value={countryCode} onChange={onCountryChange} />
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(CountrySelectionModal);
